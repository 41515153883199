<template>
    <el-input
        v-model="value"
        :type="showPassword ? 'text' : 'password'"
        @change="onChange"
        v-bind="$attrs"
    >
        <template #prepend>
            <el-button @click="showPassword = !showPassword">
                <el-icon>
                    <View v-if="!showPassword" />
                    <Hide v-else />
                </el-icon>
            </el-button>
        </template>
        <template #append>
            <el-button @click="onGenPassword">{{ $t('bookingList.assignTo.generate') }}</el-button>
        </template>
    </el-input>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { View, Hide } from '@element-plus/icons-vue';

const props = withDefaults(
    defineProps<{
        modelValue: string;
        max: number;
    }>(),
    {
        max: 8,
        modelValue: ''
    }
);

const emit = defineEmits<{
    'update:modelValue': [value: string];
    change: [value: string];
}>();

const value = ref(props.modelValue);
const showPassword = ref(false);
const onGenPassword = () => {
    const str = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const gen = (val: string = ''): string => {
        val += str[parseInt(String(Math.random() * (str.length - 1)))];
        return val.length >= props.max ? val : gen(val);
    };
    value.value = gen();
    onChange();
};

const onChange = () => {
    emit('update:modelValue', value.value);
    emit('change', value.value);
};
</script>
