<template>
    <g-remote-select
        v-model="driver"
        value-key="id"
        clearable
        :apiService="apiService"
        @change="onChange"
    ></g-remote-select>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { userRepositories } from '@/repositories';
import { ITeamMemberInfo } from '@ctypes';

const props = defineProps<{
    modelValue: ITeamMemberInfo;
}>();
const emit = defineEmits<{
    'update:modelValue': [value: ITeamMemberInfo];
    change: [value: ITeamMemberInfo];
}>();

const driver = ref<ITeamMemberInfo>(props.modelValue);

const onChange = () => {
    emit('update:modelValue', driver.value);
    emit('change', driver.value);
};

const apiService: IApiService<ILabelValue<ITeamMemberInfo>[], string> = async (
    keyword?: string
) => {
    const data = await userRepositories.getTeamMemberList({
        name: keyword || '',
        pageNum: 1,
        pageSize: 10
    });
    return data.records.map((item) => ({
        label: `${item.firstName} ${item.lastName}` || '',
        value: item
    }));
};
</script>
