import { post } from '@/repositories/base';
import { ORDER_EMAIL_URL } from '@/common/enums/repositories';

/**
 * 发送unable to confirm邮件
 */
export const sendUnableToConfirmEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/unableToConfirm`
    });
};

/**
 * 发送reject邮件
 */
export const sendRejectedEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/rejected`
    });
};

/**
 * 发送invoice pdf邮件
 */
export const sendInvoicePdfEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/pdf/invoice`
    });
};

/**
 * 发送voucher pdf邮件
 */
export const sendVoucherPdfEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/pdf/voucher`
    });
};

/**
 * 发送new booking邮件
 */
export const sendNewBookingEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/newBooking`
    });
};

/**
 * 发送final邮件
 */
export const sendFinalEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/final`
    });
};

/**
 * 发送confirm邮件
 */
export const sendConfirmedEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/confirmed`
    });
};

/**
 * 发送cancelled邮件
 */
export const sendCancelledEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/cancelled`
    });
};

/**
 * 发送amended邮件
 */
export const sendAmendedEmail = async (orderId: string) => {
    return post<undefined, string>({
        url: `${ORDER_EMAIL_URL}/${orderId}/amended`
    });
};
