<template>
    <div :class="positionClass">
        <div class="mg-r_10" :class="labelPositionClass" :style="labelStyle" v-if="title">
            <g-icon-title :title="title" :icon="icon"></g-icon-title>:
        </div>
        <slot></slot>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { Component } from 'vue';
const props = withDefaults(
    defineProps<{
        title?: string;
        icon?: string | Component;
        labelPosition?: 'left' | 'right' | 'top';
        labelWidth?: number;
    }>(),
    {
        labelPosition: 'left',
        labelWidth: 50
    }
);

const positionClass = computed(() => {
    if (['left', 'right'].includes(props.labelPosition)) {
        return ['flex', 'flex-left'];
    }
    return [];
});
const labelPositionClass = computed(() => {
    if (props.labelPosition === 'left') {
        return ['ta-l'];
    }
    if (props.labelPosition === 'right') {
        return ['ta-r'];
    }
    return [];
});
const labelStyle = computed(() => {
    return {
        width: isNaN(props.labelWidth) ? props.labelWidth : `${props.labelWidth}px`
    };
});
</script>
