<template>
    <el-switch
        inline-prompt
        v-model="isDark"
        :active-icon="Moon"
        :inactive-icon="Sunny"
    ></el-switch>
</template>

<script lang="ts" setup>
import { Sunny, Moon } from '@element-plus/icons-vue';
import { useDark } from '@use';

const { isDark } = useDark();
</script>
