<template>
    <el-tag :class="showStatus" :type="showType">
        {{ showStatus === BookingStatusEnum.COMPLETED ? '✓' : '' }}
        {{ $t(`status.${showStatus}`) }}
    </el-tag>
    <p><el-text v-if="isAmended && isAdmin" type="warning">Amended</el-text></p>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useUserStore } from '@/stores';
import { RoleEnum, BookingStatusEnum, BookingStatusType } from '@enums';

const props = defineProps<{
    status: BookingStatusEnum;
    isAmended?: boolean;
}>();

const { hasRole, isAdmin } = useUserStore();

const showType = computed(() => {
    return ['success', 'info', 'warning', 'danger', ''].includes(
        BookingStatusType[showStatus.value]
    )
        ? BookingStatusType[showStatus.value]
        : '';
});
const showStatus = computed(() => {
    if (hasRole(RoleEnum.Guest).value || hasRole(RoleEnum.Agent).value) {
        if (
            [BookingStatusEnum.NEW, BookingStatusEnum.VERIFIED, BookingStatusEnum.PREBOOK].includes(
                props.status
            )
        ) {
            return BookingStatusEnum.NEW;
        }
        if (
            [
                BookingStatusEnum.FINAL,
                BookingStatusEnum.TOKYO_FINAL,
                BookingStatusEnum.TEHAI
            ].includes(props.status)
        ) {
            return BookingStatusEnum.FINAL;
        }
    }
    return props.status;
});
</script>

<style lang="scss" scoped>
.NOT_PAID,
.NEW {
}
.VERIFIED {
    --el-tag-bg-color: var(--el-color-success-light-9) !important;
}
.CONFIRMED {
    --el-tag-bg-color: var(--el-color-primary-light-8) !important;
}
.FINAL {
    --el-tag-bg-color: var(--el-color-primary-light-3) !important;
    --el-tag-text-color: var(--el-color-white) !important;
}
.TOKYO_FINAL {
    --el-tag-bg-color: var(--el-color-success-light-7) !important;
}
.PARTIALLY_PAID,
.TEHAI {
    --el-tag-bg-color: var(--el-color-success-light-5) !important;
    --el-tag-text-color: var(--el-color-white) !important;
}
.FULLY_PAID,
.COMPLETED {
    --el-tag-bg-color: var(--el-color-success-light-3) !important;
    --el-tag-text-color: var(--el-color-white) !important;
}
.OVERDUE,
.CANCELLED,
.REJECTED {
    color: var(--el-color-error) !important;
}
.PRE_BOOK {
    color: var(--el-color-info) !important;
}
</style>
