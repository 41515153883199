<template>
    <div class="flex flex-column">
        <g-pre-order-tips tip-place="routerChoose" />
        <div class="w_100 pd_10 br_c bs-d bd_1 of-h">
            <g-remote-select
                ref="remoteSelectRef"
                v-model="routerInfo"
                value-key="routeId"
                class="w_100 pd_0"
                :api-service="searchApi"
                :placeholder="$t('bookingOrder.searchRouter.placeholder')"
                @change="onRouterChange"
            >
                <template #prefix>
                    <el-icon size="20"><Search /></el-icon>
                </template>
            </g-remote-select>
        </div>
        <div class="w_100 flex flex-between flex-top mg-y_20 pd-x_20">
            <div class="flex1">
                <g-icon-title :icon="LocationFilled" :title="$t('pickUp')"></g-icon-title>
                <p class="fs_20 fw-b primary">{{ routerInfo.pickUpLocation || '--' }}</p>
            </div>
            <div class="flex1">
                <g-icon-title :icon="LocationFilled" :title="$t('dropOff')"></g-icon-title>
                <p class="fs_20 fw-b primary">{{ routerInfo.dropOffLocation || '--' }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, Ref, inject } from 'vue';
import { ElMessage } from 'element-plus';
import { Search, LocationFilled } from '@element-plus/icons-vue';
import { routerRepositories } from '@/repositories';
import { IRouter } from '@ctypes';
import { useVueI18n } from '@use';
import { useBookingOrderStore } from '@/stores';

const { tJoin } = useVueI18n();
const validate = async () => {
    if (!routerInfo.value.pickUpLocation || !routerInfo.value.dropOffLocation) {
        ElMessage.warning(tJoin('bookingOrder.selectRouter').value);
        return Promise.reject();
    }
    return true;
};

const { routerInfo, amendInfo } = useBookingOrderStore();

const remoteSelectRef = ref<RemoteSelectInstance>();

const searchApi: IApiService<ILabelValue<IRouter>[], string> = async (query = '') => {
    const { records } = await routerRepositories.getSearchRouter({
        pageNum: 1,
        pageSize: 10,
        queries: query
    });
    return records.map((router) => ({
        label: router.routeName,
        value: router
    }));
};

const routerChange = inject<Ref<boolean>>('routerChange');
const onRouterChange = () => {
    if (routerChange) {
        routerChange.value = true;
        amendInfo.value = undefined;
    }
};

defineExpose({
    validate
});
</script>

<style lang="scss" scoped>
::v-deep .el-select {
    .el-input__wrapper {
        box-shadow: none !important;
    }
    .is-focus {
        .el-input__wrapper {
            box-shadow: none !important;
        }
    }
}
::v-deep .remote-select__option {
    padding: 0;
}
.disabled {
    color: var(--el-disabled-text-color);
}
</style>
