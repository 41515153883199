<template>
    <g-remote-select
        v-bind="$attrs"
        v-model="value"
        clearable
        :apiService="apiService"
        value-key="id"
        @change="onChange"
    ></g-remote-select>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { userRepositories } from '@/repositories';
import { RoleEnum } from '@/common/enums';

const props = withDefaults(
    defineProps<{
        modelValue: string;
    }>(),
    {
        modelValue: ''
    }
);
const emit = defineEmits<{
    'update:modelValue': [value: any];
    change: [value: any];
}>();

const value = ref(props.modelValue);
watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue;
    }
);
const onChange = () => {
    emit('update:modelValue', value.value);
    emit('change', value.value);
};

const apiService: IApiService<ILabelValue<any>[], string> = async (name?: string) => {
    console.log(name);
    const { records } = await userRepositories.getCarCompanyAndDriverList({
        pageNum: 1,
        pageSize: 10,
        name: name || ''
    });
    return records.map((item) => ({
        label:
            item.jobTitle === RoleEnum.CarCompany
                ? item.companyName
                : `${item.firstName} ${item.lastName}`,
        value: item.id
    }));
};
</script>
