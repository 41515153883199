<template>
    <form-title :title="$t('guest.name') + $t('details')">
        <g-pre-order-tips tip-place="SNS" />
        <g-option-form
            ref="optionFormRef"
            labelPosition="top"
            :model="guestInfo"
            :options="formOptions"
        ></g-option-form
    ></form-title>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import useDetailOptions from './useDetailOptions';
import FormTitle from './FormTitle.vue';
import { useBookingOrderStore } from '@/stores';

const { getGuestOptions } = useDetailOptions();
const formOptions = getGuestOptions();

const { guestInfo } = useBookingOrderStore();

const optionFormRef = ref<OptionFormInstance>();
const validate = async function <T>(): Promise<T> {
    if (optionFormRef.value) {
        return await optionFormRef.value.validate<T>();
    }
    return Promise.reject();
};

defineExpose({
    validate
});
</script>
