<template>
    <div ref="optionFormRef">
        <el-form
            class="w_100"
            ref="formRef"
            :label-position="labelPosition"
            :model="formModel"
            :rules="(formRules as FormRules)"
            :inline="computedInline"
            v-bind="$attrs"
        >
            <el-form-item
                v-for="(option, index) in computedOptions"
                class="mg-r_0"
                :key="index"
                :prop="option.prop"
                :class="{
                    'pd-x_10': computedInline
                }"
                :style="{
                    width: getWidth(option.width) || widthPercent || 'inherit'
                }"
            >
                <template #label v-if="option.label">
                    <g-icon-title :icon="option.icon" :title="option.label"></g-icon-title>
                </template>
                <slot
                    v-if="option.slot"
                    :name="option.slot"
                    :formModel="formModel"
                    :option="option"
                ></slot>
                <component
                    v-else
                    v-model="formModel[option.prop!]"
                    class="w_100"
                    clearable
                    :is="switchComponent(option.component || 'elInput')"
                    v-bind="option.props"
                    :prop="option.prop"
                    :placeholder="
                        option.placeholder ? toValue(option.placeholder) : toValue(option.label)
                    "
                    @change="(value: any) => onChange(value, option)"
                ></component>
            </el-form-item>
        </el-form>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch, computed, toValue, onMounted } from 'vue';
import { ElForm } from 'element-plus';
import type { FormRules } from 'element-plus';
import { useResize, useForm, useOptions } from '@use';

const props = withDefaults(
    defineProps<{
        model: Record<string, unknown>;
        options: IFormOption[];
        colNum?: number;
        inline?: boolean;
        minColWidth?: number;
        labelPosition?: 'top' | 'right' | 'left';
    }>(),
    {
        model: () => ({}),
        options: () => [],
        colNum: 1,
        minColWidth: 150,
        labelPosition: 'top'
    }
);
const emit = defineEmits<{
    change: [value: any];
}>();

const formRef = ref<InstanceType<typeof ElForm>>();
const formModel = ref<Record<string, any>>({});
const formRules = ref<Record<string, IFormItemRule[]>>({});
const initFormModel = () => {
    formRef.value?.resetFields();
    props.options.forEach((option) => {
        const { prop, rules } = option;
        if (prop) {
            formModel.value[prop!] = props.model[prop!] ?? undefined;
            if (rules) {
                formRules.value[prop!] = rules;
            }
        }
    });
};
const computedOptions = computed(() => {
    return props.options.filter((option) => {
        if (option.showAction) {
            return option.showAction(formModel.value);
        }
        return true;
    });
});
initFormModel();
watch(
    () => props.model,
    () => {
        initFormModel();
    }
);

const { switchComponent } = useOptions();

const onChange = (value: any, option: IFormOption) => {
    if (option.action) {
        option.action(formModel.value);
    }
    emit('change', formModel.value);
};
const validate = async function <T>(): Promise<T> {
    if (formRef.value) {
        const valid = await formRef.value.validate();
        const params = { ...formModel.value };
        Object.entries(params).forEach(([key, value]) => {
            const find = computedOptions.value.find((option) => option.prop === key);
            if (find && find.handleParam && value) {
                const newValue = find.handleParam(value, formModel.value);
                if (Object.prototype.toString.call(newValue) === '[object Object]') {
                    Object.assign(params, newValue);
                    delete params[key];
                } else {
                    params[key] = newValue;
                }
            }
        });
        if (valid) {
            return params as T;
        }
    }
    return Promise.reject();
};

const optionFormRef = ref<HTMLElement>();
const { width, height, getSize } = useResize(optionFormRef);
onMounted(() => {
    getSize();
});
const getWidth = (width?: string | number) => {
    if (/^\d+$/.test(String(width))) {
        return `${width}px`;
    }
    return width;
};
const widthPercent = computed(() => {
    if (props.colNum && computedInline.value) {
        return `${100 / props.colNum}%`;
    }
    return null;
});

const computedInline = computed(() => {
    // if (width.value && width.value / props.colNum < props.minColWidth) {
    //     return false;
    // }
    return props.inline;
});

const { setFormContext } = useForm();
setFormContext(formRef);

defineExpose({
    validate,
    formModel,
    formRef,
    width,
    height
});
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
    .el-form-item__label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
</style>
