<template>
    <g-pre-order-tips tip-place="serviceDate" />
    <g-pre-order-tips tip-place="people" />
    <g-option-form
        ref="optionFormRef"
        :model="peopleDateInfo"
        :options="formOptions"
        :inline="true"
        :colNum="4"
        @change="onChange"
    ></g-option-form>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { useVueI18n, useOptions } from '@use';
import { useBookingOrderStore } from '@/stores';
import { IPeopelDate } from '@ctypes';
import { getJPTimestamp, getJPDate } from '@utils';

const { t, tJoin } = useVueI18n();
const { getBaseProps } = useOptions();
const { peopleDateInfo, routerInfo, pickUpDetail, dropOffDetail, amendOrderId } =
    useBookingOrderStore();
const disabledDate = (date: Date) => {
    const { availableStartTime, availableEndTime } = routerInfo.value;
    return !isInValidityPeriod(date, availableStartTime, availableEndTime);
};
watch(routerInfo, () => {
    const { serviceDate } = peopleDateInfo.value;
    pickUpDetail.value = {};
    dropOffDetail.value = {};
    noticeMsg();
    if (serviceDate) {
        const { availableStartTime, availableEndTime } = routerInfo.value;
        if (!isInValidityPeriod(serviceDate, availableStartTime, availableEndTime)) {
            if (optionFormRef.value) {
                optionFormRef.value.formModel.serviceDate = '';
            }
        }
    }
});

const noticeMsg = () => {
    ElMessage.closeAll('warning');
    let { availableStartTime, availableEndTime } = routerInfo.value;
    const offset = new Date().getTimezoneOffset() / 60;
    if (availableStartTime && availableEndTime) {
        availableStartTime += (9 + offset) * 60 * 60 * 1000;
        availableEndTime += (9 + offset) * 60 * 60 * 1000;
        const startDate = new Date(availableStartTime);
        const endDate = new Date(availableEndTime);
        ElMessage.warning({
            message: t('bookingOrder.hasStartAndEnd', {
                startYear: startDate.getFullYear(),
                startMonth: startDate.getMonth() + 1,
                startDay: startDate.getDate(),
                endYear: endDate.getFullYear(),
                endMonth: endDate.getMonth() + 1,
                endDay: endDate.getDate()
            }).value,
            duration: 5000
        });
    } else if (availableStartTime && !availableEndTime) {
        console.log(availableStartTime);
        availableStartTime += (9 + offset) * 60 * 60 * 1000;
        console.log(availableStartTime);
        const startDate = new Date(availableStartTime);
        ElMessage.warning({
            message: t('bookingOrder.hasStartNoEnd', {
                startYear: startDate.getFullYear(),
                startMonth: startDate.getMonth() + 1,
                startDay: startDate.getDate()
            }).value,
            duration: 5000
        });
    } else if (!availableStartTime && availableEndTime) {
        const endDate = new Date(availableEndTime);
        ElMessage.warning({
            message: t('bookingOrder.hasStartNoEnd', {
                endYear: endDate.getFullYear(),
                endMonth: endDate.getMonth() + 1,
                endDay: endDate.getDate()
            }).value,
            duration: 5000
        });
    }
};

const isInValidityPeriod = (
    currDate: Date | string | number,
    availableStartTime?: string,
    availableEndTime?: string
) => {
    // if (amendOrderId.value) {
    //     return true;
    // }
    if (!availableStartTime && !availableEndTime) {
        // 如果起止有效期都为空，则一直有效
        return true;
    }
    if (!currDate) {
        return true;
    }
    const isIn = (start: string, end: string) => {
        const currDateTime = new Date(currDate).getTime();
        const startTime = start ? new Date(start).getTime() : '';
        const endTime = end ? new Date(end).getTime() : '';
        if (startTime && endTime) {
            return currDateTime >= startTime && currDateTime <= endTime;
        }
        if (startTime && !endTime) {
            return currDateTime >= startTime;
        }
        if (!startTime && endTime) {
            return currDateTime <= endTime;
        }
    };
    if (availableStartTime && !availableEndTime) {
        // 若存在有效期起，不存在有效期止，则有效期为有效期起
        return isIn(availableStartTime, '');
    }
    if (!availableStartTime && availableEndTime) {
        // 若不存在有效期起，存在有效期止，则有效期为至有效期止
        return isIn('', availableEndTime);
    }
    if (availableStartTime && availableEndTime) {
        if (availableStartTime <= availableEndTime) {
            // 若存在有效期起，存在有效期止，并且有效期起小于等于有效期止，则有效期为有效期起至有效期止
            return isIn(availableStartTime, availableEndTime);
        } else {
            // 若存在有效期起，存在有效期止，并且有效期起大于有效期止，则配置无效，默认有效
            return true;
        }
    }
    return true;
};
const formOptions: IFormOption[] = [
    {
        label: t('serviceDate'),
        icon: '',
        prop: 'serviceDate',
        rules: [{ required: true, message: tJoin('pleaseSelect', 'serviceDate') }],
        // component: 'gDatePickerJP',
        component: 'elDatePicker',
        props: {
            disabledDate: disabledDate
        },
        placeholder: tJoin('serviceDate')
    },
    {
        label: tJoin('adults', '(12+)'),
        icon: '',
        prop: 'adultCount',
        rules: [
            { required: true, message: tJoin('pleaseSelect', 'adults') },
            {
                type: 'number',
                min: 1,
                message: tJoin('pleaseSelect', 'adults'),
                trigger: 'blur'
            }
        ],
        component: 'elInputNumber'
    },
    {
        label: tJoin('child', '(2-11)'),
        icon: '',
        prop: 'childCount',
        component: 'elInputNumber'
    },
    {
        label: tJoin('infants', '(<2)'),
        icon: '',
        prop: 'infantCount',
        component: 'elInputNumber'
    }
];

const onChange = (formData: IPeopelDate) => {
    peopleDateInfo.value.serviceDate = getJPTimestamp(formData.serviceDate);
};

const optionFormRef = ref<OptionFormInstance>();
const validate = async (): Promise<IPeopelDate> => {
    if (optionFormRef.value) {
        const formData = await optionFormRef.value.validate<IPeopelDate>();
        peopleDateInfo.value = formData;
        return formData;
    }
    return Promise.reject();
};

defineExpose({
    validate
});
</script>
