<template>
    <div>
        <p v-if="hasPickUpTime"><el-text Light>Departure</el-text></p>
        <p v-if="!hasPickUpTime"><el-text>Arrival</el-text></p>
        <el-dropdown v-if="hasPickUpTime && showFile">
            <el-button type="success" size="small" link>
                <el-icon><Document /></el-icon>
                Departure Notice
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="onDownloadDeparture('EN')">{{
                        $t('preview.en')
                    }}</el-dropdown-item>
                    <el-dropdown-item @click="onDownloadDeparture('JP')">{{
                        $t('preview.jp')
                    }}</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { Document, Loading } from '@element-plus/icons-vue';
import { pdfRepositories } from '@/repositories';
import { useVueI18n } from '@use';
import { RouterTypeEnum } from '@enums';
import { IBooking } from '@/common/types';

const props = defineProps<{
    id: string | number;
    data: IBooking;
    showFile?: boolean;
}>();

const hasPickUpTime = computed(() => {
    if (
        [RouterTypeEnum.AIRPORT, RouterTypeEnum.STATION, RouterTypeEnum.PORT].includes(
            props.data.pickUpLocationType
        )
    ) {
        return false;
    }
    if (
        [RouterTypeEnum.AIRPORT, RouterTypeEnum.STATION, RouterTypeEnum.PORT].includes(
            props.data.dropOffLocationType
        )
    ) {
        return true;
    }
    if ([RouterTypeEnum.CITY, RouterTypeEnum.ACTIVITY].includes(props.data.pickUpLocationType)) {
        return true;
    }
    return true;
});

const { tJoin } = useVueI18n();
const isLoading = ref(false);
const onDownloadDeparture = async (language: 'EN' | 'JP') => {
    if (props.id && !isLoading.value) {
        isLoading.value = true;
        try {
            ElMessage.warning({
                message: tJoin('bookingList.downloadDepartureNotice').value,
                type: 'warning',
                icon: Loading,
                duration: 0,
                customClass: 'el-message--warning sign-out__message'
            });
            await pdfRepositories.downloadDepartureNoticePDF(
                `${props.id}`,
                language,
                `Departure Notice_${new Date(props.data.serviceDate).format('YYYYMMDD')}_${
                    props.data.uniqueReferenceNo
                }_${new Date().format('YYYY-MM-DD HH_mm_ss')}`
            );
        } catch (e) {
            console.log(e);
        }
        ElMessage.closeAll('warning');
        isLoading.value = false;
    }
};
</script>

<style lang="scss">
.sign-out__message {
    .el-icon {
        animation: rotateCircle 2s infinite linear;
    }
}
</style>
