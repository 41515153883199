import { get, post, put } from '@/repositories/base';
import { ORDER_URL } from '@/common/enums/repositories';
import {
    IBookingOrder,
    IBooking,
    IBookingLog,
    IPayment,
    IInvoiceEdit,
    IAssignTo,
    IColChange,
    IEmail
} from '@ctypes';
import { BookingStatusEnum } from '@enums';

/**
 * 创建订单
 */
export const postNewBooking = async (data: IBookingOrder) => {
    return post<IBookingOrder, string[]>({
        url: ORDER_URL,
        data
    });
};

/**
 * 获取订单列表
 */
export const getBookingList = async (data: IPage) => {
    return get<IPage, IListResponse<IBooking>>({
        url: `${ORDER_URL}/bookings`,
        data
    });
};

/**
 * 根据id查询订单
 */
export const getBookingById = async (orderId: string) => {
    return get<string, IBooking>({
        url: `${ORDER_URL}/${orderId}`
    });
};

/**
 * 获取订单列表invoice
 */
export const getInvoiceList = async (data: IPage) => {
    return get<IPage, IListResponse<IBooking>>({
        url: `${ORDER_URL}/invoices`,
        data
    });
};

/**
 * 验证字段
 */
export const verifyBookingFields = async (id: string, key: string, value: boolean) => {
    return put<IKeyValue<boolean>, boolean>({
        url: `${ORDER_URL}/${id}/verify`,
        data: {
            key,
            value
        }
    });
};

/**
 * 修改invoice
 */
export const editInvoice = async (id: string, data: Partial<IInvoiceEdit>) => {
    return put<Partial<IInvoiceEdit>, boolean>({
        url: `${ORDER_URL}/${id}/invoice/edit`,
        data
    });
};

/**
 * 更改订单状态
 */
export const updateOrderStatus = async (
    orderId: string,
    status: BookingStatusEnum,
    reason?: string
) => {
    return put<{ reason?: string }, boolean>({
        url: `${ORDER_URL}/${orderId}/status/${status}`,
        data: {
            reason
        }
    });
};

/**
 * 更新订单全量
 */

export const updateOrderAll = async (orderId: string, data: IBookingOrder) => {
    return put<IBookingOrder, IBooking>({
        url: `${ORDER_URL}/${orderId}/edit`,
        data
    });
};

/**
 * 更新订单某个值
 */

export const updateOrder = async (
    orderId: string,
    name: string,
    value: any,
    isSendEmail = false,
    fieldName = ''
) => {
    return put<{ name: string; value: any }, boolean>({
        url: `${ORDER_URL}/${orderId}?isSendEmail=${isSendEmail}&fieldName=${fieldName}`,
        data: {
            name,
            value
        }
    });
};

/**
 * 取消订单
 */
export const cancelOrder = async (orderId: string) => {
    return put({
        url: `${ORDER_URL}/${orderId}/cancel`
    });
};

/**
 * assign to订单
 */
export const assignToOrder = async (orderId: string, data: IAssignTo) => {
    return put<IAssignTo, boolean>({
        url: `${ORDER_URL}/${orderId}/assign`,
        data
    });
};

/**
 * 获取订单操作日志
 */

export const getOrderLog = async (orderId: string) => {
    return get<undefined, IBookingLog[]>({
        url: `${ORDER_URL}/${orderId}/log`
    });
};

/**
 * 查看某个字段的历史记录
 */
export const getColChangeLog = async (orderId: string, colName: string) => {
    return get<undefined, IColChange[]>({
        url: `${ORDER_URL}/${orderId}/col/${colName}`
    });
};

/**
 * 获取支付信息列表
 */
export const getPaymentList = async (orderId: string) => {
    return get({
        url: `${ORDER_URL}/${orderId}/payment`
    });
};

/**
 * 新增支付信息
 */
export const addPayment = async (orderId: string, data: Partial<IPayment>) => {
    return post({
        url: `${ORDER_URL}/${orderId}/payment`,
        data
    });
};

/**
 * 更新订单邮件信息
 */
export const updateEmailInfo = async (
    orderId: string,
    type: 'Invoice' | 'Voucher',
    data: Partial<IEmail>
) => {
    return put<Partial<IEmail>, boolean>({
        url: `${ORDER_URL}/email/${orderId}/${type}`,
        data
    });
};

/**
 * 获取邮件信息
 */
export const getEmailInfo = async (orderId: string, type: 'Invoice' | 'Voucher') => {
    return get<undefined, IEmail>({
        url: `${ORDER_URL}/email/html/${orderId}/${type}`
    });
};

/**
 * 车公司确认订单
 */
export const carCompanyConfirmOrder = async (
    orderId: string,
    data: {
        driverAcceptStatus: 'ACCEPT' | 'REJECT';
        driverPickUpTime?: string;
        driverReason?: string;
    }
) => {
    return put({
        url: `${ORDER_URL}/${orderId}/accept/status`,
        data
    });
};
