<template>
    <el-select
        class="w_100"
        v-model="value"
        v-bind="$attrs"
        filterable
        remote
        :remote-method="remoteMethod"
        @change="onChange"
    >
        <template #prefix v-if="slots.prefix">
            <slot name="prefix"></slot>
        </template>
        <template v-for="(item, index) in options" :key="index">
            <el-option
                v-if="!$slots.option"
                class="remote-select__option"
                :label="item.label"
                :value="item.value"
            >
                {{ item.label }}
            </el-option>
            <slot v-else name="option" :option="item"></slot>
        </template>
    </el-select>
</template>

<script lang="ts" setup>
import { ref, useSlots, watch } from 'vue';

const props = withDefaults(
    defineProps<{
        modelValue?: string | number | boolean | object;
        apiService: IApiService<any, any>;
    }>(),
    {
        apiService: () => Promise.resolve([])
    }
);
const emit = defineEmits<{
    (e: 'update:modelValue', value: undefined | string | number | boolean | object): void;
    (e: 'change', value: undefined | string | number | boolean | object): void;
}>();

const slots = useSlots();

const value = ref(props.modelValue);

watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue;
    }
);
const options = ref<ILabelValue<any>[]>([]);

const onChange = () => {
    emit('update:modelValue', value.value);
    emit('change', value.value);
};

const remoteMethod = async (query: string = '') => {
    if (props.apiService instanceof Function) {
        options.value = await props.apiService(query);
    }
};
remoteMethod('');
defineExpose({
    remoteMethod
});
</script>
