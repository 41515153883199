<template>
    <div v-if="isAssignTo">
        <el-button v-if="companyName" type="primary" @click="onClick" link>
            {{ companyName }}
        </el-button>
        <el-dropdown>
            <el-button class="mg-l_0" size="small" type="success" link>
                <g-icon type="icon-car-fill"></g-icon>
                Haisha Tehaisho
            </el-button>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="onPreview('EN')">
                        {{ $t('preview.preview') }} {{ $t('preview.en') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="onPreview('JP')">
                        {{ $t('preview.preview') }} {{ $t('preview.jp') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="onDonwload('EN')">
                        {{ $t('preview.download') }} {{ $t('preview.en') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click="onDonwload('JP')">
                        {{ $t('preview.download') }} {{ $t('preview.jp') }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
        <el-dialog destroy-on-close append-to-body v-model="showPreview" width="800">
            <iframe class="preview-iframe w_100" :src="pdfUrl"></iframe>
        </el-dialog>
    </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { Loading } from '@element-plus/icons-vue';
import { pdfRepositories } from '@/repositories';
import { useRouter, useVueI18n } from '@use';
import { IBooking } from '@/common/types';
import { baseUrl } from '@utils';
const props = defineProps<{
    companyName?: string;
    data: IBooking;
    isAssignTo: boolean;
    id?: string | number;
}>();

const { Router, ROUTER_PATH_MAP } = useRouter();
const onClick = () => {
    if (props.id) {
        Router.push(`${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${props.id}`);
    }
};

const driverAcceptStatus = computed(() => {
    if (props.data) {
        if (props.data.driverAcceptStatus === 'ACCEPT') {
            return 'OK';
        } else if (props.data.driverAcceptStatus === 'REJECT') {
            return 'NG';
        }
    }
    return 'PENDING';
});

const { tJoin } = useVueI18n();
const isLoading = ref(false);
const onDonwload = async (language: 'EN' | 'JP' = 'EN') => {
    if (props.id && !isLoading.value) {
        isLoading.value = true;
        try {
            ElMessage.warning({
                message: tJoin('bookingList.downloadHaishaTip').value,
                type: 'warning',
                icon: Loading,
                duration: 0,
                customClass: 'el-message--warning sign-out__message'
            });
            await pdfRepositories.downloadHaishaTehaishoPDF(
                `${props.id}`,
                language,
                true,
                `Haisha Tehaisho_${new Date(props.data.serviceDate).format('YYYYMMDD')}_${
                    props.data.uniqueReferenceNo
                }_${new Date().format('YYYY-MM-DD HH_mm_ss')}`
            );
        } catch (e) {
            console.log(e);
        }
        ElMessage.closeAll('warning');
        isLoading.value = false;
    }
};
const language = ref('');
const showPreview = ref(false);
const pdfUrl = computed(() => {
    if (props.id) {
        return `${baseUrl}${pdfRepositories.previewHaishaTehaishoPDF(`${props.id}`)}?language=${
            language.value
        }#toolbar=1`;
    }
    return '';
});
const onPreview = (lang: 'EN' | 'JP' = 'EN') => {
    showPreview.value = true;
    language.value = lang;
};
</script>

<style lang="scss">
.sign-out__message {
    .el-icon {
        animation: rotateCircle 2s infinite linear;
    }
}
.preview-iframe {
    border: none;
    height: 700px;
    margin: 0 auto;
}
</style>
