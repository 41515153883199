<template>
    <div class="w_80 mg-x_a flex flex-column">
        <el-button class="mg-b_10" round @click="go(-1)">close preview</el-button>
        <preview class="w_100" :type="(params.type as any)"></preview>
    </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from '@use';
import { useBookingStore } from '@/stores';
import Preview from '@/components/preview/Preview.vue';

const { go } = useRouter();
const { params } = useRoute();
const { getCurrBooking } = useBookingStore();
getCurrBooking();
</script>
