const en = {
    bookingOrder: {
        searchRouter: {
            placeholder: 'Enter destination and search'
        },
        selectRouter: 'Please select destination',
        noOfVehicle: 'No.Of Vehicle',
        cancelPolicy: 'Important! Please view our Cancellation Policy!',
        newBooking: 'New Booking',
        AmendBooking: 'Amend Booking',
        hasStartAndEnd:
            'This product can only be booked during the period from {startYear}, {startMonth}, {startDay} to {endYear}, {endMonth}, {endDay}',
        hasStartNoEnd: 'This product can only be booked from {startYear}, {startMonth}, {startDay}',
        noStartHasEnd: 'This product will stop booking until {endYear}, {endMonth}, {endDay}'
    },
    status: {
        NEW: 'New',
        PRE_BOOK: 'PreBook',
        VERIFIED: 'Verified',
        CONFIRMED: 'Confirmed',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        FINAL: 'Final',
        TOKYO_FINAL: 'Tokyo Final',
        TEHAI: 'Tehai',
        COMPLETED: 'Completed',
        OVERDUE: 'Over Due',
        DRAFT: 'Draft',
        NOT_PAID: 'Not Paid',
        PARTIALLY_PAID: 'Partially Paid',
        FULLY_PAID: 'Fully Paid',
        MALE: 'male',
        FEMALE: 'female'
    },
    bookingList: {
        title: 'Booking List',
        status: 'Status',
        agentAndClient: 'Agent/Client',
        client: 'Client',
        referenceNoAgent: 'Reference_No_Agent',
        referenceNo: 'Reference No',
        rawOurRef: 'raw_Our_Ref',
        uniqueReferenceNo: 'Reference No.',
        guestName: 'Guest Name',
        guestInfo: 'Guest Info',
        bookedDate: 'Booked Date',
        updateDate: 'Update Date',
        pickUpAirportCityAttraction: 'Pick Up Airport/ City/ Attraction',
        dropOffAirportCityAttraction: 'Drop Off Airport/ City/ Attraction',
        type: 'Type of car',
        vehicleCount: 'No. of Vehicle',
        destinationInfo: 'Destination Info',
        arrivalAndDeparture: 'Arrival/Departure',
        serviceDate: 'Service Date',
        pickUpFrom: 'Pick Up From',
        pickUpAddress: 'Pick Up Address',
        ETA: 'ETA',
        dropOff: 'Drop Off',
        dropOffAddress: 'Drop Off Address',
        ETD: 'ETD',
        adult: 'Adult',
        child: 'Child',
        infant: 'Infant',
        payment: 'Payment',
        pickUpTime: 'Pick Up Time',
        transportInfo: 'Transport Info',
        driverInfo: 'Driver Info',
        remark: 'Remark',
        remarkTokyo: 'Car company communication',
        remarkKL: 'inter office Remarks',
        assignedTo: 'Assigned To',
        creator: 'Creator',
        operation: {
            cancel: 'Cancel',
            assignTo: 'Assign To'
        },
        assignTo: {
            status: 'Status',
            carCompany: 'Car Company',
            driver: 'Driver',
            terminal: 'Terminal',
            assignTo: 'Assign to',
            transportInfo: 'Transport Info',
            pickUpTime: 'Pick Up Time',
            meetingPoint: 'Meeting Point',
            note: 'Note',
            sayHello: 'いつもお世話になっております。',
            generate: 'Generate',
            international: 'International',
            internationalT1: 'International_T1',
            internationalT2: 'International_T2',
            internationalT3: 'International_T3',
            domestic: 'Domestic',
            domesticT1: 'Domestic_T1',
            domesticT2: 'Domestic_T2',
            domesticT3: 'Domestic_T3',
            emptyTip: 'Please select at least one item'
        },
        verifyFields: {
            verifyFields: 'Verify Fields',
            fieldName: 'Field Name',
            fieldValue: 'Field Value',
            verified: 'Verified?',
            newFieldValue: 'New Field Value',
            name: 'field',
            canNotVerify: 'Field cannot be validated in current status'
        },
        changeStatus: {
            confimrChange: 'Are you sure to change the status to {status}?'
        },
        cancelConfirmInfo: 'Are you sure to cancel this booking?',
        downloadHaishaTip: 'Downloading Haisha Tehaisho',
        downloadDepartureNotice: 'Downloading Departure Notice',
        assigned: 'Assign To',
        searchData: 'Search Data',
        amendVerified: 'Amended Verified',
        amendedTip: 'The order has been Amended by the agent/client, please verify again',
        rejectReason: 'Rejected Reason',
        rejectReasonPlaceholder: "Fully booked, time doesn't suit",
        serviceTime: 'Available service time',
        driverConfirm: 'Check'
    },
    invoiceList: {
        title: 'Invoices/Vouchers',
        status: 'Invoice Status',
        billDate: 'Bill Date',
        dueDate: 'Due Date',
        billTo: 'Bill To',
        totalPurchase: 'Total Purchase',
        balanceDue: 'Balance Due',
        paymentReceived: 'Payment Received',
        voucherSendDate: 'Voucher Send Date',
        voucherSendStatus: 'Voucher Send Status',
        taxCommissior: 'TAX/Commissior',
        invoiceNote: 'Note',
        addItems: 'Add_items'
    },
    paymentList: {
        title: 'Payment List',
        payMethod: 'Payment Method',
        payDate: 'Payment Date',
        amount: 'Amount',
        note: 'Note',
        invoiceDetail: {
            repeatSendinvoice: 'Are you sure to send the invoice email again?',
            repeatSendvoucher: 'Are you sure to send the voucher email again?'
        }
    },
    role: {
        Staff: 'Staff',
        Driver: 'Driver',
        CarCompany: 'CarCompany',
        Agent: 'Agent',
        Guest: 'Guest',
        Operator: 'Operator',
        SubAdmin: 'Admin',
        SuperAdmin: 'SuperAdmin'
    },
    preview: {
        preview: 'Preview',
        invoice: 'Preview Invoice',
        voucher: 'Preview Voucher',
        notice: 'Preview Departure Notice',
        editInvoice: 'Edit Invoice',
        editVoucher: 'Edit Voucher',
        download: 'Download',
        email: 'Email',
        emailInvoice: 'Email Invoice',
        emailVoucher: 'Email Voucher',
        emailDepartureNotice: 'Email Departure Notice',
        downloadInvoice: 'Download Invoice',
        downloadVoucher: 'Download Voucher',
        downloadDepartureNotice: 'Download Departure Notice',
        sendingInvoice: 'Sending Invoice Email',
        sendingVoucher: 'Sending Voucher Email',
        sendingAmended: 'Sending Amended Email',
        confirmSendAmended: 'Do you want to send Amended email',
        sendSuccess: 'Sending Success',
        en: 'English',
        jp: 'Japanese'
    },
    airport: {
        departureDate: 'Departure Date (flight departure date) ',
        departureDatePlaceholder: 'Flight date to Japan',
        departureDatePlaceholder2: 'Flight date from Japan',
        name: 'Airport Name ',
        filght: 'Flight Number (e.g. D7522, JL88) ',
        filghtPlaceholder: 'D7522',
        filghtDetail:
            'Flight Details: Departure & Arrival Airport Name and ETD/ETA(e.g. KUL/HND/1430/2230) '
    },
    port: {
        name: 'Port Name ',
        cruiseName: 'Cruise Line/Name (e.g. Diamond Princess) ',
        cruiseNamePlaceholder: 'Diamond Princess',
        cruiseTime: 'Cruise Arrival/Departure Time'
    },
    activity: {
        name: 'Activities Name ',
        pickUpTime: 'Pick UP time'
    },
    station: {
        name: 'Station Name ',
        trainName: 'Train Name ',
        trainNumber: 'Train Number ',
        route: 'Route'
    },
    city: {
        name: 'City Name ',
        hotelSearch: 'Hotel Search',
        hotelSearchPlaceholder: 'Key in to search your hotel',
        info: 'Hotel Name/AirBnB Apt Name',
        infoPlaceholder: 'e.g. Keihan Hotel, Sakura Share House, etc.',
        detail: 'Hotel Address/AirBnB Owner Contact and Tel No.',
        detailPlaceholder: 'e.g. 5-1-2 Ginza, Chuo-ku, Tokyo Tel: +81-2-1234-567)'
    },
    guest: {
        name: 'Guest ',
        detailName: 'Guest Name(as per passport) & Child age during travel date',
        SNS: 'SNS (WhatsApp, Line, Messenger, telephone number) ',
        SNSPlaceholder: 'phone number: +81-90-1111-2222',
        agentOwnReferenceNo: 'Agent Own Reference No ',
        remarks: 'Remarks'
    },
    userInfo: {
        company: 'Company',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        gender: 'Gender',
        password: 'Password',
        retype: 'Retype',
        createYourBookingAccount: 'Create Your Booking Account',
        myProfile: 'My Profile',
        city: 'City ',
        country: 'Country',
        phone: 'Phone',
        alternativePhone: 'Alternative Phone',
        birthTime: 'Date of birth',
        branchName: 'Branch Name',
        createTime: 'Create Time',
        generalInfo: 'General Info'
    },
    login: {
        repasswordError: 'The two passwords do not match',
        chooseCharacter: 'choose your character',
        rechoose: 'Re-Choose',
        agreeText: 'I have read and agreed to the ',
        termsAndConditions: 'terms and conditions',
        forgotPassword: 'Forgot password',
        forgotPasswordTip: `You don't have an account?`,
        passwordFormat: 'The password must be large with 6 characters',
        sendResetPasswordEmail: 'A password reset email has been sent, please check.',
        resetPasswordSuccess: 'Password reset successful',
        resetPassword: 'Reset Password',
        newPassword: 'New Password'
    },
    http: {
        unknownMistake: 'unknown mistake',
        apiNotFound: 'api not found',
        serviceError: 'service error'
    },
    remark: {
        tokyoRemarks: 'Remark Tokyo',
        elRemarks: 'Remark KL'
    },
    table: {
        order: 'Order ',
        orderFields: 'Order Fields',
        operations: 'Operations',
        addOne: 'Add One',
        edit: 'Edit',
        emptyDataTip: 'Please select at least one piece of data',
        downloadAll: 'Download all',
        downloadBatch: 'Batch download'
    },
    teamMember: {
        jobTitle: 'Job Title',
        joinedDate: 'Joined Date',
        city: 'City ',
        country: 'Country',
        phone: 'Phone',
        mainCompany: 'Company(Main)',
        branchName: 'Branch Name'
    },
    meetingPoint: {
        title: 'Meeting point',
        locationName: 'Location Name',
        placeName: 'Place Name',
        details: 'Details'
    },
    hotel: {
        title: 'Hotel',
        hotel: 'Hotel',
        hotelJp: 'Hotel(jp)',
        address: 'Address',
        addressJp: 'Address(jp)',
        tel: 'Tel',
        fax: 'Fax',
        email: 'Email'
    },
    agent: {
        title: 'Agents/Clients',
        agent: 'Agent ',
        primaryContact: 'Primary Contact',
        phone: 'Phone',
        joinedDate: 'Joined Date',
        clientGroups: 'Client Groups',
        paymentReceived: 'Payment Received',
        due: 'Due',
        refCode: 'Ref.Code',
        travelLicence: 'Travel Licence',
        contacts: 'Contacts',
        agentInfo: 'Agent/Client Info',
        invoices: 'Invoices',
        payments: 'Payments',
        totalPurchase: 'Total Purchase'
    },
    agentInfo: {
        companyName: 'Company Name',
        address: 'Address',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        country: 'Country',
        phone: 'Phone',
        website: 'Website',
        clientGroups: 'Client groups',
        refCode: 'Ref. Code',
        travelLicence: 'Travel licence'
    },
    router: {
        title: 'Destination',
        availableTimeTitle: 'Set All Destination Expiry Dates',
        import: 'import by excel',
        checkPrice: 'Check price',
        routeId: 'Destination Id',
        routeName: 'Destination Name',
        routeNameJp: 'Destination Name(JP) ',
        pickUpLocation: 'Pick Up Location',
        pickUpLocationJp: 'Pick Up Location(JP)',
        pickUpLocationType: 'Pick Up Location type',
        dropOffLocation: 'Drop Off Location',
        dropOffLocationJp: 'Drop Off Location(JP)',
        dropOffLocationType: 'Drop Off Location type',
        gc: 'GV',
        validityPeriod: 'validity period',
        availableStart: 'Valid from(Japan date)',
        availableEnd: 'Expiry date(Japan date)',
        CharterVanTaxi: 'Charter Van Taxi',
        LIMOGrandSedan: 'LIMO Grand Sedan',
        LIMOGrandMPV: 'LIMO Grand MPV',
        CharterSedanTaxi: 'Charter Sedan Taxi',
        CharterMPVTaxi: 'Charter MPV Taxi',
        LIMOGrandVan: 'LIMO Grand Van',
        SharedTransfer: 'Shared Transfer',
        priceTip: 'Please input price',
        errorPriceTip: 'price only number',
        category: 'category',
        categoryPlaceholder: 'please enter category by price',
        importRouter: 'Import Router Data',
        importMode: 'Import mode',
        replaceAll: 'replace all',
        updateRouter: 'update router',
        fileDrag: 'Drag files here or',
        fileClick: 'Click to upload file',
        onlyExcel: 'Only supports Excel files (.xlsx)',
        downloadFile: 'Click to download the template file',
        priority: 'Sorting Priority',
        noChooseExcel: 'Please select the Excel file first.',
        emptyPrice: 'The category and price must not be empty.',
        addRouterEmptyPrice: 'Please create at least one price data entry.'
    },
    guestAgentList: {
        name: 'Name',
        email: 'Email',
        gender: 'Gender',
        createTime: 'CreateTime',
        bookingFor: 'Booking For',
        cardTitle: 'Booking For Guest/Agent',
        stepOne: 'Choose a Guest/Agent',
        stepTwo: 'New Booking'
    },
    userMenu: {
        myProfile: 'My Profile',
        signOuting: 'Signing Out'
    },
    lang: {
        en: 'English',
        zhCn: 'Chinese',
        jp: 'Japanese'
    },
    fontSize: {
        sm: 'Small',
        base: 'Normal',
        lg: 'Large'
    },
    system: {
        reason: 'Reason',
        notExist: 'Not Exist',
        systemTip: 'System Hint',
        ok: 'OK',
        cancel: 'Cancel'
    },
    menu: {
        bookings: 'Bookings',
        invoiceVouchers: 'Invoice/Vouchers',
        agentsClients: 'Agents/Clients',
        teamMembers: 'Team Members',
        hotel: 'Hotel',
        meetingPoint: 'Meeting Point',
        router: 'Destination',
        preOrder: 'PreOrder'
    },
    email: {
        to: 'To',
        cc: 'CC',
        bcc: 'BCC',
        subject: 'Subject',
        attached: 'Attached',
        title: 'Email { type } to Client'
    },
    preOrder: {
        createOrder: 'Create Order',
        importOrder: 'Import order',
        isOrderCreate: 'Has the order been created?',
        yes: 'yes',
        no: 'not yet',
        created: 'created',
        notCreate: 'Not created',
        notHaveValidData: 'There is no valid service date data available',
        importSuccess: 'Import was successful',
        createSuccess: 'Order created successfully, please check on the Booking List page',
        importExcel: 'Import by Excel'
    },
    price: 'price',
    pricePlaceholder: 'please enter price',
    newBooking: 'New Booking',
    systemTip: 'System Hint',
    confirmDelete: 'Are you sure to delete?',
    cancel: 'Cancel ',
    send: 'Send ',
    name: 'Name ',
    number: 'Number ',
    info: 'Info ',
    details: 'Details ',
    search: 'Search ',
    reset: 'Reset',
    edit: 'Edit',
    check: 'Check',
    delete: 'Delete',
    signIn: 'Sign In ',
    signUp: 'Sign Up ',
    createNewAccount: 'Create a new account',
    findPasswordTip: 'To find password, fill in your Email and click Send button',
    signOut: 'Sign Out ',
    pickUp: 'Pick Up ',
    below: 'below ',
    dropOff: 'Drop Off ',
    japanTravelServices: 'Japan Travel Services ',
    japanTravelServicesInfo:
        'AISC Japan offers airports to cities, stations, activities, hot springs (onsen) and port transfers in over 546 destinations in whole of Japan. We provide Door-to-Door service transfers with a wide range of private and shared vehicles such as airport taxis, shuttles, minibuses, Sedans, MPV, vans and even luxury limousines. Our professional and affordable airport, port and train transportation are the perfect solutions in getting to your destinations without hassle. ',
    serviceDate: 'Service Date(JP) ',
    adults: 'Adults ',
    child: 'Child ',
    infants: 'Infants ',
    change: 'Change',
    pleaseEnter: 'Please enter  ',
    pleaseChoose: 'Please choose  ',
    pleaseSelect: 'Please select  ',
    pleaseAgree: 'Please agree  ',
    nextStep: 'Next step ',
    prevStep: 'Previous ',
    bookCar: 'Book Car ',
    charterTime: 'for charter please advise your pick up time. ',
    vehicleNumber: 'vehicle number ',
    transportType: 'Transport Type ',
    totalPrice: 'Total Amount',
    success: 'Success ',
    save: 'Save',
    all: 'All'
};

export default en;
