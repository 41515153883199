import { ref, watch, Ref, toValue, isRef } from 'vue';
export const useTheme = (value?: Ref<string>) => {
    const theme = isRef(value) ? value : ref<string>(toValue(value) || '');
    watch(theme, (newStr, old) => {
        trigger(old);
    });
    const trigger = (oldTheme?: string): void => {
        console.log(oldTheme);
        const html = document.documentElement;
        if (oldTheme && html.classList.contains(oldTheme)) {
            html.classList.remove(oldTheme);
        }
        if (html.classList.contains(theme.value)) {
            html.classList.remove(theme.value);
        } else {
            html.classList.add(theme.value);
        }
    };
    const setTheme = (value?: string, dark?: boolean) => {
        if (value) {
            if (dark) {
                value += '-dark';
            }
            if (value) {
                theme.value = value;
            }
        }
    };
    return {
        theme,
        trigger,
        setTheme
    };
};

export const useDark = (dark?: boolean, classStr: string = 'dark') => {
    const isDark = ref(dark);
    const darkClass = ref(classStr);
    watch(isDark, () => {
        trigger();
    });
    const { trigger } = useTheme(darkClass);
    return {
        isDark,
        trigger
    };
};
