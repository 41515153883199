<template>
    <div>
        <div
            class="car-price-item flex flex-between mg-y_10"
            v-for="(item, index) in carPrice"
            :key="index"
        >
            <el-input
                :placeholder="$t('router.category')"
                class="flex1"
                v-model="carPrice[index].category"
            ></el-input>
            <span class="mg-x_10">:</span>
            <el-input-number
                :placeholder="$t('price')"
                class="flex1"
                v-model="carPrice[index].price"
            ></el-input-number>
            <el-button
                class="mg-l_10"
                type="danger"
                :icon="Delete"
                circle
                @click="onDelete(index)"
            />
        </div>
        <el-button @click="onAdd">{{ $t('table.addOne') }}</el-button>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Delete } from '@element-plus/icons-vue';
import { ICarPrice } from '@ctypes';
const props = defineProps<{
    modelValue?: ICarPrice[];
}>();

const emit = defineEmits<{
    'update:modelValue': [value: Partial<ICarPrice>[]];
    change: [value: Partial<ICarPrice>[]];
}>();

const carPrice = ref<Partial<ICarPrice>[]>(props.modelValue || []);

const onAdd = () => {
    carPrice.value.push({ category: '', price: 0 });
};

const onDelete = (index: number) => {
    carPrice.value.splice(index, 1);
};

const onChange = (index: number) => {
    carPrice.value[index].category = carPrice.value[index].category?.toLocaleUpperCase();
};

watch(
    carPrice,
    () => {
        if (carPrice.value.length === 0) {
            emit('update:modelValue', []);
        } else {
            emit(
                'update:modelValue',
                carPrice.value.filter(
                    (item) => item.category !== undefined && item.price !== undefined
                )
            );
        }
        emit('change', carPrice.value);
    },
    { deep: true }
);
</script>
