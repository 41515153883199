<template>
    <g-option-form
        ref="optionFormRef"
        labelPosition="top"
        :labelWidth="100"
        :model="model"
        :options="formOptions"
    ></g-option-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useVueI18n } from '@use';
import { passwordValidator } from '@utils';

withDefaults(
    defineProps<{
        model: Record<string, any>;
    }>(),
    {
        model: () => ({})
    }
);

const { tJoin } = useVueI18n();
const formOptions = ref<IFormOption[]>([
    {
        label: tJoin('userInfo.email'),
        prop: 'email',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.email') }]
    },
    {
        label: tJoin('userInfo.password'),
        prop: 'password',
        component: 'elInput',
        rules: [
            {
                required: true,
                message: tJoin('pleaseEnter', 'userInfo.password')
            },
            {
                message: tJoin('pleaseEnter', 'login.passwordFormat'),
                validator: passwordValidator
            }
        ],
        props: {
            type: 'password'
        }
    }
]);

const optionFormRef = ref<OptionFormInstance>();
const validate = async function <T>(): Promise<T> {
    if (optionFormRef.value) {
        return await optionFormRef.value.validate<T>();
    }
    return Promise.reject();
};

defineExpose({
    validate
});
</script>
