<template>
    <el-row class="w_100 bd-t_1 pd-b_20 pd-t_10" justify="center" :gutter="10">
        <el-col :span="6">
            <div class="flex flex-center h_100 fs_14" style="word-break: break-all">
                {{ data.category }}
            </div>
        </el-col>
        <el-col :span="6">
            <div class="flex flex-center h_100">
                <g-price :price="price" type="success" :font-size="22"></g-price>
            </div>
        </el-col>
        <el-col :span="6">
            <g-option-form
                v-if="name !== 'Shared Transfer'"
                class="flex1"
                ref="numberFormRef"
                :options="formOptions"
                :model="formModel"
                @change="onFormChange"
            ></g-option-form>
        </el-col>
        <el-col :span="6">
            <div class="flex flex-center h_100">
                <el-button type="success" :icon="Right" size="large" @click="onBookCar">{{
                    $t('bookCar')
                }}</el-button>
            </div>
        </el-col>
    </el-row>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { Right } from '@element-plus/icons-vue';
import { ICarPrice } from '@ctypes';
import { useVueI18n } from '@use';
import OptionForm from '@global/form/OptionForm.vue';
import { useBookingOrderStore } from '@/stores';

interface FormModel {
    vehicleCount: number;
}

const props = defineProps<{
    data: ICarPrice;
    name: string;
}>();

const emit = defineEmits<{
    booking: [vehicleCount: number, price: number, id: number];
}>();

const { carInfo, peopleDateInfo, gvNum } = useBookingOrderStore();

const price = computed(() => {
    if (props.name === 'Shared Transfer') {
        const { adultCount: B, childCount: C } = peopleDateInfo.value;
        const adultPrice = props.data.price;
        const childPrice = adultPrice / 2;
        const A = gvNum.value;
        if (A > B) {
            const D = B + C;
            if (D <= A) {
                return A * adultPrice;
            } else {
                const E = D - A;
                return A * adultPrice + E * childPrice;
            }
        } else {
            return B * adultPrice + C * childPrice;
        }
    }
    return props.data.price * newFormData.value.vehicleCount;
});

const { t, tJoin } = useVueI18n();
const formOptions: IFormOption[] = [
    {
        label: t('bookingOrder.noOfVehicle'),
        prop: 'vehicleCount',
        component: 'elInputNumber',
        rules: [
            { required: true, message: tJoin('pleaseEnter', 'bookingOrder.noOfVehicle') },
            {
                type: 'number',
                min: 1,
                message: tJoin('pleaseEnter', 'bookingOrder.noOfVehicle'),
                trigger: 'blur'
            }
        ],
        props: {
            min: 1
        }
    }
];

const formModel = ref<FormModel>({
    vehicleCount: props.name === carInfo.value.name ? carInfo.value.vehicleCount : 1
});
const numberFormRef = ref<InstanceType<typeof OptionForm>>();
const validate = async (): Promise<FormModel> => {
    if (numberFormRef.value) {
        return await numberFormRef.value.validate<FormModel>();
    }
    return {
        vehicleCount: 1
    };
};
const onBookCar = async () => {
    const { vehicleCount } = await validate();
    emit('booking', vehicleCount, props.data.price, props.data.id);
};

const newFormData = ref<FormModel>({
    vehicleCount: formModel.value.vehicleCount
});
const onFormChange = (data: FormModel) => {
    newFormData.value = data;
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
    margin-bottom: 0;
}
</style>
