<template>
    <h4>{{ $t('bookingList.verifyFields.verifyFields') }}</h4>
    <g-option-table border :options="tableOptions" :data="tableData" :showOrder="false">
        <template #value="{ row, option }">
            <el-text type="primary">{{ row[option.prop] }}</el-text>
            <el-button
                v-if="!row.verify && canVerify"
                class="mg-l_10"
                :icon="Edit"
                link
                @click="onEdit(row.originValue || row.value, row)"
            ></el-button>
        </template>
        <template #verify="{ row }">
            <el-switch
                :modelValue="row.verify"
                :disabled="!canVerify"
                @click="onVerify(row)"
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
            ></el-switch>
        </template>
    </g-option-table>
    <el-dialog destroy-on-close width="600" :title="$t('edit')" v-model="isShowEdit">
        <g-option-form ref="editFormRef" :model="editFormModel" :options="editFormOptions">
            <template #oldValue="{ formModel, option }">
                <el-text>{{ formModel[option.prop] }}</el-text>
            </template>
            <template #newValue="{ formModel, option }">
                <component
                    v-model="formModel[option.prop]"
                    :is="currRow.component || 'elInput'"
                    v-bind="currRow.props"
                ></component>
            </template>
        </g-option-form>
        <template #footer>
            <el-button :loading="isLoading" type="primary" @click="onSave">{{
                $t('save')
            }}</el-button>
            <el-button :loading="isLoading" @click="isShowEdit = false">{{
                $t('cancel')
            }}</el-button>
        </template>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, onUnmounted, toValue } from 'vue';
import { ElMessageBox } from 'element-plus';
import { Edit } from '@element-plus/icons-vue';
import { BookingStatusEnum, RouterTypeEnum } from '@enums';
import { useVueI18n, useBookingTableOptions } from '@use';
import { getJPTimestamp } from '@utils';
import { useBookingStore, useBookingOrderStore } from '@/stores';
import useDetailOptions from '@/components/bookingOrder/orderForm/useDetailOptions';
import GuestInput from '@/components/bookingOrder/orderForm/widgets/GuestInput.vue';
import { IBooking } from '@/common/types';
import { jpToLocal } from '@utils';
import CarTypeChoose from './CarTypeChoose.vue';

const { t, tJoin, locale } = useVueI18n();

const { currBooking, currVerifyFields, getCurrBooking, updateOrder, verifyOrderField } =
    useBookingStore();
const { routerInfo, carInfo } = useBookingOrderStore();
routerInfo.value.pickUpLocation = currBooking.value?.pickUpLocationName;
routerInfo.value.pickUpLocationType = currBooking.value?.pickUpLocationType;
routerInfo.value.dropOffLocation = currBooking.value?.dropOffLocationName;
routerInfo.value.dropOffLocationType = currBooking.value?.dropOffLocationType;
carInfo.value.name = currBooking.value?.vehicleName;

const { tableOptions: bookingTableOptions } = useBookingTableOptions();
const { getOptions } = useDetailOptions();
const tableData = ref<IRecord[]>([]);
const getTableData = () => {
    tableData.value = [];
    const firstFields = ['serviceDate'];
    const firstBaseOptions = bookingTableOptions.value.filter((tableOption) =>
        firstFields.includes(tableOption.prop)
    );
    firstBaseOptions.forEach((option) => {
        if (currBooking.value) {
            const { verifyDetails } = currBooking.value;
            tableData.value.push({
                name: option.label,
                value: option.formatValue
                    ? option.formatValue(currBooking.value, currBooking.value[option.prop])
                    : currBooking.value[option.prop],
                originValue: currBooking.value[option.prop],
                verify: verifyDetails ? verifyDetails[option.prop] : false,
                component: option.component || 'elInput',
                props: option.props,
                prop: option.prop,
                option: option,
                allData: currBooking.value
            });
        }
    });
    const terminalOptions = [
        {
            label: tJoin('bookingList.assignTo.international').value,
            value: 'International'
        },
        {
            label: tJoin('bookingList.assignTo.internationalT1').value,
            value: 'International_T1'
        },
        {
            label: tJoin('bookingList.assignTo.internationalT2').value,
            value: 'International_T2'
        },
        {
            label: tJoin('bookingList.assignTo.internationalT3').value,
            value: 'International_T3'
        },
        { label: tJoin('bookingList.assignTo.domestic').value, value: 'Domestic' },
        { label: tJoin('bookingList.assignTo.domesticT1').value, value: 'Domestic_T1' },
        { label: tJoin('bookingList.assignTo.domesticT2').value, value: 'Domestic_T2' },
        { label: tJoin('bookingList.assignTo.domesticT3').value, value: 'Domestic_T3' }
    ];
    const switchTerminal = (terminal?: string) => {
        const find = terminalOptions.find((item) => item.value === terminal);
        if (find) {
            return computed(() => find.label);
        }
        return terminal;
    };
    if (currBooking.value?.pickUpLocationType === RouterTypeEnum.AIRPORT) {
        const { verifyDetails } = currBooking.value;
        tableData.value.push({
            name: t('bookingList.assignTo.terminal'),
            value: switchTerminal(currBooking.value.terminal),
            verify: verifyDetails ? verifyDetails.terminal : false,
            component: 'gSelect',
            prop: 'terminal',
            props: {
                options: terminalOptions
            },
            allData: currBooking.value
        });
    }
    const pickUpDetailOptions = getOptions(currBooking.value?.pickUpLocationType, 'pickUp');
    pickUpDetailOptions.forEach((option) => {
        if (currBooking.value && !['hotelInfo'].includes(option.prop)) {
            const { verifyDetails } = currBooking.value;
            tableData.value.push({
                name: option.label,
                value: option.formatValue
                    ? option.formatValue(
                          currBooking.value.pickUpDetails,
                          currBooking.value.pickUpDetails[option.prop]
                      )
                    : currBooking.value.pickUpDetails[option.prop],
                originValue: currBooking.value.pickUpDetails[option.prop],
                verify: verifyDetails ? verifyDetails[`pickUpDetails.${option.prop}`] : false,
                component: option.component || 'elInput',
                props: option.props,
                prop: `pickUpDetails.${option.prop}`,
                option: option,
                allData: currBooking.value.pickUpDetails
            });
        }
    });
    const dropOffDetailOptions = getOptions(currBooking.value?.dropOffLocationType, 'dropOff');
    dropOffDetailOptions.forEach((option) => {
        if (currBooking.value && !['hotelInfo'].includes(option.prop)) {
            const { verifyDetails } = currBooking.value;
            tableData.value.push({
                name: option.label,
                value: option.formatValue
                    ? option.formatValue(
                          currBooking.value.dropOffDetails,
                          currBooking.value.dropOffDetails[option.prop]
                      )
                    : currBooking.value.dropOffDetails[option.prop],
                originValue: currBooking.value.dropOffDetails[option.prop],
                verify: verifyDetails ? verifyDetails[`dropOffDetails.${option.prop}`] : false,
                component: option.component || 'elInput',
                props: option.props,
                prop: `dropOffDetails.${option.prop}`,
                option: option,
                allData: currBooking.value.dropOffDetails
            });
        }
    });
    const fields = [
        'adultCount',
        'childCount',
        'infantCount',
        'guestNames',
        'sns',
        'manualAmount',
        'vehicleName',
        'vehicleCount',
        'remarks'
    ];
    const baseOptions = bookingTableOptions.value
        .filter((tableOption) => {
            if (
                currBooking.value?.vehicleName === 'Shared Transfer' &&
                tableOption.prop === 'vehicleCount'
            ) {
                return false;
            }
            return fields.includes(tableOption.prop);
        })
        .map((tableOption) => {
            const option = {
                ...tableOption
            };
            if (tableOption.prop === 'guestNames') {
                option.component = GuestInput;
            }
            if (tableOption.prop === 'vehicleName') {
                option.component = CarTypeChoose;
                option.props = {
                    routeId: currBooking.value?.routeId,
                    modelValue: {
                        vehicleName: currBooking.value?.vehicleName,
                        routeVehiclePriceCategory: currBooking.value?.routeVehiclePriceCategory
                    }
                };
                option.formatValue = (record: IBooking) =>
                    `${record.vehicleName}\n(${record.routeVehiclePriceCategory})`;
            }
            return option;
        });
    baseOptions.forEach((option) => {
        if (currBooking.value) {
            const { verifyDetails } = currBooking.value;
            tableData.value.push({
                name: option.label,
                value: option.formatValue
                    ? option.formatValue(currBooking.value, currBooking.value[option.prop])
                    : currBooking.value[option.prop],
                originValue: currBooking.value[option.prop],
                verify: verifyDetails ? verifyDetails[option.prop] : false,
                component: option.component || 'elInput',
                props: option.props,
                prop: option.prop,
                option: option,
                allData: currBooking.value
            });
        }
    });
};
getTableData();
console.log(tableData.value);
currVerifyFields.value = tableData.value;
onUnmounted(() => {
    currVerifyFields.value = [];
});
const tableOptions = ref<ITableOption[]>([
    {
        label: tJoin('bookingList.verifyFields.fieldName'),
        prop: 'name',
        props: { width: 400 },
        decorate: 'elText',
        decorateProps: {
            type: 'primary'
        }
    },
    {
        label: tJoin('bookingList.verifyFields.fieldValue'),
        prop: 'value',
        slot: 'value'
    },
    { label: tJoin('bookingList.verifyFields.verified'), prop: 'verify', slot: 'verify' }
]);

const editFormOptions = ref<IFormOption[]>([
    {
        label: tJoin('bookingList.verifyFields.newFieldValue'),
        prop: 'newValue',
        slot: 'newValue',
        rules: [
            {
                required: true,
                message: tJoin('pleaseEnter'),
                validator: (rule, value, callback) => {
                    let isPass = false;
                    if (typeof value === 'object') {
                        isPass = Object.values(value).every((item) => item);
                    } else {
                        isPass = !!value;
                    }
                    if (isPass) {
                        callback();
                    } else {
                        callback(tJoin('pleaseEnter').value);
                    }
                }
            }
        ]
    }
]);
const editFormModel = ref<IRecord>({
    oldValue: '',
    newValue: ''
});
const currRow = ref<any>({});
const isShowEdit = ref(false);
const editFormRef = ref<OptionFormInstance>();
const onEdit = (value: string, row: IRecord) => {
    currRow.value = row;
    isShowEdit.value = true;
    if (row.prop === 'serviceDate' || ~row.prop.indexOf('.departureDate')) {
        editFormModel.value.oldValue = jpToLocal(value);
        editFormModel.value.newValue = jpToLocal(value);
    } else if (row.prop === 'vehicleName') {
        editFormModel.value.oldValue = {
            routeVehiclePriceCategory: row.allData.routeVehiclePriceCategory,
            vehicleName: row.allData.vehicleName
        };
        editFormModel.value.newValue = {
            routeVehiclePriceCategory: row.allData.routeVehiclePriceCategory,
            vehicleName: row.allData.vehicleName
        };
    } else {
        editFormModel.value.oldValue = value;
        editFormModel.value.newValue = value;
    }
};
const isLoading = ref(false);
const onSave = async () => {
    if (editFormRef.value && currBooking.value) {
        const formData = await editFormRef.value.validate<{ newValue: any }>();
        if (currRow.value.prop === 'serviceDate' || ~currRow.value.prop.indexOf('.departureDate')) {
            formData.newValue = getJPTimestamp(formData.newValue);
        }
        let isSendEmail = false;
        try {
            await ElMessageBox.confirm(
                tJoin('preview.confirmSendAmended').value,
                tJoin('system.systemTip').value
            );
            isSendEmail = true;
        } catch (e) {
            isSendEmail = false;
        }
        const nowLocale = locale.value;
        locale.value = 'en';
        const name = toValue(currRow.value.name);
        locale.value = nowLocale;
        isLoading.value = true;
        try {
            if (['pickUpDetails.pickUp', 'pickUpDetails.detail'].includes(currRow.value.prop)) {
                if (currRow.value.allData.hotelInfo) {
                    await updateOrder('pickUpDetails.hotelInfo', null, false, '');
                }
            }
            if (['dropOffDetails.pickUp', 'dropOffDetails.detail'].includes(currRow.value.prop)) {
                if (currRow.value.allData.hotelInfo) {
                    await updateOrder('dropOffDetails.hotelInfo', null, false, '');
                }
            }
            if (currRow.value.prop === 'vehicleName') {
                Object.entries(formData.newValue).forEach(async ([key, value]) => {
                    if (key === 'vehicleName') {
                        await updateOrder('vehicleName', value, isSendEmail, name);
                    } else {
                        await updateOrder('routeVehiclePriceCategory', value, isSendEmail, name);
                    }
                });
            } else {
                await updateOrder(currRow.value.prop, formData.newValue, isSendEmail, name);
            }
            // currRow.value.verify = false;
            await onVerify(currRow.value);
            await getCurrBooking();
            getTableData();
            // currRow.value.allData[currRow.value.prop] = formData.newValue;
            // currRow.value.value =
            //     currRow.value.option && currRow.value.option.formatValue
            //         ? currRow.value.option.formatValue(currRow.value.allData, formData.newValue)
            //         : formData.newValue;
            isShowEdit.value = false;
        } catch (e) {
            console.log(e);
        }
        isLoading.value = false;
    }
};

const canVerify = computed(() => {
    return (
        currBooking.value &&
        ![
            BookingStatusEnum.COMPLETED,
            BookingStatusEnum.REJECTED,
            BookingStatusEnum.CANCELLED
        ].includes(currBooking.value?.status)
    );
});
const onVerify = async (row: any) => {
    if (canVerify.value) {
        row.verify = !row.verify;
        await verifyOrderField(row.prop, row.verify);
        if (row.prop === 'vehicleName') {
            await verifyOrderField('vehicleCount', false);
        }
    } else {
        // ElMessage.warning(t('bookingList.verifyFields.canNotVerify').value);
    }
};
</script>

<style lang="scss">
.booking__message {
    .el-icon {
        animation: rotateCircle 2s infinite linear;
    }
}
</style>
