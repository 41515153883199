<template>
    <h1>{{ $t('japanTravelServices') }}</h1>
    <base-info></base-info>
    <g-pre-order-tips tip-place="carChoose" />
    <div class="mg-t_20 of-a_x" v-loading="isLoadingVehicle">
        <book-car-item
            v-for="(vehicle, index) in vehicles"
            :key="index"
            :data="vehicle"
        ></book-car-item>
        <el-empty v-if="vehicles.length === 0" description="没有可定车辆"></el-empty>
    </div>
</template>

<script lang="ts" setup>
import { computed, watch, ref } from 'vue';
import BaseInfo from '@/components/bookingOrder/orderForm/BaseInfo.vue';
import BookCarItem from '@/components/bookingOrder/orderCar/BookCarItem.vue';
import { useBookingOrderStore } from '@/stores';
import { ICarPrice } from '@ctypes';
import { routerRepositories } from '@/repositories';
const { routerInfo, vehicleList, isLoadingVehicle, getAllVehicle } = useBookingOrderStore();

getAllVehicle();
const vehiclePriceList = ref<ICarPrice[]>([]);
const getVehiclePrice = async () => {
    if (routerInfo.value.routeId) {
        vehiclePriceList.value = await routerRepositories.getVehiclePriceByRouter(
            routerInfo.value.routeId
        );
        console.log(vehiclePriceList.value);
    }
};
watch(routerInfo, getVehiclePrice, { immediate: true });
const vehicles = computed(() => {
    return vehicleList.value
        .map((vehicle) => {
            const priceList: ICarPrice[] = [];
            vehiclePriceList.value.forEach((vehiclePrice) => {
                if (vehiclePrice.vehicleId === vehicle.id) {
                    priceList.push(vehiclePrice);
                }
            });
            return {
                ...vehicle,
                priceList: priceList
            };
        })
        .filter((vehicle) => vehicle.priceList.length);
});
</script>
