<template>
    <el-dropdown trigger="click" placement="top-start" v-if="userInfo">
        <div class="flex cs-p">
            <el-avatar class="mg-r_10 w_40p" fit="contain" :src="baseUrl + userInfo.avatarImg">
                <el-icon size="20"><Avatar /></el-icon>
            </el-avatar>
            <p>{{ userInfo?.firstName }} {{ userInfo?.lastName }}</p>
        </div>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="onToProfile">
                    <g-icon type="icon-shuyi_gerenyonghu" class="mg-r_10" :size="16"></g-icon>
                    {{ $t('userMenu.myProfile') }}
                </el-dropdown-item>
                <!-- <el-dropdown-item :icon="Key" @click="onToChangePassword">{{
                    `${$t('change')} ${$t('userInfo.password')}`
                }}</el-dropdown-item> -->
                <el-dropdown-item @click="onSignOut" divided>
                    {{ $t('signOut') }}
                    <g-icon type="icon-tuichu" class="mg-l_10" :size="16"></g-icon>
                </el-dropdown-item>
            </el-dropdown-menu>
            <!-- <el-dropdown-menu>
                <theme-btn></theme-btn>
            </el-dropdown-menu> -->
        </template>
    </el-dropdown>
</template>

<script lang="ts" setup>
import { useRouter, useRoute, useVueI18n } from '@use';
import { ElMessage } from 'element-plus';
import { Avatar, User, Key, Loading } from '@element-plus/icons-vue';
import { useUserStore, useLoginStore, useBookingOrderStore } from '@/stores';
import { baseUrl } from '@utils';
import ThemeBtn from '@/components/header/ThemeBtn.vue';
const { userInfo, currUserInfo } = useUserStore();

const { push, replace, ROUTER_PATH_MAP } = useRouter();
const { clearAllData } = useBookingOrderStore();
const { Route } = useRoute();
const onToProfile = () => {
    currUserInfo.value = userInfo.value;
    if (Route.path.indexOf(ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL) > -1) {
        replace(`${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${userInfo.value?.id}`);
    } else {
        push(`${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${userInfo.value?.id}`);
    }
};
const onToChangePassword = () => {
    currUserInfo.value = userInfo.value;
    if (Route.path.indexOf(ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL) > -1) {
        replace({
            path: `${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${userInfo.value?.id}`,
            query: {
                action: 'changePassword'
            }
        });
    } else {
        push({
            path: `${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${userInfo.value?.id}`,
            query: {
                action: 'changePassword'
            }
        });
    }
};

const { tJoin } = useVueI18n();
const { signOut } = useLoginStore();
const onSignOut = async () => {
    ElMessage.warning({
        message: tJoin('userMenu.signOuting').value,
        type: 'warning',
        icon: Loading,
        duration: 0,
        customClass: 'el-message--warning sign-out__message'
    });
    await signOut();
    clearAllData();
    ElMessage.closeAll('warning');
};
</script>

<style lang="scss">
.sign-out__message {
    .el-icon {
        animation: rotateCircle 2s infinite linear;
    }
}
</style>
