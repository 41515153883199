<template>
    <logo class="h_80p mg-y_50"></logo>
    <template v-if="!isSend">
        <p class="mg-y_10 fw-b fs_16">{{ $t('findPasswordTip') }}</p>
        <g-option-form
            ref="optionFormRef"
            labelPosition="top"
            :labelWidth="100"
            :model="model"
            :options="formOptions"
        ></g-option-form>
        <el-button
            type="primary"
            class="w_100 mg-t_20 h_50p"
            @click="onSend"
            :loading="isLoading"
            >{{ $t('send') }}</el-button
        >
    </template>
    <p v-else class="mg-y_10 fw-b fs_16 ta-c">{{ $t('login.sendResetPasswordEmail') }}</p>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useVueI18n } from '@use';
import { loginRepositories } from '@/repositories';
import Logo from '../header/Logo.vue';

const model = ref({});

const { tJoin } = useVueI18n();
const formOptions = ref<IFormOption[]>([
    {
        label: tJoin('userInfo.email'),
        prop: 'email',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.email') }]
    }
]);

const optionFormRef = ref<OptionFormInstance>();
const validate = async function <T>(): Promise<T> {
    if (optionFormRef.value) {
        return await optionFormRef.value.validate<T>();
    }
    return Promise.reject();
};

const isSend = ref(false);
const isLoading = ref(false);
const onSend = async () => {
    const { email } = await validate<{ email: string }>();
    isLoading.value = true;
    try {
        await loginRepositories.sendResetPasswordEmail(email);
        ElMessage.success({
            message: tJoin('login.sendResetPasswordEmail').value,
            duration: 2000
        });
        isSend.value = true;
    } catch (e) {
        console.log(e);
    }
    isLoading.value = false;
};

defineExpose({
    validate
});
</script>
