<template>
    <g-option-form
        class="w_50"
        ref="changePasswordRef"
        labelPosition="top"
        :model="formModel"
        :inline="false"
        :options="formOptions"
    ></g-option-form>
    <el-button type="primary" @click="onSave" :loading="isloadingSave">{{ $t('save') }}</el-button>
</template>

<script lang="ts" setup>
import { ref, toValue } from 'vue';
import { ElMessage } from 'element-plus';
import { useVueI18n } from '@use';
import { passwordValidator } from '@utils';
import { useUserStore } from '@/stores';
import { userRepositories } from '@/repositories';

const emit = defineEmits<{
    confirm: [];
}>();

const { tJoin } = useVueI18n();
const { currUserInfo } = useUserStore();
const changePasswordRef = ref<OptionFormInstance>();
const formModel = ref({
    email: currUserInfo.value?.email
});
const formOptions: IFormOption[] = [
    {
        label: tJoin('email'),
        prop: 'email',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.firstName') }],
        props: {
            disabled: true
        }
    },
    {
        label: tJoin('userInfo.password'),
        prop: 'password',
        props: {
            type: 'password'
        },
        rules: [
            {
                required: true,
                message: tJoin('pleaseEnter', 'userInfo.password')
            },
            {
                message: tJoin('pleaseEnter', 'login.passwordFormat'),
                validator: passwordValidator
            }
        ]
    },
    {
        label: tJoin('retype', 'userInfo.password'),
        prop: 'repassword',
        props: {
            type: 'password'
        },
        rules: [
            {
                required: true,
                message: tJoin('pleaseEnter', 'retype', 'userInfo.password')
            },
            {
                required: true,
                validator: (rule, value, callback) => {
                    if (changePasswordRef.value?.formModel.password !== value) {
                        callback(toValue(tJoin('login.repasswordError')));
                    } else {
                        callback();
                    }
                }
            }
        ]
    }
];

const isloadingSave = ref(false);
const onSave = async () => {
    const formData = await changePasswordRef.value?.validate<IRecord>();
    if (formData) {
        isloadingSave.value = true;
        try {
            await userRepositories.updateUserPassword(formData.password);
            ElMessage.success(tJoin('success').value);
            emit('confirm');
        } catch (e) {
            console.log(e);
        }
        isloadingSave.value = false;
    }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
    color: #fff;
}
</style>
