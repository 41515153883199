<template>
    <el-table :data="problemList" style="width: 100%">
        <el-table-column
            v-for="(item, index) in tableCol"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            width="180"
        />
        <el-table-column fixed="right" label="操作" width="120">
            <template #default="{ row }">
                <el-button link type="primary" size="small" @click="onFixed(row)">修复</el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { RouterTypeEnum } from '@/common/enums';
import { IBooking, ICityDetailInfo } from '@/common/types';
import { bookingRepositories } from '@/repositories';

const problemList = ref<IBooking[]>([]);
const tableCol = ref([
    {
        prop: 'id',
        label: '订单号'
    },
    {
        prop: 'pickUpDetails.hotelInfo.hotelName',
        label: 'pickUpDetails.hotelInfo.hotelName'
    },
    {
        prop: 'pickUpDetails.pickUp',
        label: 'pickUpDetails.pickUp'
    },
    {
        prop: 'dropOffDetails.hotelInfo.hotelName',
        label: 'dropOffDetails.hotelInfo.hotelName'
    },
    {
        prop: 'dropOffDetails.pickUp',
        label: 'dropOffDetails.pickUp'
    },
    {
        prop: 'pickUpDetails.hotelInfo.address',
        label: 'pickUpDetails.hotelInfo.address'
    },
    {
        prop: 'pickUpDetails.detail',
        label: 'pickUpDetails.detail'
    },
    {
        prop: 'dropOffDetails.hotelInfo.address',
        label: 'dropOffDetails.hotelInfo.address'
    },
    {
        prop: 'dropOffDetails.detail',
        label: 'dropOffDetails.detail'
    }
]);
const getAllList = async (num: number) => {
    const data = await bookingRepositories.getBookingList({
        pageNum: num,
        pageSize: 100
    });
    console.log(data);
    if (data.records.length) {
        const problem = data.records.filter(
            (item) =>
                (item.pickUpLocationType === RouterTypeEnum.CITY &&
                    (item.pickUpDetails as ICityDetailInfo).hotelInfo &&
                    ((item.pickUpDetails as ICityDetailInfo).hotelInfo?.hotelName !==
                        item.pickUpDetails.pickUp ||
                        (item.pickUpDetails as ICityDetailInfo).hotelInfo?.address !==
                            (item.pickUpDetails as ICityDetailInfo).detail.split(/\n|\r/)[0])) ||
                (item.dropOffLocationType === RouterTypeEnum.CITY &&
                    (item.dropOffDetails as ICityDetailInfo).hotelInfo &&
                    ((item.dropOffDetails as ICityDetailInfo).hotelInfo?.hotelName !==
                        item.dropOffDetails.pickUp ||
                        (item.dropOffDetails as ICityDetailInfo).hotelInfo?.address !==
                            (item.dropOffDetails as ICityDetailInfo).detail.split(/\n|\r/)[0]))
        );
        problemList.value = problemList.value.concat(problem);
        console.log('problem', problem);
        getAllList(num + 1);
    }
};
getAllList(1);

const onFixed = async (booking: IBooking) => {
    if (booking.dropOffLocationType === RouterTypeEnum.CITY) {
        await bookingRepositories.updateOrder(
            booking.id,
            'dropOffDetails.hotelInfo',
            null,
            false,
            ''
        );
    } else if (booking.pickUpLocationType === RouterTypeEnum.CITY) {
        await bookingRepositories.updateOrder(
            booking.id,
            'pickUpDetails.hotelInfo',
            null,
            false,
            ''
        );
    }
    problemList.value = [];
    getAllList(1);
};
</script>
