export enum RouterTypeEnum {
    AIRPORT = 'Airport',
    PORT = 'Port',
    ACTIVITY = 'Activities',
    STATION = 'Station',
    CITY = 'City'
}

export const RouterType = {
    [RouterTypeEnum.AIRPORT]: RouterTypeEnum.AIRPORT,
    [RouterTypeEnum.PORT]: RouterTypeEnum.PORT,
    [RouterTypeEnum.ACTIVITY]: RouterTypeEnum.ACTIVITY,
    [RouterTypeEnum.STATION]: RouterTypeEnum.STATION,
    [RouterTypeEnum.CITY]: RouterTypeEnum.CITY
};

export enum ROUTER_PATH_MAP {
    HOME_PATH = '/home',
    HOME_BOOKING_PATH = '/home/booking',
    HOME_FIND_PASSWORD_PATH = '/home/findPassword',
    HOME_SIGN_UP_PATH = '/home/signUp',
    HOME_SIGN_IN_PATH = '/home/signIn',
    HOME_RESET_PASSWORD_PATH = '/home/resetPassword/:token',
    MENU_PATH = '/menu',
    MENU_BOOKING_PATH = '/menu/booking',
    MENU_BOOKING_LIST_PATH = '/menu/bookingList',
    MENU_BOOKING_REQUEST = '/menu/bookingRequest',
    MENU_BOOKING_REQUEST_PATH = '/menu/bookingRequest/:id',
    MENU_INVOICE_LIST_PATH = '/menu/invoiceList',
    MENU_INVOICE_DETAIL = '/menu/invoiceDetail',
    MENU_INVOICE_DETAIL_PATH = '/menu/invoiceDetail/:id',
    MENU_TEAM_MEMBER_LIST_PATH = '/menu/teamMemberList',
    MENU_TEAM_MEMBER_DETAIL = '/menu/teamMemberDetail',
    MENU_TEAM_MEMBER_DETAIL_PATH = '/menu/teamMemberDetail/:id',
    MENU_TEAM_MEMBER_DETAIL_CHANGE_PASSWORD_PATH = '/menu/teamMemberDetail/:id/changePassword',
    MENU_AGENT_LIST = '/menu/agentList',
    MENU_AGENT_DETAIL = '/menu/agentDetail',
    MENU_AGENT_DETAIL_PATH = '/menu/agentDetail/:id',
    MENU_PREVIEW = '/menu/preview',
    MENU_PREVIEW_PATH = '/menu/preview/:type/:id',
    MENU_ROUTER_LIST_PATH = '/menu/routerList'
}

export const getRouterName = (path: string): string => {
    const strArr = path.split('/');
    return strArr.filter((item) => !!item).join('-');
};
