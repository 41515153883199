<template>
    <el-popover placement="bottom-start" :width="200" trigger="hover">
        <div class="h-max_300p of-a">
            <draggable :animation="200" v-model="fixLeftOptions" @change="onChange">
                <template #item="{ element }">
                    <div class="option-item pd-y_5 cs-p flex flex-between">
                        <el-checkbox
                            class="mg-r_5"
                            v-model="element.checked"
                            @change="onCheckboxChange"
                        >
                            <el-text size="small">{{ element.label }}</el-text>
                        </el-checkbox>
                        <el-button
                            type="primary"
                            :icon="Close"
                            link
                            @click="onCancelFix(element)"
                        ></el-button>
                    </div>
                </template>
            </draggable>
            <el-divider class="mg-y_5" v-if="fixLeftOptions.length"></el-divider>
            <draggable :animation="200" v-model="orderOptions" @change="onChange">
                <template #item="{ element }">
                    <div class="option-item pd-y_5 cs-p flex flex-between">
                        <el-checkbox
                            class="mg-r_5"
                            v-model="element.checked"
                            @change="onCheckboxChange"
                        >
                            <el-text class="w_90p" truncated size="small">{{
                                element.label
                            }}</el-text>
                        </el-checkbox>
                        <div class="nowrap">
                            <el-button
                                type="primary"
                                :icon="DArrowLeft"
                                link
                                @click="onFixed(element, 'left')"
                            ></el-button>
                            <el-button
                                type="primary"
                                :icon="DArrowRight"
                                link
                                @click="onFixed(element, 'right')"
                            ></el-button>
                        </div>
                    </div>
                </template>
            </draggable>
            <el-divider class="mg-y_5" v-if="fixRightOptions.length"></el-divider>
            <draggable :animation="200" v-model="fixRightOptions" @change="onChange">
                <template #item="{ element }">
                    <div class="option-item pd-y_5 cs-p flex flex-between">
                        <el-checkbox
                            class="mg-r_5"
                            v-model="element.checked"
                            @change="onCheckboxChange"
                        >
                            <el-text size="small">{{ element.label }}</el-text>
                        </el-checkbox>
                        <el-button
                            type="primary"
                            :icon="Close"
                            link
                            @click="onCancelFix(element)"
                        ></el-button>
                    </div>
                </template>
            </draggable>
        </div>
        <template #reference>
            <el-button link>
                <el-icon size="18"><Setting /></el-icon>
            </el-button>
        </template>
    </el-popover>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import draggable from 'vuedraggable';
import { Setting, DArrowLeft, DArrowRight, Close } from '@element-plus/icons-vue';
import { setLocalStorage, getLocalStorage } from '@/common/utils';
import { useUserStore } from '@/stores';

const props = withDefaults(
    defineProps<{
        tableOptions: ITableOption[];
        modelValue: ITableOption[];
        storageable: boolean;
    }>(),
    {
        storageable: true,
        tableOptions: () => [],
        modelValue: () => []
    }
);
const emit = defineEmits<{
    'update:modelValue': [value: ITableOption[]];
}>();

const { userInfo } = useUserStore();

watch(
    () => props.modelValue,
    () => {
        changeOptions();
    }
);

const Route = useRoute();
let storage = props.storageable
    ? getLocalStorage<{ prop: string; fixed?: string; checked?: boolean }[]>(
          `${Route.path}-${userInfo.value?.id}`
      )
    : null;

if (!storage) {
    storage = props.modelValue.map((item) => ({
        prop: item.prop,
        checked: item.checked ?? true,
        fixed: item.props?.fixed
    }));
}

const fixLeftOptions = ref<any>(
    storage
        .filter((item) => item.fixed == 'left')
        .map((item) => {
            const option = props.modelValue.find((option) => option.prop == item.prop);
            return {
                ...option,
                props: {
                    ...option!.props,
                    fixed: 'left'
                },
                label: computed(() => option!.label),
                checked: item.checked
            };
        })
);

const orderOptions = ref<any>(
    storage
        .filter((item) => !item.fixed)
        .map((item) => {
            const option = props.modelValue.find((option) => option.prop == item.prop);
            const obj = {
                ...option,
                props: {
                    ...(option?.props ? option!.props : {}),
                    fixed: undefined
                },
                label: computed(() => option?.label),
                checked: item.checked
            };
            return obj;
        })
);
const fixRightOptions = ref<any>(
    storage
        .filter((item) => item.fixed == 'right')
        .map((item) => {
            const option = props.modelValue.find((option) => option.prop == item.prop);
            return {
                ...option,
                props: {
                    ...option!.props,
                    fixed: 'right'
                },
                label: computed(() => option!.label),
                checked: item.checked
            };
        })
);
const changeOptions = () => {
    fixLeftOptions.value = props.modelValue.filter((option) => option.props?.fixed === 'left');
    orderOptions.value = props.modelValue.filter((option) => !option.props?.fixed);
    fixRightOptions.value = props.modelValue.filter((option) => option.props?.fixed === 'right');
};

const onCancelFix = (option: ITableOption) => {
    if (option.props && option.props.fixed) {
        delete option.props.fixed;
        onChange();
    }
};
const onFixed = (option: ITableOption, direction: 'left' | 'right') => {
    if (!option.props) {
        option.props = {};
    }
    option.props.fixed = direction;
    onChange();
};

const onChange = () => {
    const result = [...fixLeftOptions.value, ...orderOptions.value, ...fixRightOptions.value];
    props.storageable &&
        setLocalStorage(
            `${Route.path}-${userInfo.value?.id}`,
            result.map((item) => ({
                prop: item.prop,
                checked: item.checked,
                fixed: item.props?.fixed
            }))
        );
    emit('update:modelValue', result);
};
const onCheckboxChange = () => {
    onChange();
};
onChange();
</script>
