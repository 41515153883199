export const letterValidator = (
    rule: any,
    value: any,
    callback: (error?: string | Error | undefined) => void
) => {
    if (/[a-zA-Z]/.test(value)) {
        callback();
    }
    callback(new Error());
};

export const numberValidator = (
    rule: any,
    value: any,
    callback: (error?: string | Error | undefined) => void
) => {
    if (/[0-9]/.test(value)) {
        callback();
    }
    callback(new Error());
};

export const passwordValidator = (
    rule: any,
    value: any,
    callback: (error?: string | Error | undefined) => void
) => {
    if (/^[\w\W]{6,}$/.test(value)) {
        callback();
    }
    callback(new Error());
};
