<template>
    <form-title :title="title">
        <g-pre-order-tips :tip-place="type === 'pickUp' ? 'pickUpDetail' : 'dropOffDetail'" />
        <g-option-form
            ref="optionFormRef"
            labelPosition="top"
            :model="formModel"
            :options="formOptions"
        >
            <template #hotel="{ formModel, option }">
                <hotel-search
                    v-model="formModel[option.prop]"
                    :type="type"
                    :prop="option.prop"
                ></hotel-search>
            </template>
        </g-option-form>
    </form-title>
</template>

<script lang="ts" setup>
import { ref, Ref } from 'vue';
import { useBookingOrderStore } from '@/stores';
import { RouterTypeEnum } from '@enums';
import {
    IAirportDetailInfo,
    IPortDetailInfo,
    IAcitiviyDetailInfo,
    IStationDetailInfo,
    ICityDetailInfo,
    IHotel
} from '@ctypes';
import { getJPTimestamp } from '@utils';
import useDetailOptions from './useDetailOptions';
import FormTitle from './FormTitle.vue';
import HotelSearch from './widgets/HotelSearch.vue';

const props = defineProps<{
    routerType?: IRouterType;
    type: 'pickUp' | 'dropOff';
    title: string | Ref<string>;
}>();

const { getOptions } = useDetailOptions();
const formOptions = getOptions(props.routerType, props.type);

const {
    airportInfo,
    portInfo,
    acitiviyInfo,
    stationInfo,
    cityInfo,
    cityInfoDrop,
    pickUpDetail,
    dropOffDetail,
    routerInfo
} = useBookingOrderStore();

const formModel = props.type === 'pickUp' ? pickUpDetail : dropOffDetail;
console.log(formOptions);
console.log(formModel);

const optionFormRef = ref<OptionFormInstance>();
const validate = async function <
    T extends
        | IAirportDetailInfo
        | IPortDetailInfo
        | IAcitiviyDetailInfo
        | IStationDetailInfo
        | ICityDetailInfo
>(): Promise<T> {
    if (optionFormRef.value) {
        const model = await optionFormRef.value.validate<T>();
        if (typeof model.departureDate === 'number') {
            model.departureDate = getJPTimestamp(model.departureDate);
        }
        if (props.routerType === RouterTypeEnum.CITY) {
            const hotel = (model as ICityDetailInfo).hotelInfo;
            if (hotel) {
                let address = hotel.address;
                let addressJP = hotel.addressJp;
                if (hotel.tel) {
                    address += `\nTel:${hotel.tel}`;
                    addressJP += `\nTel:${hotel.tel}`;
                }
                if (hotel.fax) {
                    address += `\nFax:${hotel.fax}`;
                    addressJP += `\nFax:${hotel.fax}`;
                }
                if (hotel.email) {
                    address += `\nEmail:${hotel.email}`;
                    addressJP += `\nEmail:${hotel.email}`;
                }
                if (hotel.hotelName === model.pickUp) {
                    model.pickUpJP = hotel.hotelNameJp;
                }
                if (address === model.detail) {
                    model.detailJP = addressJP;
                }
                if (hotel.hotelName !== model.pickUp || address !== model.detail) {
                    model.hotelInfo = undefined;
                }
            }
        }
        let pickUpLocation = routerInfo.value.pickUpLocation || '';
        let pickUpLocationJp = routerInfo.value.pickUpLocationJp || pickUpLocation;
        let dropOffLocation = routerInfo.value.dropOffLocation || '';
        let dropOffLocationJp = routerInfo.value.dropOffLocationJp || dropOffLocation;
        if (props.type === 'pickUp') {
            if (props.routerType === RouterTypeEnum.AIRPORT) {
                if (model.airportName === pickUpLocation) {
                    model.airportNameJP = pickUpLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.CITY) {
                if (model.cityName === pickUpLocation) {
                    model.cityNameJP = pickUpLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.ACTIVITY) {
                if (model.activityName === pickUpLocation) {
                    model.activityNameJP = pickUpLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.PORT) {
                if (model.portName === pickUpLocation) {
                    model.portNameJP = pickUpLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.STATION) {
                if (model.stationName === pickUpLocation) {
                    model.stationNameJP = pickUpLocationJp;
                }
            }
        } else if (props.type === 'dropOff') {
            if (props.routerType === RouterTypeEnum.AIRPORT) {
                if (model.airportName === dropOffLocation) {
                    model.airportNameJP = dropOffLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.CITY) {
                if (model.cityName === dropOffLocation) {
                    model.cityNameJP = dropOffLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.ACTIVITY) {
                if (model.activityName === dropOffLocation) {
                    model.activityNameJP = dropOffLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.PORT) {
                if (model.portName === dropOffLocation) {
                    model.portNameJP = dropOffLocationJp;
                }
            }
            if (props.routerType === RouterTypeEnum.STATION) {
                if (model.stationName === dropOffLocation) {
                    model.stationNameJP = dropOffLocationJp;
                }
            }
        }
        props.type === 'pickUp'
            ? (pickUpDetail.value = model as any)
            : (dropOffDetail.value = model as any);
        setStore({
            ...model
        });
        return {
            ...model
        };
    }
    return Promise.reject();
};
const setStore = (model: unknown): void => {
    switch (props.routerType) {
        case RouterTypeEnum.AIRPORT:
            airportInfo.value = model as IAirportDetailInfo;
            break;
        case RouterTypeEnum.PORT:
            portInfo.value = model as IPortDetailInfo;
            break;
        case RouterTypeEnum.ACTIVITY:
            acitiviyInfo.value = model as IAcitiviyDetailInfo;
            break;
        case RouterTypeEnum.STATION:
            stationInfo.value = model as IStationDetailInfo;
            break;
        case RouterTypeEnum.CITY:
            props.type === 'pickUp'
                ? (cityInfo.value = model as ICityDetailInfo)
                : (cityInfoDrop.value = model as ICityDetailInfo);
            break;
    }
};

defineExpose({
    validate
});
</script>
