<template>
    <el-menu class="h_100" :default-active="defaultActive" :collapse="isExpand">
        <template v-for="(menu, index) in computedMenuList" :key="index">
            <el-sub-menu :index="`${index}`" v-if="menu.children?.length">
                <template #title>
                    <g-icon class="mg-r_5" :type="menu.icon"></g-icon>
                    <span>{{ menu.label }}</span>
                </template>
                <el-menu-item
                    v-for="(child, childIndex) in menu.children"
                    :key="childIndex"
                    :index="child.path"
                    :route="child.path"
                >
                    {{ child.label }}
                </el-menu-item>
            </el-sub-menu>
            <el-menu-item v-else :index="menu.path" @click="onJump(menu.path)">
                <g-icon class="mg-r_5" :type="menu.icon"></g-icon>
                <template #title>{{ menu.label }}</template>
            </el-menu-item>
        </template>
    </el-menu>
</template>

<script lang="ts" setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import { useUserStore, useLoginStore, useMenuStore } from '@/stores';
import { useRoute, useRouter, useVueI18n } from '@use';

const { isExpand, hasMenu } = useMenuStore();
hasMenu.value = true;
onBeforeUnmount(() => {
    hasMenu.value = false;
});
const { isSignIn } = useLoginStore();
const { menuList, userInfo } = useUserStore();

const { tJoin } = useVueI18n();
const computedMenuList = computed(() => {
    return (
        isSignIn &&
        menuList.value
            .filter(
                (menu) =>
                    !menu.belong ||
                    menu.belong.find((item) =>
                        userInfo.value?.roleList.find((role) => role.name === item)
                    )
            )
            .map((menu) => ({
                ...menu,
                label: tJoin(`menu.${menu.label}`).value
            }))
    );
});

const isCollapse = ref(false);

const { push } = useRouter();
const { Route, ROUTER_PATH_MAP } = useRoute();
const defaultActive = ref('/' + Route.path.split('/').pop());
const onJump = (path: string) => {
    push(`${ROUTER_PATH_MAP.MENU_PATH}${path}`);
};
</script>

<style lang="scss">
.expand-btn {
}
</style>
