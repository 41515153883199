import type { AxiosResponse, InternalAxiosRequestConfig, AxiosError } from 'axios';
import { ElMessage } from 'element-plus';
import { ROUTER_PATH_MAP } from '@enums';

export const requestSuccess = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    return config;
};

export const requestFail = (error: any) => {
    Promise.reject(error);
};

export const responseSuccess = async (
    response: AxiosResponse<IHttpResponse<any>>
): Promise<any> => {
    const { data } = response;
    if (Object.prototype.toString.call(data) === '[object Object]') {
        const vaild = await handleCode(data.code, data.message);
        return vaild ? data.data : Promise.reject();
    }
    return data;
};

export const responseFail = (error: AxiosError<IHttpResponse<any>>) => {
    const { response } = error;
    if (response) {
        return handleResponseError(response);
    }
    return Promise.reject(error);
};

const handleResponseError = async (response: AxiosResponse<IHttpResponse<any>>) => {
    const { status, data } = response;
    const { message } = data;
    await handleCode(status, message);
    return Promise.reject();
};
const handleCode = async (code: number, message?: string) => {
    switch (true) {
        case /^2\d{2}$/.test(String(code)):
            return true;
        case code === 401:
            {
                if (!/^\/home\//.test(location.pathname)) {
                    window.location.href = `${location.origin}${ROUTER_PATH_MAP.HOME_SIGN_IN_PATH}`;
                }
                ElMessage.warning(message);
            }
            return false;
        case code === 404:
            ElMessage.warning('http.apiNotFound');
            return false;
        case /^5\d{2}$/.test(String(code)):
            ElMessage.warning(`${'http.serviceError'}(${code})`);
            return false;
        default:
            ElMessage.error(message || 'http.unknownMistake');
            return false;
    }
};
