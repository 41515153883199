<template>
    <g-remote-select
        v-bind="$attrs"
        fit-input-width
        v-model="meetingPoint"
        clearable
        :teleported="false"
        :apiService="apiService"
        @change="onChange"
    ></g-remote-select>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { meetingPointRepositories } from '@/repositories';
import { IMeetingPoint } from '@/common/types';

const props = defineProps<{
    modelValue: string;
}>();
const emit = defineEmits<{
    'update:modelValue': [value: string];
    change: [value: string];
}>();

const meetingPoint = ref(props.modelValue);
watch(
    () => props.modelValue,
    () => {
        meetingPoint.value = props.modelValue;
    }
);
const onChange = () => {
    emit('update:modelValue', meetingPoint.value);
    emit('change', meetingPoint.value);
};

const allMeetingPoint = ref<IMeetingPoint[]>([]);
const getMeetingPoint = async () => {
    if (!allMeetingPoint.value.length) {
        allMeetingPoint.value = await meetingPointRepositories.getAllMeetingPointList();
    }
};

const apiService: IApiService<ILabelValue<string>[], string> = async (value?: string) => {
    await getMeetingPoint();
    return allMeetingPoint.value
        .filter(
            (meetingPoint) =>
                !value ||
                ~meetingPoint.locationName.toLocaleLowerCase().indexOf(value.toLocaleLowerCase())
        )
        .map((item) => ({
            label: item.details || '',
            value: item.id
        }));
};
</script>

<style lang="scss" scoped>
::v-deep .remote-select__option {
    white-space: normal;
    height: inherit;
    border-bottom: solid 1px var(--el-border-color);
}
</style>
