import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css';
import { createPinia } from 'pinia';
import i18n from '@/common/i18n';
import '@utils/dateFormat';

import App from './App.vue';
import router from './router';

const app = createApp(App);
console.log(app);
app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(ElementPlus);

const globalComponents: Record<string, any> = import.meta.globEager('./components/global/**/*.vue');
console.log(globalComponents);
Object.keys(globalComponents).forEach((path) => {
    if (/\/(\w+).vue$/.test(path)) {
        app.component(`G${RegExp.$1}`, globalComponents[path].default);
    }
});

app.mount('#app');
