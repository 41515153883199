<template>
    <div class="flex flex-left">
        <el-avatar class="mg-r_10 w_40p" fit="contain" :src="baseUrl + avatar" @error="onError">
            <el-icon size="20"><Avatar /></el-icon>
        </el-avatar>
        <el-text :type="disabled ? 'info' : 'primary'" class="flex1 cs-p" @click="onClick" link>{{
            name
        }}</el-text>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Avatar } from '@element-plus/icons-vue';
import { useRouter } from '@use';
import { ITeamMemberInfo } from '@ctypes';
import { baseUrl } from '@utils';
const props = defineProps<{
    avatar?: string;
    name?: string;
    record?: ITeamMemberInfo;
    disabled?: boolean;
}>();

const isError = ref(false);
const onError = () => {
    isError.value = true;
};
const { push, ROUTER_PATH_MAP } = useRouter();
const onClick = () => {
    if (props.record) {
        push(`${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${props.record.id}`);
    }
};
</script>
