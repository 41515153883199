import { get, post, put, del } from '@/repositories/base';
import { VEHICLE_URL, ROUTE_URL } from '@/common/enums/repositories';
import { IVehicle, IRouterSearch, IRouter, ICarPrice } from '@ctypes';

/**
 * 获取所有交通工具信息
 */
export const getVehicleList = async () => {
    return get<undefined, IVehicle[]>({
        url: VEHICLE_URL
    });
};

/**
 * 搜索线路
 */
export const getSearchRouter = async (data: IRouterSearch) => {
    return get<IRouterSearch, IListResponse<IRouter>>({
        url: ROUTE_URL,
        data: {
            ...data,
            includeUnavailable: false
        }
    });
};

export const getRouterList = async (data: IRouterSearch) => {
    return get<IRecord, IListResponse<IRouter>>({
        url: ROUTE_URL,
        data: {
            ...data,
            includeUnavailable: true
        }
    });
};

/**
 * 创建线路
 */
export const addRouter = async (data: Partial<IRouter>) => {
    return post<Partial<IRouter>, string>({
        url: ROUTE_URL,
        data
    });
};

/**
 * 更新线路
 */
export const updateRouter = async (id: string | number, data: Partial<IRouter & ICarPrice>) => {
    return put<Partial<IRouter>, string>({
        url: `${ROUTE_URL}/${id}`,
        data
    });
};

/**
 * 通过id获取线路信息
 */
export const getRouterById = async (id: string) => {
    return get<undefined, IRouter>({
        url: `${ROUTE_URL}/${id}`
    });
};

/**
 * 删除线路
 */
export const delRouterById = async (id: string) => {
    return del<Partial<IRouter>, string>({
        url: `${ROUTE_URL}/${id}`
    });
};

/**
 * 设置所有线路有效期
 */
export const setAllAvailable = async (data: {
    availableStartTime?: string;
    availableEndTime?: string;
}) => {
    return put<Partial<IRouter>, string>({
        url: `${ROUTE_URL}/all/availableTime`,
        data
    });
};

/**
 * 根据线路获取所有车辆价格
 */
export const getVehiclePriceByRouter = async (routerId: string) => {
    return get<any, ICarPrice[]>({
        url: `${ROUTE_URL}/${routerId}/vehicles`
    });
};

/**
 * 删除线路车辆价格
 */
export const delVehiclePriceById = async (id: number) => {
    return del<ICarPrice[], string>({
        url: `${ROUTE_URL}/vehicle/${id}`
    });
};

/**
 * 导入线路excel
 */
export const importRouterByExcel = async (formData: FormData) => {
    return post<FormData>({
        url: `${ROUTE_URL}/import`,
        data: formData
    });
};
