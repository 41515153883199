import { post, put } from '@/repositories/base';
import { LOGIN_URL, USER_URL, SIGN_OUT_URL } from '@/common/enums/repositories';
import { ISignInInfo, ISignUpInfo } from '@ctypes';

/**
 * 登陆
 */
export const signIn = async (data: ISignInInfo) => {
    return post<ISignInInfo, number>({
        url: LOGIN_URL,
        data
    });
};

/**
 * 注册
 */
export const signUp = async (data: ISignUpInfo) => {
    return post<ISignUpInfo, string>({
        url: USER_URL,
        data
    });
};

/**
 * 退出登陆
 */
export const signOut = async () => {
    return post<undefined, string>({
        url: SIGN_OUT_URL
    });
};

/**
 * 发送重置密码邮件
 */
export const sendResetPasswordEmail = async (email: string) => {
    return post({
        url: `${USER_URL}/email/${email}/resetPassword`
    });
};

/**
 * 重置密码
 */
export const resetPassword = async (token: string, data: { email: string; password: string }) => {
    return put({
        url: `${USER_URL}/password/${token}`,
        data
    });
};
