<template>
    <el-dropdown @command="onChange">
        <el-button link>
            <g-icon type="icon-yuyan" :size="25"></g-icon>
        </el-button>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item
                    v-for="(item, index) in options"
                    :key="index"
                    :value="item.value"
                    :command="item.value"
                >
                    {{ item.label.value }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script lang="ts" setup>
import { Ref } from 'vue';
import { useVueI18n } from '@use';
const { locale, t } = useVueI18n();

const options: ILabelValue<string, Ref<string>>[] = [
    { label: t('lang.en'), value: 'en' },
    { label: t('lang.zhCn'), value: 'zhCn' },
    { label: t('lang.jp'), value: 'jp' }
];

const onChange = (lang: string) => {
    locale.value = lang;
};
</script>
