<template>
    <g-option-form
        ref="editFormRef"
        :labelWidth="140"
        labelPosition="right"
        :model="formModel"
        :options="formOption"
    ></g-option-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useVueI18n } from '@use';
import { getJPTimestamp } from '@utils';
import { useBookingStore } from '@/stores';
import { IInvoiceEdit } from '@/common/types';
import AddItems from './AddItems.vue';

const { tJoin } = useVueI18n();

const editFormRef = ref<OptionFormInstance>();

const { currBooking } = useBookingStore();
const formModel = ref({
    billDate: currBooking.value?.billDate,
    dueDate: currBooking.value?.dueDate,
    billTo: currBooking.value?.billTo || currBooking.value?.userDetails.email,
    taxCommission: currBooking.value?.taxCommission,
    invoiceNote: currBooking.value?.invoiceNote,
    companyNameOrGuestName: currBooking.value?.companyNameOrGuestName,
    addItems: currBooking.value?.feeItems || []
});
const formOption: IFormOption[] = [
    {
        label: tJoin('invoiceList.billDate'),
        prop: 'billDate',
        component: 'elDatePicker',
        props: { format: 'YYYY-MM-DD', valueFormat: 'x' }
    },
    {
        label: tJoin('invoiceList.dueDate'),
        prop: 'dueDate',
        component: 'elDatePicker',
        props: { format: 'YYYY-MM-DD', valueFormat: 'x' }
    },
    {
        label: tJoin('bookingList.agentAndClient'),
        prop: 'companyNameOrGuestName',
        component: 'gSelectAgent',
        props: {
            valueKey: 'companyName'
        }
    },
    { label: tJoin('invoiceList.billTo'), prop: 'billTo' },
    {
        label: tJoin('invoiceList.taxCommissior'),
        prop: 'taxCommission',
        component: 'gSelectCommissior'
    },
    // { label: 'Meeting Point', prop: 'meetingPoint', component: 'gSelectMeetingPoint' },
    { label: tJoin('invoiceList.invoiceNote'), prop: 'invoiceNote', props: { type: 'textarea' } },
    { label: tJoin('invoiceList.addItems'), prop: 'addItems', component: AddItems }
];

const validate = async function () {
    if (editFormRef.value) {
        const formData = await editFormRef.value.validate<IInvoiceEdit>();
        formData.billDate = getJPTimestamp(
            `${new Date(formData.billDate).format('YYYY-MM-DD')} 23:59:59`
        );
        formData.dueDate = getJPTimestamp(
            `${new Date(formData.dueDate).format('YYYY-MM-DD')} 23:59:59`
        );
        return formData;
    }
    return Promise.reject();
};

defineExpose({
    validate
});
</script>
