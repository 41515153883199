<template>
    <g-value-form :options="formOptinos" :model="formModel" :labelWidth="130"></g-value-form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useBookingOrderStore } from '@/stores';
import { useVueI18n } from '@use';

const { peopleDateInfo, routerInfo, carInfo, gvNum, amendInfo } = useBookingOrderStore();

const { t } = useVueI18n();
const formOptinos = ref<any>([
    { label: t('pickUp'), prop: 'pickUpLocation' },
    { label: t('dropOff'), prop: 'dropOffLocation' },
    {
        label: t('serviceDate'),
        prop: 'serviceDate',
        formatValue: (record: IRecord) =>
            record.serviceDate ? new Date(record.serviceDate).format('YYYY-MM-DD') : ''
    },
    { label: t('adults'), prop: 'adultCount' },
    { label: t('child'), prop: 'childCount' },
    { label: t('infants'), prop: 'infantCount' }
]);
const formModel = ref<IRecord>({
    ...peopleDateInfo.value,
    ...routerInfo.value,
    ...carInfo.value
});
if (carInfo.value.name) {
    formOptinos.value.push({ label: t('transportType'), prop: 'name' });
    formOptinos.value.push({
        label: t('totalPrice'),
        prop: 'amount',
        decorate: 'gPrice',
        decorateProps: (record: IRecord) => ({
            price: record.amount
        }),
        formatValue: (record: IRecord, value: string) => `${value} yen`
    });
    if (amendInfo.value?.amount) {
        formModel.value.amount = amendInfo.value.amount;
    } else {
        if (carInfo.value.name === 'Shared Transfer') {
            let total = 0;
            const { adultCount: B, childCount: C } = peopleDateInfo.value;
            const adultPrice = carInfo.value.price;
            const childPrice = adultPrice / 2;
            const A = gvNum.value;
            if (A > B) {
                const D = B + C;
                if (D <= A) {
                    total = A * adultPrice;
                } else {
                    const E = D - A;
                    total = A * adultPrice + E * childPrice;
                }
            } else {
                total = B * adultPrice + C * childPrice;
            }
            formModel.value.amount = total;
        } else {
            formModel.value.amount = carInfo.value.price * carInfo.value.vehicleCount;
        }
    }
}
</script>
