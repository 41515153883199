<template>
    <el-upload v-if="upload" :show-file-list="false" :before-upload="beforeAvatarUpload">
        <el-avatar
            v-if="imageBase64"
            :size="100"
            :icon="Avatar"
            :src="imageBase64"
            style="font-size: 50px"
        ></el-avatar>
        <el-avatar v-else :size="100" :icon="Plus" style="font-size: 40px"> </el-avatar>
    </el-upload>
    <el-avatar v-else :size="100" :icon="Avatar" style="font-size: 50px"></el-avatar>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { ElMessage, UploadProps } from 'element-plus';
import { Avatar, Plus } from '@element-plus/icons-vue';

const props = defineProps<{
    modelValue?: string;
    upload?: boolean;
}>();
const emit = defineEmits<{
    'update:modelValue': [value: string];
    change: [value: string];
}>();

const imageBase64 = ref(props.modelValue || '');

watch(
    () => props.modelValue,
    () => {
        imageBase64.value = props.modelValue || '';
    }
);

const beforeAvatarUpload: UploadProps['beforeUpload'] = async (rawFile) => {
    console.log(rawFile);
    if (rawFile.type !== 'image/jpeg') {
        ElMessage.error('Avatar picture must be JPG format!');
        return false;
    } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('Avatar picture size can not exceed 2MB!');
        return false;
    }
    const base64 = await getBase64(rawFile);
    emit('update:modelValue', base64);
    emit('change', base64);
    return false;
};

const getBase64 = (file: File): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
        reader.onload = (readRes) => {
            if (readRes.target) {
                resolve(readRes.target.result as string);
            }
        };
    });
};
</script>

<style lang="scss" scoped>
::v-deep .el-image__error {
    height: 100%;
    width: 100%;
}
</style>
