import { createI18n } from 'vue-i18n';
import en from './locale/en';
import zhCn from './locale/zh-cn';
import jp from './locale/jp';

const i18n = createI18n({
    locale: 'en',
    legacy: false,
    messages: {
        en,
        jp,
        zhCn
    }
});

export default i18n;
