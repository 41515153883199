import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { useRoute } from '@use';
import { agentRepositories } from '@/repositories';
import { IAgentInfo, IAgentStatistic } from '@/common/types';

/**
 * 储存
 */
export const useAgentStore = () => {
    const store = defineStore('agent', () => {
        const { Route } = useRoute();
        const currAgent = ref<IAgentInfo>();
        /**
         * 获取agent详情
         */
        const getAgentInfo = async () => {
            if (Route.params.id as string) {
                currAgent.value = await agentRepositories.getAgentInfo(Route.params.id as string);
            }
        };

        /**
         * 获取agent统计金额
         */
        const agentStatistic = ref<IAgentStatistic>();
        const getAgentStatistic = async () => {
            if (currAgent.value?.id) {
                agentStatistic.value = await agentRepositories.getAgentStatistic(
                    currAgent.value.id
                );
            }
        };

        const isloadingUpdateAgent = ref(false);
        /**
         * 更新agent基本信息
         */
        const updateAgentInfo = async (data: IRecord) => {
            if (currAgent.value?.id) {
                isloadingUpdateAgent.value = true;
                try {
                    await agentRepositories.updateAgentById(currAgent.value.id, data);
                    isloadingUpdateAgent.value = false;
                    return Promise.resolve();
                } catch (e) {
                    console.log(e);
                }
                isloadingUpdateAgent.value = false;
            }
            return Promise.reject();
        };

        /**
         * 获取agent支付列表
         */
        const getPaymentList = async (data: IRecord) => {
            if (Route.params.id) {
                return await agentRepositories.getAgentPaymentList(Route.params.id as string, data);
            }
            return {
                records: [],
                total: 0
            };
        };

        const clientGroupList = ref([]);

        /**
         * 获取clientGroups
         */
        const getClientGroupList = async () => {
            const res = await agentRepositories.getClientGroupList();
            console.log(res);
        };

        const getAgentInvoiceList = async (data: IPage) => {
            return await agentRepositories.getAgentInvoice(Route.params.id as string, data);
        };
        return {
            currAgent,
            getAgentInfo,
            agentStatistic,
            clientGroupList,
            getAgentStatistic,
            isloadingUpdateAgent,
            updateAgentInfo,
            getPaymentList,
            getAgentInvoiceList
        };
    });
    return {
        ...store(),
        ...storeToRefs(store())
    };
};
