<template>
    <ul
        class="of-h relative pd-b_30 fc_6"
        :style="{
            maxHeight: maxHeight
        }"
    >
        <li v-for="(log, index) in orderLog" :key="index" class="fs_12">
            <span class="mg-r_10">----></span>
            {{ new Date(log.createTime).format('YYYY-MM-DD HH:mm:ss') }}
            {{ `${log.userFirstName} ${log.userLastName}` }}:
            {{ log.log }}
        </li>
        <div
            v-if="orderLog.length > 4"
            class="expand-bar absolute-b w_100 flex h_30p cs-p"
            @click="onExpand"
        >
            <el-icon
                class="info"
                size="20"
                :class="{
                    rotate_90: !isExpand,
                    'rotate-r_90': isExpand
                }"
                ><DArrowRight
            /></el-icon>
        </div>
    </ul>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { DArrowRight } from '@element-plus/icons-vue';
import { useBookingStore } from '@/stores';

const { orderLog, getOrderLog } = useBookingStore();

const maxHeight = computed(() => {
    return isExpand.value ? 'inherit' : '40px';
});
const isExpand = ref(false);
const onExpand = () => {
    isExpand.value = !isExpand.value;
};

getOrderLog();
</script>

<style lang="scss" scoped>
.expand-bar {
    background-image: linear-gradient(0deg, var(--white-color), var(--white-color-opacity-0));
}
</style>
