<template>
    <payment-card></payment-card>
    <el-tabs>
        <el-tab-pane :label="$t('agent.contacts')">
            <contact-list></contact-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('agent.agentInfo')">
            <client-info></client-info>
        </el-tab-pane>
        <el-tab-pane :label="$t('agent.invoices')">
            <invoice-list></invoice-list>
        </el-tab-pane>
        <el-tab-pane :label="$t('agent.payments')">
            <payment-list></payment-list>
        </el-tab-pane>
    </el-tabs>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { useRoute } from '@use';
import { useAgentStore } from '@/stores';
import PaymentCard from './agentDetail/PaymentCard.vue';
import ContactList from './agentDetail/ContactList.vue';
import ClientInfo from './agentDetail/ClientInfo.vue';
import PaymentList from './agentDetail/PaymentList.vue';
import InvoiceList from './agentDetail/InvoiceList.vue';
const { getAgentInfo, getAgentStatistic } = useAgentStore();
const queryData = async () => {
    await getAgentInfo();
    await getAgentStatistic();
};
queryData();

const { Route, ROUTER_PATH_MAP } = useRoute();
watch(Route, () => {
    if (~Route.path.indexOf(ROUTER_PATH_MAP.MENU_AGENT_DETAIL)) {
        queryData();
    }
});
</script>
