<template>
    <g-page-table
        ref="pageTableRef"
        :storageable="false"
        :options="tableOptions"
        :api-service="getPaymentList"
    ></g-page-table>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRoute, useVueI18n } from '@use';
import { useAgentStore } from '@/stores';
import { IPayment } from '@/common/types';

const { tJoin } = useVueI18n();

const { getPaymentList } = useAgentStore();
const tableOptions: ITableOption[] = [
    {
        label: tJoin('paymentList.payDate'),
        prop: 'payTime',
        canSearch: true,
        decorate: 'gTimestamp',
        decorateProps: (record: IPayment) => ({
            timestamp: record.payTime
        }),
        component: 'elDatePicker',
        formProps: {
            valueFormat: 'x',
            type: 'daterange'
        },
        handleParam: (value: string[]) => ({
            startPayTime: value[0],
            endPayTime: value[1]
        }),
        props: { width: 120 }
    },
    { label: tJoin('paymentList.payMethod'), prop: 'payMethod', props: { width: 150 } },
    { label: tJoin('paymentList.note'), prop: 'notes' },
    {
        label: tJoin('paymentList.amount'),
        prop: 'amount',
        decorate: 'gPrice',
        decorateProps: (record: IPayment) => ({
            price: record.amount,
            currency: record.currency
        })
    }
];
const pageTableRef = ref<PageTableInstance>();
const { Route, ROUTER_PATH_MAP } = useRoute();
watch(Route, () => {
    if (~Route.path.indexOf(ROUTER_PATH_MAP.MENU_AGENT_DETAIL) && pageTableRef.value) {
        pageTableRef.value.getData();
    }
});
</script>
