<template>
    <logo class="h_80p mg-y_50" :size="1"></logo>
    <div class="pd-x_50">
        <sign-in-form ref="signInFormRef"></sign-in-form>
        <!-- <p class="flex flex-left">
            <span class="ellipsis">{{ $t('login.agreeText') }}</span>
            <el-button class="ellipsis" link type="primary" @click="onTerms">{{
                $t('login.termsAndConditions')
            }}</el-button>
            <el-checkbox v-model="isAgreed"></el-checkbox>
        </p> -->
        <el-button
            type="primary"
            class="w_100 mg-t_20 h_50p"
            :loading="isLoading"
            @click="onSignIn"
            >{{ $t('signIn') }}</el-button
        >
        <el-button class="mg-l_0 mg-t_5" link type="primary" @click="onFindPassword"
            >{{ $t('login.forgotPassword') }}?</el-button
        >
        <p class="flex flex-left mg-t_20">
            {{ $t('login.forgotPasswordTip') }}
            <el-button link type="primary" @click="onSignUp">{{ $t('signUp') }}</el-button>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { ref, toValue } from 'vue';
import { ElMessage } from 'element-plus';
import { useVueI18n, useRouter } from '@use';
import { ISignInInfo } from '@ctypes';
import { useLoginStore } from '@/stores';
import Logo from '../header/Logo.vue';
import SignInForm from './SignInForm.vue';

const emit = defineEmits<{
    (e: 'jump'): void;
}>();

const { tJoin } = useVueI18n();

const signInFormRef = ref<OptionFormInstance>();
const validate = async function <T>(): Promise<T> {
    if (signInFormRef.value) {
        return await signInFormRef.value.validate<T>();
    }
    return Promise.reject();
};

const { push, ROUTER_PATH_MAP } = useRouter();

const onTerms = () => {
    window.open('/term.pdf', '_blank');
};

const { signIn, isLoading } = useLoginStore();
const isAgreed = ref(true);
const onSignIn = async () => {
    if (!isAgreed.value) {
        ElMessage.warning(toValue(tJoin('pleaseAgree', 'login.termsAndConditions')));
        return;
    }
    const model = await validate<ISignInInfo>();
    await signIn(model);
    ElMessage.success(tJoin('signIn', 'success').value);
    push(ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH);
};

const onFindPassword = () => {
    emit('jump');
    push(ROUTER_PATH_MAP.HOME_FIND_PASSWORD_PATH);
};
const onSignUp = () => {
    emit('jump');
    push(ROUTER_PATH_MAP.HOME_SIGN_UP_PATH);
};
defineExpose({
    validate
});
</script>
