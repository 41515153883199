import { get, post, put } from '@/repositories/base';
import { USER_URL, TEAM_USER_URL, ROLE_TEAM_URL } from '@/common/enums/repositories';
import { IUserInfo, ITeamMemberInfo, ITeamMemberBaseInfo, IRole, IBaseUserInfo } from '@ctypes';

/**
 * 获取用户信息
 */
export const getUser = async () => {
    return get<undefined, IUserInfo>({
        url: USER_URL
    });
};

/**
 * 更新登陆用户信息
 */
export const updateUser = async (data: Partial<IUserInfo>) => {
    return put<Partial<IUserInfo>, IUserInfo>({
        url: USER_URL,
        data
    });
};

/**
 * 更新指定用户信息
 */
export const updateUserById = async (userId: string, data: Partial<IUserInfo>) => {
    return put<Partial<IUserInfo>, boolean>({
        url: `${USER_URL}/${userId}`,
        data
    });
};

/**
 * 更新用户自己秘密
 */
export const updateUserPassword = async (password: string) => {
    return put<{ password: string }, boolean>({
        url: `${USER_URL}/password`,
        data: {
            password
        }
    });
};

/**
 * 根据id获取用户信息
 * @param data
 * @returns
 */
export const getUserInfoById = async (userId: string) => {
    return get<undefined, IUserInfo>({
        url: `${USER_URL}/${userId}`
    });
};

/**
 * 获取team member列表用户
 */
export const getTeamMemberList = async (data: Record<string, any>) => {
    return get<Record<string, any>, IListResponse<ITeamMemberInfo>>({
        url: TEAM_USER_URL,
        data
    });
};

/**
 * 新增team member角色
 */
export const addRole = async (data: ITeamMemberBaseInfo) => {
    return post<ITeamMemberBaseInfo, ITeamMemberInfo>({
        url: TEAM_USER_URL,
        data
    });
};

/**
 * 修改team member角色
 */
export const updateRole = async (data: ITeamMemberBaseInfo) => {
    return put<ITeamMemberBaseInfo, ITeamMemberInfo>({
        url: TEAM_USER_URL,
        data
    });
};

/**
 * 获取team member角色列表
 */
export const getRoleList = async () => {
    return get<undefined, IRole[]>({
        url: ROLE_TEAM_URL
    });
};

/**
 * 获取所有车公司
 */
export const getAllCarCompany = async () => {
    return get<undefined, IBaseUserInfo[]>({
        url: `${USER_URL}/carCompany`
    });
};

/**
 * 获取车公司下所有员工
 */
export const getDriverByCarCompany = async (carCompany: string) => {
    return get<undefined, ITeamMemberInfo[]>({
        url: `${USER_URL}/driver/${carCompany}`
    });
};

/**
 * 分页搜索guset/agent用户列表
 */
export const getGusetAgentList = async (data: IPage & { name?: string }) => {
    return get<IPage, IListResponse<IUserInfo>>({
        url: `${USER_URL}/search/guestAgent`,
        data
    });
};

/**
 * 锁定用户
 */
export const lockUser = async (userId: string) => {
    return put({
        url: `${USER_URL}/lock/${userId}`
    });
};

/**
 * 解锁用户
 */
export const unlockUser = async (userId: string) => {
    return put({
        url: `${USER_URL}/unlock/${userId}`
    });
};

/**
 * 搜索车公司/车司机
 */
export const getCarCompanyAndDriverList = async (data: IPage & { name: string }) => {
    return get<IPage, IListResponse<ITeamMemberInfo>>({
        url: `${USER_URL}/search/carCompanyDriver`,
        data
    });
};
