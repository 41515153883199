import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import en from 'element-plus/lib/locale/lang/en';
import ja from 'element-plus/lib/locale/lang/ja';

const elLocaleMap: Record<string, any> = {
    zhCn,
    en,
    jp: ja
};
export const useVueI18n = () => {
    const { t: $t, locale } = useI18n();
    const elLocale = computed(() => elLocaleMap[locale.value]);
    const t = (name: string, params?: any) => {
        return computed(() => (name ? $t(name, params) : ''));
    };
    const tJoin = (...args: string[]) => {
        return computed(() =>
            args.reduce((a, b) => {
                return t(a).value + t(b).value;
            }, '')
        );
    };
    return {
        t,
        $t,
        tJoin,
        locale,
        elLocale
    };
};
