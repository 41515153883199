<template>
    <h1>{{ $t('japanTravelServices') }}</h1>
    <p class="mg-b_20">
        {{ $t('japanTravelServicesInfo') }}
    </p>
    <div class="w_200p h_100p mg-x_a mg-b_50">
        <logo class="h_80p mg-y_50"></logo>
    </div>
    <order-router ref="orderRouterRef"></order-router>
    <order-people ref="orderPeopleRef"></order-people>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import Logo from '@/components/header/Logo.vue';
import OrderRouter from '@/components/bookingOrder/orderHome/OrderRouter.vue';
import OrderPeople from '@/components/bookingOrder/orderHome/OrderPeople.vue';

const orderPeopleRef = ref<InstanceType<typeof OrderPeople>>();
const orderRouterRef = ref<InstanceType<typeof OrderRouter>>();
const validate = async () => {
    const promises = [];
    if (orderPeopleRef.value) {
        promises.push(orderPeopleRef.value.validate());
    }
    if (orderRouterRef.value) {
        promises.push(orderRouterRef.value.validate());
    }
    return await Promise.all(promises);
};

defineExpose({
    validate
});
</script>

<style lang="scss" scoped></style>
