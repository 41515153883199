import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import { ISignInInfo, ISignUpInfo, IMenuItem, IUserInfo, IRole } from '@ctypes';
import { useRouter } from '@use';
import { loginRepositories, userRepositories } from '@api';
import { queryMenuData } from '@mock';
import { RoleEnum, BookingStatusList, BookingStatusEnum } from '@enums';

/**
 * 储存用户信息
 */
export const useUserStore = () => {
    const store = defineStore('user', () => {
        /**
         * 当前登陆人信息
         */
        const userInfo = ref<IUserInfo>();
        const isLoadingUserInfo = ref(false);

        const hasAgent = computed(
            () => !!userInfo.value?.roleList.find((role) => role.name === RoleEnum.Agent)
        );
        const hasGuest = computed(
            () => !!userInfo.value?.roleList.find((role) => role.name === RoleEnum.Guest)
        );
        const hasRole = (role: RoleEnum) => {
            return computed(
                () => !!userInfo.value?.roleList.find((roleItem) => roleItem.name === role)
            );
        };

        /**
         * 角色列表
         */
        const roleList = ref<IRole[]>([]);

        const isAdmin = computed(
            () =>
                !!userInfo.value?.roleList.find((role) =>
                    [RoleEnum.SuperAdmin, RoleEnum.SubAdmin, RoleEnum.Operator].includes(role.name)
                )
        );

        const isSuperAdmin = hasRole(RoleEnum.SuperAdmin);

        const isCarCompany = computed(() => {
            return !!userInfo.value?.roleList.find((role) =>
                [RoleEnum.CarCompany].includes(role.name)
            );
        });

        const isDriver = computed(() => {
            return !!userInfo.value?.roleList.find((role) => [RoleEnum.Driver].includes(role.name));
        });

        const isOnlyLook = computed(() =>
            userInfo.value?.roleList.find((role) =>
                [RoleEnum.Driver, RoleEnum.Staff].includes(role.name)
            )
        );

        const bookingStatusList = computed(() => {
            if (!isAdmin.value) {
                return BookingStatusList.filter((status) => {
                    return ![
                        BookingStatusEnum.PREBOOK,
                        BookingStatusEnum.VERIFIED,
                        BookingStatusEnum.TOKYO_FINAL,
                        BookingStatusEnum.TEHAI,
                        BookingStatusEnum.COMPLETED
                    ].includes(status);
                });
            }
            return BookingStatusList;
        });

        /**
         * 当前选中人的信息
         */
        const currUserInfo = ref<IUserInfo>();

        const canEditCurrUserInfo = computed(() => {
            if (!currUserInfo.value) {
                return false;
            }
            if (hasRole(RoleEnum.SuperAdmin).value) {
                return true;
            } else if (hasRole(RoleEnum.SubAdmin).value) {
                return !currUserInfo.value?.roleList.find((role) => {
                    return [RoleEnum.SuperAdmin].includes(role.name);
                });
            } else if (hasRole(RoleEnum.Operator).value) {
                return !currUserInfo.value?.roleList.find((role) => {
                    return [RoleEnum.SuperAdmin, RoleEnum.SubAdmin].includes(role.name);
                });
            }
            return false;
        });

        const isLoadingCurrUser = ref(false);

        /**
         * 菜单列表
         */
        const menuList = ref<IMenuItem[]>([]);

        const isSameUser = computed(() => {
            return userInfo.value?.email === currUserInfo.value?.email;
        });

        /**
         * 获取菜单权限
         */
        const getMenuData = async () => {
            menuList.value = queryMenuData();
        };

        /**
         * 获取用户信息
         */
        const getUserInfo = async () => {
            isLoadingUserInfo.value = true;
            try {
                userInfo.value = await userRepositories.getUser();
                await getMenuData();
                await getRoleList();
                const { isSignIn } = useLoginStore();
                isSignIn.value = true;
            } catch (e) {
                console.log(e);
            }
            isLoadingUserInfo.value = false;
        };

        /**
         * 获取角色列表
         */
        const getRoleList = async () => {
            userInfo.value?.roleList.find((role) => {
                switch (role.name) {
                    case RoleEnum.SuperAdmin:
                    case RoleEnum.SubAdmin:
                    case RoleEnum.Operator:
                        userRepositories.getRoleList().then((data) => (roleList.value = data));
                        break;
                }
            });
        };

        /**
         * 根据id获取用户信息
         */
        const getUserInfoById = async (id: string) => {
            if (userInfo.value) {
                isLoadingCurrUser.value = true;
                try {
                    if (id == userInfo.value.id) {
                        currUserInfo.value = await userRepositories.getUser();
                    } else {
                        currUserInfo.value = await userRepositories.getUserInfoById(id);
                    }
                } catch (e) {
                    console.log(e);
                }
                isLoadingCurrUser.value = false;
            }
        };

        /**
         * 更新用户信息
         * @param data 用户信息
         */
        const updateUserInfo = async (id: string, data: Partial<IUserInfo>) => {
            if (userInfo.value) {
                isLoadingCurrUser.value = true;
                try {
                    if (id == userInfo.value.id) {
                        await userRepositories.updateUser(data);
                    } else {
                        await userRepositories.updateUserById(id, data);
                    }
                    await getUserInfoById(id);
                } catch (e) {
                    console.log(e);
                }
                isLoadingCurrUser.value = false;
            }
        };

        return {
            isAdmin,
            isSuperAdmin,
            isCarCompany,
            isDriver,
            isLoadingUserInfo,
            isOnlyLook,
            isSameUser,
            hasAgent,
            userInfo,
            roleList,
            menuList,
            currUserInfo,
            canEditCurrUserInfo,
            isLoadingCurrUser,
            bookingStatusList,
            hasRole,
            getUserInfo,
            getMenuData,
            getRoleList,
            getUserInfoById,
            updateUserInfo
        };
    });
    return {
        ...store(),
        ...storeToRefs(store())
    };
};

/**
 *
 * 储存登陆信息
 */
export const useLoginStore = () => {
    const store = defineStore('login', () => {
        const isLoading = ref(false);
        /**
         * 是否登陆
         */
        const isSignIn = ref(false);

        /**
         * 注册信息
         */
        const signUpInfo = ref({});

        /**
         * 登陆逻辑
         */
        const signIn = async (data: ISignInInfo) => {
            isLoading.value = true;
            const { getUserInfo } = useUserStore();
            try {
                await loginRepositories.signIn(data);
                await getUserInfo();
                isLoading.value = false;
                return true;
            } catch (e) {
                console.log(e);
            }
            isLoading.value = false;
            return Promise.reject();
        };

        /**
         * 注册逻辑
         */
        const signUp = async (info: ISignUpInfo) => {
            isLoading.value = true;
            try {
                await loginRepositories.signUp(info);
                isLoading.value = false;
                return true;
            } catch (e) {
                console.log(e);
            }
            isLoading.value = false;
            return Promise.reject();
        };

        /**
         * 退出登陆逻辑
         */
        const { Router, ROUTER_PATH_MAP } = useRouter();
        const signOut = async () => {
            await loginRepositories.signOut();
            isSignIn.value = false;
            Router.push(ROUTER_PATH_MAP.HOME_SIGN_IN_PATH);
        };

        return {
            isLoading,
            isSignIn,
            signUpInfo,
            signIn,
            signUp,
            signOut
        };
    });
    return {
        ...store(),
        ...storeToRefs(store())
    };
};
