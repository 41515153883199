<template>
    <div
        class="ta-c flex"
        :class="{
            'cs-p': clickToHome
        }"
        :style="{
            transform: `scale(${size})`
        }"
        @click="onClick"
    >
        <img class="h_100" :src="Logo" />
        <img class="h_100" :src="Logo3" />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useLoginStore } from '@/stores';
import { useRouter } from '@use';
import Logo from '@/assets/images/logo.png';
import Logo1 from '@/assets/images/logo1.png';
import Logo2 from '@/assets/images/logo2.png';
import Logo3 from '@/assets/images/logo3.png';
const props = withDefaults(
    defineProps<{
        clickToHome?: boolean;
        type?: 1 | 2 | 3;
        size?: number;
    }>(),
    {
        size: 1,
        type: 1
    }
);

const logo = computed(() => {
    switch (props.type) {
        case 1:
            return Logo1;
        case 2:
            return Logo2;
        case 3:
            return Logo3;
        default:
            return Logo1;
    }
});
const logoHeight = computed(() => {
    switch (props.type) {
        case 1:
            return '120px';
        case 2:
            return '60%';
        case 3:
            return '100%';
        default:
            return '60%';
    }
});

const { isSignIn } = useLoginStore();

const { push, ROUTER_PATH_MAP } = useRouter();
const onClick = () => {
    if (props.clickToHome) {
        if (isSignIn.value) {
            push(ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH);
        } else {
            push(ROUTER_PATH_MAP.HOME_BOOKING_PATH);
        }
    }
};
</script>
