<template>
    <el-radio-group v-model="pickUp" class="ml-4" @change="onChange">
        <el-radio v-if="pickUpType === 'am'" label="8:30am ~ 9:30am" size="large">
            8:30am ~ 9:30am
        </el-radio>
        <el-radio v-if="pickUpType === 'am'" label="10:30am ~ 11:30am" size="large">
            10:30am ~ 11:30am
        </el-radio>
        <el-radio v-if="pickUpType === 'pm'" label="17:00pm" size="large">17:00 pm</el-radio>
        <el-radio v-if="pickUpType === 'pm'" label="21:00pm" size="large">21:00 pm</el-radio>
    </el-radio-group>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useBookingOrderStore } from '@/stores';
import { RouterTypeEnum } from '@enums';
import { useForm } from '@use';

const props = defineProps<{
    modelValue: string;
    unit?: 'am' | 'pm';
    prop?: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
}>();

const pickUp = ref(props.modelValue);
const { routerInfo } = useBookingOrderStore();
const pickUpType = computed(() => {
    if (props.unit && ['am', 'pm'].includes(props.unit)) {
        return props.unit;
    }
    if (routerInfo.value.pickUpLocationType === RouterTypeEnum.ACTIVITY) {
        return 'pm';
    }
    if (routerInfo.value.dropOffLocationType === RouterTypeEnum.ACTIVITY) {
        return 'am';
    }
    return '';
});

const { formContext } = useForm();
const onChange = () => {
    emit('update:modelValue', pickUp.value);
    props.prop && formContext?.value?.validateField([props.prop]);
};
</script>
