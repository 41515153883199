import { ref, computed } from 'vue';
import { BookingStatusType, RouterTypeEnum, RoleEnum, InvoiceStatusList } from '@enums';
import { useVueI18n, useOptions, useRouter } from '@use';
import { useUserStore, useBookingStore } from '@/stores';
import {
    IAcitiviyDetailInfo,
    IAirportDetailInfo,
    IBooking,
    ICityDetailInfo,
    IPortDetailInfo,
    IRoute,
    IStationDetailInfo
} from '@/common/types';
import { getJPDate, getJPTimestamp } from '@utils';

export const useRouteTypeDetailInfo = () => {
    const getAirportCityAttraction = (
        record: Partial<IBooking>,
        type: 'pickUp' | 'dropOff' = 'pickUp',
        language: 'EN' | 'JP' = 'EN'
    ) => {
        const typeField = type === 'pickUp' ? 'pickUpLocationType' : 'dropOffLocationType';
        const field = type === 'pickUp' ? 'pickUpDetails' : 'dropOffDetails';
        switch (record[typeField] as RouterTypeEnum) {
            case RouterTypeEnum.AIRPORT:
                if (language === 'JP' && (record[field] as IAirportDetailInfo).airportNameJP) {
                    return (record[field] as IAirportDetailInfo).airportNameJP;
                }
                return (record[field] as IAirportDetailInfo).airportName;
            case RouterTypeEnum.CITY:
                if (language === 'JP' && (record[field] as ICityDetailInfo).cityNameJP) {
                    return (record[field] as ICityDetailInfo).cityNameJP;
                }
                return (record[field] as ICityDetailInfo).cityName;
            case RouterTypeEnum.PORT:
                if (language === 'JP' && (record[field] as IPortDetailInfo).portNameJP) {
                    return (record[field] as IPortDetailInfo).portNameJP;
                }
                return (record[field] as IPortDetailInfo).portName;
            case RouterTypeEnum.ACTIVITY:
                if (language === 'JP' && (record[field] as IAcitiviyDetailInfo).activityNameJP) {
                    return (record[field] as IAcitiviyDetailInfo).activityNameJP;
                }
                return (record[field] as IAcitiviyDetailInfo).activityName;
            case RouterTypeEnum.STATION:
                if (language === 'JP' && (record[field] as IStationDetailInfo).stationNameJP) {
                    return (record[field] as IStationDetailInfo).stationNameJP;
                }
                return (record[field] as IStationDetailInfo).stationName;
            default:
                return '';
        }
    };
    const getName = (
        record: IBooking,
        type: 'pickUp' | 'dropOff' = 'pickUp',
        language: 'EN' | 'JP' = 'EN'
    ) => {
        const typeField = type === 'pickUp' ? 'pickUpLocationType' : 'dropOffLocationType';
        const field = type === 'pickUp' ? 'pickUpDetails' : 'dropOffDetails';
        switch (record[typeField] as RouterTypeEnum) {
            case RouterTypeEnum.AIRPORT:
                return (record[field] as IAirportDetailInfo).flightNumber;
            case RouterTypeEnum.CITY: {
                const { pickUp, hotelInfo } = record[field] as ICityDetailInfo;
                if (hotelInfo && pickUp === hotelInfo?.hotelName) {
                    // 说明没变化可以翻译
                    if (language === 'EN') {
                        return pickUp;
                    }
                    return hotelInfo?.hotelNameJp || pickUp;
                }
                return pickUp;
            }
            case RouterTypeEnum.PORT:
                return (record[field] as IPortDetailInfo).portName;
            case RouterTypeEnum.ACTIVITY:
                return '';
            case RouterTypeEnum.STATION:
                return (record[field] as IStationDetailInfo).trainName;
            default:
                return '';
        }
    };
    const getAddress = (
        record: IBooking,
        type: 'pickUp' | 'dropOff' = 'pickUp',
        language: 'EN' | 'JP' = 'EN'
    ) => {
        const typeField = type === 'pickUp' ? 'pickUpLocationType' : 'dropOffLocationType';
        const field = type === 'pickUp' ? 'pickUpDetails' : 'dropOffDetails';
        switch (record[typeField] as RouterTypeEnum) {
            case RouterTypeEnum.AIRPORT:
                return (record[field] as IAirportDetailInfo).filghtDetail;
            case RouterTypeEnum.CITY: {
                const { detail, hotelInfo } = record[field] as ICityDetailInfo;
                if (hotelInfo && typeof detail === 'string') {
                    const [address, ...args] = detail.split(/\n/);
                    if (address === hotelInfo?.address) {
                        // 说明没变化可以翻译
                        if (language === 'EN') {
                            return detail;
                        }
                        return `${hotelInfo?.addressJp || detail}\n${args.join('\n')}`;
                    }
                }
                return detail;
            }
            case RouterTypeEnum.PORT:
                return (record[field] as IPortDetailInfo).cruiseName;
            case RouterTypeEnum.ACTIVITY:
                return '';
            case RouterTypeEnum.STATION:
                return (record[field] as IStationDetailInfo).route;
            default:
                return '';
        }
    };
    const parseFilghtDetail = (filghtDetail: string) => {
        if (filghtDetail) {
            try {
                const [start, end, startTime, endTime] = filghtDetail.split('/');
                return [start, end, startTime, endTime];
            } catch (e) {
                console.log(e);
            }
        }
        return ['', '', '', ''];
    };
    const getET = (record: Partial<IBooking>, type: 'pickUp' | 'dropOff' = 'pickUp') => {
        const typeField = type === 'pickUp' ? 'pickUpLocationType' : 'dropOffLocationType';
        const field = type === 'pickUp' ? 'pickUpDetails' : 'dropOffDetails';
        switch (record[typeField] as RouterTypeEnum) {
            case RouterTypeEnum.AIRPORT:
                return parseFilghtDetail((record[field] as IAirportDetailInfo).filghtDetail)[
                    type === 'pickUp' ? 3 : 2
                ];
            case RouterTypeEnum.CITY: {
                const { pickUpTime } = record[field] as IAcitiviyDetailInfo;
                if (isNaN(Number(pickUpTime))) {
                    return pickUpTime;
                }
                return pickUpTime ? getJPDate(pickUpTime).format('HH:mm') : '';
            }
            case RouterTypeEnum.PORT: {
                const { cruiseTime } = record[field] as IPortDetailInfo;
                if (isNaN(Number(cruiseTime))) {
                    return cruiseTime;
                }
                return cruiseTime ? getJPDate(cruiseTime).format('HH:mm') : '';
            }
            case RouterTypeEnum.ACTIVITY: {
                const { pickUpTime } = record[field] as IAcitiviyDetailInfo;
                if (isNaN(Number(pickUpTime))) {
                    return pickUpTime;
                }
                return pickUpTime ? getJPDate(pickUpTime).format('HH:mm') : '';
            }
            case RouterTypeEnum.STATION: {
                const regex = /DEPART:[\W\w]+\(ETD:([\W\w]+)\)\/ARR:[\W\w]+\(ETA:([\W\w]+)\)/;
                if (regex.test((record[field] as IStationDetailInfo).route)) {
                    return type === 'pickUp' ? RegExp.$2 : RegExp.$1;
                }
                return '';
            }
            default:
                return '';
        }
    };
    return {
        getAirportCityAttraction,
        getName,
        getAddress,
        getET
    };
};
export const useBookingTableOptions = (type: 'booking' | 'invoice' = 'booking') => {
    const { tJoin } = useVueI18n();
    const { getBaseProps } = useOptions();
    const { userInfo, bookingStatusList, isAdmin } = useUserStore();
    const { Router, ROUTER_PATH_MAP } = useRouter();
    const { hasAlreadyAssignTo } = useBookingStore();

    const pageTableRef = ref<PageTableInstance>();
    const { getAirportCityAttraction, getName, getAddress, getET } = useRouteTypeDetailInfo();
    const options: Array<ITableOption & { type?: 'booking' | 'invoice' }> = [
        {
            label: tJoin('bookingList.uniqueReferenceNo'),
            prop: 'uniqueReferenceNo',
            canSearch: true,
            width: 130,
            decorate: 'gReferenceNoCol',
            decorateProps: (row: IBooking) => ({
                id: row.uniqueReferenceNo,
                agentNo: row.referenceNo,
                data: row
            }),
            props: {
                fixed: 'left'
            },
            formatValue: (record: IBooking) => {
                let value = record.uniqueReferenceNo;
                if (record.referenceNo) {
                    value += `
    ${record.referenceNo}`;
                }
                return value;
            }
        },
        {
            label: tJoin('bookingList.status'),
            prop: 'status',
            props: {
                fixed: 'left',
                width: 120
            },
            canSearch: true,
            component: 'elSelect',
            decorate: 'gStatusCol',
            decorateProps: (record: IBooking) => ({
                status: record.status,
                isAmended: record.isAmended
            }),
            formatValue: (record: IBooking, value: any) => {
                return tJoin(`status.${value}`).value;
            },
            formProps: {
                options: bookingStatusList.value.map((status) => ({
                    label: tJoin(`status.${status}`).value,
                    value: status
                }))
            },
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.agentAndClient'),
            prop: 'agentCompanyName',
            canSearch: isAdmin.value,
            width: 180,
            decorate: 'elButton',
            decorateProps: (row: IBooking) => ({
                link: true,
                type: 'primary',
                onClick: () => {
                    if (row.agentId) {
                        Router.push({
                            path: `${ROUTER_PATH_MAP.MENU_AGENT_DETAIL}/${row.agentId}`
                        });
                    } else if (row.userDetails?.id) {
                        Router.push({
                            path: `${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${row.userDetails.id}`
                        });
                    }
                }
            }),
            formatValue: (record: IBooking) => {
                if (record.agentCompanyName) {
                    return record.agentCompanyName;
                }
                return `${record.userDetails.firstName} ${record.userDetails.lastName}`;
            },
            component: 'gSelectAgent',
            props: {
                valueKey: 'companyName'
            },
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`
        },
        {
            label: tJoin('bookingList.guestName'),
            canSearch: true,
            prop: 'guestNames',
            width: 120
        },
        {
            label: tJoin('bookingList.guestInfo'),
            prop: 'sns',
            width: 120,
            belong: `${RoleEnum.Operator},${RoleEnum.Agent},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`,
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.bookedDate'),
            prop: 'createTime',
            width: 120,
            canSearch: true,
            component: 'elDatePicker',
            decorate: 'gTimestamp',
            decorateProps: (record: IBooking) => ({
                timestamp: record.createTime,
                format: 'YYYY-MM-DD HH:mm:ss'
            }),
            props: {
                ...getBaseProps('elDatePicker'),
                type: 'daterange'
            },
            handleParam: (value: string[]) => ({
                startCreateTime: new Date(
                    `${new Date(value[0]).format('YYYY-MM-DD')} 00:00:00`
                ).getTime(),
                endCreateTime: new Date(
                    `${new Date(value[1]).format('YYYY-MM-DD')} 23:59:59`
                ).getTime()
            }),
            formatValue: (record: IBooking) => new Date(record.createTime).format('YYYY-MM-DD'),
            type: 'booking',
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.updateDate'),
            prop: 'updateTime',
            canSearch: true,
            component: 'elDatePicker',
            width: 120,
            decorate: 'gTimestamp',
            decorateProps: (record: IBooking) => ({
                timestamp: record.updateTime,
                format: 'YYYY-MM-DD HH:mm:ss'
            }),
            props: {
                ...getBaseProps('elDatePicker'),
                type: 'daterange'
            },
            handleParam: (value: string[]) => ({
                startUpdateTime: new Date(
                    `${new Date(value[0]).format('YYYY-MM-DD')} 00:00:00`
                ).getTime(),
                endUpdateTime: new Date(
                    `${new Date(value[1]).format('YYYY-MM-DD')} 23:59:59`
                ).getTime()
            }),
            formatValue: (record: IBooking) => new Date(record.updateTime).format('YYYY-MM-DD'),
            type: 'booking'
        },
        {
            label: tJoin('bookingList.destinationInfo'),
            prop: 'routeName',
            width: 140,
            type: 'booking',
            decorate: 'gDestinationInfoCol',
            decorateProps: (record: IBooking) => {
                return {
                    record
                };
            },
            formatValue: (record: IBooking, value: string, language: 'EN' | 'JP' = 'EN') => {
                if (language === 'JP') {
                    return record.routeNameJp || record.routeName;
                }
                return record.routeName;
            },
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.serviceDate'),
            prop: 'serviceDate',
            width: 120,
            canSearch: true,
            // component: 'gDatePickerJP',
            component: 'elDatePicker',
            decorate: 'gServiceDate',
            decorateProps: (record: IBooking, value: string) => ({
                value,
                pickUpFilghtDate: record.pickUpDetails?.departureDate,
                dropOffFilghtDate: record.dropOffDetails?.departureDate
            }),
            props: {
                ...getBaseProps('elDatePicker')
            },
            formProps: {
                ...getBaseProps('elDatePicker'),
                type: 'daterange'
            },
            handleParam: (value: string[]) => ({
                startServiceDate: getJPTimestamp(
                    new Date(`${new Date(value[0]).format('YYYY-MM-DD')} 00:00:00`)
                ),
                endServiceDate: getJPTimestamp(
                    new Date(`${new Date(value[1]).format('YYYY-MM-DD')} 23:59:59`)
                )
            }),
            formatValue: (record: IBooking) => {
                return getJPDate(record.serviceDate).format('YYYY-MM-DD');
            }
        },
        {
            label: tJoin('bookingList.arrivalAndDeparture'),
            prop: 'departureArrival',
            canSearch: true,
            component: 'elSelect',
            width: 150,
            decorate: 'gDepartureCol',
            headerSlot: 'arrivalAndDeparture',
            decorateProps: (record: IBooking) => ({
                id: record.id,
                data: record,
                showFile: isAdmin.value
            }),
            formatValue: (record: IBooking) => {
                return record.pickUpTime ? 'Departure' : 'Arrival';
            },
            formProps: {
                options: [
                    { label: 'Departure', value: 'DEPARTURE' },
                    { label: 'Arrival', value: 'ARRIVAL' }
                ]
            },
            type: 'booking'
        },
        {
            label: tJoin('bookingList.pickUpAirportCityAttraction'),
            prop: 'pickUpDetails',
            width: 150,
            formatValue: (record: IBooking, value?: string, language = 'EN') =>
                getAirportCityAttraction(record, 'pickUp', language)
        },
        {
            label: tJoin('bookingList.pickUpFrom'),
            prop: 'pickUpLocationName',
            width: 130,
            formatValue: (record: IBooking, value?: string, language = 'EN') =>
                getName(record, 'pickUp', language)
        },
        {
            label: tJoin('bookingList.pickUpAddress'),
            prop: 'pickUpInfo',
            width: 140,
            formatValue: (record: IBooking, value?: string, language = 'EN') =>
                getAddress(record, 'pickUp', language)
        },
        {
            label: tJoin('bookingList.ETA'),
            prop: 'ETA',
            props: {
                sortable: 'custom'
            },
            formatValue: (record: IBooking) => getET(record, 'pickUp')
        },
        {
            label: tJoin('bookingList.dropOffAirportCityAttraction'),
            prop: 'dropOffDetails',
            width: 150,
            formatValue: (record: IBooking, value?: string, language = 'EN') =>
                getAirportCityAttraction(record, 'dropOff', language)
        },
        {
            label: tJoin('bookingList.dropOff'),
            prop: 'dropOffLocationName',
            width: 100,
            formatValue: (record: IBooking, value?: string, language = 'EN') =>
                getName(record, 'dropOff', language)
        },
        {
            label: tJoin('bookingList.dropOffAddress'),
            prop: 'dropOffInfo',
            width: 150,
            formatValue: (record: IBooking, value?: string, language = 'EN') =>
                getAddress(record, 'dropOff', language)
        },
        {
            label: tJoin('bookingList.ETD'),
            prop: 'ETD',
            props: {
                sortable: 'custom'
            },
            formatValue: (record: IBooking) => getET(record, 'dropOff')
        },
        {
            label: tJoin('bookingList.type'),
            prop: 'vehicleName',
            width: 120,
            type: 'booking',
            formatValue: (record: IBooking) => {
                if (record.vehicleName === 'Shared Transfer') {
                    return record.vehicleName;
                }
                return `${record.vehicleName} (X${record.vehicleCount})\n${record.routeVehiclePriceCategory}`;
            },
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.vehicleCount'),
            prop: 'vehicleCount',
            width: 170,
            component: 'elInputNumber',
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('invoiceList.totalPurchase'),
            prop: 'manualAmount',
            width: 130,
            decorate: 'gPrice',
            decorateProps: (record: IBooking) => {
                return {
                    price: record.manualAmount || record.amount,
                    currency: record.currency
                };
            },
            component: 'elInputNumber',
            formatValue: (record: IBooking) => record.manualAmount || record.amount,
            type: 'booking',
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('invoiceList.balanceDue'),
            prop: 'finalAmount',
            width: 130,
            decorate: 'gPrice',
            decorateProps: (record: IBooking) => {
                return {
                    price: record.finalAmount,
                    currency: record.currency
                };
            },
            type: 'invoice',
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('invoiceList.paymentReceived'),
            prop: 'paidAmount',
            width: 160,
            decorate: 'gPrice',
            decorateProps: (record: IBooking) => {
                return {
                    price: record.paidAmount,
                    currency: record.currency
                };
            },
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.adult'),
            prop: 'adultCount',
            component: 'elInputNumber'
        },
        {
            label: tJoin('bookingList.child'),
            prop: 'childCount',
            component: 'elInputNumber',
            formatValue: (record: IBooking) => record.childCount || 0
        },
        {
            label: tJoin('bookingList.infant'),
            prop: 'infantCount',
            component: 'elInputNumber',
            formatValue: (record: IBooking) => record.infantCount || 0
        },
        {
            label: tJoin('bookingList.remark'),
            prop: 'remarks',
            props: {
                type: 'textarea',
                minWidth: 200
            },
            type: 'booking',
            notBelong: `${RoleEnum.CarCompany},${RoleEnum.Driver}`
        },
        {
            label: tJoin('bookingList.creator'),
            prop: 'creator',
            props: {
                type: 'textarea'
            },
            decorate: 'elButton',
            decorateProps: (record: IBooking) => ({
                type: 'primary',
                link: true,
                onClick: () => {
                    if (record.userDetails.id) {
                        Router.push({
                            path: `${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${record.userDetails.id}`
                        });
                    }
                }
            }),
            formatValue: (record: IBooking) =>
                `${record.userDetails.firstName} ${record.userDetails.lastName}`,
            type: 'booking',
            belong: `${RoleEnum.Agent},${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`
        },
        {
            label: tJoin('invoiceList.billDate'),
            prop: 'billDate',
            component: 'elDatePicker',
            width: 120,
            props: {
                ...getBaseProps('elDatePicker')
            },
            decorate: 'gTimestamp',
            decorateProps: (record: IBooking) => ({
                timestamp: record.billDate
            }),
            formatValue: (record: IBooking, value?: number) =>
                value && new Date(value).format('YYYY-MM-DD HH:mm:ss'),
            type: 'invoice'
        },
        {
            label: tJoin('invoiceList.dueDate'),
            prop: 'dueDate',
            component: 'elDatePicker',
            width: 120,
            props: {
                ...getBaseProps('elDatePicker')
            },
            decorate: 'gTimestamp',
            decorateProps: (record: IBooking) => ({
                timestamp: record.dueDate
            }),
            formatValue: (record: IBooking, value?: number) =>
                value && new Date(value).format('YYYY-MM-DD HH:mm:ss'),
            type: 'invoice'
        },
        {
            label: tJoin('invoiceList.voucherSendDate'),
            prop: 'voucherSendDate',
            component: 'elDatePicker',
            width: 160,
            props: {
                ...getBaseProps('elDatePicker')
            },
            decorate: 'gTimestamp',
            decorateProps: (record: IBooking) => ({
                timestamp: record.voucherSendDate,
                type: 'success'
            }),
            formatValue: (record: IBooking, value?: number) =>
                value && new Date(value).format('YYYY-MM-DD'),
            type: 'invoice'
        },
        {
            label: tJoin('invoiceList.invoiceNote'),
            prop: 'invoiceNote',
            width: 170,
            type: 'invoice'
        },
        // {
        //     label: tJoin('bookingList.assignedTo'),
        //     prop: 'assignedTo',
        //     width: 110,
        //     belong: `${RoleEnum.Agent},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`
        // },
        {
            label: tJoin('bookingList.transportInfo'),
            prop: 'transportInfo',
            decorate: 'gTransportInfoCol',
            decorateProps: (record: IBooking) => ({
                companyName: record.carCompanyUserDetails?.companyName,
                isAssignTo: hasAlreadyAssignTo(record),
                data: record,
                id: record?.id
            }),
            headerSlot: 'transportInfo',
            width: 120,
            type: 'booking',
            formatValue: (record: IBooking) => record.carCompanyUserDetails?.companyName || '',
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`
        },
        {
            label: tJoin('bookingList.driverInfo'),
            prop: 'driverUserDetails',
            decorate: 'elButton',
            decorateProps: (record: IBooking) => ({
                link: true,
                type: 'primary',
                onClick: () => {
                    if (record.driverUserDetails?.id) {
                        Router.push(
                            `${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${record.driverUserDetails.id}`
                        );
                    }
                }
            }),
            width: 100,
            type: 'booking',
            formatValue: (record: IBooking) =>
                record.driverUserDetails && Object.keys(record.driverUserDetails).length
                    ? `${record.driverUserDetails.firstName} ${record.driverUserDetails.lastName}`
                    : '',
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`
        },
        {
            label: tJoin('bookingList.pickUpTime'),
            prop: 'pickUpTime',
            decorate: 'gPickUpTime',
            decorateProps: (record: IBooking) => ({
                pickUpTime: record.pickUpTime,
                isAssignTo: hasAlreadyAssignTo(record),
                data: record
            }),
            width: 120,
            formatValue: (record: IBooking) => {
                return record.pickUpTime || '';
            },
            type: 'booking'
        },
        {
            label: tJoin('bookingList.remarkTokyo'),
            prop: 'tokyoRemarks',
            decorate: 'gBookingRemarkCol',
            decorateProps: (record: IBooking) => ({
                value: record.tokyoRemarks,
                id: record.id,
                prop: 'tokyoRemarks',
                onRefresh: () => {
                    if (pageTableRef.value) {
                        pageTableRef.value.getData();
                    }
                }
            }),
            width: 130,
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin},${RoleEnum.CarCompany},${RoleEnum.Driver}`,
            type: 'booking'
        },
        {
            label: tJoin('bookingList.remarkKL'),
            prop: 'elRemarks',
            decorate: 'gBookingRemarkCol',
            decorateProps: (record: IBooking) => ({
                value: record.elRemarks,
                id: record.id,
                prop: 'elRemarks',
                onRefresh: () => {
                    if (pageTableRef.value) {
                        pageTableRef.value.getData();
                    }
                }
            }),
            width: 120,
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`,
            type: 'booking'
        },
        // {
        //     label: tJoin('bookingList.assigned'),
        //     prop: 'assigned',
        //     component: 'gSelect',
        //     props: {
        //         options: [
        //             { label: 'All', value: '' },
        //             { label: 'Already Assign', value: true },
        //             { label: 'Not Assign', value: false }
        //         ]
        //     },
        //     canSearch: true,
        //     hidden: true,
        //     width: 120,
        //     belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`,
        //     type: 'booking'
        // },
        {
            label: tJoin('bookingList.assigned'),
            prop: 'assignedUserId',
            component: 'gSelectCarCompanyAndDriver',
            canSearch: true,
            hidden: true,
            width: 120,
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`,
            type: 'booking'
        },
        {
            label: tJoin('bookingList.searchData'),
            prop: 'queries',
            canSearch: true,
            hidden: true,
            width: 120,
            belong: `${RoleEnum.Operator},${RoleEnum.SubAdmin},${RoleEnum.SuperAdmin}`
        }
    ];
    const searchModel = { assigned: '' };
    const tableOptions = computed(() =>
        options.filter((option) => {
            if (
                option.notBelong &&
                option.notBelong
                    .replace(/\s/g, '')
                    .split(',')
                    .find((item) => userInfo.value?.roleList.find((role) => role.name === item))
            ) {
                return false;
            }
            if (
                !option.belong ||
                option.belong
                    .replace(/\s/g, '')
                    .split(',')
                    .find((item) => userInfo.value?.roleList.find((role) => role.name === item))
            ) {
                if (!option.type || option.type === type) {
                    return true;
                }
            }
            return false;
        })
    );

    return {
        getName,
        getAddress,
        getET,
        tableOptions,
        searchModel,
        pageTableRef
    };
};
