<template>
    <div class="reset-password">
        <h3>{{ $t('login.resetPassword') }}</h3>
        <g-option-form
            ref="resetFormRef"
            labelPosition="top"
            :model="formModal"
            :options="formOptions"
        ></g-option-form>
        <el-button class="w_100" type="primary" @click="onReset" :loading="isLoading">{{
            $t('confirm')
        }}</el-button>
    </div>
</template>

<script lang="ts" setup>
import { ref, toValue } from 'vue';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter, useVueI18n } from '@use';
import { passwordValidator } from '@utils';
import { loginRepositories } from '@/repositories';

const { tJoin } = useVueI18n();
const resetFormRef = ref<OptionFormInstance>();
const formModal = ref({});
const formOptions = ref<IFormOption[]>([
    {
        label: tJoin('userInfo.email'),
        prop: 'email',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.email') }]
    },
    {
        label: tJoin('login.newPassword'),
        prop: 'password',
        component: 'elInput',
        rules: [
            {
                required: true,
                message: tJoin('pleaseEnter', 'userInfo.password')
            },
            {
                message: tJoin('pleaseEnter', 'login.passwordFormat'),
                validator: passwordValidator
            }
        ],
        props: {
            type: 'password'
        }
    },
    {
        label: tJoin('userInfo.retype', 'userInfo.password'),
        prop: 'repassword',
        component: 'elInput',
        rules: [
            {
                required: true,
                validator: (rule, value, callback) => {
                    if (resetFormRef.value?.formModel.password !== value) {
                        callback(toValue(tJoin('login.repasswordError')));
                    } else {
                        callback();
                    }
                }
            }
        ],
        props: {
            type: 'password',
            inputStyle: {
                autocomplete: 'off'
            }
        }
    }
]);

const isLoading = ref(false);
const { Route } = useRoute();
const { Router, ROUTER_PATH_MAP } = useRouter();
const onReset = async () => {
    if (Route.params.token && resetFormRef.value) {
        const { email, password } = await resetFormRef.value.validate<{
            email: string;
            password: string;
        }>();
        isLoading.value = true;
        try {
            await loginRepositories.resetPassword(Route.params.token as string, {
                email,
                password
            });
            ElMessage.success({
                message: tJoin('login.resetPasswordSuccess').value,
                duration: 2000
            });
            Router.push(ROUTER_PATH_MAP.HOME_SIGN_IN_PATH);
        } catch (e) {
            console.log(e);
        }
        isLoading.value = false;
    }
};
</script>

<style lang="scss" scoped>
.reset-password {
    width: 400px;
}
</style>
