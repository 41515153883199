<template>
    <el-card class="mg-x_a">
        <!-- <g-tag-label
            v-if="currBooking"
            :label="$t(`status.${currBooking.invoiceStatus}`)"
            :type="BookingStatusType[currBooking.invoiceStatus]"
        ></g-tag-label> -->
        <iframe
            class="preview-iframe w_100"
            v-if="type === 'invoice'"
            :src="`${baseUrl}${previewInvoicePDf}#toolbar=1`"
        ></iframe>
        <iframe
            class="preview-iframe w_100"
            v-if="type === 'voucher'"
            :src="`${baseUrl}${previewVoucherPDF}#toolbar=1`"
        ></iframe>
        <iframe
            class="preview-iframe w_100"
            v-if="type === 'notice'"
            :src="`${baseUrl}${previewDepatureNoticePDF}#toolbar=1`"
        ></iframe>
        <iframe
            class="preview-iframe w_100"
            v-if="type === 'haishaTehaisho'"
            :src="`${baseUrl}${previewHaishaTehaishoPDF}#toolbar=1`"
        ></iframe>
    </el-card>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { BookingStatusType } from '@enums';
import { useBookingStore } from '@/stores';
import { baseUrl } from '@utils';

defineProps<{
    type: 'invoice' | 'voucher' | 'notice' | 'haishaTehaisho';
}>();

const {
    currBooking,
    previewInvoicePDf,
    previewVoucherPDF,
    previewDepatureNoticePDF,
    previewHaishaTehaishoPDF
} = useBookingStore();

onMounted(async () => {});
</script>

<style lang="scss" scoped>
.preview-iframe {
    border: none;
    height: 700px;
    margin: 0 auto;
}
::v-deep .el-card__body {
    text-align: center;
}
</style>
