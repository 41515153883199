<template>
    <div>{{ pickUpTime }}</div>
    <div v-if="isAssignTo">
        <el-tag
            :type="
                driverAcceptStatus === 'OK'
                    ? 'success'
                    : driverAcceptStatus === 'NG'
                    ? 'danger'
                    : 'warning'
            "
        >
            {{ driverAcceptStatus }}
            <el-text type="success" size="small" v-if="data.driverPickUpTime">{{
                data.driverPickUpTime
            }}</el-text>
        </el-tag>
        <div v-if="data.driverReason">
            <el-text type="info" size="small">{{ data.driverReason }}</el-text>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { IBooking } from '@/common/types';
const props = defineProps<{
    pickUpTime: string;
    isAssignTo: boolean;
    data: IBooking;
}>();

const driverAcceptStatus = computed(() => {
    if (props.data) {
        if (props.data.driverAcceptStatus === 'ACCEPT') {
            return 'OK';
        } else if (props.data.driverAcceptStatus === 'REJECT') {
            return 'NG';
        }
    }
    return 'PENDING';
});
</script>
