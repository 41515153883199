<template>
    <el-dropdown class="mg-l_10" split-button trigger="click" type="success" @command="onCommand">
        {{ $t('preview.email') }}
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item command="Invoice">
                    <el-text :type="isSendInvoice && 'success'">
                        <el-icon class="mg-r_0"><Message /></el-icon>
                        {{ $t('preview.emailInvoice') }}
                    </el-text>
                    <el-text v-if="isSendInvoice" type="success">✓</el-text>
                </el-dropdown-item>
                <el-dropdown-item v-if="isShowVoucher" command="Voucher">
                    <el-text :type="isSendVoucher && 'success'">
                        <el-icon class="mg-r_0"><Message /></el-icon>
                        {{ $t('preview.emailVoucher') }}
                    </el-text>
                    <el-text v-if="isSendVoucher" type="success">✓</el-text>
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
    <el-dialog
        width="700"
        :title="$t('email.title', { type: emailType })"
        v-model="showEmailDetail"
    >
        <email-detail
            :type="emailType"
            @cancel="showEmailDetail = false"
            @comfirm="onSended"
        ></email-detail>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { Message } from '@element-plus/icons-vue';
import { useBookingStore } from '@/stores';
import EmailDetail from './EmailDetail.vue';

const { currBooking, getCurrBooking, isShowVoucher } = useBookingStore();

const isSendInvoice = computed(() => currBooking.value?.billDate);
const isSendVoucher = computed(() => currBooking.value?.voucherSendDate);

const showEmailDetail = ref(false);
const emailType = ref<'Invoice' | 'Voucher'>('Invoice');

const onCommand = async (command: 'Invoice' | 'Voucher') => {
    emailType.value = command;
    showEmailDetail.value = true;
};
const onSended = () => {
    showEmailDetail.value = false;
    getCurrBooking();
};
</script>
