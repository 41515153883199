<template>
    <div>{{ locale === 'jp' ? record.routeNameJp : record.routeName }}</div>
    <el-text type="warning" size="small" v-if="pickUpTime">{{ pickUpTime }}</el-text>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { RouterTypeEnum } from '@enums';
import { IBooking } from '@ctypes';
import { useVueI18n } from '@use';

const props = defineProps<{
    record: IBooking;
}>();

const { locale } = useVueI18n();

const pickUpTime = computed(() => {
    const getOffset = (time: string | number | Date) => {
        return new Date(time).getTimezoneOffset() / 60;
    };
    if (props.record.pickUpLocationType === RouterTypeEnum.ACTIVITY) {
        const { pickUpTime } = props.record.pickUpDetails;
        return isNaN(Number(pickUpTime))
            ? pickUpTime
            : new Date(
                  Number(pickUpTime) + (9 + getOffset(Number(pickUpTime))) * 60 * 60 * 1000
              ).format('HH:mm');
    }
    if (props.record.dropOffLocationType === RouterTypeEnum.ACTIVITY) {
        const { pickUpTime } = props.record.pickUpDetails;
        return isNaN(Number(pickUpTime))
            ? pickUpTime
            : new Date(
                  Number(pickUpTime) + (9 + getOffset(Number(pickUpTime))) * 60 * 60 * 1000
              ).format('HH:mm');
    }
    return '';
});
</script>
