<template>
    <g-page-table
        ref="pageTableRef"
        operationTypes="check"
        :storageable="false"
        :handleSortParams="handleSortParams"
        :check-func="checkFunc"
        :options="tableOptions"
        :updateFormOptions="updateFormOptions"
        :api-service="getAgentInvoiceList"
        :check-path="ROUTER_PATH_MAP.MENU_INVOICE_DETAIL"
    ></g-page-table>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useVueI18n, useBookingTableOptions, useRoute } from '@use';
import { IBooking } from '@ctypes';
import { useBookingStore, useAgentStore } from '@/stores';

const { tJoin } = useVueI18n();
const { tableOptions } = useBookingTableOptions('invoice');

const { currBooking } = useBookingStore();
const { getAgentInvoiceList } = useAgentStore();
const checkFunc = (record: IBooking) => {
    currBooking.value = record;
};
const updateFormOptions = ref<IFormOption[]>([
    {
        label: tJoin('hotel.hotel'),
        prop: 'hotelName',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'hotel.hotel') }]
    },
    {
        label: tJoin('hotel.address'),
        prop: 'address',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'hotel.address') }]
    },
    {
        label: tJoin('hotel.tel'),
        prop: 'tel',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'hotel.tel') }]
    },
    {
        label: tJoin('hotel.fax'),
        prop: 'fax',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'hotel.fax') }]
    }
]);

const handleSortParams = ({ prop, order }: any) => {
    return {
        orderBy: `${prop},${order === 'descending' ? 'desc' : 'asc'}`
    };
};

const pageTableRef = ref<PageTableInstance>();
const { Route, ROUTER_PATH_MAP } = useRoute();
watch(Route, () => {
    console.log(Route);
    if (~Route.path.indexOf(ROUTER_PATH_MAP.MENU_AGENT_DETAIL) && pageTableRef.value) {
        pageTableRef.value.getData();
    }
});
</script>
