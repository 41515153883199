import { ref, onBeforeUnmount, Ref } from 'vue';
export const useResize = (el: Ref<HTMLElement | undefined>) => {
    const width = ref(el.value?.clientWidth || 0);
    const height = ref(el.value?.clientHeight || 0);
    const resize = function () {
        if (el?.value) {
            width.value = el.value.clientWidth;
            height.value = el.value.clientHeight;
        }
    };
    const getSize = () => {
        if (el.value) {
            width.value = el.value.clientWidth;
            height.value = el.value.clientHeight;
        }
    };
    window.addEventListener('resize', resize);
    onBeforeUnmount(() => {
        console.log('remove');
        window.removeEventListener('resize', resize);
    });
    return {
        width,
        height,
        getSize
    };
};
