import { get, post, put, del } from '@/repositories/base';
import { HOTEL_URL } from '@/common/enums/repositories';
import { IHotel } from '@ctypes';

/**
 * 获取酒店信息
 */
export const getHotelList = async (data: IPage & { hotelName: string }) => {
    return get<IPage, IListResponse<IHotel>>({
        url: HOTEL_URL,
        data
    });
};

/**
 * 新增酒店信息
 */
export const addHotel = async (data: IHotel) => {
    return post<IHotel, string>({
        url: HOTEL_URL,
        data
    });
};

/**
 * 根据id获取酒店信息
 */
export const getHotelById = async (id: string) => {
    return get<undefined, IHotel>({
        url: `${HOTEL_URL}/${id}`
    });
};

/**
 * 更新酒店信息
 */
export const updateHotel = async (id: string, data: Partial<IHotel>) => {
    return put<Partial<IHotel>, IHotel>({
        url: `${HOTEL_URL}/${id}`,
        data
    });
};

/**
 * 删除酒店地点
 */
export const delHotel = async (id: string) => {
    return del<undefined, IHotel>({
        url: `${HOTEL_URL}/${id}`
    });
};
