<template>
    <main class="pd-x_20 relative">
        <div class="mg-y_20">
            <order-home v-if="currStep === 1" ref="orderHomeRef"></order-home>
            <order-car v-if="currStep === 2"></order-car>
            <order-detail
                v-if="currStep === 3"
                :agentCompanyName="agentCompanyName"
                @success="onSuccess"
            ></order-detail>
        </div>
        <div class="ta-r mg-t_20" v-show="currStep === 1">
            <el-button
                :icon="Right"
                type="primary"
                @click="next(!routerChange && amendOrderId ? 2 : 1)"
                >{{ $t('search') }}</el-button
            >
        </div>
        <div class="mg-t_20" v-show="currStep !== 1">
            <el-button
                :icon="Back"
                type="primary"
                plain
                @click="next(!routerChange && amendOrderId ? -2 : -1)"
                >{{ $t('prevStep') }}</el-button
            >
        </div>
    </main>
</template>

<script lang="ts" setup>
import { ref, onBeforeUnmount, provide } from 'vue';
import { Back, Right } from '@element-plus/icons-vue';
import OrderHome from '@/components/bookingOrder/OrderHome.vue';
import OrderCar from '@/components/bookingOrder/OrderCar.vue';
import OrderDetail from '@/components/bookingOrder/OrderDetail.vue';
import { useBookingOrderStore } from '@/stores';

defineProps<{
    agentCompanyName?: string;
}>();

const emit = defineEmits<{
    success: [];
}>();

const { currStep, bookingOrderUser, setDetailInfos, clearAllData, amendOrderId } =
    useBookingOrderStore();

const routerChange = ref(false);
provide('routerChange', routerChange);
const next = async (step: number) => {
    if (currStep.value === 1) {
        await validateOrderHome();
    }
    currStep.value += step;
    setDetailInfos();
};

const orderHomeRef = ref<InstanceType<typeof OrderHome>>();
/**
 * 校验线路，时间，人数
 */
const validateOrderHome = async () => {
    if (orderHomeRef.value) {
        return await orderHomeRef.value.validate();
    }
    return Promise.reject();
};

const onSuccess = () => {
    emit('success');
};

onBeforeUnmount(() => {
    clearAllData();
    bookingOrderUser.value = undefined;
});
</script>

<style lang="scss" scoped>
.booking {
    main {
        max-width: 1000px;
    }
}
.guest-label {
    background-color: var(--el-color-warning);
    position: absolute;
    top: -20px;
}
</style>
