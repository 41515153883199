<template>
    <g-option-form
        ref="signUpFormRef"
        labelPosition="top"
        :model="model"
        :options="formOptions"
    ></g-option-form>
</template>

<script lang="ts" setup>
import { ref, watch, toValue } from 'vue';
import { useVueI18n } from '@use';
import { passwordValidator } from '@utils';

export type ISignUpType = 'guest' | 'agent' | '';

const props = withDefaults(
    defineProps<{
        type?: ISignUpType;
        model: Record<string, any>;
    }>(),
    {
        type: 'guest',
        model: () => ({})
    }
);

const { tJoin } = useVueI18n();
const options: Array<IFormOption> = [
    {
        label: tJoin('userInfo.company'),
        prop: 'companyName',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.company') }],
        belong: 'agent'
    },
    {
        label: tJoin('userInfo.firstName'),
        prop: 'firstName',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.firstName') }]
    },
    {
        label: tJoin('userInfo.lastName'),
        prop: 'lastName',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.lastName') }]
    },
    {
        label: tJoin('userInfo.email'),
        prop: 'email',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.email') }]
    },
    {
        label: tJoin('userInfo.password'),
        prop: 'password',
        component: 'elInput',
        rules: [
            {
                required: true,
                message: tJoin('pleaseEnter', 'userInfo.password')
            },
            {
                message: tJoin('pleaseEnter', 'login.passwordFormat'),
                validator: passwordValidator
            }
        ],
        props: {
            type: 'password',
            inputStyle: {
                autocomplete: 'off'
            },
            autocomplete: 'new-password'
        }
    },
    {
        label: tJoin('userInfo.retype'),
        prop: 'repassword',
        component: 'elInput',
        rules: [
            {
                required: true,
                validator: (rule, value, callback) => {
                    if (signUpFormRef.value?.formModel.password !== value) {
                        callback(toValue(tJoin('login.repasswordError')));
                    } else {
                        callback();
                    }
                }
            }
        ],
        props: {
            type: 'password',
            inputStyle: {
                autocomplete: 'off'
            }
        }
    },
    {
        label: tJoin('userInfo.gender'),
        prop: 'gender',
        component: 'GRadioGroup',
        rules: [
            {
                required: true,
                message: tJoin('pleaseChoose', 'userInfo.gender')
            }
        ],
        props: {
            options: [
                { label: tJoin('status.MALE').value, value: 'MALE' },
                { label: tJoin('status.FEMALE').value, value: 'FEMALE' }
            ]
        }
    }
];
const formOptions = ref<IFormOption[]>(
    options.filter((option) => !option.belong || props.type === option.belong)
);
watch(
    () => props.type,
    () => {
        formOptions.value = options.filter(
            (option) => !option.belong || props.type === option.belong
        ) as any;
    }
);

const signUpFormRef = ref<OptionFormInstance>();
const validate = async function <T>(): Promise<T> {
    if (signUpFormRef.value) {
        return await signUpFormRef.value.validate<T>();
    }
    return Promise.reject();
};

defineExpose({
    validate,
    signUpFormRef
});
</script>
