<template>
    <el-select clearable v-bind="$attrs">
        <el-option
            v-for="(option, index) in options"
            :key="index"
            :label="option.label"
            :value="option.value"
        >
            {{ option.label }}
        </el-option>
    </el-select>
</template>

<script lang="ts" setup>
import { TaxCommissionEnum } from '@enums';
const options: ILabelValue<number>[] = [
    { label: TaxCommissionEnum[99], value: TaxCommissionEnum['Agent`s Commission 1%'] },
    { label: TaxCommissionEnum[95], value: TaxCommissionEnum['Agent`s Commission 5%'] },
    { label: TaxCommissionEnum[90], value: TaxCommissionEnum['Agent`s Commission 10%'] },
    { label: TaxCommissionEnum[85], value: TaxCommissionEnum['Agent`s Commission 15%'] },
    {
        label: TaxCommissionEnum[81],
        value: TaxCommissionEnum['Agent`s Commission less 10% less 10%']
    },
    { label: TaxCommissionEnum[106], value: TaxCommissionEnum['GST Tax(6%)'] },
    { label: TaxCommissionEnum[110], value: TaxCommissionEnum['VAT(10%)'] }
];
</script>
