<template>
    <div class="flex flex-between">
        <h3 class="w_200p">{{ $t('userInfo.generalInfo') }}</h3>
        <div v-if="canEditCurrUserInfo">
            <el-button v-if="!isEdit" type="primary" plain @click="isEdit = true">
                {{ $t('edit') }}
            </el-button>
            <el-button v-if="isEdit" type="primary" @click="onSave">{{ $t('save') }}</el-button>
            <el-button v-if="isEdit" @click="isEdit = false">{{ $t('cancel') }}</el-button>
        </div>
    </div>
    <g-value-form
        v-if="!isEdit"
        v-loading="isLoadingCurrUser"
        :options="formOptions"
        labelPosition="right"
        :model="currUserInfo || {}"
        :labelWidth="200"
        divider
    ></g-value-form>
    <g-option-form
        v-else
        ref="optionFormRef"
        v-loading="isLoadingCurrUser"
        :options="formOptions"
        labelPosition="right"
        :model="currUserInfo || {}"
        :labelWidth="200"
        divider
    ></g-option-form>
</template>

<script lang="ts" setup>
import { ref, onBeforeUnmount, computed, watch } from 'vue';
import { useVueI18n } from '@use';
import { useUserStore } from '@/stores';
import { IUserInfo } from '@/common/types';
import { RoleEnum } from '@enums';

const isEdit = ref(false);

const { tJoin } = useVueI18n();
const { currUserInfo, isLoadingCurrUser, updateUserInfo, canEditCurrUserInfo, isAdmin } =
    useUserStore();

onBeforeUnmount(() => {
    currUserInfo.value = undefined;
});

const canEditCompanyName = computed(() => {
    return (
        [RoleEnum.Driver, RoleEnum.CarCompany].includes(currUserInfo.value?.jobTitle as RoleEnum) &&
        isAdmin.value
    );
});

const formOptions = computed<IFormOption[]>(() => {
    return [
        { label: tJoin('userInfo.firstName'), prop: 'firstName' },
        { label: tJoin('userInfo.lastName'), prop: 'lastName' },
        { label: tJoin('userInfo.email'), prop: 'email' },
        { label: tJoin('userInfo.city'), prop: 'city' },
        { label: tJoin('userInfo.country'), prop: 'country' },
        { label: tJoin('userInfo.phone'), prop: 'phone' },
        { label: tJoin('userInfo.alternativePhone'), prop: 'phone2' },
        {
            label: tJoin('userInfo.birthTime'),
            prop: 'birthTime',
            component: 'elDatePicker',
            props: {
                format: 'YYYY-MM-DD',
                valueFormat: 'x'
            },
            formatValue: (record: IUserInfo, value: number) => {
                return value ? new Date(value).format('YYYY-MM-DD') : '--';
            }
        },
        {
            label: tJoin('userInfo.gender'),
            prop: 'gender',
            component: 'gRadioGroup',
            props: {
                options: [
                    { label: tJoin('status.MALE'), value: 'MALE' },
                    { label: tJoin('status.FEMALE'), value: 'FEMALE' }
                ]
            },
            formatValue: (record: IUserInfo) => tJoin(`status.${record.gender}`).value
        },
        {
            label: tJoin('userInfo.company'),
            prop: 'companyName',
            props: { disabled: !canEditCompanyName.value }
        },
        { label: tJoin('userInfo.branchName'), prop: 'branchCompanyName' }
    ];
});

const optionFormRef = ref<OptionFormInstance>();
const onSave = async () => {
    if (optionFormRef.value) {
        const formData = await optionFormRef.value.validate<Partial<IUserInfo>>();
        await updateUserInfo(currUserInfo.value!.id, formData);
        isEdit.value = false;
        console.log(formData);
    }
};
</script>

<style lang="scss" scoped>
.base-info {
    &__item {
    }
}
</style>
