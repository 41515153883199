<template>
    <div class="w_80 mg-x_a h-min_40p" v-loading="isLoading">
        <template v-if="!isLoading">
            <el-card class="mg-x_a mg-b_20">
                <el-page-header @back="go(-1)">
                    <template #content>
                        <el-dropdown @command="onPreviewCommand">
                            <el-button class="flex flex-left" link>
                                <h3 class="mg_0">{{ $t(`preview.${previewType}`) }}</h3>
                                <el-icon class="mg-l_10">
                                    <arrow-down />
                                </el-icon>
                            </el-button>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item v-if="isShowInvoice" command="invoice">
                                        {{ $t('preview.invoice') }}
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="isShowVoucher" command="voucher">
                                        {{ $t('preview.voucher') }}
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="isShowDepartureNotice" command="notice">
                                        {{ $t('preview.notice') }}
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </template>
                    <template #extra>
                        <el-button
                            v-if="isAdmin"
                            type="warning"
                            @click="isShowEdit = !isShowEdit"
                            >{{ $t('preview.editInvoice') }}</el-button
                        >
                        <download-btn v-if="isAdmin"></download-btn>
                        <email-btn v-if="isAdmin && isShowSendEmail"></email-btn>
                    </template>
                </el-page-header>
            </el-card>
            <preview :type="previewType"></preview>
            <el-card class="mg-t_20" v-if="isAdmin">
                <payment-list></payment-list>
            </el-card>
        </template>
    </div>
    <el-dialog :title="$t('preview.editInvoice')" v-model="isShowEdit">
        <edit-form ref="editFormRef"></edit-form>
        <template #footer>
            <el-button @click="isShowEdit = false">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="onSaveEdit" :loading="isLoadingEditInvoice">{{
                $t('save')
            }}</el-button>
        </template>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue';
import { useRouter, useRoute } from '@use';
import { useBookingStore, useUserStore } from '@/stores';
import Preview from '@/components/preview/Preview.vue';
import EditForm from './components/EditForm.vue';
import PaymentList from './PaymentList.vue';
import EmailBtn from './components/EmailBtn.vue';
import DownloadBtn from './components/DownloadBtn.vue';

const { go } = useRouter();
const { isAdmin } = useUserStore();
const {
    isLoading,
    getCurrBooking,
    isLoadingEditInvoice,
    editInvoice,
    isShowSendEmail,
    isShowInvoice,
    isShowVoucher,
    isShowDepartureNotice
} = useBookingStore();
getCurrBooking();

const editFormRef = ref<InstanceType<typeof EditForm>>();
const isShowEdit = ref(false);
const onSaveEdit = async () => {
    if (editFormRef.value) {
        const formData = await editFormRef.value.validate();
        await editInvoice(formData);
        isShowEdit.value = false;
    }
};

const { Route } = useRoute();

type PreviewType = 'invoice' | 'voucher' | 'notice';
const previewType = ref<PreviewType>((Route.query.type as PreviewType) || 'invoice');
const onPreviewCommand = (command: PreviewType) => {
    previewType.value = command;
};
</script>
