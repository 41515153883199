import { useVueI18n, useOptions } from '@use';
import { RouterTypeEnum } from '@enums';
import { useBookingOrderStore } from '@/stores';
import FilghtDetailInput from './widgets/FilghtDetailInput.vue';
import ActivityPickUpTime from './widgets/ActivityPickUpTime.vue';
import RouteInfoInput from './widgets/RouteInfoInput.vue';
import GuestInput from './widgets/GuestInput.vue';
import JsonTime from './widgets/JsonTime.vue';
import { getJPDate } from '@utils';
import { IAirportDetailInfo } from '@/common/types';

const useDetailOptions = () => {
    const { tJoin } = useVueI18n();
    const { getBaseProps } = useOptions();
    const getAirportOptions = (type: 'pickUp' | 'dropOff' = 'pickUp'): IFormOption[] => {
        const options: IFormOption[] = [
            {
                label: tJoin('airport.departureDate'),
                icon: '',
                prop: 'departureDate',
                rules: [
                    { required: true, message: tJoin('pleaseChoose', 'airport.departureDate') }
                ],
                component: 'elDatePicker',
                props: {
                    ...getBaseProps('elDatePicker')
                },
                placeholder:
                    type === 'pickUp'
                        ? tJoin('airport.departureDatePlaceholder')
                        : tJoin('airport.departureDatePlaceholder2'),
                decorate: 'gTimestamp',
                decorateProps: (record: IAirportDetailInfo) => ({
                    timestamp: record.departureDate
                }),
                formatValue: (record: IRecord, value: any) => {
                    return value ? getJPDate(Number(value)).format('YYYY-MM-DD') : '';
                }
            },
            {
                label: tJoin('airport.name'),
                icon: '',
                prop: 'airportName',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'airport.name') }],
                component: 'elInput'
            },
            {
                label: tJoin('airport.filght'),
                icon: '',
                prop: 'flightNumber',
                placeholder: tJoin('airport.filghtPlaceholder'),
                rules: [
                    { required: true, message: tJoin('pleaseEnter', 'airport.filght', 'number') }
                ],
                component: 'elInput'
            },
            {
                label: tJoin('airport.filghtDetail'),
                icon: '',
                prop: 'filghtDetail',
                rules: [
                    {
                        required: true,
                        message: tJoin('pleaseEnter', 'airport.filght', 'details')
                    }
                ],
                component: FilghtDetailInput
            }
        ];
        return options;
    };
    const getPortOptions = (): IFormOption[] => {
        return [
            {
                label: tJoin('port.name'),
                icon: '',
                prop: 'portName',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'port.name') }],
                component: 'elInput'
            },
            {
                label: tJoin('port.cruiseName'),
                icon: '',
                prop: 'cruiseName',
                placeholder: tJoin('port.cruiseNamePlaceholder'),
                rules: [
                    { required: true, message: tJoin('pleaseEnter', 'port.cruiseName', 'number') }
                ],
                component: 'elInput'
            },
            {
                label: tJoin('port.cruiseTime'),
                icon: '',
                prop: 'cruiseTime',
                placeholder: '',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'port.cruiseTime') }],
                component: 'elTimePicker',
                props: {
                    format: 'HH:mm',
                    valueFormat: 'HH:mm'
                }
            }
        ];
    };
    const getActivityOptions = (type?: 'pickUp' | 'dropOff'): IFormOption[] => {
        const { routerInfo, carInfo } = useBookingOrderStore();
        const options: IFormOption[] = [
            {
                label: tJoin('activity.name'),
                icon: '',
                prop: 'activityName',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'activity.name') }],
                component: 'elInput'
            }
        ];

        if (type === 'pickUp') {
            if (
                carInfo.value.name === 'Shared Transfer' &&
                routerInfo.value.pickUpLocation &&
                ['Disneyland', 'Disneysea', 'Universal Studios Japan'].find(
                    (item) => ~routerInfo.value.pickUpLocation!.indexOf(item)
                )
            ) {
                // 如果选择的是公共车辆，并且是起点迪士尼，环球影城，则显示17pm,21pm
                options.push({
                    label: tJoin('activity.pickUpTime'),
                    icon: '',
                    prop: 'pickUpTime',
                    rules: [
                        { required: true, message: tJoin('pleaseEnter', 'activity.pickUpTime') }
                    ],
                    component: ActivityPickUpTime,
                    props: {
                        unit: 'pm',
                        prop: 'pickUpTime'
                    }
                });
            } else {
                // 其他车辆
                options.push({
                    label: tJoin('activity.pickUpTime'),
                    icon: '',
                    prop: 'pickUpTime',
                    rules: [
                        { required: true, message: tJoin('pleaseEnter', 'activity.pickUpTime') }
                    ],
                    component: 'elTimePicker',
                    props: {
                        valueFormat: 'HH:mm',
                        format: 'HH:mm'
                    }
                });
            }
        }
        return options;
    };
    const getStationOptions = (): IFormOption[] => {
        return [
            {
                label: tJoin('station.name'),
                icon: '',
                prop: 'stationName',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'station.name') }],
                component: 'elInput'
            },
            {
                label: tJoin('station.trainName', 'station.trainNumber'),
                icon: '',
                prop: 'trainName',
                rules: [
                    {
                        required: true,
                        message: tJoin('pleaseEnter', 'station.trainName', 'station.trainNumber')
                    }
                ],
                component: 'elInput'
            },
            {
                label: tJoin('station.route'),
                icon: '',
                prop: 'route',
                rules: [
                    {
                        required: true,
                        message: tJoin('pleaseEnter', 'station.route')
                    }
                ],
                component: RouteInfoInput
            }
        ];
    };
    const getCityOptions = (): IFormOption[] => {
        const options: IFormOption[] = [
            {
                label: tJoin('city.name'),
                icon: '',
                prop: 'cityName',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'city.name') }],
                component: 'elInput'
            },
            {
                label: tJoin('city.hotelSearch'),
                icon: '',
                prop: 'hotelInfo',
                slot: 'hotel'
            },
            {
                label: tJoin('city.info'),
                icon: '',
                prop: 'pickUp',
                rules: [
                    {
                        required: true,
                        message: tJoin('pleaseEnter', 'pickUp', 'info')
                    }
                ],
                component: 'elInput',
                placeholder: tJoin('city.infoPlaceholder')
            },
            {
                label: tJoin('city.detail'),
                icon: '',
                prop: 'detail',
                rules: [
                    {
                        required: true,
                        message: tJoin('pleaseEnter', 'pickUp', 'details')
                    }
                ],
                component: 'elInput',
                placeholder: tJoin('city.detailPlaceholder'),
                props: {
                    type: 'textarea'
                }
            }
        ];
        const { routerInfo, carInfo } = useBookingOrderStore();
        if (
            routerInfo.value.dropOffLocation &&
            ['Disneyland', 'Disneysea', 'Universal Studios Japan'].find(
                (item) => ~routerInfo.value.dropOffLocation!.indexOf(item)
            )
        ) {
            if (carInfo.value.name === 'Shared Transfer') {
                // 如果选择的是公共车辆，并且下车点是迪士尼，环球影城，则选择时间点
                options.push({
                    label: tJoin('activity.pickUpTime'),
                    icon: '',
                    prop: 'pickUpTime',
                    rules: [
                        { required: true, message: tJoin('pleaseEnter', 'activity.pickUpTime') }
                    ],
                    component: ActivityPickUpTime,
                    props: {
                        unit: 'am',
                        prop: 'pickUpTime'
                    }
                });
            } else {
                options.push({
                    label: tJoin('activity.pickUpTime'),
                    icon: '',
                    prop: 'pickUpTime',
                    rules: [
                        { required: true, message: tJoin('pleaseEnter', 'activity.pickUpTime') }
                    ],
                    component: 'elTimePicker',
                    props: {
                        valueFormat: 'HH:mm',
                        format: 'HH:mm'
                    }
                });
            }
        }
        return options;
    };
    const getGuestOptions = (): IFormOption[] => {
        return [
            {
                label: tJoin('guest.detailName'),
                icon: '',
                prop: 'guestNames',
                rules: [{ required: true, message: tJoin('pleaseEnter', 'guest.name') }],
                component: GuestInput
            },
            {
                label: tJoin('guest.SNS'),
                icon: '',
                prop: 'sns',
                placeholder: tJoin('guest.SNSPlaceholder'),
                rules: [{ required: true, message: tJoin('pleaseEnter', 'guest.SNS') }],
                component: 'elInput'
            },
            {
                label: tJoin('guest.remarks'),
                icon: '',
                prop: 'remarks',
                component: 'elInput',
                props: {
                    type: 'textarea'
                }
            },
            {
                label: tJoin('guest.agentOwnReferenceNo'),
                icon: '',
                prop: 'referenceNo',
                component: 'elInput'
            }
        ];
    };
    const getOptions = (routeType?: IRouterType, type?: 'pickUp' | 'dropOff') => {
        switch (routeType) {
            case RouterTypeEnum.AIRPORT:
                return getAirportOptions(type);
            case RouterTypeEnum.PORT:
                return getPortOptions();
            case RouterTypeEnum.ACTIVITY:
                return getActivityOptions(type);
            case RouterTypeEnum.STATION:
                return getStationOptions();
            case RouterTypeEnum.CITY:
                return getCityOptions();
            default:
                return [];
        }
    };
    return {
        getAirportOptions,
        getPortOptions,
        getActivityOptions,
        getStationOptions,
        getCityOptions,
        getGuestOptions,
        getOptions
    };
};

export default useDetailOptions;
