const jp = {
    bookingOrder: {
        searchRouter: {
            placeholder: '目的地を入力して検索'
        },
        selectRouter: '目的地を入力して検索',
        noOfVehicle: '車両数',
        cancelPolicy: '重要！キャンセルポリシーをご覧ください。',
        newBooking: '新規予約',
        AmendBooking: 'サブスクリプションの変更',
        hasStartAndEnd:
            'この回線は{startYear}、{startMonth}、{startDay}から{endYear}、{endMonth}、{endDay}の期間のみ予約ができます',
        hasStartNoEnd: '注意: この路線は {startYear}、{startMonth}、{startDay} から予約ができます',
        noStartHasEnd: 'この回線は {endYear}、{endMonth}、{endDay} まで予約を停止します'
    },
    status: {
        NEW: '新規',
        PRE_BOOK: '暫定予約',
        VERIFIED: '検証済み',
        CONFIRMED: '確認済み',
        REJECTED: '拒否',
        CANCELLED: 'キャンセル',
        FINAL: 'ファイナル',
        TOKYO_FINAL: '東京ファイナル',
        TEHAI: '手配',
        COMPLETED: '完了',
        OVERDUE: '期限過ぎ',
        DRAFT: '下書き',
        NOT_PAID: '未払い',
        PARTIALLY_PAID: '一部支払い済み',
        FULLY_PAID: '支払い済み',
        MALE: '男性',
        FEMALE: '女性'
    },
    bookingList: {
        title: '予約リスト',
        status: '状態',
        agentAndClient: 'エージェント/クライアント',
        client: 'クライアント',
        referenceNoAgent: 'エージェントの予約番号',
        referenceNo: '旅行会社の予約番号',
        rawOurRef: 'raw_our_ref',
        uniqueReferenceNo: '予約番号',
        guestName: 'お客様の名前',
        guestInfo: 'お客様情報',
        bookedDate: '予約日',
        updateDate: '更新日',
        pickUpAirportCityAttraction: '乗車場所 空港/ホテル/駅/港/観光スポット',
        dropOffAirportCityAttraction: '降車場所 空港/ホテル/駅/港/観光スポット',
        type: 'タイプ',
        vehicleCount: '車両数',
        destinationInfo: '目的地情報',
        arrivalAndDeparture: '到着/出発',
        serviceDate: '乗車日',
        pickUpFrom: '乗車場所',
        pickUpAddress: '乗車住所',
        ETA: 'ETA',
        dropOff: '降車場所',
        dropOffAddress: '降車住所',
        ETD: 'ETD',
        adult: '大人',
        child: '子供',
        infant: '幼児',
        payment: '支払い',
        pickUpTime: 'ピックアップ時間',
        transportInfo: '配車情報',
        driverInfo: 'ドライバー情報',
        remark: '備考',
        remarkTokyo: '車会社との連絡',
        remarkKL: '車内との連絡',
        assignedTo: '配車',
        creator: '予約者',
        operation: {
            cancel: 'キャンセル',
            assignTo: '配車'
        },
        assignTo: {
            status: '状態',
            carCompany: '車会社',
            driver: 'ドライバー',
            terminal: 'ターミナル',
            assignTo: '配車',
            transportInfo: '配車情報',
            pickUpTime: 'ピックアップ時間',
            meetingPoint: '集合場所',
            note: 'メモ',
            sayHello: 'いつもお世話になっております。',
            generate: '生成',
            international: '国際線',
            internationalT1: '国際線ターミナル1',
            internationalT2: '国際線ターミナル2',
            internationalT3: '国際線ターミナル3',
            domestic: '国内線',
            domesticT1: '国内線ターミナル1',
            domesticT2: '国内線ターミナル2',
            domesticT3: '国内線ターミナル3',
            emptyTip: '少なくとも1つの項目を記入してください'
        },
        verifyFields: {
            verifyFields: 'フィールドの検証',
            fieldName: 'フィールド名',
            fieldValue: 'フィールド値',
            verified: '検証した?',
            newFieldValue: '新しいフィールド値',
            name: 'フィールド',
            canNotVerify: '現在のステータスではフィールドを検証できません'
        },
        changeStatus: {
            confimrChange: 'ステータスを {status}にしてもよろしいでしょうか?'
        },
        cancelConfirmInfo: 'この予約をキャンセルしてもよろしいでしょうか?',
        downloadHaishaTip: '配車手配書をダウンロードする',
        downloadDepartureNotice: '出発案内をダウンロードする',
        assigned: '配車',
        searchData: 'データ検索',
        amendVerified: '修正済みの確認',
        amendedTip: 'The order has been Amended by the agent/client, please verify again',
        rejectReason: '拒否理由',
        rejectReasonPlaceholder: '満車、時間が合わない',
        serviceTime: 'サービス可能時間',
        driverConfirm: '確認する'
    },
    invoiceList: {
        title: '請求書/バウチャー',
        status: '請求書状態',
        billDate: '請求日',
        dueDate: '期限',
        billTo: '請求先',
        totalPurchase: '全額',
        balanceDue: 'Balance Due',
        paymentReceived: '入金額',
        voucherSendDate: 'バウチャー発行日',
        voucherSendStatus: 'バウチャー送信ステータス',
        taxCommissior: '税金/手数料',
        invoiceNote: 'メモ',
        addItems: 'Add_items'
    },
    paymentList: {
        title: '支払いリスト',
        payMethod: '支払い方法',
        payDate: '支払日',
        amount: '金額',
        note: 'メモ',
        invoiceDetail: {
            repeatSendinvoice: '請求書メールを再度送信してもよろしいでしょうか?',
            repeatSendvoucher: 'バウチャーメールを再度送信してもよろしいでしょうか?'
        }
    },
    role: {
        Staff: '社員',
        Driver: 'ドライバー',
        CarCompany: '車会社',
        Agent: '代理店',
        Guest: 'お客様',
        Operator: 'オペレーター',
        SubAdmin: '管理者',
        SuperAdmin: 'クリスタル'
    },
    preview: {
        preview: 'プレビュー',
        invoice: '請求書のプレビュー',
        voucher: 'バウチャーのプレビュー',
        notice: '出発案内のプレビュー',
        editInvoice: '請求書の編集',
        editVoucher: 'バウチャーの編集',
        download: 'ダウンロード',
        email: 'メール',
        emailInvoice: '請求書をメールで送る',
        emailVoucher: 'バウチャーをメールで送る',
        emailDepartureNotice: '出発案内をメールで送る',
        downloadInvoice: '請求書をダウンロードする',
        downloadVoucher: 'バウチャーをダウンロードする',
        downloadDepartureNotice: '出発案内をダウンロードする',
        sendingInvoice: '請求書を送信中',
        sendingVoucher: 'バウチャーを送信中',
        sendingAmended: '変更メールを送信中',
        confirmSendAmended: '修正したメールを送信しますか?',
        sendSuccess: '送信成功',
        en: '英語版',
        jp: '日本語版'
    },
    airport: {
        departureDate: '出発日 (飛行機の出発日) ',
        departureDatePlaceholder: '飛行機の出発日',
        departureDatePlaceholder2: '日本から出発日',
        name: '空港名 ',
        filght: '便名',
        filghtPlaceholder: 'D7522, JL88',
        filghtDetail: 'フライト詳細: 出発 & 到着 空港名 ETD/ETA(e.g. KUL/HND/1430/2230) '
    },
    port: {
        name: '港名 ',
        cruiseName: 'クルーズ会社/名前 (e.g. ) ',
        cruiseNamePlaceholder: 'ダイヤモンド・プリンセス',
        cruiseTime: 'クルーズ 到着時刻/出発時刻'
    },
    activity: {
        name: 'アクティビティ ',
        pickUpTime: 'ピックアップ時間'
    },
    station: {
        name: '駅名 ',
        trainName: '列車名 ',
        trainNumber: '列車番号 ',
        route: 'どこからどもまで'
    },
    city: {
        name: '都市名 ',
        hotelSearch: 'ホテル検索',
        hotelSearchPlaceholder: 'ホテル名を入力して検索',
        info: 'ホテル名/民宿/アパート名',
        infoPlaceholder: '例；京阪ホテル, 桜シェアハウスなど',
        detail: 'ホテル/旅館/民宿の住所 & AirBnB オーナーの連絡先',
        detailPlaceholder: '例；東京都中央区銀座５丁目１番２　Tel:+81-2-1234-567)'
    },
    guest: {
        name: 'お客様 ',
        detailName: 'お客様の名前(パスポート通り) & 子供の年齢（乗車日の年齢）',
        SNS: 'SNS (WhatsApp, Line, メッセンジャー, 電話番号) ',
        SNSPlaceholder: '携帯電話番号: +81-90-1111-2222',
        agentOwnReferenceNo: '代理店予約番号 ',
        remarks: '備考'
    },
    userInfo: {
        company: '会社名',
        firstName: '名前',
        lastName: '苗字',
        email: 'メール',
        gender: '性別',
        password: 'パスワード',
        retype: 'パスワードを再入力',
        createYourBookingAccount: '予約アカウントを作成する',
        myProfile: 'プロフィール',
        city: '都市 ',
        country: '国',
        phone: '電話番号',
        alternativePhone: '携帯電話番号',
        birthTime: '誕生日',
        branchName: '支店名',
        createTime: '作成時間',
        generalInfo: '一般情報'
    },
    login: {
        repasswordError: '2つのパスワードが一致しません',
        chooseCharacter: 'あなたのキャラクターを選択してください',
        rechoose: '再選択',
        agreeText: 'を読んで同意しました ',
        termsAndConditions: '規約と条件',
        forgotPassword: 'パスワードを忘れた',
        forgotPasswordTip: 'アカウントをお持ちではありません?',
        passwordFormat: 'パスワードは6文字と長くする必要があります',
        sendResetPasswordEmail: 'パスワード再設定メールを送信しましたのでご確認ください。',
        resetPasswordSuccess: 'パスワードのリセットが成功しました',
        resetPassword: 'パスワードを再設定',
        newPassword: '新しいパスワード'
    },
    http: {
        unknownMistake: '未知の間違い',
        apiNotFound: 'api 見つからない',
        serviceError: 'サービスエラー'
    },
    remark: {
        tokyoRemarks: '東京の備考',
        elRemarks: 'KLの備考'
    },
    table: {
        order: '注文 ',
        orderFields: '注文フィールド',
        operations: 'オペレーション',
        addOne: '追加',
        edit: '変更',
        emptyDataTip: '少なくとも1つのデータを選択してください',
        downloadAll: 'すべてダウンロード',
        downloadBatch: '一括ダウンロード'
    },
    teamMember: {
        jobTitle: '職名',
        joinedDate: '入会日',
        city: '都市 ',
        country: '国',
        phone: '電話番号',
        mainCompany: '本社',
        branchName: '支店'
    },
    meetingPoint: {
        title: '集合場所',
        locationName: '場所',
        placeName: '地名',
        details: '詳細'
    },
    hotel: {
        title: 'ホテル',
        hotel: 'ホテル',
        hotelJp: 'ホテル(日本語)',
        address: '住所',
        addressJp: '住所(日本語)',
        tel: 'Tel',
        fax: 'Fax',
        email: 'Email'
    },
    agent: {
        title: 'エージェント/クライアント',
        agent: 'エージェント ',
        primaryContact: '連絡先',
        phone: '電話番号',
        joinedDate: '入会日',
        clientGroups: 'クライアントグループ',
        paymentReceived: '入金額',
        due: '締め切り',
        refCode: '参照コード',
        travelLicence: '旅行許可証の番号',
        contacts: '連絡先',
        agentInfo: 'エージェント情報',
        invoices: '請求書リスト',
        payments: '支払いリスト',
        totalPurchase: '合計金額'
    },
    agentInfo: {
        companyName: '会社名',
        address: '住所',
        city: '都市',
        state: '県',
        zip: '郵便番号',
        country: '国',
        phone: '電話番号',
        website: 'ウェブサイト',
        clientGroups: 'クライアントグループ',
        refCode: '参考コード',
        travelLicence: '旅行許可証の番号'
    },
    router: {
        title: '行き先',
        availableTimeTitle: '一括有効期間を設定する',
        import: 'エクセルでインポート',
        checkPrice: '回線価格の表示',
        routeId: '行き先ID',
        routeName: '目的地名',
        routeNameJp: 'Destination Name(JP) ',
        pickUpLocation: '乗車場所',
        pickUpLocationJp: 'Pick Up Location(JP)',
        pickUpLocationType: '乗車場所詳細',
        dropOffLocation: '下車場所',
        dropOffLocationJp: 'Drop Off Location(JP)',
        dropOffLocationType: '下車場所詳細',
        gc: 'GV',
        validityPeriod: '有効期限',
        availableStart: 'から有効(日本の日付)',
        availableEnd: 'まで有効(日本の日付)',
        CharterVanTaxi: 'Charter Van Taxi',
        LIMOGrandSedan: 'LIMO Grand Sedan',
        LIMOGrandMPV: 'LIMO Grand MPV',
        CharterSedanTaxi: 'Charter Sedan Taxi',
        CharterMPVTaxi: 'Charter MPV Taxi',
        LIMOGrandVan: 'LIMO Grand Van',
        SharedTransfer: 'Shared Transfer',
        priceTip: '価格を入力してください',
        errorPriceTip: '価格は数字のみ',
        category: 'カテゴリー',
        categoryPlaceholder: '価格によるカテゴリーを入力してください',
        importRouter: 'ルーターデータをインポート',
        importMode: 'インポートモード',
        replaceAll: 'すべて置き換える',
        updateRouter: 'ルーターを更新',
        fileDrag: 'ファイルをここにドラッグするか、',
        fileClick: 'ファイルをアップロードするにはクリックしてください',
        onlyExcel: 'Excelファイルのみサポート (.xlsx)',
        downloadFile: 'テンプレートファイルをダウンロードするにはクリックしてください',
        priority: '並べ替えの優先度',
        noChooseExcel: 'Excelファイルを選択してください',
        emptyPrice: '価格タイプと価格を入力してください',
        addRouterEmptyPrice: '少なくとも1つの価格データを作成してください'
    },
    guestAgentList: {
        name: '名前',
        email: 'メール',
        gender: '性別',
        createTime: '作成日',
        bookingFor: '予約対象',
        cardTitle: 'ゲスト/代理店の予約',
        stepOne: 'ゲスト/代理店を選択',
        stepTwo: '新規予約'
    },
    userMenu: {
        myProfile: 'プロフィール',
        signOuting: 'ログアウト'
    },
    lang: {
        en: '英語',
        zhCn: '中国語',
        jp: '日本語'
    },
    system: {
        reason: '理由',
        notExist: '存在します',
        systemTip: 'システムのヒント',
        ok: 'OK',
        cancel: 'キャンセル'
    },
    menu: {
        bookings: '予約リスト',
        invoiceVouchers: '請求書/バウチャー',
        agentsClients: 'エージェント/クライアント',
        teamMembers: 'チームメンバー',
        hotel: 'ホテルのデータ',
        meetingPoint: '集合場所のデータ',
        router: '商品一覧'
    },
    email: {
        to: 'To',
        cc: 'CC',
        bcc: 'BCC',
        subject: 'Subject',
        attached: '添付ファイル',
        title: 'クライアントに { type } メールを送る'
    },
    preOrder: {
        createOrder: '注文を作成する',
        importOrder: '注文をインポートする',
        isOrderCreate: '注文は作成されましたか',
        yes: 'はい',
        no: 'いいえ',
        created: '作成済み',
        notCreate: '作成されていない',
        notHaveValidData: '有効なサービスデーターが存在しません',
        importSuccess: 'インポート成功',
        createSuccess: '注文の作成が成功しました。注文リストページで確認してください',
        importExcel: 'Excelのインポート'
    },
    price: 'price',
    pricePlaceholder: 'please enter price',
    newBooking: '新規注文',
    systemTip: 'システムのヒント',
    confirmDelete: '削除しますか?',
    cancel: 'キャンセル ',
    send: '送る ',
    name: '名前 ',
    number: 'ナンバー ',
    info: '情報 ',
    details: '詳細 ',
    search: '検索 ',
    reset: 'リセット',
    edit: '編集',
    check: 'チェック',
    delete: '削除',
    signIn: 'ログイン ',
    signUp: '登録',
    createNewAccount: 'アカウントを新規作成する',
    findPasswordTip:
        'パスワードをリセットするには、電子メールを入力して「送信」ボタンをクリックしてください',
    signOut: 'ログアウト ',
    pickUp: '乗車場所 ',
    below: '以下 ',
    dropOff: '下車場所 ',
    japanTravelServices: '日本旅行サービス ',
    japanTravelServicesInfo:
        'AISCは、日本全国546以上の目的地で、空港から都市、駅、アクティビティ、温泉、港への送迎を提供しています。当社は、空港タクシー、シャトル、ミニバス、セダン、MPV、バン、さらには高級リムジンなど、幅広い自家用車および乗り合い車両によるドアツードアの送迎サービスを提供しています。当社のプロフェッショナルかつ手頃な価格の空港、港、鉄道の交通手段は、目的地にスムーズに到着するための完璧なソリューションです。',
    serviceDate: '運行日(JP) ',
    adults: '大人 ',
    child: '子供 ',
    infants: '幼児 ',
    change: '変更',
    pleaseEnter: ' 入力してください ',
    pleaseChoose: '選択してください  ',
    pleaseSelect: '選択してください  ',
    pleaseAgree: '賛成してください  ',
    nextStep: '次へ ',
    prevStep: '戻る ',
    bookCar: '注文する ',
    charterTime: 'チャーターの場合、お迎え時間を教えてください. ',
    vehicleNumber: '車両数 ',
    transportType: '車両タイプ ',
    totalPrice: '合計',
    success: '成功した ',
    save: '保存',
    all: 'すべて'
};

export default jp;
