<template>
    <el-date-picker
        v-model="date"
        format="YYYY-MM-DD"
        value-format="x"
        @change="onChange"
        v-bind="$attrs"
    ></el-date-picker>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { DateModelType } from 'element-plus';

const props = defineProps<{
    modelValue: number | string;
}>();

const emit = defineEmits<{
    'update:modelValue': [value: number | string | [DateModelType, DateModelType]];
    change: [value: number | string | [DateModelType, DateModelType]];
}>();

watch(
    () => props.modelValue,
    () => {
        initDate();
    }
);

const date = ref<string | number | [DateModelType, DateModelType]>('');

const initDate = () => {
    if (Array.isArray(props.modelValue) && props.modelValue.length === 2) {
        const offset1 = new Date(props.modelValue[0]).getTimezoneOffset() / 60;
        const offset2 = new Date(props.modelValue[1]).getTimezoneOffset() / 60;
        date.value = [
            new Date(props.modelValue[0]).getTime() + (9 + offset1) * 60 * 60 * 1000,
            new Date(props.modelValue[1]).getTime() + (9 + offset2) * 60 * 60 * 1000
        ];
    } else {
        const offset = new Date(props.modelValue).getTimezoneOffset() / 60;
        date.value = new Date(props.modelValue).getTime() + (9 + offset) * 60 * 60 * 1000;
    }
};
initDate();

const onChange = () => {
    console.log(date.value);
    emit('update:modelValue', date.value);
    emit('change', date.value);
};
</script>
