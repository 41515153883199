<template>
    <template v-if="currBooking">
        <div class="bg-color mg-b_20">
            <el-page-header
                class="w_100 bd-b_1 pd-x_10 bs-bb"
                @back="replace(ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH)"
            >
                <template #content>
                    <div class="flex flex-left">
                        <h2>Booking Request</h2>
                        <h2 class="mg-l_10">#{{ currBooking.uniqueReferenceNo }}</h2>
                        <h2>/(Invoice: N/A)</h2>
                    </div>
                </template>
                <template #extra>
                    <el-badge value="✓" type="success" :hidden="!isAssignTo" v-if="canAssignTo()">
                        <el-button
                            :type="isAssignTo ? 'success' : 'primary'"
                            @click="isVisibleAssignTo = true"
                            >{{ $t('bookingList.assignTo.assignTo') }}</el-button
                        >
                    </el-badge>
                </template>
            </el-page-header>
            <g-descriptions
                class="pd-x_10 pd-y_10"
                :column="4"
                :model="baseInfoFormModel"
                :options="baseInfoFormOptions"
            ></g-descriptions>
            <booking-log></booking-log>
        </div>
        <section class="bg-color pd_10">
            <status-bar :status="currBooking.status"></status-bar>
            <verify-fields></verify-fields>
        </section>
    </template>
    <el-dialog
        destroy-on-close
        :title="$t('bookingList.assignTo.assignTo')"
        v-model="isVisibleAssignTo"
    >
        <assign-to
            @cancel="isVisibleAssignTo = false"
            @confirm="isVisibleAssignTo = false"
        ></assign-to>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useVueI18n, useRouter } from '@use';
import { useBookingStore } from '@/stores';
import AssignTo from '@/components/bookingList/components/AssignTo.vue';
import VerifyFields from './components/VerifyFields.vue';
import StatusBar from './components/StatusBar.vue';
import BookingLog from './components/BookingLog.vue';

const { t } = useVueI18n();

const { go, replace, ROUTER_PATH_MAP } = useRouter();

const { currBooking, isAssignTo, getCurrBooking, canAssignTo } = useBookingStore();

const baseInfoFormModel = ref({
    status: currBooking.value?.status,
    agent: currBooking.value?.agentId
        ? currBooking.value?.agentCompanyName
        : `${currBooking.value?.userDetails.firstName} ${currBooking.value?.userDetails.lastName}`,
    createTime: currBooking.value?.createTime,
    updateTime: currBooking.value?.updateTime
});
getCurrBooking();
watch(currBooking, () => {
    baseInfoFormModel.value.status = currBooking.value?.status;
    baseInfoFormModel.value.agent = currBooking.value?.agentId
        ? currBooking.value?.agentCompanyName
        : `${currBooking.value?.userDetails.firstName} ${currBooking.value?.userDetails.lastName}`;
    baseInfoFormModel.value.createTime = currBooking.value?.createTime;
    baseInfoFormModel.value.updateTime = currBooking.value?.updateTime;
});
const baseInfoFormOptions = ref<IFormOption[]>([
    { label: t('bookingList.agentAndClient'), prop: 'agent' },
    {
        label: t('bookingList.bookedDate'),
        prop: 'createTime',
        formatValue: (record: any, value: number) => new Date(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        label: t('bookingList.updateDate'),
        prop: 'updateTime',
        formatValue: (record: any, value: number) => new Date(value).format('YYYY-MM-DD HH:mm:ss')
    }
]);

const isVisibleAssignTo = ref(false);
</script>
