import { toRefs } from 'vue';
import { useRouter as useVueRouter, useRoute as useVueRoute } from 'vue-router';
import { ROUTER_PATH_MAP } from '@enums';

export const useRouter = () => {
    const Router = useVueRouter();
    return {
        Router,
        ROUTER_PATH_MAP,
        ...Router
    };
};

export const useRoute = () => {
    const Route = useVueRoute();
    return {
        Route,
        ROUTER_PATH_MAP,
        ...toRefs(Route)
    };
};
