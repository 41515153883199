<template>
    <g-header-layout>
        <template #logo>
            <logo class="h_70" :click-to-home="true"></logo>
        </template>
        <template #left>
            <el-button v-if="hasMenu" class="mg-l_20" size="large" link @click="switchExpand">
                <el-icon size="25"><Fold /></el-icon>
            </el-button>
        </template>
        <template #right>
            <!-- <font-size-btn class="mg-r_20"></font-size-btn> -->
            <locale-btn class="mg-r_20"></locale-btn>
            <dark-mode-btn v-if="isSignIn" class="mg-r_20"></dark-mode-btn>
            <user v-if="isSignIn"></user>
            <login-btn v-else></login-btn>
        </template>
    </g-header-layout>
</template>

<script lang="ts" setup>
import { Fold } from '@element-plus/icons-vue';
import Logo from '@/components/header/Logo.vue';
import User from '@/components/user/User.vue';
import LoginBtn from '@/components/login/LoginBtn.vue';
import DarkModeBtn from '@/components/header/DarkModeBtn.vue';
import LocaleBtn from '@/components/header/LocaleBtn.vue';
// import FontSizeBtn from '@/components/header/FontSizeBtn.vue';
import { useLoginStore, useMenuStore } from '@/stores';

const { isSignIn } = useLoginStore();
const { hasMenu, switchExpand } = useMenuStore();
</script>
