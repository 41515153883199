<template>
    <el-dropdown class="mg-l_10" split-button trigger="click" type="success" @command="onCommand">
        {{ $t('preview.download') }}
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item command="invoice">
                    <el-button link>
                        <el-icon><Download /></el-icon>
                        {{ $t('preview.downloadInvoice') }}
                    </el-button>
                </el-dropdown-item>
                <el-dropdown-item v-if="isShowVoucher" command="voucher">
                    <el-button link>
                        <el-icon><Download /></el-icon>
                        {{ $t('preview.downloadVoucher') }}
                    </el-button>
                </el-dropdown-item>
                <el-dropdown-item v-if="isShowDepartureNotice" command="departure">
                    <el-dropdown>
                        <el-button link>
                            <el-icon><Download /></el-icon>
                            {{ $t('preview.downloadDepartureNotice') }}
                            <el-icon class="mg-l_5"><Document /></el-icon>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="onDownloadDepartureNotice('EN')">
                                    <el-button link>
                                        {{ $t('preview.en') }}
                                    </el-button>
                                </el-dropdown-item>
                                <el-dropdown-item @click="onDownloadDepartureNotice('JP')">
                                    <el-button link>
                                        {{ $t('preview.jp') }}
                                    </el-button>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script lang="ts" setup>
import { useBookingStore } from '@/stores';
import { Document, Download } from '@element-plus/icons-vue';

const {
    downloadInvoice,
    downloadVoucher,
    downloadDepartureNotice,
    isShowVoucher,
    isShowDepartureNotice
} = useBookingStore();

const onCommand = async (command: string) => {
    switch (command) {
        case 'invoice':
            await downloadInvoice();
            break;
        case 'voucher':
            await downloadVoucher();
            break;
    }
};
const onDownloadDepartureNotice = async (language: 'EN' | 'JP') => {
    await downloadDepartureNotice(language);
};
</script>

<style lang="scss">
.send-email__message {
    .el-icon {
        animation: rotateCircle 2s infinite linear;
    }
}
</style>
