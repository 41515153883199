<template>
    <h1>{{ $t('japanTravelServices') }}</h1>
    <base-info></base-info>
    <detail-form-wrap
        ref="pickUpRef"
        :routerType="pickUpType"
        type="pickUp"
        :title="$t('pickUp') + $t('details')"
    ></detail-form-wrap>
    <detail-form-wrap
        ref="dropOffRef"
        :routerType="dropOffType"
        type="dropOff"
        :title="$t('dropOff') + $t('details')"
    ></detail-form-wrap>
    <guest-form ref="guestRef"></guest-form>
    <form-title :title="$t('userInfo.createYourBookingAccount')" v-if="!isSignIn">
        <sign-up-form ref="signUpRef"></sign-up-form>
    </form-title>
    <el-button class="mg-b_50" type="danger" :icon="InfoFilled" @click="showPolicy = !showPolicy">{{
        $t('bookingOrder.cancelPolicy')
    }}</el-button>
    <div class="ta-c">
        <el-button
            v-if="amendOrderId"
            type="warning"
            size="large"
            @click="onOrder(2)"
            :loading="isLoading"
            >{{ $t('bookingOrder.AmendBooking') }}</el-button
        >
        <el-button type="success" size="large" @click="onOrder(1)" :loading="isLoading">{{
            $t('bookingOrder.newBooking')
        }}</el-button>
    </div>
    <el-dialog v-model="showPolicy" width="800">
        <cancel-policy></cancel-policy>
    </el-dialog>
</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import { InfoFilled } from '@element-plus/icons-vue';
import SignUpForm from '@/components/login/SignUpForm.vue';
import { useBookingOrderStore, useLoginStore } from '@/stores';
import { useRouteTypeDetailInfo } from '@use';
import { IBookingOrder, IUserInfo, IStationDetailInfo } from '@ctypes';
import { bookingRepositories, routerRepositories } from '@/repositories';
import { useRouter } from '@use';
import BaseInfo from './orderForm/BaseInfo.vue';
import DetailFormWrap from './orderForm/DetailFormWrap.vue';
import GuestForm from './orderForm/GuestForm.vue';
import FormTitle from './orderForm/FormTitle.vue';
import CancelPolicy from './CancelPolicy.vue';
import { RouterTypeEnum } from '@/common/enums';
import { getJPTimestamp } from '@utils';

const props = defineProps<{
    agentCompanyName?: string;
}>();

const emit = defineEmits<{
    success: [];
}>();

const { routerInfo, carInfo, peopleDateInfo, amendOrderId, preOrderTips } = useBookingOrderStore();
const pickUpType = computed(() => routerInfo.value.pickUpLocationType);
const dropOffType = computed(() => routerInfo.value.dropOffLocationType);

const { isSignIn, signIn } = useLoginStore();

const pickUpRef = ref<InstanceType<typeof DetailFormWrap>>();
const dropOffRef = ref<InstanceType<typeof DetailFormWrap>>();
const guestRef = ref<InstanceType<typeof GuestForm>>();
const signUpRef = ref<InstanceType<typeof SignUpForm>>();
const validate = async (): Promise<Partial<IBookingOrder>> => {
    const model: Partial<IBookingOrder> = {};
    const promises = [];
    if (pickUpRef.value) {
        promises.push(pickUpRef.value.validate());
        // model.pickUpDetails = await pickUpRef.value.validate<any>();
    }
    if (dropOffRef.value) {
        promises.push(dropOffRef.value.validate());
        // model.dropOffDetails = await dropOffRef.value.validate<any>();
    }
    if (guestRef.value) {
        promises.push(guestRef.value.validate());
        // const guest = await guestRef.value.validate<IGuestDetailInfo>();
        // Object.assign(model, guest);
    }
    const data = await Promise.all<any>(promises);
    const [pickUpDetails, dropOffDetails, guest] = data;
    model.pickUpDetails = pickUpDetails;
    model.dropOffDetails = dropOffDetails;
    Object.assign(model, guest);
    if (signUpRef.value) {
        model.user = await signUpRef.value.validate<IUserInfo>();
    }
    return model;
};

const { push, ROUTER_PATH_MAP } = useRouter();
const isLoading = ref(false);
const onOrder = async (type: 1 | 2) => {
    const model = await validate();
    if (routerInfo.value) {
        const { getET } = useRouteTypeDetailInfo();
        let findVehiclePrice = null;
        if (!carInfo.value.routeVehiclePriceId) {
            const vehiclePrice = await routerRepositories.getVehiclePriceByRouter(
                routerInfo.value.routeId!.toString()
            );
            findVehiclePrice = vehiclePrice.find(
                (item) => item.vehicleId === carInfo.value.vehicleId
            );
        }
        const data: Partial<IBookingOrder> = {
            routeId: routerInfo.value.routeId!,
            vehicleId: carInfo.value.vehicleId,
            vehicleCount: carInfo.value.vehicleCount,
            serviceDate: getJPTimestamp(peopleDateInfo.value.serviceDate),
            adultCount: peopleDateInfo.value.adultCount,
            childCount: peopleDateInfo.value.childCount,
            infantCount: peopleDateInfo.value.infantCount,
            guestNames: model.guestNames!,
            sns: model.sns!,
            referenceNo: model.referenceNo!,
            remarks: model.remarks!,
            pickUpDetails: model.pickUpDetails!,
            dropOffDetails: model.dropOffDetails!,
            routeVehiclePriceId: carInfo.value.routeVehiclePriceId || findVehiclePrice?.id
        };
        if (props.agentCompanyName) {
            data.agentCompanyName = props.agentCompanyName;
        }

        let ETA = getET(
            {
                pickUpLocationType: routerInfo.value.pickUpLocationType!,
                dropOffLocationType: routerInfo.value.dropOffLocationType,
                pickUpDetails: model.pickUpDetails!,
                dropOffDetails: model.dropOffDetails!
            },
            'pickUp'
        );
        let ETD = getET(
            {
                pickUpLocationType: routerInfo.value.pickUpLocationType!,
                dropOffLocationType: routerInfo.value.dropOffLocationType,
                pickUpDetails: model.pickUpDetails!,
                dropOffDetails: model.dropOffDetails!
            },
            'dropOff'
        );
        // airport需要转换时分
        if (routerInfo.value.pickUpLocationType === RouterTypeEnum.AIRPORT) {
            ETA = ETA.replace(/(?=(\d{2})$)/g, ':');
        }
        // airport需要转换时分
        if (routerInfo.value.dropOffLocationType === RouterTypeEnum.AIRPORT) {
            ETD = ETD.replace(/(?=(\d{2})$)/g, ':');
        }
        const regex = /DEPART:[\W\w]+\(ETD:([\W\w]+)\)\/ARR:[\W\w]+\(ETA:([\W\w]+)\)/;
        if (ETA) {
            if (routerInfo.value.pickUpLocationType === RouterTypeEnum.STATION) {
                if (regex.test((data.pickUpDetails as IStationDetailInfo).route)) {
                    ETA = `${new Date(peopleDateInfo.value.serviceDate).format('YYYY-MM-DD')} ${
                        RegExp.$2
                    }`;
                }
            } else {
                ETA = `${new Date(peopleDateInfo.value.serviceDate).format('YYYY-MM-DD')} ${ETA}`;
            }
            data.eta = getJPTimestamp(ETA);
        }
        if (ETD) {
            if (routerInfo.value.dropOffLocationType === RouterTypeEnum.STATION) {
                ETD = `${new Date(peopleDateInfo.value.serviceDate).format('YYYY-MM-DD')} ${
                    RegExp.$1
                }`;
            } else {
                ETD = `${new Date(peopleDateInfo.value.serviceDate).format('YYYY-MM-DD')} ${ETD}`;
            }
            data.etd = getJPTimestamp(ETD);
        }
        if (model.user) {
            data.user = model.user;
        }
        isLoading.value = true;
        try {
            if (type === 1) {
                await bookingRepositories.postNewBooking(data as IBookingOrder);
            } else {
                await bookingRepositories.updateOrderAll(amendOrderId.value, data as IBookingOrder);
            }
            if (model.user) {
                await signIn({
                    email: model.user.email,
                    password: model.user.password
                });
            }
            if (!preOrderTips.value) {
                push(ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH);
            }
            emit('success');
        } catch (e) {
            console.log(e);
        }
        isLoading.value = false;
    }
};

const showPolicy = ref(false);
defineExpose({
    validate
});
</script>
