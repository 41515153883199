<template>
    <div class="flex flex-left flex-wrap w_100">
        <div
            class="flex relative mg-r_10 mg-b_10 w_40"
            v-for="(item, index) in gusetList"
            :key="index"
        >
            <el-button
                v-if="gusetList.length > 1"
                class="absolute-tr"
                size="small"
                circle
                :style="{
                    width: '16px',
                    height: '16px',
                    top: '-8px',
                    right: '-8px',
                    zIndex: 10
                }"
                @click="onMinus(index)"
                type="danger"
                >x</el-button
            >
            <el-input
                v-model="item.name"
                @input="onChange"
                placeholder="Mary Jane/Perter Junior(8 years old)"
            ></el-input>
            <el-button
                v-if="index === gusetList.length - 1"
                class="absolute-r_o"
                :icon="Plus"
                link
                @click="onAdd"
            ></el-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { Plus } from '@element-plus/icons-vue';
import { useForm } from '@use';

const props = defineProps<{
    modelValue: string;
    prop?: string;
}>();

const emit = defineEmits<{
    'update:modelValue': [value: string];
}>();

const gusetList = ref<{ name: string }[]>([{ name: '' }]);

const init = () => {
    gusetList.value = props.modelValue?.split(',').map((name) => ({ name })) || [{ name: '' }];
};
init();

const onAdd = () => {
    gusetList.value.push({
        name: ''
    });
};
const onMinus = (index: number) => {
    gusetList.value.splice(index, 1);
    onChange();
};

const { formContext } = useForm();
const onChange = () => {
    emit(
        'update:modelValue',
        gusetList.value
            .filter((guest) => guest.name)
            .map((guest) => guest.name)
            .join(',')
    );
    props.prop && formContext?.value?.validateField([props.prop]);
};
</script>
