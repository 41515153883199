<template>
    <el-form ref="formRef" :model="formModel" :rules="formRules" class="flex flex-left mg-b_10">
        <div class="flex mg-r_20">
            <h5 class="w_50p">Depart:</h5>
            <el-form-item class="mg-x_20" prop="departName">
                <el-input v-model="formModel.departName"></el-input>
            </el-form-item>
            <el-form-item class="w_100p" prop="departTime">
                <el-time-picker
                    v-model="formModel.departTime"
                    format="HH:mm"
                    value-format="HH:mm"
                ></el-time-picker>
            </el-form-item>
        </div>
        <div class="flex">
            <h5 class="w_30p">Arr:</h5>
            <el-form-item class="mg-x_20" prop="arrName">
                <el-input v-model="formModel.arrName"></el-input>
            </el-form-item>
            <el-form-item class="w_100p" prop="arrTime">
                <el-time-picker
                    v-model="formModel.arrTime"
                    format="HH:mm"
                    value-format="HH:mm"
                ></el-time-picker>
            </el-form-item>
        </div>
    </el-form>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useForm } from '@use';
import { FormRules, ElForm } from 'element-plus';

interface IRouteInfo {
    departName: string;
    departTime: string;
    arrName: string;
    arrTime: string;
}

const props = defineProps<{
    modelValue?: string;
    prop?: string;
}>();

const regex = /DEPART:([\W\w]+)\(ETD:([\W\w]+)\)\/ARR:([\W\w]+)\(ETA:([\W\w]+)\)/;
const formRef = ref<InstanceType<typeof ElForm>>();
const formModel = ref<IRouteInfo>({
    departName: '',
    departTime: '',
    arrName: '',
    arrTime: ''
});
if (typeof props.modelValue === 'string' && regex.test(props.modelValue)) {
    formModel.value.departName = RegExp.$1;
    formModel.value.departTime = RegExp.$2;
    formModel.value.arrName = RegExp.$3;
    formModel.value.arrTime = RegExp.$4;
}
const emit = defineEmits<{
    'update:modelValue': [value?: string];
    change: [value?: string];
}>();

const formRules: FormRules = {
    departName: [{ required: true, message: '' }],
    departTime: [{ required: true, message: '' }],
    arrName: [{ required: true, message: '' }],
    arrTime: [{ required: true, message: '' }]
};

const { formContext } = useForm();
const validate = async () => {
    try {
        await formRef.value?.validate();
        console.log(formModel.value);
        const res = `DEPART:${formModel.value.departName}(ETD:${formModel.value.departTime})/ARR:${formModel.value.arrName}(ETA:${formModel.value.arrTime})`;
        emit('update:modelValue', res);
        emit('change', res);
        props.prop && formContext?.value?.validateField([props.prop]);
    } catch (e) {
        emit('update:modelValue', undefined);
        emit('change', undefined);
    }
};
watch(
    formModel,
    () => {
        validate();
    },
    {
        deep: true
    }
);
</script>
