const zhCn = {
    bookingOrder: {
        searchRouter: {
            placeholder: '请输入线路名进行搜索'
        },
        selectRouter: '请选择线路',
        noOfVehicle: '车辆数',
        cancelPolicy: '重要！请阅读我们的取消政策！',
        newBooking: '新建订单',
        AmendBooking: '修改订单',
        hasStartAndEnd:
            '该线路在{startYear}年{startMonth}月{startDay}日到{endYear}年{endMonth}月{endDay}日期间才能预定',
        hasStartNoEnd: '该线路从{startYear}年{startMonth}月{startDay}日起才能预定',
        noStartHasEnd: '该线路截止{endYear}年{endMonth}月{endDay}日停止预定'
    },
    status: {
        NEW: '新建',
        PRE_BOOK: '预定',
        VERIFIED: '已验证',
        CONFIRMED: '已确认',
        REJECTED: '已驳回',
        CANCELLED: '已取消',
        FINAL: '已支付',
        TOKYO_FINAL: '已支付(东京)',
        TEHAI: '已派车',
        COMPLETED: '已完成',
        OVERDUE: '已过期',
        DRAFT: '暂存',
        NOT_PAID: '未支付',
        PARTIALLY_PAID: '部分支付',
        FULLY_PAID: '完全支付',
        MALE: '男',
        FEMALE: '女'
    },
    bookingList: {
        title: '订单列表',
        status: '状态',
        agentAndClient: '代理商',
        client: '代理商',
        referenceNoAgent: 'Reference No Agent',
        referenceNo: '旅行社订单号',
        rawOurRef: 'raw_Our_Ref',
        uniqueReferenceNo: '订单号',
        guestName: '客户名称',
        guestInfo: '客户信息',
        bookedDate: '订单日期',
        updateDate: '更新日期',
        pickUpAirportCityAttraction: '接客地类型 Airport/ City/ Attraction',
        dropOffAirportCityAttraction: '下客地类型 Airport/ City/ Attraction',
        type: '类型',
        vehicleCount: '车辆数',
        destinationInfo: '线路信息',
        arrivalAndDeparture: '抵达/离开',
        serviceDate: '服务日期',
        pickUpFrom: '接客地信息',
        pickUpAddress: '接客地点',
        ETA: '抵达日期',
        dropOff: '下客地',
        dropOffAddress: '下客地点',
        ETD: '离开日期',
        adult: '成年人',
        child: '小孩',
        infant: '婴儿',
        payment: '支付金额',
        pickUpTime: '接客时间',
        transportInfo: '车公司',
        driverInfo: '车司机',
        remark: '备注',
        remarkTokyo: '跟车公司之间的沟通',
        remarkKL: '公司内部之间的沟通',
        assignedTo: '派单',
        creator: '创建人',
        operation: {
            cancel: '取消',
            assignTo: '派单'
        },
        assignTo: {
            status: '状态',
            carCompany: '车公司',
            driver: '车司机',
            terminal: '航站楼',
            assignTo: '派单',
            transportInfo: '车公司',
            pickUpTime: '接送时间',
            meetingPoint: '会面地点',
            note: '备注',
            sayHello: 'いつもお世話になっております。',
            generate: '生成',
            international: '国际航线',
            internationalT1: '国际T1航站楼',
            internationalT2: '国际T2航站楼',
            internationalT3: '国际T3航站楼',
            domestic: '国内航线',
            domesticT1: '国内T1航站楼',
            domesticT2: '国内T2航站楼',
            domesticT3: '国内T3航站楼',
            emptyTip: '请至少填入一项'
        },
        verifyFields: {
            verifyFields: '验证字段',
            fieldName: '字段名称',
            fieldValue: '字段值',
            verified: '验证?',
            newFieldValue: '字段新值',
            name: '字段',
            canNotVerify: '当前订单状态无法修改字段'
        },
        changeStatus: {
            confimrChange: '确定将订单状态改为{status}?'
        },
        cancelConfirmInfo: '是否确定删除该订单?',
        downloadHaishaTip: '下载 Haisha Tehaisho',
        downloadDepartureNotice: '下载 Departure Notice',
        assigned: '派单',
        searchData: '综合搜索',
        amendVerified: '订单修改验证',
        amendedTip: '该订单被Agent/Client修改过，请重新校验。',
        rejectReason: '拒绝原因',
        rejectReasonPlaceholder: '拒绝原因，比如车满',
        serviceTime: '可服务时间',
        driverConfirm: '确认订单'
    },
    invoiceList: {
        title: '账单/坐车票',
        status: '账单状态',
        billDate: '账单发送日期',
        dueDate: '账单支付截止日期',
        billTo: '收件人',
        totalPurchase: '总金额',
        balanceDue: '最终金额',
        paymentReceived: '已收金额',
        voucherSendDate: '坐车票发送日期',
        voucherSendStatus: '坐车票发送状态',
        taxCommissior: '稅/优惠',
        invoiceNote: '备注',
        addItems: 'Add_items'
    },
    paymentList: {
        title: '支付列表',
        payMethod: '支付方式',
        payDate: '支付日期',
        amount: '支付金额',
        note: '备注',
        invoiceDetail: {
            repeatSendinvoice: '是否再次发送账单邮件?',
            repeatSendvoucher: '是否再次发送坐车票邮件?'
        }
    },
    role: {
        Staff: '员工',
        Driver: '司机',
        CarCompany: '车公司',
        Agent: '代理商',
        Guest: '客户',
        Operator: '操作员',
        SubAdmin: '管理员',
        SuperAdmin: '超级管理员'
    },
    preview: {
        preview: '预览',
        invoice: '预览账单',
        voucher: '预览坐车票',
        notice: '预览出发通知单',
        editInvoice: '修改账单信息',
        editVoucher: '修改坐车票',
        download: '下载',
        email: '邮件',
        emailInvoice: '发送账单邮件',
        emailVoucher: '发送坐车票邮件',
        emailDepartureNotice: '发送出发通知单邮件',
        downloadInvoice: '下载账单',
        downloadVoucher: '下载坐车票',
        downloadDepartureNotice: '下载出发通知单',
        sendingInvoice: '账单邮件发送中。。。',
        sendingVoucher: '坐车票发送中。。。',
        sendingAmended: '订单更改邮件发送中。。。',
        confirmSendAmended: '是否发送订单更改邮件？',
        sendSuccess: '发送成功',
        en: '英文版',
        jp: '日文版'
    },
    airport: {
        departureDate: '离开日期(航班离开日期) ',
        departureDatePlaceholder: '去日本的航班日期',
        departureDatePlaceholder2: '从日本出发的航班日期',
        name: '机场名称 ',
        filght: '航班号(eg. D7522, JL88) ',
        filghtPlaceholder: 'D7522',
        filghtDetail: '航班信息：离开/出发机场名字和ETD/ETA(eg. KUL/HND/1430/2230) '
    },
    port: {
        name: '港口名字 ',
        cruiseName: '航线名称 (e.g. Diamond Princess) ',
        cruiseNamePlaceholder: 'Diamond Princess',
        cruiseTime: '航线出发/离开时间'
    },
    activity: {
        name: '活动名称 ',
        pickUpTime: '接客时间'
    },
    station: {
        name: '车站名称 ',
        trainName: '火车名字 ',
        trainNumber: '车次 ',
        route: '线路信息'
    },
    city: {
        name: '城市/酒店名称 ',
        hotelSearch: '搜索酒店',
        hotelSearchPlaceholder: '按酒店名称搜索',
        info: '酒店名称/民宿/公寓名称',
        infoPlaceholder: '例如：京阪酒店、樱花共享屋等。',
        detail: '酒店/旅馆/民宿地址和爱彼迎业主联系信息',
        detailPlaceholder: '例如：东京都中央区银座 5-1-2 电话：+81-2-1234-567)'
    },
    guest: {
        name: '客人名称 ',
        detailName: '客人名称(与护照保持一致)，小孩年龄(旅途中)',
        SNS: '联系方式 (WhatsApp, Line, Messenger, telephone number) ',
        SNSPlaceholder: '联系方式: +81-90-1111-2222',
        agentOwnReferenceNo: '旅行社订单号 ',
        remarks: '备注'
    },
    userInfo: {
        company: '公司',
        firstName: '名',
        lastName: '姓',
        email: '电子邮箱',
        gender: '性别',
        password: '密码',
        retype: '重新输入',
        createYourBookingAccount: '创建您的账户',
        myProfile: '个人信息',
        city: '城市 ',
        country: '国家',
        phone: '手机号',
        alternativePhone: '其他电话',
        birthTime: '输入日期',
        branchName: '分公司',
        createTime: '创建日期',
        generalInfo: '基本信息'
    },
    login: {
        repasswordError: '两次密码不一致',
        chooseCharacter: '选择您的角色',
        rechoose: '重新选择',
        agreeText: '我已阅读并同意 ',
        termsAndConditions: '条款和协议',
        forgotPassword: '忘记密码',
        forgotPasswordTip: `已有账户?`,
        passwordFormat: '密码至少6位数',
        sendResetPasswordEmail: '重置密码邮件已发送您的邮箱，请查收',
        resetPasswordSuccess: '密码重置成功',
        resetPassword: '重置密码',
        newPassword: '新密码'
    },
    http: {
        unknownMistake: '未知错误',
        apiNotFound: '接口未找到',
        serviceError: '服务错误'
    },
    remark: {
        tokyoRemarks: '备注(东京)',
        elRemarks: '备注(吉隆坡)'
    },
    table: {
        order: '订单 ',
        orderFields: '订单字段',
        operations: '操作',
        addOne: '新增',
        edit: '修改',
        emptyDataTip: '请至少选择一条数据',
        downloadAll: '全部下载',
        downloadBatch: '批量下载'
    },
    teamMember: {
        jobTitle: '角色',
        joinedDate: '创建日期',
        city: '城市 ',
        country: '国家',
        phone: '手机号',
        mainCompany: '公司(主要)',
        branchName: '子公司'
    },
    meetingPoint: {
        title: '会面地点',
        locationName: '地点名称',
        placeName: '地方名称',
        details: '地点详情'
    },
    hotel: {
        title: '酒店',
        hotel: '酒店(英文)',
        hotelJp: '酒店(日文)',
        address: '酒店地点(英文)',
        addressJp: '酒店地点(日文)',
        tel: '电话',
        fax: '传真',
        email: 'Email'
    },
    agent: {
        title: '代理商',
        agent: '代理商 ',
        primaryContact: '主要联系人',
        phone: '电话',
        joinedDate: '创建日期',
        clientGroups: 'Client Groups',
        paymentReceived: '支付金额',
        due: '未支付金额',
        refCode: '公司编号',
        travelLicence: '旅行社执照',
        contacts: '联系人',
        agentInfo: '信息',
        invoices: '账单列表',
        payments: '支付列表',
        totalPurchase: '总金额'
    },
    agentInfo: {
        companyName: '公司名称',
        address: '地点',
        city: '城市',
        state: '省/州',
        zip: '邮件编号',
        country: '国家',
        phone: '手机号',
        website: '网站地址',
        clientGroups: 'Client groups',
        refCode: '公司编号',
        travelLicence: '旅行社执照'
    },
    router: {
        title: '线路',
        availableTimeTitle: '设置所有线路的有效期',
        import: '导入excel线路文件',
        checkPrice: '查看线路价格',
        routeId: '线路编号',
        routeName: '线路名称',
        routeNameJp: '线路名称(JP) ',
        pickUpLocation: '接客地点',
        pickUpLocationJp: '接客地点(JP)',
        pickUpLocationType: '接口地点类型',
        dropOffLocation: '下客地点',
        dropOffLocationJp: '下客地点(JP)',
        dropOffLocationType: '下客地点类型',
        gc: 'GV',
        validityPeriod: 'validity period',
        availableStart: '有效期起(日本日期)',
        availableEnd: '有效期止(日本日期)',
        CharterVanTaxi: '面包车',
        LIMOGrandSedan: '豪华轿车',
        LIMOGrandMPV: 'LIMO 大型 MPV',
        CharterSedanTaxi: '轿车出租车',
        CharterMPVTaxi: 'MPV出租车',
        LIMOGrandVan: '大型货车',
        SharedTransfer: '共享接送服务',
        priceTip: '请输入价格',
        errorPriceTip: '价格只能是数字',
        category: '价格类型',
        categoryPlaceholder: '请输入价格类型',
        importRouter: '导入线路',
        importMode: '导入模式',
        replaceAll: '全部替换',
        updateRouter: '增量更新',
        fileDrag: '拖拽文件到此或者',
        fileClick: '点击上传文件',
        onlyExcel: '仅支持excel文件(.xlsx)',
        downloadFile: '点击下载模板文件',
        priority: '排序优先级',
        noChooseExcel: '请先选择excel文件',
        emptyPrice: '价格类型和价格不能为空',
        addRouterEmptyPrice: '请至少创建一条价格数据'
    },
    guestAgentList: {
        name: '名字',
        email: '邮箱',
        gender: '性别',
        createTime: '创建日期',
        bookingFor: '创建订单',
        cardTitle: '为代理商/客户创建订单',
        stepOne: '选择代理商/客户',
        stepTwo: '创建订单'
    },
    userMenu: {
        myProfile: '个人信息',
        signOuting: '退出登陆'
    },
    lang: {
        en: '英文',
        zhCn: '中文',
        jp: '日文'
    },
    fontSize: {
        sm: '一般',
        base: '较大',
        lg: '最大'
    },
    system: {
        reason: '原因',
        notExist: '不存在',
        systemTip: '系统提示',
        ok: '确认',
        cancel: '取消'
    },
    menu: {
        bookings: '订单列表',
        invoiceVouchers: '账单/坐车票',
        agentsClients: '代理商列表',
        teamMembers: '成员列表',
        hotel: '酒店列表',
        meetingPoint: '会面地点列表',
        router: '线路列表'
    },
    email: {
        to: '收件人',
        cc: '抄送',
        bcc: '密件抄送',
        subject: '主题',
        attached: '附件',
        title: '发送{ type }'
    },
    preOrder: {
        createOrder: '创建订单',
        importOrder: '导入订单',
        isOrderCreate: '订单是否已创建',
        yes: '是',
        no: '否',
        created: '已创建',
        notCreate: '未创建',
        notHaveValidData: '不存在有效的服务日期数据',
        importSuccess: '导入成功',
        createSuccess: '创建订单成功，请在订单列表页查看',
        importExcel: '导入Excel',
        notHaveSheet: 'Excel文件中不存在'
    },
    price: '价格',
    pricePlaceholder: '请输入价格',
    newBooking: '创建订单',
    systemTip: '系统提示',
    confirmDelete: '确认删除?',
    cancel: '取消 ',
    send: '发送 ',
    name: '名字 ',
    number: '号码 ',
    info: '信息 ',
    details: '详情 ',
    search: '搜索 ',
    reset: '重置',
    edit: '编辑',
    check: '查看',
    delete: '删除',
    signIn: '登陆 ',
    signUp: '注册 ',
    createNewAccount: '创建新账户',
    findPasswordTip: '请输入您的邮箱以找回密码',
    signOut: '退出 ',
    pickUp: '接客 ',
    below: 'below ',
    dropOff: '下客 ',
    japanTravelServices: '日本旅行服务 ',
    japanTravelServicesInfo:
        'AISC Japan 在日本全国超过 546 个目的地提供机场至城市、车站、活动、温泉和港口接送服务。我们提供各种私人和共享车辆的门到门接送服务，例如机场出租车、班车、小巴、轿车、MPV、面包车甚至豪华轿车。我们专业且价格实惠的机场、港口和火车交通是您轻松抵达目的地的完美解决方案。',
    serviceDate: '服务日期(JP) ',
    adults: '成年人 ',
    child: '小孩 ',
    infants: '婴儿 ',
    change: '修改',
    pleaseEnter: '请输入  ',
    pleaseChoose: '请选择  ',
    pleaseSelect: '请选择  ',
    pleaseAgree: '请同意  ',
    nextStep: '下一步 ',
    prevStep: '上一步 ',
    bookCar: '订车 ',
    charterTime: '如需包机，请告知您的接载时间。 ',
    vehicleNumber: '车辆数 ',
    transportType: '运输类型 ',
    totalPrice: '总金额',
    success: '成功 ',
    save: '保存',
    all: '全部'
};

export default zhCn;
