import service from '@/common/axios';
import { AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';

interface IParams<T> {
    url: string;
    data?: T;
}

export const get = <T, R>(params: IParams<T>, options: AxiosRequestConfig = {}) => {
    const { url, data } = params;
    return service.get<any, R, T>(url, {
        params: data,
        ...options
    });
};

export const downloadFile = async <T>(
    params: IParams<T>,
    fileName: string,
    autoDownload = true
) => {
    const file = await get<T, Blob>(params, {
        responseType: 'blob'
    });
    autoDownload && saveAs(file, fileName);
    return file;
};

export const post = <T, R = null>(params: IParams<T>) => {
    const { url, data } = params;
    return service.post<any, R, T>(url, data);
};

export const put = <T, R>(params: IParams<T>): Promise<R> => {
    const { url, data } = params;
    return service.put<any, R, T>(url, data);
};

export const del = <T, R>(params: IParams<T>): Promise<R> => {
    const { url, data } = params;
    return service.delete<any, R, T>(url, {
        data
    });
};
