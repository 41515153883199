<template>
    <el-config-provider :locale="elLocale">
        <header-bar></header-bar>
        <div class="flex1 of-a" v-loading="isLoadingUserInfo">
            <router-view v-slot="{ Component, route }">
                <template v-if="route.meta && route.meta.keepAlive">
                    <keep-alive>
                        <component :is="Component"></component>
                    </keep-alive>
                </template>
                <component v-else :is="Component"></component>
            </router-view>
        </div>
    </el-config-provider>
</template>

<script setup lang="ts">
import HeaderBar from '@/components/header/HeaderBar.vue';
import { RouterView } from 'vue-router';
import { useVueI18n, useRoute, useRouter } from '@use';
import { RoleEnum } from '@enums';
import { useUserStore, useBookingOrderStore } from '@/stores';
import { getLocalStorage, setLocalStorage } from './common/utils';
const { elLocale } = useVueI18n();
const { isLoadingUserInfo, getUserInfo, hasRole } = useUserStore();

const { Route, ROUTER_PATH_MAP } = useRoute();
const { Router } = useRouter();

const {
    routerInfo,
    peopleDateInfo,
    carInfo,
    airportInfo,
    portInfo,
    acitiviyInfo,
    stationInfo,
    cityInfo,
    cityInfoDrop,
    guestInfo,
    amendInfo,
    amendOrderId
} = useBookingOrderStore();

const autoLogin = async () => {
    if (document.cookie && ~document.cookie.indexOf('JSESSIONID')) {
        try {
            await getUserInfo();
            const amendInfoStorage = getLocalStorage<any>('amendInfo');
            if (amendInfoStorage) {
                amendInfo.value = amendInfoStorage.amendInfo;
                routerInfo.value = amendInfoStorage.routerInfo;
                peopleDateInfo.value = amendInfoStorage.peopleDateInfo;
                carInfo.value = amendInfoStorage.carInfo;
                airportInfo.value = amendInfoStorage.airportInfo;
                portInfo.value = amendInfoStorage.portInfo;
                acitiviyInfo.value = amendInfoStorage.acitiviyInfo;
                stationInfo.value = amendInfoStorage.stationInfo;
                cityInfo.value = amendInfoStorage.cityInfo;
                cityInfoDrop.value = amendInfoStorage.cityInfoDrop;
                guestInfo.value = amendInfoStorage.guestInfo;
                amendOrderId.value = amendInfoStorage.amendOrderId;
                setLocalStorage('amendInfo', '');
            } else {
                if (
                    !hasRole(RoleEnum.Guest).value &&
                    !hasRole(RoleEnum.Agent).value &&
                    ~Route.path.indexOf(ROUTER_PATH_MAP.HOME_PATH)
                ) {
                    Router.push(ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH);
                }
            }
        } catch (e) {
            if (!~Route.path.indexOf(ROUTER_PATH_MAP.HOME_PATH)) {
                Router.push(ROUTER_PATH_MAP.HOME_BOOKING_PATH);
            }
        }
    } else if (!~location.pathname.indexOf(ROUTER_PATH_MAP.HOME_PATH)) {
        Router.push(ROUTER_PATH_MAP.HOME_BOOKING_PATH);
    }
};
autoLogin();
</script>

<style lang="scss">
@import '@/assets/style/global.scss';
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>
