<template>
    <div>
        <el-button @click="onSignIn">{{ $t('signIn') }}</el-button>
        <el-button @click="onSignUp">{{ $t('signUp') }}</el-button>
    </div>
    <el-dialog width="500" v-model="showSignUp" destroy-on-close>
        <sign-in @jump="showSignUp = false"></sign-in>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter, useRoute } from '@use';
import SignIn from './SignIn.vue';

const showSignUp = ref(false);

const { push, ROUTER_PATH_MAP } = useRouter();

const { Route } = useRoute();
const onSignUp = () => {
    push(ROUTER_PATH_MAP.HOME_SIGN_UP_PATH);
};
const onSignIn = () => {
    console.log(Route);
    if (Route.path === ROUTER_PATH_MAP.HOME_BOOKING_PATH) {
        showSignUp.value = true;
    } else {
        push(ROUTER_PATH_MAP.HOME_SIGN_IN_PATH);
    }
};
</script>
