<template>
    <div class="h_100 flex flex-top of-a">
        <el-card
            class="mg-y_20"
            :style="{ width: computedWidth, maxWidth: computedMaxWidth }"
            v-bind="$attrs"
        >
            <slot></slot>
        </el-card>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
        width?: string | number;
        maxWidth?: string | number;
    }>(),
    {
        width: '60%',
        maxWidth: 1200
    }
);
const computedWidth = computed(() => {
    if (/^\d+$/.test(String(props.width))) {
        return `${props.width}px!important`;
    }
    return `${props.width}!important` || 'inherit';
});
const computedMaxWidth = computed(() => {
    if (/^\d+$/.test(String(props.maxWidth))) {
        return `${props.maxWidth}px!important`;
    }
    return `${props.maxWidth}!important` || 'inherit';
});
</script>
