<template>
    <g-page-table
        ref="pageTableRef"
        :title="currAgent?.companyName"
        operationTypes="add"
        :storageable="false"
        :updateFormOptions="updateFormOption"
        :options="tableOptions"
        :apiService="getConnectList"
        :addApiService="addConnectList"
    ></g-page-table>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useVueI18n, useRoute, useRouter } from '@use';
import { useAgentStore } from '@/stores';
import { agentRepositories } from '@/repositories';
import { ITeamMemberInfo } from '@/common/types';

const { tJoin } = useVueI18n();

const { currAgent } = useAgentStore();

const { Router, ROUTER_PATH_MAP } = useRouter();
const tableOptions = ref<ITableOption[]>([
    {
        label: tJoin('name'),
        prop: 'name',
        canSearch: true,
        decorate: 'elButton',
        decorateProps: (record: ITeamMemberInfo) => ({
            link: true,
            type: 'primary',
            onClick: () => {
                if (record.id) {
                    Router.push(`${ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL}/${record.id}`);
                }
            }
        }),
        width: 180,
        formatValue: (record: ITeamMemberInfo) => `${record.firstName} ${record.lastName}`
    },
    {
        label: tJoin('userInfo.email'),
        prop: 'email'
    },
    {
        label: tJoin('userInfo.phone'),
        prop: 'phone'
    },
    {
        label: tJoin('userInfo.createTime'),
        prop: 'createTime',
        decorate: 'gTimestamp',
        decorateProps: (record: ITeamMemberInfo) => ({
            timestamp: record.createTime,
            format: 'YYYY-MM-DD HH:mm:ss'
        })
    }
]);
const updateFormOption = ref<IFormOption[]>([
    {
        label: tJoin('userInfo.firstName'),
        prop: 'firstName',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.firstName') }]
    },
    {
        label: tJoin('userInfo.lastName'),
        prop: 'lastName',
        component: 'elInput',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.lastName') }]
    },
    {
        label: tJoin('userInfo.phone'),
        prop: 'phone'
    },
    {
        label: tJoin('userInfo.email'),
        prop: 'email',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.email') }]
    },
    {
        label: tJoin('userInfo.password'),
        prop: 'password',
        component: 'gGenPassword',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'userInfo.password') }],
        belong: 'add'
    },
    {
        label: tJoin('userInfo.gender'),
        prop: 'gender',
        component: 'GRadioGroup',
        rules: [
            {
                required: true,
                message: tJoin('pleaseChoose', 'userInfo.gender')
            }
        ],
        props: {
            options: [
                { label: 'male', value: 'MALE' },
                { label: 'female', value: 'FEMALE' }
            ]
        }
    }
]);
const { Route } = useRoute();
const getConnectList = async (data: IRecord) => {
    if (Route.params.id as string) {
        return await agentRepositories.getAgentConnectList(Route.params.id as string, data);
    }
    return {
        records: [],
        total: 0
    };
};
const addConnectList = async (data: IRecord) => {
    if (Route.params.id as string) {
        await agentRepositories.addAgentConnect(Route.params.id as string, data);
        return Promise.resolve();
    }
    return Promise.reject();
};

const pageTableRef = ref<PageTableInstance>();
watch(Route, () => {
    if (~Route.path.indexOf(ROUTER_PATH_MAP.MENU_AGENT_DETAIL) && pageTableRef.value) {
        pageTableRef.value.getData();
    }
});
</script>
