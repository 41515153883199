<template>
    <div class="flex flex-left">
        <el-form ref="formRef" :inline="true" :rules="formRules" :model="formModel">
            <el-form-item
                class="mg-r_0"
                v-for="(option, index) in formOptions"
                :key="index"
                :prop="option.prop"
            >
                <el-input
                    v-if="option.type === 'input'"
                    class="input-wrap relative mg-x_2"
                    :input-style="{
                        textAlign: 'center'
                    }"
                    :ref="`input${index}`"
                    v-model="formModel[option.prop]"
                    :maxlength="1"
                    :minlength="1"
                    :validate-event="false"
                    @input="onInput(index)"
                    @keydown="onKeydown($event as KeyboardEvent, index)"
                    @focus="onFocus(index)"
                    @click="selectAll(index)"
                ></el-input>
                <span class="mg-x_2" v-else>{{ option.type }}</span>
            </el-form-item>
        </el-form>
    </div>
</template>

<script lang="ts" setup>
import { ref, getCurrentInstance } from 'vue';
import { ElInput, ElForm, FormRules, FormItemRule } from 'element-plus';
import { letterValidator, numberValidator } from '@utils';
import { useForm } from '@use';

interface SingleInput {
    type: string;
    value: string | number;
    rules?: FormItemRule[];
}

const props = defineProps<{
    modelValue: string;
    prop?: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
    (e: 'blur'): void;
}>();

const blur = () => {
    emit('blur');
};

const numList = ref<SingleInput[]>([
    { type: 'input', value: '', rules: [{ required: true, validator: letterValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: letterValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: letterValidator }] },
    { type: '/', value: '/' },
    { type: 'input', value: '', rules: [{ required: true, validator: letterValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: letterValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: letterValidator }] },
    { type: '/', value: '/' },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: '/', value: '/' },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: 'input', value: '', rules: [{ required: true, validator: numberValidator }] },
    { type: '+', value: '+' },
    { type: 'input', value: '' }
]);
props.modelValue.split('').forEach((value, index) => {
    numList.value[index].value = value;
});
const formModel = ref<Record<string, string | number>>({});
const formRules = ref<FormRules>({});
const formOptions = ref<Array<SingleInput & IFormOption>>([]);

/**
 * 初始化配置，规则。。
 */
const initOptions = () => {
    numList.value.forEach((item, index) => {
        const prop = `input${index}`;
        if (item.rules) {
            formRules.value[prop] = item.rules;
        }
        formModel.value[prop] = item.value || '';
        formOptions.value.push({
            prop,
            ...item
        });
    });
};
initOptions();

// 获取最终值
const formRef = ref<InstanceType<typeof ElForm>>();

const { formContext } = useForm();
const getValue = async () => {
    if (formRef.value) {
        try {
            await formRef.value.validate();
            let res = Object.values(formModel.value).reduce(
                (a, b) => String(a) + String(b)
            ) as string;
            // 若最后一个没有填，则删除最后一个+
            if (res.length !== numList.value.length) {
                res = res.slice(0, res.length - 1);
            }
            emit('update:modelValue', res);
        } catch (e) {
            // emit('update:modelValue', '');
        }
        props.prop && formContext?.value?.validateField([props.prop]);
    }
};

const isExistValue = (index: number) => {
    return !!String(formModel.value[`input${index}`]);
};
const instance = getCurrentInstance();
const validate = async (index: number) => {
    const { prop } = formOptions.value[index];
    await formRef.value?.validateField([prop]);
};
const onInput = async (index: number) => {
    try {
        await validate(index);
        if (/[a-z]+/.test(String(formModel.value[`input${index}`]))) {
            formModel.value[`input${index}`] = (
                formModel.value[`input${index}`] as string
            ).toLocaleUpperCase();
        }
        if (isExistValue(index)) {
            focus(index);
        }
        getValue();
    } catch (e) {
        formModel.value[`input${index}`] = '';
    }
};
const onFocus = (index: number) => {
    selectAll(index);
};

const selectAll = (index: number) => {
    if (isExistValue(index)) {
        const instance = getElInputElement(index);
        if (instance && instance.input) {
            selectText(instance.input);
        }
    }
};
/**
 * 获取ElInput实例
 * @param index 当前输入框下标
 */
const getElInputElement = (index: number): InstanceType<typeof ElInput> | null => {
    if (instance) {
        const curr = numList.value[index];
        if (curr && curr.type === 'input' && Array.isArray(instance.refs[`input${index}`])) {
            return (instance.refs[`input${index}`] as Array<InstanceType<typeof ElInput>>)[0];
        }
    }
    return null;
};

/**
 * 聚焦
 * @param index 当前输入框下标
 * @param distance 移动方向 1向左，2向右
 */
const focus = (index: number, distance: number = 1) => {
    const elInstance = getElInputElement(index + distance);
    if (elInstance) {
        elInstance.focus();
    } else if (numList.value[index + distance]) {
        focus(index + distance, distance);
    }
};

/**
 * 设置全选
 * @param el 输入框dom
 */
const selectText = (el: HTMLInputElement) => {
    setTimeout(() => {
        el.setSelectionRange(0, el.value.length);
    });
};

/**
 * 处理按键
 */
const onKeydown = (e: KeyboardEvent, index: number) => {
    if (e.code === 'Backspace') {
        if (!isExistValue(index)) {
            e.preventDefault();
            focus(index, -1);
        }
    } else if (e.code === 'ArrowRight') {
        focus(index, 1);
    } else if (e.code === 'ArrowLeft') {
        focus(index, -1);
    } else if (e.key === formModel.value[`input${index}`]) {
        focus(index);
    }
};

defineExpose({
    blur
});
</script>

<style lang="scss" scoped>
.input-wrap {
    max-width: 40px;
}
.el-form-item:last-child {
    ::v-deep .el-input__wrapper {
        box-shadow: none;
        border: dashed 1px var(--el-border-color);
    }
}
.input-wrap::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 4px;
    width: 50%;
    height: 2px;
    line-height: 2px;
    background-color: var(--el-border-color);
}
::v-deep .el-input__wrapper {
    padding: 0;
}
</style>
