<template>
    <h3 class="fw-n" v-if="toValue(title)">{{ toValue(title) }}</h3>
    <el-card
        ref="searchFormCardRef"
        shadow="never"
        class="mg-b_20"
        v-if="searchFormOptions.length > 1"
    >
        <g-option-form
            ref="searchFormRef"
            :model="searchFormModel"
            :options="searchFormOptions"
            :inline="true"
            label-position="right"
            @change="onSearchOptionChange"
        >
            <template #searchBtn>
                <div class="nowrap">
                    <el-button type="primary" @click="getData" :loading="isLoading">{{
                        $t('search')
                    }}</el-button>
                    <el-button @click="onReset">{{ $t('reset') }}</el-button>
                </div>
            </template>
        </g-option-form>
    </el-card>
    <el-card shadow="never" v-loading="isLoading">
        <div class="flex flex-between mg-b_20">
            <div class="flex flex-left">
                <g-export-data
                    v-if="exportExcel"
                    :idKey="idKey"
                    :title="toValue(title)"
                    :queryParams="queryParams"
                    :options="computedOptions"
                    :apiService="apiService"
                    :selectionRows="selectionRows"
                    @batchExport="(batch: boolean) => (showSelection = batch)"
                ></g-export-data>
                <slot name="left"></slot>
            </div>
            <div class="flex flex-right">
                <slot name="right"></slot>
                <el-button v-if="canAdd" type="primary" :icon="Plus" @click="onAdd">{{
                    $t('table.addOne')
                }}</el-button>
                <g-order-options
                    :tableOptions="options"
                    v-model="tableOption"
                    :storageable="storageable"
                ></g-order-options>
            </div>
        </div>
        <g-option-table
            :data="tableData"
            :options="computedOptions"
            :border="true"
            :height="tableHeight"
            :showSelection="showSelection"
            @selection-change="onSelectionChange"
            v-bind="$attrs"
            @sort-change="onSortChange"
        >
            <template #operation="{ row }">
                <div class="nowrap flex flex-left">
                    <template v-for="(operation, index) in checkOperations" :key="index">
                        <template v-if="!operation.slot">
                            <el-button
                                link
                                :type="getOperationType(operation.type)"
                                :icon="operation.icon"
                                @click="onClick(operation, row)"
                                v-bind="operation.props"
                            >
                                {{ toValue(operation.label) }}
                            </el-button>
                        </template>
                        <slot v-else :name="operation.slot"></slot>
                    </template>
                    <slot name="operation" :row="row"></slot>
                    <el-dropdown class="mg-l_10" v-if="editAndDelOperations.length">
                        <el-button type="primary" :icon="More" link></el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item
                                    v-for="(operation, index) in editAndDelOperations"
                                    :key="index"
                                >
                                    <template v-if="!operation.slot">
                                        <el-button
                                            link
                                            :type="getOperationType(operation.type)"
                                            :icon="operation.icon"
                                            @click="onClick(operation, row)"
                                            v-bind="operation.props"
                                        >
                                            {{ toValue(operation.label) }}
                                        </el-button>
                                    </template>
                                    <slot v-else :name="operation.slot"></slot>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </template>
            <template v-for="(item, key, index) in colSlots" :key="index" #[key]="{ row, option }">
                <slot :name="key" :row="row" :option="option"></slot>
            </template>
            <template #col="{ row, option }">
                <slot name="col" :row="row" :option="option"></slot
            ></template>
            <template #header="{ column, option }">
                <slot name="header" :column="column" :option="option"></slot>
            </template>
        </g-option-table>
    </el-card>
    <div class="flex flex-right mg-t_20 mg-b_50" v-if="pageable">
        <el-pagination
            v-model:page-size="page.pageSize"
            v-model:current-page="page.pageNum"
            background
            layout="total, prev, pager, next, sizes"
            :total="page.total || 0"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :pager-count="4"
            @size-change="getData"
            @current-change="getData"
        />
    </div>
    <el-dialog
        width="500"
        :title="operationType === OperationEnum.ADD ? $t('table.addOne') : $t('table.edit')"
        v-model="isShowUpdate"
        destroy-on-close
    >
        <g-option-form
            v-loading="isConfirmLoading"
            ref="updateFormRef"
            labelPosition="top"
            :model="currRow"
            :options="computedUpdateFormOptions"
        ></g-option-form>
        <div class="flex flex-right">
            <el-button type="primary" @click="onConfirm" :loading="isConfirmLoading">{{
                operationType === OperationEnum.ADD ? $t('table.addOne') : $t('save')
            }}</el-button>
            <el-button @click="onCancel">{{ $t('cancel') }}</el-button>
        </div>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, toValue, useSlots, provide, Ref, watchEffect } from 'vue';
import { Plus, Edit, Delete, More } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useVueI18n, useRouter, useRoute } from '@use';
import { usePageTableStore } from '@/stores';
import { OperationEnum } from '@enums';
import { setLocalStorage, getLocalStorage } from '@utils';

const props = withDefaults(
    defineProps<{
        title?: string | Ref<string>;
        idKey?: string;
        checkPath?: string;
        checkMode?: string;
        checkFunc?: IFunction;
        searchModel?: Record<string, any>;
        exportExcel?: boolean;
        options: ITableOption[];
        operationTypes?: string;
        operationWidth?: number;
        pageable?: boolean;
        storageable?: boolean;
        height?: number;
        updateFormOptions: IFormOption[];
        handleParams?: IFunction<IRecord, IRecord>;
        handleSortParams?: IFunction<IRecord, IRecord>;
        apiService?: IApiService<any, any>;
        addApiService?: IApiService<any, any>;
        updateApiService?: IApiService<any, any>;
        deleteApiService?: IApiService<any, any>;
    }>(),
    {
        idKey: 'id',
        pageable: true,
        storageable: true,
        checkMode: 'push',
        updateFormOptions: () => [],
        operationTypes: '',
        options: () => [],
        handleParams: (formData?: IRecord) => formData || {},
        apiService: async () => ({ records: [], total: 0 })
    }
);

const slots = useSlots();
const colSlots = Object.fromEntries(
    Object.entries(slots).filter(([key]) => !['operation', 'right'].includes(key))
);
provide('pageTableSlots', colSlots);

const { t, tJoin } = useVueI18n();

const searchFormCardRef = ref();
const tableHeight = computed(() => {
    // let height = 0;
    // if (props.height) {
    //     height = props.height;
    //     if (props.title) {
    //         height -= 51;
    //     }
    //     if (searchFormRef.value) {
    //         height -= searchFormRef.value.height;
    //     }
    //     height = Number(height.toFixed(0));
    // }
    return props.height ? props.height - 102 - 40 : undefined;
});

const { Route } = useRoute();

const searchFormRef = ref<OptionFormInstance>();
// 搜索表单配置
const searchFormModel = ref<Record<string, string | number | boolean>>({});
let cacheSearchOptions: Record<string, string | number | boolean> | null =
    props.storageable && Route.name
        ? getLocalStorage<Record<string, string | number | boolean>>(Route.name as string)
        : null;
onMounted(() => {
    if (cacheSearchOptions && Object.keys(cacheSearchOptions).length) {
        searchFormModel.value = { ...cacheSearchOptions };
    }
});
const searchFormOptions = computed(() => {
    const options: IFormOption[] = props.options
        .filter((option) => option.canSearch)
        .map((option) => ({
            ...option,
            prop: option.formProp || option.prop?.split(',')[0].split('.')[0] || '',
            props: option.formProps || option.props || {},
            width: 350
        }));
    options.forEach((option) => {
        if (option.prop && searchFormModel.value[option.prop] === undefined) {
            searchFormModel.value[option.prop] = props.searchModel
                ? props.searchModel[option.prop]
                : '';
        }
    });
    options.push({
        slot: 'searchBtn',
        prop: '',
        width: 160
    });
    return options;
});
const onSearchOptionChange = (searchOptions: Record<string, string | number | boolean>) => {
    props.storageable && Route.name && setLocalStorage(Route.name as string, searchOptions);
};
/**
 * 重置查询条件
 */
const onReset = () => {
    if (searchFormRef.value) {
        props.storageable && Route.name && setLocalStorage(Route.name as string, {});
        cacheSearchOptions = {};
        searchFormRef.value.formRef?.resetFields();
    }
    getData();
};
// 获取列表数据
const { setCachePage, getCachePage } = usePageTableStore();
const page = ref<IPage>(getCachePage(toValue(props.title) || (Route.name as string)));
const isLoading = ref(false);
const tableData = ref<object[]>([]);
const orderByParams = ref({});
const onSortChange = ({ column, prop, order }: any) => {
    if (props.handleSortParams) {
        orderByParams.value = props.handleSortParams({ column, prop, order });
        getData();
    }
};
const searchFormData = ref<IRecord>();
const queryParams = ref<IRecord>();
const getData = async () => {
    if (searchFormRef.value) {
        searchFormData.value = await searchFormRef.value.validate<Record<string, any>>();
    }
    if (props.apiService instanceof Function) {
        isLoading.value = true;
        try {
            const params: IRecord = {
                ...(props.handleParams instanceof Function
                    ? props.handleParams(searchFormData.value)
                    : searchFormData.value),
                ...orderByParams.value
            };
            if (props.pageable) {
                Object.assign(params, page.value);
            }
            for (const key in params) {
                if (params[key] !== 0 && !params[key]) {
                    delete params[key];
                }
            }
            queryParams.value = params;
            const { records, total } = await props.apiService(params);
            tableData.value = records;
            if (props.pageable) {
                page.value.total = total;
            } else {
                page.value.total = records.length;
            }
            setCachePage(page.value, toValue(props.title) || (Route.name as string));
        } catch (e) {
            console.log(e);
        }
        isLoading.value = false;
    }
};

const tableOption = ref<ITableOption[]>(props.options.filter((option) => !option.hidden));
// 表格字段配置
const computedOptions = computed(() => {
    const options: ITableOption[] = tableOption.value.filter((option) => option.checked).slice(0);
    if (
        slots.operation ||
        (props.operationTypes &&
            !!props.operationTypes.split(',').filter((item) => item !== 'add').length)
    ) {
        options.push({
            label: t('table.operations'),
            prop: 'operation',
            slot: 'operation',
            props: {
                fixed: 'right',
                width: props.operationWidth || (computedOperations.value.length > 2 ? 160 : 110)
            }
        });
    }
    return options;
});

const operationType = ref<OperationEnum>(OperationEnum.ADD);
const currRow = ref();
const isShowUpdate = ref(false);
// 新增按钮
const onAdd = () => {
    currRow.value = {};
    operationType.value = OperationEnum.ADD;
    isShowUpdate.value = true;
};
// 编辑按钮
const onEdit = (row: Record<string, any>): void => {
    currRow.value = row;
    operationType.value = OperationEnum.EDIT;
    isShowUpdate.value = true;
};
// 查看按钮
const { push } = useRouter();
const onCheck = async (row: Record<string, any>) => {
    currRow.value = row;
    if (props.checkFunc instanceof Function) {
        props.checkFunc(currRow.value);
    }
    const id = row[props.idKey || 'id'];
    if (props.checkMode === 'push') {
        push(`${props.checkPath}/${id}`);
    } else if (props.checkMode === 'window') {
        window.open(`${window.location.origin}${props.checkPath}/${id}`);
    }
};
// 删除按钮
const onDelete = async (row: Record<string, any>) => {
    if (props.deleteApiService instanceof Function) {
        await ElMessageBox.confirm(tJoin('confirmDelete').value, tJoin('systemTip').value);
        await props.deleteApiService(row[props.idKey]);
        ElMessage.success(tJoin('success').value);
        getData();
    }
};
const operationOptions: ITableOperation[] = [
    {
        label: tJoin('check'),
        type: 'check',
        props: {
            onClick: onCheck
        }
    },
    {
        label: tJoin('edit'),
        type: 'edit',
        icon: Edit,
        props: {
            onClick: onEdit
        }
    },
    {
        label: tJoin('delete'),
        type: 'delete',
        icon: Delete,
        props: {
            onClick: onDelete
        }
    }
];
const findOperation = (type: string) =>
    props.operationTypes.replace(/\s/g, '').split(',').includes(type);
const canAdd = computed(() => findOperation('add'));
// 表格操作列配置
const computedOperations = computed(() => {
    return operationOptions
        .filter((operation) => findOperation(operation.type))
        .map((operation) => {
            if (operation.props) {
                const { props } = operation;
                ['onClick'].forEach((eventName) => {
                    if (props[eventName]) {
                        props[`${eventName}New`] = props[eventName];
                        delete props[eventName];
                    }
                });
            }
            return {
                ...operation
            };
        });
});
const checkOperations = computed(() => {
    return computedOperations.value.filter((operation) => operation.type === 'check');
});
const editAndDelOperations = computed(() => {
    return computedOperations.value.filter((operation) =>
        ['edit', 'delete'].includes(operation.type)
    );
});
// 操作列颜色
const getOperationType = (type: string) => {
    switch (type) {
        case 'check':
            return 'primary';
        case 'edit':
            return 'primary';
        case 'delete':
            return 'danger';
        default:
            return '';
    }
};
// 重写onClick属性
const onClick = (option: Pick<ITableOperation, 'props'>, row: Record<string, any>) => {
    if (option.props && option.props['onClickNew'] instanceof Function) {
        option.props['onClickNew'](row);
    }
};

// 增加/修改表单配置
const computedUpdateFormOptions = computed(() => {
    return props.updateFormOptions.filter(
        (option) => !option.belong || option.belong === operationType.value
    );
});

// 确认保存和新增
const updateFormRef = ref<OptionFormInstance>();
const isConfirmLoading = ref(false);
const onConfirm = async () => {
    if (updateFormRef.value) {
        const model = await updateFormRef.value.validate<object>();
        isConfirmLoading.value = true;
        try {
            if (operationType.value === OperationEnum.ADD && props.addApiService) {
                await props.addApiService(model);
            } else if (props.updateApiService) {
                await props.updateApiService({
                    ...currRow.value,
                    ...model
                });
            }
            ElMessage.success(tJoin('success').value);
            isShowUpdate.value = false;
            getData();
        } catch (e) {
            console.log(e);
        }
        isConfirmLoading.value = false;
    }
};
// 关闭弹窗
const onCancel = () => {
    isShowUpdate.value = false;
};

// 批量选择
const showSelection = ref(false);
const selectionRows = ref([]);
const onSelectionChange = (val: any) => {
    selectionRows.value = val;
};
onMounted(() => {
    getData();
});

defineExpose({
    getData,
    tableData,
    isLoading,
    updateFormRef
});
</script>
