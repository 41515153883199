import type { RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Fix from '@/views/fix.vue';
import Booking from '@/components/bookingOrder/Booking.vue';
import SignUp from '@/components/login/SignUp.vue';
import SignIn from '@/components/login/SignIn.vue';
import FindPassword from '@/components/login/FindPassword.vue';
import ResetPassword from '@/components/login/ResetPassword.vue';
import { ROUTER_PATH_MAP, getRouterName } from '@enums';

const routers: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: ROUTER_PATH_MAP.HOME_BOOKING_PATH
    },
    {
        path: '/fix',
        name: 'fix',
        component: Fix
    },
    {
        path: ROUTER_PATH_MAP.HOME_PATH,
        name: getRouterName(ROUTER_PATH_MAP.HOME_PATH),
        component: Home,
        children: [
            {
                path: ROUTER_PATH_MAP.HOME_RESET_PASSWORD_PATH,
                name: getRouterName(ROUTER_PATH_MAP.HOME_RESET_PASSWORD_PATH),
                component: ResetPassword
            },
            {
                path: ROUTER_PATH_MAP.HOME_BOOKING_PATH,
                name: getRouterName(ROUTER_PATH_MAP.HOME_BOOKING_PATH),
                component: Booking
            },
            {
                path: ROUTER_PATH_MAP.HOME_FIND_PASSWORD_PATH,
                name: getRouterName(ROUTER_PATH_MAP.HOME_FIND_PASSWORD_PATH),
                component: FindPassword
            },
            {
                path: ROUTER_PATH_MAP.HOME_SIGN_UP_PATH,
                name: getRouterName(ROUTER_PATH_MAP.HOME_SIGN_UP_PATH),
                component: SignUp
            },
            {
                path: ROUTER_PATH_MAP.HOME_SIGN_IN_PATH,
                name: getRouterName(ROUTER_PATH_MAP.HOME_SIGN_IN_PATH),
                component: SignIn
            }
        ]
    }
];

export default routers;
