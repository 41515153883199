<template>
    <g-option-form ref="formRef" :model="formModel" :options="formOptions">
        <template #html="{ formModel, option }">
            <EditHTML v-if="formModel[option.prop]" v-model="formModel[option.prop]"></EditHTML>
        </template>
        <template #attached>
            <div>
                {{ $t('email.attached') }}
                <el-button type="primary" link @click="onDownload">{{ type }}.pdf</el-button>
            </div>
        </template>
    </g-option-form>
    <div class="flex flex-right">
        <el-button :loading="isSending" @click="onCancel">{{ $t('cancel') }}</el-button>
        <el-button :loading="isSending" type="success" @click="onSend">{{ $t('send') }}</el-button>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ElMessageBox } from 'element-plus';
import { useVueI18n } from '@use';
import { useBookingStore } from '@/stores';
import getHtml from './html';
import EditHTML from './EditHTML.vue';
import { IEmail } from '@ctypes';

const props = defineProps<{
    type: 'Invoice' | 'Voucher';
}>();

const emit = defineEmits<{
    cancel: [];
    comfirm: [];
}>();

const { tJoin } = useVueI18n();

const {
    currBooking,
    getEmailInfo,
    updateEmailInfo,
    sentInvoiceEmail,
    sentVoucherEmail,
    downloadInvoice,
    downloadVoucher
} = useBookingStore();

const formModel = ref<IEmail & { receiver: string }>({
    receiver: currBooking.value?.billTo || currBooking.value?.userDetails.email || '',
    cc: '',
    bcc: '',
    subject: props.type === 'Voucher' ? 'Voucher for your coming trip!' : 'Proforma Invoice',
    html: ''
});

const initData = async () => {
    const data = await getEmailInfo(props.type);
    if (data) {
        formModel.value = {
            ...data,
            receiver: currBooking.value?.billTo || currBooking.value?.userDetails.email || ''
        };
    }
};
initData();

const onDownload = async () => {
    switch (props.type) {
        case 'Invoice':
            await downloadInvoice();
            break;
        case 'Voucher':
            await downloadVoucher;
            break;
    }
};
const formOptions: IFormOption[] = [
    {
        label: tJoin('email.to'),
        prop: 'receiver',
        props: {
            disabled: true
        },
        rules: [{ required: true, message: tJoin('pleaseEnter', 'email') }]
    },
    { label: tJoin('email.cc'), prop: 'cc' },
    { label: tJoin('email.bcc'), prop: 'bcc' },
    {
        label: tJoin('email.subject'),
        prop: 'subject',
        rules: [{ required: true, message: tJoin('pleaseEnter', 'email.subject') }]
    },
    { label: '', prop: 'html', slot: 'html' },
    { label: '', prop: 'attached', slot: 'attached' }
];

const onCancel = () => {
    emit('cancel');
};
const formRef = ref<OptionFormInstance>();
const isSending = ref(false);
const onSend = async () => {
    if (formRef.value) {
        const formdata = await formRef.value.validate<IEmail>();
        await updateEmailInfo(props.type, formdata);
        if (props.type === 'Invoice') {
            await ElMessageBox.confirm(
                tJoin(`paymentList.invoiceDetail.repeatSendinvoice`).value,
                tJoin('system.systemTip').value
            );
        }
        if (props.type === 'Voucher') {
            await ElMessageBox.confirm(
                tJoin(`paymentList.invoiceDetail.repeatSendvoucher`).value,
                tJoin('system.systemTip').value
            );
        }
        isSending.value = true;
        switch (props.type) {
            case 'Invoice':
                await sentInvoiceEmail();
                break;
            case 'Voucher':
                await sentVoucherEmail();
                break;
        }
        isSending.value = false;
        emit('comfirm');
    }
};
</script>
