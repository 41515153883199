<template>
    <p class="ta-c fs_20 fw-b">{{ $t('login.chooseCharacter') }}</p>
    <div class="flex pd-y_50">
        <div class="flex1 ta-c" @click="onSignUpType('guest')">
            <div
                class="scale-lg-hv_1 warning-bg-hv white-hv transition cs-p bd_2 w_80 h_120p br_c mg-x_a flex flex-column info bs-d"
            >
                <el-icon size="50"><UserFilled /></el-icon>
                <p class="mg-t_5 ellipsis w_70">{{ $t('guest.name') }}{{ $t('signUp') }}</p>
            </div>
        </div>
        <div class="flex1 ta-c" @click="onSignUpType('agent')">
            <div
                class="scale-lg-hv_1 warning-bg-hv white-hv transition cs-p bd_2 w_80 h_120p br_c mg-x_a flex flex-column info bs-d"
            >
                <el-icon size="50"><Stamp /></el-icon>
                <p class="mg-t_5 ellipsis w_70">{{ $t('agent.agent') }} {{ $t('signUp') }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { UserFilled, Stamp } from '@element-plus/icons-vue';
import { ISignUpType } from './SignUpForm.vue';

const props = defineProps<{
    modelValue?: ISignUpType;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: ISignUpType): void;
}>();
const signUpType = ref<ISignUpType | undefined>(props.modelValue);
const onSignUpType = (type: ISignUpType) => {
    signUpType.value = type;
    emit('update:modelValue', signUpType.value);
};
</script>
