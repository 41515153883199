<template>
    <g-remote-select
        v-model="hotel"
        value-key="id"
        class="w_100"
        :api-service="searchApi"
        :placeholder="$t('city.hotelSearchPlaceholder')"
        @change="onChange"
    >
    </g-remote-select>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { hotelRepositories } from '@/repositories';
import { useBookingOrderStore } from '@/stores';
import { IHotel } from '@ctypes';

const props = defineProps<{
    modelValue: IHotel;
    type: 'pickUp' | 'dropOff';
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: IHotel): void;
}>();

const hotel = ref<IHotel>();
const searchApi: IApiService<ILabelValue<IHotel>[], string> = async (hotelName?: string) => {
    const { records } = await hotelRepositories.getHotelList({
        pageNum: 1,
        pageSize: 10,
        hotelName: hotelName || ''
    });
    return records.map((item) => ({
        label: item.hotelName,
        value: item
    }));
};
searchApi();

const { pickUpDetail, dropOffDetail, cityInfo, cityInfoDrop } = useBookingOrderStore();
const onChange = () => {
    if (hotel.value) {
        emit('update:modelValue', hotel.value);
        let detail = hotel.value.address;
        if (hotel.value.tel) {
            detail += `\nTel:${hotel.value.tel}`;
        }
        if (hotel.value.fax) {
            detail += `\nFax:${hotel.value.fax}`;
        }
        if (hotel.value.email) {
            detail += `\nEmail:${hotel.value.email}`;
        }
        if (props.type === 'pickUp') {
            cityInfo.value = {
                ...cityInfo.value,
                hotelInfo: hotel.value,
                detail,
                pickUp: hotel.value.hotelName
            };
            pickUpDetail.value = {
                ...pickUpDetail.value,
                hotelInfo: hotel.value,
                detail,
                pickUp: hotel.value.hotelName
            };
        } else {
            cityInfoDrop.value = {
                ...cityInfoDrop.value,
                hotelInfo: hotel.value,
                detail,
                pickUp: hotel.value.hotelName
            };
            dropOffDetail.value = {
                ...dropOffDetail.value,
                hotelInfo: hotel.value,
                detail,
                pickUp: hotel.value.hotelName
            };
        }
    }
};
</script>
