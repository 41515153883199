import { watch } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './staticRouter';
import asyncAddRouter from './asyncRouter';
import { useUserStore } from '@/stores';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

let registerRouteFresh = true;
router.beforeEach(async (to, from, next) => {
    // 动态菜单
    const { menuList } = useUserStore();
    watch(menuList, () => {
        if (menuList.value.length) {
            addRouter(menuList.value);
            router.replace(router.currentRoute.value.fullPath);
        }
    });
    if (registerRouteFresh) {
        next({ ...to, replace: true });
        registerRouteFresh = false;
    } else {
        next();
    }
});

export const addRouter = asyncAddRouter(router);

export default router;
