<template>
    <div>
        <div class="flex mg-b_10">
            <p class="nowrap w_100p ta-r mg-r_10">{{ $t('bookingList.assignTo.carCompany') }}:</p>
            <g-select-car-company
                class="flex1"
                value-type="object"
                v-model="carCompany"
                @change="onCarCompanyChange"
            ></g-select-car-company>
        </div>
        <div class="flex" v-if="carCompany">
            <p class="nowrap w_100p ta-r mg-r_10">{{ $t('bookingList.assignTo.driver') }}:</p>
            <g-remote-select
                class="flex1"
                ref="driverRef"
                v-model="driver"
                value-key="id"
                clearable
                :apiService="driverApiService"
                @change="onDriverChange"
            ></g-remote-select>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { userRepositories } from '@/repositories';
import { ITeamMemberInfo, ICarCompanyDriverDetail } from '@ctypes';

interface IValue {
    carCompany?: ICarCompanyDriverDetail;
    driver?: ICarCompanyDriverDetail;
}

const props = defineProps<{
    modelValue?: IValue;
}>();
const emit = defineEmits<{
    'update:modelValue': [value: IValue];
    change: [value: IValue];
}>();

const meetingPoint = ref('');

const carCompany = ref<ICarCompanyDriverDetail>();
if (props.modelValue?.carCompany) {
    carCompany.value = {
        ...props.modelValue.carCompany
    };
}

const driverRef = ref<RemoteSelectInstance>();
const onCarCompanyChange = () => {
    driver.value = undefined;
    if (driverRef.value) {
        driverRef.value.remoteMethod();
    }
    emit('update:modelValue', {
        carCompany: carCompany.value,
        driver: driver.value
    });
};

const driver = ref<ICarCompanyDriverDetail>();
if (props.modelValue?.driver) {
    driver.value = {
        ...props.modelValue.driver
    };
}
const driverApiService: IApiService<ILabelValue<ITeamMemberInfo>[], string> = async (
    value?: string
) => {
    if (carCompany.value?.companyName) {
        const data = await userRepositories.getDriverByCarCompany(carCompany.value.companyName);
        return data
            .map((item) => ({
                label: `${item.firstName} ${item.lastName}` || '',
                value: item
            }))
            .filter(
                (item) =>
                    !value || ~item.label!.toLocaleLowerCase().indexOf(value.toLocaleLowerCase())
            );
    }
    return [];
};
const onDriverChange = () => {
    emit('update:modelValue', {
        carCompany: carCompany.value,
        driver: driver.value
    });
};
</script>
