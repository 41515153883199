<template>
    <div class="home w_100 h_100">
        <g-card :width="width">
            <router-view></router-view>
        </g-card>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from '@use';

const { path, ROUTER_PATH_MAP } = useRoute();
const width = computed(() => {
    switch (path.value) {
        case ROUTER_PATH_MAP.HOME_SIGN_IN_PATH:
        case ROUTER_PATH_MAP.HOME_SIGN_UP_PATH:
        case ROUTER_PATH_MAP.HOME_FIND_PASSWORD_PATH:
            return 600;
        case ROUTER_PATH_MAP.HOME_BOOKING_PATH:
            return 900;
        default:
            return '';
    }
});
</script>

<style lang="scss" scoped>
.home {
    background-image: url('@/assets/images/bg2.jpg');
    background-size: cover;
}
</style>
