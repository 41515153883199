import { downloadFile } from '@/repositories/base';
import { ORDER_PDF_URL } from '@/common/enums/repositories';

/**
 * 下载voucher邮件
 */
export const downloadVoucherPDF = async (orderId: string, fileName = 'voucher.pdf') => {
    await downloadFile<undefined>(
        {
            url: `${ORDER_PDF_URL}/${orderId}/voucher`
        },
        fileName
    );
};

export const previewVoucherPDF = (orderId: string) => {
    return `${ORDER_PDF_URL}/${orderId}/voucher`;
};

/**
 * 下载invoice邮件
 */
export const downloadInvoicePDF = async (orderId: string, fileName = 'invoice.pdf') => {
    return await downloadFile<undefined>(
        {
            url: `${ORDER_PDF_URL}/${orderId}/invoice`
        },
        fileName
    );
};

export const previewInvoicePDF = (orderId: string) => {
    return `${ORDER_PDF_URL}/${orderId}/invoice`;
};

/**
 * 下载departure notice邮件
 */
export const downloadDepartureNoticePDF = async (
    orderId: string,
    language: 'EN' | 'JP' = 'EN',
    fileName = 'departureNotice.pdf',
    autoDownload = true
) => {
    try {
        return await downloadFile<undefined>(
            {
                url: `${ORDER_PDF_URL}/${orderId}/departureNotice?language=${language}`
            },
            fileName,
            autoDownload
        );
    } catch (e) {
        return false;
    }
};

export const previewDepartureNoticePDF = (orderId: string, language: 'JP' | 'EN' = 'EN') => {
    return `${ORDER_PDF_URL}/${orderId}/departureNotice?language=${language}`;
};

export const previewHaishaTehaishoPDF = (orderId: string) => {
    return `${ORDER_PDF_URL}/${orderId}/haisha`;
};

/**
 * 下载haisha
 */

export const downloadHaishaTehaishoPDF = async (
    orderId: string,
    language: 'EN' | 'JP' = 'EN',
    autoDownload = true,
    fileName: string = 'HaishaTehaisho.pdf'
) => {
    try {
        return await downloadFile<undefined>(
            {
                url: `${ORDER_PDF_URL}/${orderId}/haisha?language=${language}`
            },
            fileName,
            autoDownload
        );
    } catch (e) {
        return false;
    }
};
