import jsZip from 'jszip';
import { saveAs } from 'file-saver';

export const timeToDate = (time?: string | number) => {
    if (!time) {
        return '';
    }
    if (String(time).length > 4) {
        return new Date(time).format('YYYY-MM-DD HH:mm:ss');
    }
    time = String(time);
    if (time.indexOf(':') === -1) {
        time = time.replace(/(?=(\d{2})$)/g, ':');
    }
    return new Date(`${new Date().format('YYYY-MM-DD')} ${time}`).format('YYYY-MM-DD HH:mm:ss');
};

export const baseUrl = import.meta.env.VITE_BASE_API_URL;

export const downloadZipFlils = async (
    files: { content: any; name: string }[],
    fileName: string
) => {
    const zip = new jsZip();
    files.forEach((file) => {
        zip.file(file.name, file.content);
    });
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, fileName);
};

// 当地选择时间转为日本时间戳
export const getJPTimestamp = (currDate: string | Date | number) => {
    const offset = new Date(currDate).getTimezoneOffset() / 60;
    return new Date(currDate).getTime() + -(9 + offset) * 60 * 60 * 1000;
};

// 日本时间戳转当地时间戳
export const jpToLocal = (currDate: string | Date | number): Number => {
    return (
        new Date(currDate).getTime() + (9 + new Date().getTimezoneOffset() / 60) * 60 * 60 * 1000
    );
};

export const getJPDate = (currDate: string | Date | number): Date => {
    const offset = new Date(currDate).getTimezoneOffset() / 60;
    return new Date(new Date(currDate).getTime() + (9 + offset) * 60 * 60 * 1000);
};

export const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = <T>(key: string): T | null => {
    const version = localStorage.getItem(key + '_version');
    if (!version || version !== '1.5.0') {
        localStorage.setItem(key + '_version', '1.5.0');
        return null;
    }
    const storage = localStorage.getItem(key);
    if (storage) {
        return JSON.parse(storage) as T;
    }
    return null;
};
