<template>
    <el-dropdown v-if="downloadType !== 'batch' && downloadType !== 'all'">
        <el-button type="primary" plain size="small" :loading="isExport">Excel</el-button>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="onDownload('all')">{{
                    $t('table.downloadAll')
                }}</el-dropdown-item>
                <el-dropdown-item @click="onDownload('batch')">{{
                    $t('table.downloadBatch')
                }}</el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
    <template v-else-if="downloadType === 'batch'">
        <el-button plain size="small" :loading="isExport" @click="onCancel('batch')">{{
            $t('cancel')
        }}</el-button>
        <el-button plain size="small" :loading="isExport" type="primary" @click="onExcel('EN')"
            >{{ $t('table.downloadBatch') }}({{ $t('preview.en') }})</el-button
        >
        <el-button plain size="small" :loading="isExport" type="primary" @click="onExcel('JP')"
            >{{ $t('table.downloadBatch') }}({{ $t('preview.jp') }})</el-button
        >
    </template>
    <template v-else-if="downloadType === 'all'">
        <el-button plain size="small" :loading="isExport" @click="onCancel('all')">{{
            $t('cancel')
        }}</el-button>
        <el-button plain size="small" :loading="isExport" type="primary" @click="onExcel('EN')"
            >{{ $t('table.downloadAll') }}({{ $t('preview.en') }})</el-button
        >
        <el-button plain size="small" :loading="isExport" type="primary" @click="onExcel('JP')"
            >{{ $t('table.downloadAll') }}({{ $t('preview.jp') }})</el-button
        >
    </template>
</template>

<script lang="ts" setup>
import { ref, computed, toValue } from 'vue';
import { ElMessage } from 'element-plus';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';
import { useVueI18n } from '@use';

const props = withDefaults(
    defineProps<{
        idKey?: string;
        title?: string;
        queryParams: IRecord;
        options: ITableOption[];
        apiService?: IApiService<any, any>;
        selectionRows: any[];
    }>(),
    {
        idKey: 'id',
        selectionRows: () => [],
        queryParams: () => ({}),
        searchData: () => ({}),
        options: () => []
    }
);
const { $t } = useVueI18n();

const options = computed(() => {
    const filterProps = ['operation'];
    return props.options.filter((option) => !filterProps.includes(option.prop));
});

const getData = async () => {
    if (props.apiService) {
        const { records } = await props.apiService({
            ...props.queryParams,
            pageSize: 2147483647
        });
        return records;
    }
    return [];
};

const baseStyle = {
    alignment: {
        horizontal: 'center',
        vertical: 'center'
    }
};
const name = computed(() => {
    const dateTime = new Date().format('YYYYMMDD');
    return props.title ? `${props.title} ${dateTime}` : `${props.title} ${dateTime}`;
});
const handleData = (data: IRecord[], language: 'EN' | 'JP'): any[] => {
    const res: any[] = [];
    res.push([
        {
            v: name.value,
            s: {
                font: {
                    sz: 14,
                    bold: true
                },
                ...baseStyle
            }
        }
    ]);
    const header: any = [];
    options.value.forEach((option) => {
        header.push({
            v: toValue(option.label),
            s: {
                font: {
                    bold: true
                },
                ...baseStyle
            }
        });
    });
    res.push(header);
    data.forEach((item) => {
        const curr: any = [];
        options.value.forEach((option) => {
            if (option.formatValue) {
                curr.push({
                    v: option.formatValue(item, item[option.prop], language) ?? '',
                    s: {
                        ...baseStyle
                    }
                });
            } else {
                curr.push({
                    v: item[option.prop] || '',
                    s: {
                        ...baseStyle
                    }
                });
            }
        });
        res.push(curr);
    });
    return res;
};

const isExport = ref(false);
const onExcel = async (language: 'EN' | 'JP') => {
    if (isExport.value) {
        return;
    }
    if (downloadType.value === 'batch' && props.selectionRows.length === 0) {
        ElMessage.warning($t('table.emptyDataTip'));
        return;
    }
    isExport.value = true;
    let data = [];
    if (downloadType.value === 'batch') {
        // data = data.filter((item: any) => {
        //     return props.selectionRows.find((row) => row[props.idKey] === item[props.idKey]);
        // });
        data = props.selectionRows;
    } else {
        data = await getData();
    }
    if (!data.length) {
        isExport.value = false;
        return;
    }
    try {
        const newData = handleData(data, language);
        var workbook = XLSX.utils.book_new();
        var worksheet = XLSX.utils.aoa_to_sheet(newData);
        worksheet['!merges'] = [{ s: { c: 0, r: 0 }, e: { c: options.value.length - 1, r: 0 } }];
        worksheet['!cols'] = [];
        options.value.forEach(() => {
            worksheet['!cols']!.push({ wpx: 100 });
        });
        worksheet['!rows'] = [];
        newData.forEach(() => {
            worksheet['!rows']!.push({ hpx: 30 });
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'sheet1');
        var wbout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${name.value}.xlsx`);
    } catch (e) {
        console.log(e);
    }
    isExport.value = false;
    onCancel('batch');
};

const emit = defineEmits<{
    batchExport: [boolean];
}>();
const downloadType = ref('');
const onDownload = (type: 'all' | 'batch') => {
    downloadType.value = type;
    if (type === 'batch') {
        emit('batchExport', true);
    }
};
const onCancel = (type: 'all' | 'batch') => {
    downloadType.value = '';
    if (type === 'batch') {
        emit('batchExport', false);
    }
};
</script>
