<template>
    <div
        v-loading="isLoadingCurrUser"
        class="relative flex flex-column pd_20 br_5"
        :style="{ backgroundColor: bgList[bgIndex] }"
    >
        <template v-if="type === 'changePassword'">
            <el-icon class="absolute-tl top_10 left_10 cs-p" size="20" @click="type = ''"
                ><Back
            /></el-icon>
            <change-password @confirm="onChangePasswordConfirm"></change-password>
        </template>
        <template v-else>
            <user-avatar></user-avatar>
        </template>
        <el-dropdown
            v-if="isSameUser && type !== 'changePassword'"
            class="absolute-tr right_10 top_10"
            placement="bottom-end"
            @command="handleCommand"
        >
            <el-icon class="white cs-p" size="18"><Setting /></el-icon>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item command="changePassword"
                        >{{ $t('change') }} {{ $t('password') }}</el-dropdown-item
                    >
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Setting, Back } from '@element-plus/icons-vue';
import { useRoute } from '@use';
import { useUserStore } from '@/stores';
import UserAvatar from './UserAvatar.vue';
import ChangePassword from './ChangePassword.vue';

const bgList = ['#6184a7', '#588a47'];
const bgIndex = Math.floor(Math.random() * bgList.length);
const { isLoadingCurrUser, isSameUser } = useUserStore();

const type = ref();
const { Route } = useRoute();
watch(
    Route,
    () => {
        if (Route.query.action === 'changePassword') {
            type.value = 'changePassword';
        }
    },
    {
        immediate: true
    }
);
const handleCommand = (command: string) => {
    type.value = command;
};

const onChangePasswordConfirm = () => {
    type.value = '';
};
</script>
