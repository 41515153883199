<template>
    <div class="w_100">
        <el-select v-model="status" class="w_100" @change="onChange">
            <el-option
                v-for="(option, index) in options"
                :key="index"
                :label="option.label"
                :value="option.value"
            >
                {{ option.label }}
            </el-option>
        </el-select>
        <el-input
            v-if="status === 'custom'"
            v-model="customStatus"
            @input="onChange"
            placeholder="Pleace enter"
        ></el-input>
    </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const props = defineProps<{
    modelValue: string;
}>();
const emit = defineEmits<{
    'update:modelValue': [value: string];
}>();

const isCustom = () => {
    return (
        ![
            '新規予約をお願いします',
            'この予約の変更をお願いします',
            '申し訳ございません。この予約キャンセルとなりました。またお願いします'
        ].includes(props.modelValue) && props.modelValue
    );
};
const status = ref(isCustom() ? 'custom' : props.modelValue || '');
const options: ILabelValue<string>[] = [
    { label: 'Default', value: '' },
    { label: 'New booking', value: '新規予約をお願いします' },
    { label: 'Amendment', value: 'この予約の変更をお願いします' },
    {
        label: 'Cancellation',
        value: '申し訳ございません。この予約キャンセルとなりました。またお願いします'
    },
    { label: 'Custom', value: 'custom' }
];

const customStatus = ref('');
const onChange = () => {
    if (status.value === 'custom') {
        emit('update:modelValue', customStatus.value);
    } else {
        emit('update:modelValue', status.value);
    }
};
</script>
