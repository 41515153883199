import { ref, computed } from 'vue';
import { defineStore, storeToRefs } from 'pinia';

export const useMenuStore = () => {
    const store = defineStore('menu', () => {
        const isExpand = ref(false);
        const hasMenu = ref(false);

        const switchExpand = () => {
            isExpand.value = !isExpand.value;
        };

        const containerHeight = ref(0);

        return {
            isExpand,
            hasMenu,
            containerHeight,
            switchExpand
        };
    });
    return {
        ...store(),
        ...storeToRefs(store())
    };
};
