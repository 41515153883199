<template>
    <div class="flex flex-left">
        <g-icon-title class="mg-r_10" :title="$t('bookingList.status')"></g-icon-title>
        <booking-status isEdit :status="status"></booking-status>
    </div>
    <div class="mg-y_20">
        <el-button
            v-for="(btn, index) in previewBtn"
            :key="index"
            round
            plain
            size="small"
            type="primary"
            color="#626aef"
            :icon="Tickets"
            @click="onPreview(btn.value)"
        >
            {{ btn.label }}
        </el-button>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ElMessageBox } from 'element-plus';
import { useRouter, useVueI18n } from '@use';
import { useBookingStore } from '@/stores';
import { Tickets } from '@element-plus/icons-vue';
import { BookingStatusEnum } from '@enums';
import BookingStatus from './BookingStatus.vue';

defineProps<{
    status: BookingStatusEnum;
}>();
const previewBtn = computed(() => {
    const res = [];
    if (isShowInvoice.value) {
        res.push({ label: 'Preview Invoice', value: 'invoice' });
    }
    if (isShowVoucher.value) {
        res.push({ label: 'Preview Voucher', value: 'voucher' });
    }
    return res;
});

const { currBooking, isShowInvoice, isShowVoucher } = useBookingStore();
const { push, ROUTER_PATH_MAP } = useRouter();
const onPreview = (value: string) => {
    if (currBooking.value) {
        push({
            path: `${ROUTER_PATH_MAP.MENU_INVOICE_DETAIL}/${currBooking.value.id}`,
            query: {
                type: value
            }
        });
    }
};

const { t } = useVueI18n();
if (currBooking.value?.isAmended) {
    ElMessageBox.confirm(t('bookingList.amendedTip').value, {
        showCancelButton: false
    });
}
</script>
