<template>
    <el-button
        :loading="amendedVerifing"
        v-if="currBooking?.isAmended"
        type="warning"
        @click="onAmended"
        >{{ $t('bookingList.amendVerified') }}</el-button
    >
    <span v-else class="status inline-block" v-loading="isLoading">
        <span v-if="!isEditStatus">
            <el-tag disable-transitions :type="BookingStatusType[status]">{{
                $t(`status.${status}`)
            }}</el-tag>
            <el-button v-if="showEdit" :icon="Edit" link @click="onEdit"></el-button>
        </span>

        <span class="inline-block" v-if="isEditStatus">
            <el-button
                v-for="(item, index) in computedStatus"
                :key="index"
                :type="BookingStatusType[item]"
                plain
                @click="onUpdateStatus(item)"
            >
                {{ $t(`status.${item}`) }}
            </el-button>
            <el-button link @click="onEdit">
                <el-icon size="20"><CircleClose /></el-icon>
            </el-button>
        </span>
    </span>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useVueI18n } from '@use';
import { Edit, CircleClose } from '@element-plus/icons-vue';
import { BookingStatusEnum, BookingStatusType } from '@enums';
import { useBookingStore, useUserStore } from '@/stores';
import { bookingRepositories } from '@/repositories';

const props = defineProps<{
    status: BookingStatusEnum;
    isEdit?: boolean;
}>();

const { isSuperAdmin } = useUserStore();
const computedStatus = computed(() => {
    const status = new Set<BookingStatusEnum>();
    if (isSuperAdmin.value) {
        status.add(BookingStatusEnum.NEW);
        status.add(BookingStatusEnum.PREBOOK);
        status.add(BookingStatusEnum.VERIFIED);
        status.add(BookingStatusEnum.COMFIRMED);
        status.add(BookingStatusEnum.FINAL);
        status.add(BookingStatusEnum.TOKYO_FINAL);
        status.add(BookingStatusEnum.TEHAI);
        status.add(BookingStatusEnum.COMPLETED);
        status.add(BookingStatusEnum.CANCELLED);
        status.add(BookingStatusEnum.REJECTED);
    }
    if (props.status === BookingStatusEnum.NEW) {
        status.add(BookingStatusEnum.VERIFIED);
        status.add(BookingStatusEnum.PREBOOK);
        status.add(BookingStatusEnum.CANCELLED);
        status.add(BookingStatusEnum.REJECTED);
    } else if (props.status === BookingStatusEnum.PREBOOK) {
        status.add(BookingStatusEnum.NEW);
        status.add(BookingStatusEnum.CANCELLED);
        status.add(BookingStatusEnum.REJECTED);
    } else if (props.status === BookingStatusEnum.VERIFIED) {
        status.add(BookingStatusEnum.COMFIRMED);
        status.add(BookingStatusEnum.CANCELLED);
        status.add(BookingStatusEnum.REJECTED);
    } else if (props.status === BookingStatusEnum.COMFIRMED) {
        status.add(BookingStatusEnum.FINAL);
        status.add(BookingStatusEnum.CANCELLED);
        status.add(BookingStatusEnum.REJECTED);
    } else if ([BookingStatusEnum.FINAL, BookingStatusEnum.TOKYO_FINAL].includes(props.status)) {
        status.add(BookingStatusEnum.TEHAI);
        if (props.status === BookingStatusEnum.FINAL) {
            status.add(BookingStatusEnum.TOKYO_FINAL);
        } else {
            status.add(BookingStatusEnum.FINAL);
        }
        status.add(BookingStatusEnum.CANCELLED);
    } else if (props.status === BookingStatusEnum.TEHAI) {
        status.add(BookingStatusEnum.COMPLETED);
        status.add(BookingStatusEnum.CANCELLED);
    }
    return Array.from(status);
});

const isEditStatus = ref(false);
const onEdit = () => {
    isEditStatus.value = !isEditStatus.value;
};

const { t, tJoin } = useVueI18n();
const { currVerifyFields, currBooking, updateOrderStatus, verifyOrderField, getCurrBooking } =
    useBookingStore();

const showEdit = computed(() => {
    return (
        isSuperAdmin.value ||
        (props.isEdit &&
            currBooking.value &&
            ![
                BookingStatusEnum.CANCELLED,
                BookingStatusEnum.REJECTED,
                BookingStatusEnum.COMPLETED
            ].includes(currBooking.value.status))
    );
});

const amendedVerifing = ref(false);
const onAmended = async () => {
    if (currBooking.value) {
        amendedVerifing.value = true;
        try {
            const promises: Promise<any>[] = [];
            currVerifyFields.value.forEach((item) => {
                if (!item.verify) {
                    promises.push(verifyOrderField(item.prop, true));
                    item.verify = true;
                }
            });
            await Promise.all(promises);
            await bookingRepositories.updateOrder(currBooking.value?.id, 'isAmended', false);
            ElMessage.success(
                `${tJoin('bookingList.amendVerified').value} ${tJoin('success').value}`
            );
            await getCurrBooking();
        } catch (e) {
            console.log(e);
        }
        amendedVerifing.value = false;
    }
};

const isLoading = ref(false);
const onUpdateStatus = async (status: BookingStatusEnum) => {
    if (status === currBooking.value?.status) {
        isEditStatus.value = false;
        return;
    }
    await ElMessageBox.confirm(
        t('bookingList.changeStatus.confimrChange', { status: t(`status.${status}`).value }).value,
        t('systemTip').value
    );
    let reason = '';
    if (status === BookingStatusEnum.CANCELLED || status === BookingStatusEnum.REJECTED) {
        const res = await ElMessageBox.prompt(
            tJoin('pleaseEnter', 'system.reason').value,
            t('system.systemTip').value,
            {
                confirmButtonText: tJoin('system.ok').value,
                cancelButtonText: tJoin('system.cancel').value,
                inputPattern: /.+/,
                inputErrorMessage: tJoin('pleaseEnter', 'system.reason').value
            }
        );
        reason = res.value;
    }
    isLoading.value = true;
    try {
        if (status === BookingStatusEnum.VERIFIED && currVerifyFields.value.length) {
            const promises: Promise<any>[] = [];
            currVerifyFields.value.forEach((item) => {
                if (!item.verify) {
                    promises.push(verifyOrderField(item.prop, true));
                    item.verify = true;
                }
            });
            await Promise.all(promises);
        }
        await updateOrderStatus(status, reason);
    } catch (e) {
        console.log(e);
    }
    isLoading.value = false;
    isEditStatus.value = false;
};
</script>

<style lang="scss">
.move-enter-active,
.move-leave-active {
    transform: translateX(0%);
    transition: all 0.3s;
}
.move-enter-from,
.move-leave-to {
    transform: translateX(-20%);
}
.status {
    height: 32px;
    line-height: 32px;
}
</style>
