<template>
    <div>
        <div class="flex flex-between">
            <h2 v-if="title">{{ title }}</h2>
            <slot name="left"></slot>
        </div>
        <el-divider class="mg-t_0" v-if="divider" border-style="dashed" />
        <template v-for="(option, index) in options" :key="index">
            <div class="flex flex-top flex-left mg-b_10">
                <div class="mg-r_10 ta-r nowrap" :style="{ width: computedLabelWidth }">
                    <g-icon-title :title="option.label" :icon="option.icon"></g-icon-title>:
                </div>
                <slot v-if="option.slot" :name="option.slot" :row="model" :option="option"></slot>
                <table-value
                    v-else
                    :emptyStr="emptyStr"
                    :row="model"
                    :option="option"
                ></table-value>
            </div>
            <el-divider v-if="divider" border-style="dashed" />
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import TableValue from '@/components/global/table/TableValue.vue';
const props = withDefaults(
    defineProps<{
        title?: string;
        labelWidth: number;
        divider?: boolean;
        options: IFormBaseOption[];
        emptyStr?: string;
        model: Record<string, unknown>;
    }>(),
    {
        labelWidth: 100
    }
);

const computedLabelWidth = computed(() => {
    if (/^(\d+)$/.test(String(props.labelWidth))) {
        return `${RegExp.$1}px`;
    }
    return props.labelWidth || 'inherit';
});
</script>
