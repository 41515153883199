/* eslint-disable func-names */
/**
 * 对Date的扩展，将 Date 转化为指定格式的String * 年(Y)、月(M)、日(D)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q)
 * 可以用 1-2 个占位符 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * * eg:
 * (new Date()).format("YYYY-MM-DD HH:mm:ss.S")==> 2006-07-02 08:09:04.423
 * (new Date()).format("YYYY-MM-DD E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 * (new Date()).format("YYYY-MM-DD EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 * (new Date()).format("YYYY-MM-DD EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 * (new Date()).format("YYYY-M-D h:m:s.S") ==> 2006-7-2 8:9:4.18
 */
Date.prototype.format = function (fmtParams: string) {
    let fmt = fmtParams;
    const o: any = {
        'M+': this.getMonth() + 1, // 月份
        'D+': this.getDate(), // 日
        'h+': this.getHours() % 12 === 0 ? 12 : this.getHours() % 12, // 小时
        'H+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    };
    const week: any = {
        0: '日',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六'
    };
    if (/(Y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '星期' : '周') : '') +
                week[`${this.getDay()}`]
        );
    }
    Object.keys(o).forEach((k) => {
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
            );
        }
    });
    return fmt;
};
