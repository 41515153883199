<template>
    <g-remote-select
        v-bind="$attrs"
        fit-input-width
        v-model="carCompany"
        clearable
        :teleported="false"
        :apiService="apiService"
        value-key="id"
        @change="onChange"
    ></g-remote-select>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { userRepositories } from '@/repositories';
import { IBaseUserInfo } from '@ctypes';

const props = withDefaults(
    defineProps<{
        modelValue: any;
        valueType: 'base' | 'object';
        valueField: string;
    }>(),
    {
        modelValue: '',
        valueType: 'base',
        valueField: 'id'
    }
);
const emit = defineEmits<{
    'update:modelValue': [value: any];
    change: [value: any];
}>();

const carCompany = ref(props.modelValue);
watch(
    () => props.modelValue,
    () => {
        carCompany.value = props.modelValue;
    }
);
const onChange = () => {
    emit('update:modelValue', carCompany.value);
    emit('change', carCompany.value);
};

const allCarCompany = ref<IBaseUserInfo[]>([]);
const getCarCompany = async () => {
    if (!allCarCompany.value.length) {
        allCarCompany.value = await userRepositories.getAllCarCompany();
    }
};
const apiService: IApiService<ILabelValue<any>[], string> = async (value?: string) => {
    await getCarCompany();
    return allCarCompany.value
        .map((item) => ({
            label: item.companyName || '',
            value: props.valueType === 'base' ? item[props.valueField] : item
        }))
        .filter(
            (item) => !value || ~item.label!.toLocaleLowerCase().indexOf(value.toLocaleLowerCase())
        );
};
</script>
