<template>
    <g-remote-select
        clearable
        v-model="agent"
        :apiService="apiService"
        @change="onChange"
    ></g-remote-select>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { agentRepositories } from '@/repositories';

const props = withDefaults(
    defineProps<{
        modelValue: string;
        valueKey: string;
    }>(),
    {
        valueKey: 'id'
    }
);
const emit = defineEmits<{
    'update:modelValue': [value: string];
    change: [value: string];
}>();

const agent = ref(props.modelValue || '');
watch(
    () => props.modelValue,
    () => {
        agent.value = props.modelValue || '';
    }
);
const onChange = () => {
    emit('update:modelValue', agent.value);
    emit('change', agent.value);
};

const apiService: IApiService<ILabelValue<string>[], string> = async (keyword?: string) => {
    const data = await agentRepositories.getAgentList({
        companyName: keyword,
        pageNum: 1,
        pageSize: 10
    });
    return data.records.map((item) => ({
        label: item.companyName || '',
        value: item[props.valueKey] || ''
    }));
};
</script>
