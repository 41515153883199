export enum RoleEnum {
    Staff = 'Staff',
    Driver = 'Driver',
    CarCompany = 'CarCompany',
    Operator = 'Operator',
    SubAdmin = 'SubAdmin',
    SuperAdmin = 'SuperAdmin',
    Agent = 'Agent',
    Guest = 'Guest'
}

export const RoleColorEnum: IRecord<'' | 'success' | 'warning' | 'info' | 'danger' | undefined> = {
    [RoleEnum.SubAdmin]: '',
    [RoleEnum.Operator]: '',
    [RoleEnum.CarCompany]: 'warning',
    [RoleEnum.Driver]: 'warning',
    [RoleEnum.Staff]: 'success'
};
