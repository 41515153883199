import { computed, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { Loading } from '@element-plus/icons-vue';
import { useRoute } from '@use';
import { useI18n } from 'vue-i18n';
import { defineStore, storeToRefs } from 'pinia';
import { IAssignTo, IBooking, IBookingLog, IEmail, IInvoiceEdit, IPayment } from '@ctypes';
import { BookingStatusEnum, RouterTypeEnum } from '@enums';
import { bookingRepositories, emailRepositories, pdfRepositories } from '@/repositories';

/**
 * 储存
 */
export const useBookingStore = () => {
    const store = defineStore('booking', () => {
        const { Route } = useRoute();
        const currBooking = ref<IBooking>();

        const isCancelStatus = computed(
            () => currBooking.value?.status === BookingStatusEnum.CANCELLED
        );
        const isUselessStatus = computed(
            () =>
                currBooking.value &&
                [BookingStatusEnum.CANCELLED, BookingStatusEnum.REJECTED].includes(
                    currBooking.value.status
                )
        );
        /**
         * 是否assign过
         */
        const isAssignTo = computed(() => {
            if (currBooking.value && currBooking.value.carCompanyUserDetails) {
                return hasAlreadyAssignTo(currBooking.value);
            }
            return false;
        });

        const isShowVoucher = computed(() => {
            return currBooking.value && isShowVoucherFun(currBooking.value.status);
        });
        const isShowVoucherFun = (currStatus: BookingStatusEnum) => {
            return [
                BookingStatusEnum.FINAL,
                BookingStatusEnum.TOKYO_FINAL,
                BookingStatusEnum.TEHAI,
                BookingStatusEnum.COMPLETED
            ].includes(currStatus);
        };
        const isShowInvoice = computed(() => {
            return currBooking.value && isShowInvoiceFun(currBooking.value.status);
        });
        const isShowInvoiceFun = (currStatus: BookingStatusEnum) => {
            return ![BookingStatusEnum.NEW].includes(currStatus);
        };
        const isShowDepartureNotice = computed(() => {
            return currBooking.value && currBooking.value.pickUpTime;
        });
        const isShowSendEmail = computed(() => {
            return (
                currBooking.value &&
                ![
                    BookingStatusEnum.CANCELLED,
                    BookingStatusEnum.REJECTED,
                    BookingStatusEnum.COMPLETED
                ].includes(currBooking.value.status)
            );
        });

        const isLoading = ref(false);
        /**
         * 获取当前订单信息
         */
        const getCurrBooking = async () => {
            if (Route.params.id as string) {
                isLoading.value = true;
                try {
                    currBooking.value = await bookingRepositories.getBookingById(
                        Route.params.id as string
                    );
                } catch (e) {
                    console.log(e);
                }
                isLoading.value = false;
            }
        };

        const isLoadingStatus = ref(false);
        /**
         * 更新订单状态
         */
        const updateOrderStatus = async (status: BookingStatusEnum, reason?: string) => {
            if (currBooking.value) {
                isLoadingStatus.value = true;
                try {
                    await bookingRepositories.updateOrderStatus(
                        currBooking.value.id,
                        status,
                        reason
                    );
                    currBooking.value.status = status;
                } catch (e) {
                    console.log(e);
                }
                isLoadingStatus.value = false;
            }
        };

        const isLoadingUpdate = ref(false);
        /**
         * 更新订单
         */
        const updateOrder = async (
            name: string,
            value: any,
            isSendEmail: boolean = false,
            fieldName = ''
        ): Promise<boolean> => {
            if (currBooking.value) {
                isLoadingUpdate.value = true;
                try {
                    await bookingRepositories.updateOrder(
                        currBooking.value.id,
                        name,
                        value,
                        isSendEmail,
                        fieldName
                    );
                    return true;
                } catch (e) {
                    console.log(e);
                }
                isLoadingUpdate.value = false;
            }
            return Promise.reject(false);
        };

        /**
         * 判断是否可以assign to
         */
        const canAssignTo = (
            status: BookingStatusEnum | undefined = currBooking.value?.status || undefined
        ) => {
            if (
                status &&
                [
                    BookingStatusEnum.NEW,
                    BookingStatusEnum.VERIFIED,
                    BookingStatusEnum.COMFIRMED,
                    BookingStatusEnum.FINAL,
                    BookingStatusEnum.TOKYO_FINAL,
                    BookingStatusEnum.TEHAI
                ].includes(status)
            ) {
                return true;
            }
            return false;
        };
        /**
         * 是否意见assign to
         */
        const hasAlreadyAssignTo = (record: IBooking) => {
            return [
                'carCompanyUserDetails',
                'assignToStatus',
                'meetingPointDetails',
                'assignToNote',
                'pickUpTime'
            ].some((key) => {
                if (Object.prototype.toString.call(record[key]) === '[object Object]') {
                    return Object.keys(record[key] as object).length;
                }
                if (Array.isArray(record[key])) {
                    return (record[key] as []).length;
                }
                return record[key] || record[key] === 0;
            });
        };

        /**
         * 当前可验证字段
         */
        const currVerifyFields = ref<IRecord[]>([]);
        /**
         * 验证订单字段
         */
        const verifyOrderField = async (key: string, value: boolean): Promise<boolean> => {
            if (currBooking.value) {
                isLoadingUpdate.value = true;
                try {
                    await bookingRepositories.verifyBookingFields(currBooking.value.id, key, value);
                    return true;
                } catch (e) {
                    console.log(e);
                }
                isLoadingUpdate.value = false;
            }
            return Promise.reject(false);
        };

        const orderLog = ref<IBookingLog[]>([]);
        /**
         * 获取订单更改记录
         */
        const getOrderLog = async () => {
            if (currBooking.value) {
                const data = await bookingRepositories.getOrderLog(currBooking.value.id);
                if (Array.isArray(data)) {
                    orderLog.value = data.reverse();
                }
            }
        };

        /**
         * 获取支付列表
         */
        const getPaymentList = async () => {
            if (currBooking.value) {
                return await bookingRepositories.getPaymentList(currBooking.value.id);
            }
        };
        /**
         * 新增支付信息
         */
        const addPayment = async (data: IPayment) => {
            if (currBooking.value) {
                await bookingRepositories.addPayment(currBooking.value.id, data);
                await getCurrBooking();
            }
        };

        const isLoadingEditInvoice = ref(false);
        /**
         * 修改invoice
         */
        const editInvoice = async (data: IInvoiceEdit) => {
            if (currBooking.value) {
                isLoadingEditInvoice.value = true;
                try {
                    await bookingRepositories.editInvoice(currBooking.value.id, data);
                    await getCurrBooking();
                    isLoadingEditInvoice.value = false;
                    return true;
                } catch (e) {
                    console.log(e);
                    isLoadingEditInvoice.value = false;
                }
                return Promise.reject();
            }
        };

        const isLoadingAssign = ref(false);
        /**
         * assigin to订单
         */
        const assignToOrder = async (data: IAssignTo) => {
            if (currBooking.value) {
                isLoadingAssign.value = true;
                try {
                    await bookingRepositories.assignToOrder(currBooking.value.id, data);
                    isLoadingAssign.value = false;
                    return true;
                } catch (e) {
                    console.log(e);
                    isLoadingAssign.value = false;
                }
                return Promise.reject();
            }
        };

        const isLoadingDownload = ref(false);

        const { t: $t } = useI18n();
        /**
         * 下载invoice
         */
        const downloadInvoice = async (data: IBooking | undefined = currBooking.value) => {
            if (data) {
                isLoadingDownload.value = true;
                ElMessage.warning({
                    message: $t('preview.downloadInvoice'),
                    type: 'warning',
                    icon: Loading,
                    duration: 0,
                    customClass: 'el-message--warning sign-out__message'
                });
                try {
                    const file = await pdfRepositories.downloadInvoicePDF(
                        data.id,
                        `Invoice_${new Date(data.serviceDate).format('YYYYMMDD')}_${
                            data.uniqueReferenceNo
                        }_${new Date().format('YYYY-MM-DD HH_mm_ss')}`
                    );
                    isLoadingDownload.value = false;
                    ElMessage.closeAll('warning');
                    return file;
                } catch (e) {
                    console.log(e);
                }
                ElMessage.closeAll('warning');
                isLoadingDownload.value = false;
            }
            return Promise.reject();
        };

        /**
         * 预览invoice
         */
        const previewInvoicePDf = computed(() => {
            if (currBooking.value || Route.params.id) {
                return pdfRepositories.previewInvoicePDF(
                    currBooking.value?.id || (Route.params.id as string)
                );
            }
            return '';
        });

        /**
         * 下载voucher
         */
        const downloadVoucher = async (data: IBooking | undefined = currBooking.value) => {
            if (data) {
                isLoadingDownload.value = true;
                ElMessage.warning({
                    message: $t('preview.downloadVoucher'),
                    type: 'warning',
                    icon: Loading,
                    duration: 0,
                    customClass: 'el-message--warning sign-out__message'
                });
                try {
                    await pdfRepositories.downloadVoucherPDF(
                        data.id,
                        `Voucher_${new Date(data.serviceDate).format('YYYYMMDD')}_${
                            data.uniqueReferenceNo
                        }_${new Date().format('YYYY-MM-DD HH_mm_ss')}`
                    );
                    isLoadingDownload.value = false;
                    ElMessage.closeAll('warning');
                    return true;
                } catch (e) {
                    console.log(e);
                }
                ElMessage.closeAll('warning');
                isLoadingDownload.value = false;
            }
            return Promise.reject();
        };

        /**
         * 预览voucher
         */
        const previewVoucherPDF = computed(() => {
            if (currBooking.value || Route.params.id) {
                return pdfRepositories.previewVoucherPDF(
                    currBooking.value?.id || (Route.params.id as string)
                );
            }
            return '';
        });

        /**
         * 下载departureNotice
         */
        const downloadDepartureNotice = async (language: 'EN' | 'JP' = 'EN') => {
            if (currBooking.value?.id) {
                isLoadingDownload.value = true;
                ElMessage.warning({
                    message: $t('preview.downloadDepartureNotice'),
                    type: 'warning',
                    icon: Loading,
                    duration: 0,
                    customClass: 'el-message--warning sign-out__message'
                });
                try {
                    await pdfRepositories.downloadDepartureNoticePDF(
                        currBooking.value.id,
                        language,
                        `Departure Notice_${new Date(currBooking.value.serviceDate).format(
                            'YYYYMMDD'
                        )}_${currBooking.value.uniqueReferenceNo}_${new Date().format(
                            'YYYY-MM-DD HH_mm_ss'
                        )}`
                    );
                    isLoadingDownload.value = false;
                    ElMessage.closeAll('warning');
                    return true;
                } catch (e) {
                    console.log(e);
                }
                ElMessage.closeAll('warning');
                isLoadingDownload.value = false;
            }
            return Promise.reject();
        };

        /**
         * 预览invoice
         */
        const previewDepatureNoticePDF = computed(() => {
            if (currBooking.value || Route.params.id) {
                return pdfRepositories.previewDepartureNoticePDF(
                    currBooking.value?.id || (Route.params.id as string)
                );
            }
            return '';
        });

        /**
         * 预览haishaTehaisho
         */
        const previewHaishaTehaishoPDF = computed(() => {
            if (currBooking.value || Route.params.id) {
                return (
                    pdfRepositories.previewHaishaTehaishoPDF(
                        currBooking.value?.id || (Route.params.id as string)
                    ) +
                    '?language=' +
                    Route.query.language
                );
            }
            return '';
        });

        const isLoadingSendEmail = ref(false);

        /**
         * 发送invoice邮件
         */
        const sentInvoiceEmail = async () => {
            if (currBooking.value?.id) {
                isLoadingSendEmail.value = true;
                ElMessage.warning({
                    message: $t(`preview.sendingInvoice`),
                    type: 'warning',
                    icon: Loading,
                    duration: 0,
                    customClass: 'el-message--warning send-email__message'
                });
                try {
                    await emailRepositories.sendInvoicePdfEmail(currBooking.value.id);
                    isLoadingSendEmail.value = false;
                    ElMessage.closeAll('warning');
                    ElMessage.success({
                        message: $t('preview.sendSuccess'),
                        duration: 2000
                    });
                    return true;
                } catch (e) {
                    console.log(e);
                }
                ElMessage.closeAll('warning');
                isLoadingSendEmail.value = false;
            }
            return Promise.reject();
        };

        /**
         * 发送voucher邮件
         */
        const sentVoucherEmail = async () => {
            if (currBooking.value?.id) {
                isLoadingSendEmail.value = true;
                ElMessage.warning({
                    message: $t(`preview.sendingVoucher`),
                    type: 'warning',
                    icon: Loading,
                    duration: 0,
                    customClass: 'el-message--warning send-email__message'
                });
                try {
                    await emailRepositories.sendVoucherPdfEmail(currBooking.value.id);
                    isLoadingSendEmail.value = false;
                    ElMessage.closeAll('warning');
                    ElMessage.success({
                        message: $t('preview.sendSuccess'),
                        duration: 2000
                    });
                    return true;
                } catch (e) {
                    console.log(e);
                }
                ElMessage.closeAll('warning');
                isLoadingSendEmail.value = false;
            }
            return Promise.reject();
        };

        /**
         * 发送订单内容更改邮件
         */
        const sentBookingAmendedEmail = async () => {
            if (currBooking.value?.id) {
                isLoadingSendEmail.value = true;
                try {
                    await emailRepositories.sendAmendedEmail(currBooking.value.id);
                    isLoadingSendEmail.value = false;
                    return true;
                } catch (e) {
                    console.log(e);
                }
                isLoadingSendEmail.value = false;
            }
            return Promise.reject();
        };

        /**
         * 发送订单状态更改邮件
         */
        const sentBookingStatusEmail = async () => {
            if (currBooking.value) {
                isLoadingSendEmail.value = true;
                try {
                    switch (currBooking.value.status) {
                        case BookingStatusEnum.NEW:
                            await emailRepositories.sendNewBookingEmail(currBooking.value.id);
                            break;
                        case BookingStatusEnum.COMFIRMED:
                            await emailRepositories.sendConfirmedEmail(currBooking.value.id);
                            break;
                        case BookingStatusEnum.FINAL:
                            await emailRepositories.sendFinalEmail(currBooking.value.id);
                            break;
                        case BookingStatusEnum.CANCELLED:
                            await emailRepositories.sendCancelledEmail(currBooking.value.id);
                            break;
                        case BookingStatusEnum.REJECTED:
                            await emailRepositories.sendRejectedEmail(currBooking.value.id);
                            break;
                    }
                    isLoadingSendEmail.value = false;
                    return true;
                } catch (e) {
                    console.log(e);
                }
                isLoadingSendEmail.value = false;
            }
            return Promise.reject();
        };

        const getEmailInfo = async (type: 'Invoice' | 'Voucher' = 'Invoice') => {
            if (currBooking.value) {
                return await bookingRepositories.getEmailInfo(currBooking.value.id, type);
            }
        };

        /**
         * 更改邮箱信息
         */
        const updateEmailInfo = async (type: 'Invoice' | 'Voucher' = 'Invoice', data: IEmail) => {
            if (currBooking.value) {
                await bookingRepositories.updateEmailInfo(currBooking.value.id, type, data);
            }
        };
        return {
            isLoading,
            isAssignTo,
            isCancelStatus,
            isUselessStatus,
            currBooking,
            currVerifyFields,
            isLoadingStatus,
            isShowVoucher,
            isShowInvoice,
            isShowDepartureNotice,
            isShowSendEmail,
            hasAlreadyAssignTo,
            isShowInvoiceFun,
            isShowVoucherFun,
            updateOrderStatus,
            isLoadingUpdate,
            updateOrder,
            canAssignTo,
            verifyOrderField,
            orderLog,
            getOrderLog,
            getCurrBooking,
            getPaymentList,
            addPayment,
            isLoadingEditInvoice,
            editInvoice,
            isLoadingAssign,
            assignToOrder,
            isLoadingDownload,
            downloadInvoice,
            downloadVoucher,
            downloadDepartureNotice,
            previewInvoicePDf,
            previewVoucherPDF,
            previewDepatureNoticePDF,
            previewHaishaTehaishoPDF,
            isLoadingSendEmail,
            sentInvoiceEmail,
            sentVoucherEmail,
            sentBookingAmendedEmail,
            sentBookingStatusEmail,
            updateEmailInfo,
            getEmailInfo
        };
    });
    return {
        ...store(),
        ...storeToRefs(store())
    };
};
