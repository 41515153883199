<template>
    <div class="of-a h_300p" ref="htmlRef" contenteditable v-html="html" @input="onChange"></div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue';
const props = defineProps<{
    modelValue: string;
}>();

watch(
    () => props.modelValue,
    () => {
        if (!html.value) {
            html.value = props.modelValue;
        }
    }
);
const html = ref(props.modelValue);
const emit = defineEmits<{
    'update:modelValue': [value: string];
}>();

const htmlRef = ref<HTMLElement>();
const onChange = () => {
    if (htmlRef.value) {
        emit('update:modelValue', htmlRef.value.innerHTML);
    }
};
</script>
