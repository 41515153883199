export enum BookingStatusEnum {
    NEW = 'NEW',
    PREBOOK = 'PRE_BOOK',
    VERIFIED = 'VERIFIED',
    COMFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    FINAL = 'FINAL',
    TOKYO_FINAL = 'TOKYO_FINAL',
    TEHAI = 'TEHAI',
    COMPLETED = 'COMPLETED'
}

export enum InvoiceStatusEnum {
    OVERDUE = 'OVERDUE',
    DRAFT = 'DRAFT',
    NOT_PAID = 'NOT_PAID',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    FULLY_PAID = 'FULLY_PAID'
}

export const BookingStatusType: IRecord = {
    [BookingStatusEnum.NEW]: 'warning',
    [BookingStatusEnum.PREBOOK]: 'info',
    [BookingStatusEnum.VERIFIED]: 'success',
    [BookingStatusEnum.COMFIRMED]: 'primary',
    [BookingStatusEnum.REJECTED]: 'danger',
    [BookingStatusEnum.CANCELLED]: 'danger',
    [BookingStatusEnum.FINAL]: 'primary',
    [BookingStatusEnum.TOKYO_FINAL]: 'success',
    [BookingStatusEnum.TEHAI]: 'success',
    [BookingStatusEnum.COMPLETED]: 'success',
    [InvoiceStatusEnum.OVERDUE]: 'danger',
    [InvoiceStatusEnum.DRAFT]: 'info',
    [InvoiceStatusEnum.NOT_PAID]: 'warning',
    [InvoiceStatusEnum.PARTIALLY_PAID]: 'success',
    [InvoiceStatusEnum.FULLY_PAID]: 'success'
};

export const BookingStatusList = [
    BookingStatusEnum.NEW,
    BookingStatusEnum.PREBOOK,
    BookingStatusEnum.VERIFIED,
    BookingStatusEnum.COMFIRMED,
    BookingStatusEnum.REJECTED,
    BookingStatusEnum.CANCELLED,
    BookingStatusEnum.FINAL,
    BookingStatusEnum.TOKYO_FINAL,
    BookingStatusEnum.TEHAI,
    BookingStatusEnum.COMPLETED
];

export const InvoiceStatusList = [
    InvoiceStatusEnum.OVERDUE,
    InvoiceStatusEnum.DRAFT,
    InvoiceStatusEnum.NOT_PAID,
    InvoiceStatusEnum.PARTIALLY_PAID,
    InvoiceStatusEnum.FULLY_PAID
];

export enum TaxCommissionEnum {
    'Agent`s Commission 1%' = 99,
    'Agent`s Commission 5%' = 95,
    'Agent`s Commission 10%' = 90,
    'Agent`s Commission 15%' = 85,
    'Agent`s Commission less 10% less 10%' = 81,
    'GST Tax(6%)' = 106,
    'VAT(10%)' = 110
}
