import { ref, computed, Ref } from 'vue';
import { defineStore, storeToRefs } from 'pinia';
import {
    IRouter,
    IPeopelDate,
    ICarInfo,
    IVehicle,
    IAirportDetailInfo,
    IPortDetailInfo,
    IAcitiviyDetailInfo,
    IStationDetailInfo,
    ICityDetailInfo,
    IGuestDetailInfo,
    IUserInfo
} from '@ctypes';
import { RouterTypeEnum } from '@enums';
import { routerRepositories } from '@/repositories';

/**
 * 下订单
 */

export const useBookingOrderStore = () => {
    const bookingOrderUser = ref<IUserInfo>();

    const amendOrderId = ref('');

    const amendInfo = ref();

    const bookingOrderStore = defineStore('bookingOrder', () => {
        const VehicleType = ref([
            'Charter Van Taxi',
            'LIMO Grand Sedan',
            'LIMO Grand MPV',
            'Charter Sedan Taxi',
            'Charter MPV Taxi',
            'LIMO Grand Van',
            'Shared Transfer'
        ]);
        // 当前步骤
        const currStep = ref(1);

        // 当前选择线路信息
        const routerInfo = ref<Partial<IRouter>>({
            pickUpLocation: '',
            pickUpLocationType: undefined,
            availableStartTime: '',
            availableEndTime: '',
            gv: 0,
            dropOffLocation: '',
            dropOffLocationType: undefined
        });

        const gvNum = computed(() => {
            if (routerInfo.value.gv && /GV(\d)/.test(String(routerInfo.value.gv))) {
                return Number(RegExp.$1);
            }
            return 4;
        });

        // 当前输入服务日期和人员信息
        const peopleDateInfo = ref<IPeopelDate>({
            serviceDate: new Date().getTime(),
            adultCount: 1,
            childCount: 0,
            infantCount: 0
        });

        // 当前选择车辆信息
        const carInfo = ref<ICarInfo>({
            vehicleCount: 0,
            vehicleId: '',
            routeVehiclePriceId: 0,
            price: 0,
            name: ''
        });

        // airport类型信息
        const airportInfo = ref<IAirportDetailInfo>({
            departureDate: '',
            airportName: '',
            flightNumber: '',
            filghtDetail: ''
        });

        // port类型信息
        const portInfo = ref<IPortDetailInfo>({
            portName: '',
            cruiseName: '',
            cruiseTime: ''
        });

        // acitiviy类型信息
        const acitiviyInfo = ref<IAcitiviyDetailInfo>({
            activityName: '',
            pickUpTime: ''
        });

        // station类型信息
        const stationInfo = ref<IStationDetailInfo>({
            stationName: '',
            trainName: '',
            route: ''
        });

        // city类型信息
        const cityInfo = ref<ICityDetailInfo>({
            cityName: '',
            hotel: '',
            pickUp: '',
            detail: ''
        });
        const cityInfoDrop = ref<ICityDetailInfo>({
            cityName: '',
            hotel: '',
            pickUp: '',
            detail: ''
        });

        const guestInfo = ref<IGuestDetailInfo>({
            guestNames: '',
            sns: '',
            remarks: '',
            referenceNo: ''
        });

        const preOrderTips =
            ref<Record<string, { label: string; originLabel: string; value: string }[]>>();

        const isLoadingVehicle = ref(false);
        const vehicleList = ref<IVehicle[]>([]);
        /**
         * 获取所有交通工具信息
         */
        const getAllVehicle = async () => {
            if (vehicleList.value.length) {
                return;
            }
            isLoadingVehicle.value = true;
            try {
                vehicleList.value = await routerRepositories.getVehicleList();
                isLoadingVehicle.value = false;
                return true;
            } catch (e) {
                console.log(e);
                isLoadingVehicle.value = false;
            }
            return Promise.reject();
        };

        /**
         * 下新订单
         */
        const newBooking = async () => {};

        const pickUpDetail = ref({});
        const dropOffDetail = ref({});
        /**
         * 选择线路后初始化所有detail数据
         */
        const setDetailInfos = () => {
            const { pickUpLocation, dropOffLocation, pickUpLocationType, dropOffLocationType } =
                routerInfo.value;
            const setField = function (
                type: string,
                info: Ref<any>,
                field: string,
                dropInfo?: Ref<any>
            ) {
                if (pickUpLocationType === type) {
                    info.value[field] = '';
                    info.value[field] = pickUpLocation || '';
                    pickUpDetail.value = {
                        ...info.value
                    };
                }
                if (dropOffLocationType === type) {
                    dropInfo ? (dropInfo.value[field] = '') : (info.value[field] = '');
                    dropInfo
                        ? (dropInfo.value[field] = dropOffLocation || '')
                        : (info.value[field] = dropOffLocation || '');
                    dropOffDetail.value = {
                        ...(dropInfo ? dropInfo.value : info.value)
                    };
                }
            };
            setField(RouterTypeEnum.AIRPORT, airportInfo, 'airportName');
            setField(RouterTypeEnum.PORT, portInfo, 'portName');
            setField(RouterTypeEnum.ACTIVITY, acitiviyInfo, 'activityName');
            setField(RouterTypeEnum.STATION, stationInfo, 'stationName');
            setField(RouterTypeEnum.CITY, cityInfo, 'cityName', cityInfoDrop);
        };

        const clearAllData = () => {
            currStep.value = 1;
            amendOrderId.value = '';
            routerInfo.value = {
                pickUpLocation: '',
                pickUpLocationType: undefined,
                dropOffLocation: '',
                dropOffLocationType: undefined
            };
            peopleDateInfo.value = {
                serviceDate: new Date(
                    `${new Date().getFullYear()}-${
                        new Date().getMonth() + 1
                    }-${new Date().getDate()} 00:00:00`
                ).getTime(),
                adultCount: 1,
                childCount: 0,
                infantCount: 0
            };
            carInfo.value = {
                vehicleCount: 0,
                vehicleId: '',
                routeVehiclePriceId: 0,
                price: 0,
                name: ''
            };
            airportInfo.value = {
                departureDate: '',
                airportName: '',
                flightNumber: '',
                filghtDetail: ''
            };
            portInfo.value = {
                portName: '',
                cruiseName: '',
                cruiseTime: ''
            };
            acitiviyInfo.value = {
                activityName: '',
                pickUpTime: ''
            };
            stationInfo.value = {
                stationName: '',
                trainName: '',
                route: ''
            };
            cityInfo.value = {
                cityName: '',
                hotel: '',
                pickUp: '',
                detail: ''
            };
            cityInfoDrop.value = {
                cityName: '',
                hotel: '',
                pickUp: '',
                detail: ''
            };
            guestInfo.value = {
                guestNames: '',
                sns: '',
                remarks: '',
                referenceNo: ''
            };
            preOrderTips.value = undefined;
        };

        return {
            currStep,
            gvNum,
            amendInfo,
            amendOrderId,
            routerInfo,
            peopleDateInfo,
            carInfo,
            airportInfo,
            portInfo,
            acitiviyInfo,
            stationInfo,
            cityInfo,
            cityInfoDrop,
            guestInfo,
            VehicleType,
            vehicleList,
            isLoadingVehicle,
            pickUpDetail,
            dropOffDetail,
            bookingOrderUser,
            preOrderTips,
            getAllVehicle,
            newBooking,
            setDetailInfos,
            clearAllData
        };
    });
    return {
        ...bookingOrderStore(),
        ...storeToRefs(bookingOrderStore())
    };
};
