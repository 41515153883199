<template>
    <g-option-form ref="optionFormRef" :model="formModel" :options="formOptions"></g-option-form>
    <div class="flex flex-right">
        <el-button type="primary" @click="onSave" :loading="isLoadingAssign">{{
            $t('save')
        }}</el-button>
        <el-button @click="onCancel" :loading="isLoadingAssign">{{ $t('cancel') }}</el-button>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { useVueI18n, useOptions } from '@use';
import { RouterTypeEnum } from '@enums';
import { useBookingStore } from '@/stores';
import { IAssignTo } from '@/common/types';
import SelectCarCompanyAndDriver from './SelectCarCompanyAndDriver.vue';
import AssignToStatus from './AssignToStatus.vue';

const emit = defineEmits<{
    cancel: [];
    confirm: [];
}>();

const { currBooking, isLoadingAssign, assignToOrder, getCurrBooking } = useBookingStore();
const { tJoin } = useVueI18n();
const formModel = ref<IRecord>({
    assignToStatus: currBooking.value?.assignToStatus || '',
    pickUpTime: String(currBooking.value?.pickUpTime || ''),
    meetingPointId: currBooking.value?.meetingPointDetails?.id,
    note: currBooking.value?.assignToNote,
    isHaishaShowHello: currBooking.value?.isHaishaShowHello || false
});
if (
    currBooking.value?.carCompanyUserDetails &&
    Object.keys(currBooking.value.carCompanyUserDetails).length
) {
    formModel.value.carCompanyDriver = {
        carCompany: currBooking.value.carCompanyUserDetails
    };
    if (
        currBooking.value?.driverUserDetails &&
        Object.keys(currBooking.value.driverUserDetails).length
    ) {
        formModel.value.carCompanyDriver.driver = currBooking.value.driverUserDetails;
    }
}
const options: IFormOption[] = [
    {
        label: tJoin('bookingList.assignTo.assignTo'),
        prop: 'carCompanyDriver',
        component: SelectCarCompanyAndDriver
        // rules: [{ required: true, message: tJoin('pleaseSelect', 'bookingList.assignTo.assignTo') }]
    },
    // {
    //     label: tJoin('bookingList.assignTo.transportInfo'),
    //     prop: 'transportInfo',
    //     props: {
    //         type: 'textarea'
    //     },
    //     rules: [
    //         { required: true, message: tJoin('pleaseEnter', 'bookingList.assignTo.transportInfo') }
    //     ]
    // },
    {
        label: tJoin('bookingList.assignTo.status'),
        prop: 'assignToStatus',
        component: AssignToStatus
    },
    {
        label: tJoin('bookingList.assignTo.pickUpTime'),
        prop: 'pickUpTime',
        component: 'elTimePicker',
        props: {
            format: 'HH:mm',
            valueFormat: 'HHmm'
        }
    },
    {
        label: tJoin('bookingList.assignTo.meetingPoint'),
        prop: 'meetingPointId',
        component: 'gSelectMeetingPoint'
    },
    {
        label: tJoin('bookingList.assignTo.note'),
        prop: 'note',
        props: {
            type: 'textarea'
        }
    },
    {
        label: tJoin('bookingList.assignTo.sayHello'),
        prop: 'isHaishaShowHello',
        component: 'elSwitch'
    }
];
if (
    currBooking.value?.pickUpLocationType === RouterTypeEnum.AIRPORT ||
    currBooking.value?.dropOffLocationType === RouterTypeEnum.AIRPORT
) {
    options.unshift({
        label: tJoin('bookingList.assignTo.terminal'),
        prop: 'terminal',
        component: 'gSelect',
        props: {
            clearable: true,
            options: [
                {
                    label: tJoin('bookingList.assignTo.international').value,
                    value: 'International'
                },
                {
                    label: tJoin('bookingList.assignTo.internationalT1').value,
                    value: 'International_T1'
                },
                {
                    label: tJoin('bookingList.assignTo.internationalT2').value,
                    value: 'International_T2'
                },
                {
                    label: tJoin('bookingList.assignTo.internationalT3').value,
                    value: 'International_T3'
                },
                { label: tJoin('bookingList.assignTo.domestic').value, value: 'Domestic' },
                { label: tJoin('bookingList.assignTo.domesticT1').value, value: 'Domestic_T1' },
                { label: tJoin('bookingList.assignTo.domesticT2').value, value: 'Domestic_T2' },
                { label: tJoin('bookingList.assignTo.domesticT3').value, value: 'Domestic_T3' }
            ]
        }
    });
    formModel.value.terminal = currBooking.value?.terminal;
}
const formOptions = computed<IFormOption[]>(() =>
    options.filter(
        (option) => !option.belong || option.belong === currBooking.value?.pickUpLocationType
    )
);

const optionFormRef = ref<OptionFormInstance>();
const onSave = async () => {
    if (optionFormRef.value) {
        const formData = await optionFormRef.value.validate<IRecord>();
        const hasValue = [
            'assignToStatus',
            'carCompanyDriver',
            'meetingPointId',
            'note',
            'pickUpTime'
        ].some((key) => {
            return formData[key] || formData[key] === 0;
        });
        if (!hasValue) {
            ElMessage.warning(tJoin('bookingList.assignTo.emptyTip').value);
            return;
        }
        const params: IRecord = {
            ...formData
        };
        if (formData.carCompanyDriver) {
            params.carCompanyUserId = formData.carCompanyDriver.carCompany?.id || '';
            params.driverUserId = formData.carCompanyDriver.driver?.id || '';
        } else {
            params.carCompanyUserId = '';
            params.driverUserId = '';
        }
        delete params.carCompanyDriver;
        console.log(params);
        await assignToOrder(params as IAssignTo);
        await getCurrBooking();
        ElMessage.success(tJoin('success').value);
        emit('confirm');
    }
};
const onCancel = () => {
    emit('cancel');
};
</script>
