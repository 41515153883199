<template>
    <template v-if="!signType">
        <sign-up-type v-model="signType"></sign-up-type>
    </template>
    <template v-else>
        <el-button type="info" link @click="signType = ''">
            <el-icon size="20"><Back /></el-icon>
        </el-button>
        <logo class="h_80p mg-y_50"></logo>
        <p class="mg-y_10 fw-b fs_16 ta-c">{{ $t('createNewAccount') }}</p>
        <sign-up-form ref="signUpFormRef" :type="signType"></sign-up-form>
        <el-button
            type="primary"
            class="w_100 mg-t_20 h_50p"
            :loading="isLoading"
            @click="onSignUp"
            >{{ $t('signUp') }}</el-button
        >
    </template>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { Back } from '@element-plus/icons-vue';
import { useVueI18n, useRouter } from '@use';
import { useLoginStore } from '@/stores';
import { ISignUpInfo } from '@ctypes';
import SignUpForm, { ISignUpType } from './SignUpForm.vue';
import Logo from '../header/Logo.vue';
import SignUpType from './SignUpType.vue';

const signUpFormRef = ref<InstanceType<typeof SignUpForm>>();
const validate = async function <T>(): Promise<T> {
    if (signUpFormRef.value) {
        return await signUpFormRef.value.validate<T>();
    }
    return Promise.reject();
};

const signType = ref<ISignUpType>();

const { tJoin } = useVueI18n();
const { signUp, signIn, isLoading } = useLoginStore();
const { push, ROUTER_PATH_MAP } = useRouter();
const onSignUp = async () => {
    const model = await validate<ISignUpInfo>();
    await signUp(model);
    ElMessage.success(tJoin('signUp', 'success').value);
    await signIn({
        email: model.email,
        password: model.password
    });
    push(ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH);
};

defineExpose({
    validate
});
</script>
