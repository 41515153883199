<template>
    <div>
        <div class="flex w_100 flex-left mg-b_10" v-for="(item, index) in addItems" :key="index">
            <div class="flex w_100">
                <el-input
                    class="flex1"
                    placeholder="Processing fee/Special charge.."
                    v-model="item.name"
                    @input="onChange"
                ></el-input>
                :
                <el-input-number
                    class="mg-l_10 flex1"
                    placeholder="price"
                    v-model="item.price"
                    @input="onChange"
                ></el-input-number>
            </div>
            <el-button class="mg-l_10" size="small" type="danger" circle @click="onRmove(index)">
                <el-icon>
                    <Minus />
                </el-icon>
            </el-button>
        </div>
        <div class="mg-t_10">
            <el-button circle @click="onAdd">
                <el-icon>
                    <Plus />
                </el-icon>
            </el-button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Plus, Minus } from '@element-plus/icons-vue';

interface AddItem {
    name: string;
    price: number;
}
const props = defineProps<{
    modelValue: AddItem[];
}>();
const emit = defineEmits<{
    'update:modelValue': [value: AddItem[]];
    change: [value: AddItem[]];
}>();

const addItems = ref<AddItem[]>([]);

const init = () => {
    addItems.value = props.modelValue || [];
};
init();

const onAdd = () => {
    addItems.value.push({ name: '', price: 0 });
};

const onRmove = (index: number) => {
    addItems.value.splice(index, 1);
    emitValue();
};

const onChange = () => {
    emitValue();
};

const emitValue = () => {
    emit(
        'update:modelValue',
        addItems.value.filter((item) => item.name && item.price)
    );
    emit(
        'change',
        addItems.value.filter((item) => item.name && item.price)
    );
};
</script>
