<template>
    <template v-if="isEdit">
        <div class="flex flex-left">
            <el-input-number v-bind="$attrs"></el-input-number>
            <span class="mg-l_10">{{ currency }}</span>
        </div>
    </template>
    <div v-else>
        <span v-if="rate">
            {{ rate < 0 ? '+' : '-' }}
        </span>
        <el-text
            tag="b"
            v-bind="$attrs"
            :style="{
                color: color,
                fontSize: `${fontSize}px`
            }"
        >
            {{ currency }}
            {{ priceText }}
        </el-text>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
const props = withDefaults(
    defineProps<{
        price?: number;
        rate?: number;
        currency?: string;
        isEdit?: boolean;
        color?: string;
        fontSize?: number;
    }>(),
    {
        currency: 'JPY',
        fontSize: 16
    }
);

const finalPrice = computed(() => {
    return props.price ? props.price * Math.abs(props.rate || 100) * 0.01 : 0;
});
const priceText = computed(() => {
    return String(finalPrice.value).replace(/(?!^)(?=(\d{3})+$)/g, ',');
});
</script>
