<template>
    <div class="relative w_100">
        <g-radio-group
            v-if="rangeTypeOptions.length > 1"
            class="absolute-t_o w_100 flex flex-between"
            size="small"
            v-model="rangeType"
            :options="rangeTypeOptions"
        ></g-radio-group>
        <el-date-picker
            class="w_100"
            v-bind="$attrs"
            v-model="rangeDate"
            :type="rangeType"
            value-format="YYYY-MM-DD"
            start-placeholder="Start date"
            end-placeholder="End date"
        ></el-date-picker>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
const props = withDefaults(
    defineProps<{
        modelValue?: any;
        rangeTypes?: string;
        type?: 'daterange' | 'monthrange' | 'year' | 'datetimerange';
    }>(),
    {
        rangeTypes: 'date',
        type: 'daterange',
        modelValue: () => []
    }
);
const emit = defineEmits<{
    'update:modelValue': [value: any];
}>();

const rangeDate = ref<any>(props.modelValue);
watch(
    () => props.modelValue,
    () => {
        if (!isEquery()) {
            rangeDate.value = props.modelValue;
        }
    }
);

const isEquery = () => {
    if (Array.isArray(props.modelValue) && Array.isArray(rangeDate.value)) {
        return props.modelValue.every(
            (item, index) =>
                new Date(item).format('YYYY-MM-DD') ===
                new Date((rangeDate.value as string[])[index]).format('YYYY-MM-DD')
        );
    }
    return props.modelValue === rangeDate.value;
};

const canUseful = (type: string) => {
    return props.rangeTypes?.replace(/\s/g, '').split(',').includes(type) || false;
};

const rangeType = ref<'daterange' | 'monthrange' | 'year' | 'datetimerange'>('daterange');
const rangeTypeOptions: ILabelValue<string>[] = [
    { label: 'date', value: 'daterange' },
    { label: 'month', value: 'monthrange' },
    { label: 'year', value: 'year' }
].filter((option) => canUseful(option.label));

watch(rangeType, () => {
    if (rangeType.value === 'year') {
        rangeDate.value = '';
    } else {
        rangeDate.value = [];
    }
});
const valueToUTC = (value: any) => {
    if (Array.isArray(value)) {
        const time = [' 00:00:00', ' 23:59:59'];
        return value.map((date, index) => new Date(date + time[index]).getTime());
    }
    return new Date(value as number).getTime();
};
watch(rangeDate, () => {
    emit('update:modelValue', valueToUTC(rangeDate.value));
});
</script>

<style lang="scss" scoped>
::v-deep .el-radio {
    margin-right: 0;
}
</style>
