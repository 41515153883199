import { IMenuItem } from '@ctypes';
import type { Router, RouteRecordRaw } from 'vue-router';
import { getRouterName, ROUTER_PATH_MAP } from '@enums';
import Menu from '@/views/Menu.vue';
import UserDetail from '@/components/user/UserDetail.vue';
import AgentDetail from '@/components/agent/AgentDetail.vue';
import BookingRequest from '@/components/bookingList/BookingRequest.vue';
import BookingPreview from '@/components/bookingList/BookingPreview.vue';
import InvoiceDetail from '@/components/invoice/InvoiceDetail.vue';
// import ChangePassword from '@/components/user/userDetail/ChangePassword.vue';

const toCamelCase = (path: string) => {
    const strArr = path.split('/');
    return strArr
        .map((item, index) =>
            item && index === strArr.length - 1
                ? `${item[0].toLocaleUpperCase()}${item.slice(1)}`
                : item
        )
        .join('/');
};

const modules = import.meta.glob('@/views/**/*.{vue,tsx}');
const asyncAddRouter = (router: Router) => {
    return (menuData: IMenuItem[]) => {
        const menuList: RouteRecordRaw = {
            name: 'menu',
            path: '/menu',
            component: Menu,
            children: []
        };
        const switchMenu = (menu: IMenuItem): RouteRecordRaw => {
            return {
                path: `${ROUTER_PATH_MAP.MENU_PATH}${menu.path}`,
                name: getRouterName(menu.path),
                component:
                    modules[`/src/views${ROUTER_PATH_MAP.MENU_PATH}${toCamelCase(menu.path)}.vue`]
            };
        };
        const addRouter = (menuList: IMenuItem[]): RouteRecordRaw[] => {
            const menus: RouteRecordRaw[] = [];
            for (let i = 0; i < menuList.length; i += 1) {
                const menu = switchMenu(menuList[i]);
                if (Array.isArray(menuList[i].children) && menuList[i].children?.length) {
                    menu.children = addRouter(menuList[i].children as IMenuItem[]);
                }
                menus.push(menu);
                switch (menu.path) {
                    case ROUTER_PATH_MAP.MENU_TEAM_MEMBER_LIST_PATH:
                        appendTeamMemberPath(menus);
                        break;
                    case ROUTER_PATH_MAP.MENU_AGENT_LIST:
                        appendAgentPath(menus);
                        break;
                    case ROUTER_PATH_MAP.MENU_BOOKING_LIST_PATH:
                        appendBookingPath(menus);
                        break;
                    case ROUTER_PATH_MAP.MENU_INVOICE_LIST_PATH:
                        appendInvoicePath(menus);
                        break;
                }
            }
            return menus;
        };
        menuList.children = addRouter(menuData);
        console.log(menuList);
        router.addRoute(menuList);
    };
};

// team member页面的子页面路径
const appendTeamMemberPath = (menus: RouteRecordRaw[]) => {
    menus.push({
        path: ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL_PATH,
        name: getRouterName(ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL_PATH),
        component: UserDetail
        // children: [
        //     {
        //         path: ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL_CHANGE_PASSWORD_PATH,
        //         name: getRouterName(ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL_CHANGE_PASSWORD_PATH),
        //         component: () => ChangePassword
        //     }
        // ]
    });
};

// agent页面的子页面路径
const appendAgentPath = (menus: RouteRecordRaw[]) => {
    menus.push({
        path: ROUTER_PATH_MAP.MENU_AGENT_DETAIL_PATH,
        name: getRouterName(ROUTER_PATH_MAP.MENU_AGENT_DETAIL_PATH),
        component: AgentDetail
    });
};

// booking页面的子页面路径
const appendBookingPath = (menus: RouteRecordRaw[]) => {
    menus.push({
        path: ROUTER_PATH_MAP.MENU_BOOKING_REQUEST_PATH,
        name: getRouterName(ROUTER_PATH_MAP.MENU_BOOKING_REQUEST_PATH),
        component: BookingRequest
    });
    menus.push({
        path: ROUTER_PATH_MAP.MENU_PREVIEW_PATH,
        name: getRouterName(ROUTER_PATH_MAP.MENU_PREVIEW_PATH),
        component: BookingPreview
    });
};

// invoice页面的子页面路径
const appendInvoicePath = (menus: RouteRecordRaw[]) => {
    menus.push({
        path: ROUTER_PATH_MAP.MENU_INVOICE_DETAIL_PATH,
        name: getRouterName(ROUTER_PATH_MAP.MENU_INVOICE_DETAIL_PATH),
        component: InvoiceDetail
    });
};

export default asyncAddRouter;
