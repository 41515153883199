import { IMenuItem } from '@ctypes';
import { RoleEnum } from '@enums';

export const queryMenuData = (): IMenuItem[] => {
    return [
        {
            path: '/bookingList',
            label: 'bookings',
            icon: 'icon-dingdan'
        },
        {
            path: '/invoiceList',
            label: 'invoiceVouchers',
            icon: 'icon-fapiao',
            belong: [
                RoleEnum.Operator,
                RoleEnum.SubAdmin,
                RoleEnum.SuperAdmin,
                RoleEnum.Agent,
                RoleEnum.Guest,
                RoleEnum.Staff
            ]
        },
        {
            path: '/agentList',
            label: 'agentsClients',
            icon: 'icon-dailishang',
            belong: [RoleEnum.Operator, RoleEnum.SubAdmin, RoleEnum.SuperAdmin]
        },
        {
            path: '/teamMemberList',
            label: 'teamMembers',
            icon: 'icon-tuanduichengyuan',
            belong: [RoleEnum.Operator, RoleEnum.SubAdmin, RoleEnum.SuperAdmin]
        },
        {
            path: '/hotelList',
            label: 'hotel',
            icon: 'icon-jiudian',
            belong: [RoleEnum.SubAdmin, RoleEnum.SuperAdmin]
        },
        {
            path: '/meetingPointList',
            label: 'meetingPoint',
            icon: 'icon-zhiyuandidian2',
            belong: [RoleEnum.SubAdmin, RoleEnum.SuperAdmin]
        },
        {
            path: '/routerList',
            label: 'router',
            icon: 'icon-luxian',
            belong: [RoleEnum.SubAdmin, RoleEnum.SuperAdmin]
        },
        {
            path: '/preOrderList',
            label: 'preOrder',
            icon: 'icon-luxian',
            belong: [RoleEnum.SubAdmin, RoleEnum.SuperAdmin, RoleEnum.Operator]
        }
    ];
};
