<template>
    <div class="w_100 fs_12" v-if="tips.length">
        <p v-for="(tip, index) in tips" :key="index">
            <el-text type="warning">
                {{ tip.originLabel }}({{ tip.label }}):
                <span class="mg-l_10">{{ tip.value }}</span>
            </el-text>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useBookingOrderStore } from '@/stores';

const props = defineProps<{
    tipPlace: string;
}>();

const { preOrderTips } = useBookingOrderStore();

const tips = computed(() => {
    if (preOrderTips.value) {
        return preOrderTips.value[props.tipPlace] || [];
    }
    return [];
});
</script>
