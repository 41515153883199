<template>
    <el-card>
        <div class="flex flex-between">
            <h3>Agent/Client info</h3>
            <div v-if="canEdit">
                <el-button v-if="!isEdit" type="primary" @click="isEdit = true">{{
                    $t('edit')
                }}</el-button>
                <template v-else>
                    <el-button @click="isEdit = false">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="onSave" :loading="isloadingUpdateAgent">{{
                        $t('save')
                    }}</el-button>
                </template>
            </div>
        </div>
        <g-option-form
            ref="formRef"
            labelPosition="right"
            labelWidth="120px"
            v-if="isEdit"
            :model="formModel"
            :options="formOptinos"
        ></g-option-form>
        <g-value-form
            v-else
            :options="formOptinos"
            :model="currAgent || {}"
            :labelWidth="120"
            emptyStr="--"
            divider
        ></g-value-form>
    </el-card>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { useVueI18n } from '@use';
import { useAgentStore, useUserStore } from '@/stores';

const { t } = useVueI18n();
const { currAgent, isloadingUpdateAgent, updateAgentInfo, getAgentInfo } = useAgentStore();
const { currUserInfo, isAdmin } = useUserStore();

const canEdit = computed(() => {
    return isAdmin || currAgent.value?.userId === currUserInfo.value?.id;
});
const formOptinos: IFormOption[] = [
    {
        label: t('agentInfo.companyName'),
        prop: 'companyName',
        props: {
            disabled: true
        }
    },
    { label: t('agentInfo.address'), prop: 'address' },
    { label: t('agentInfo.city'), prop: 'city' },
    { label: t('agentInfo.state'), prop: 'state' },
    { label: t('agentInfo.zip'), prop: 'zip' },
    { label: t('agentInfo.country'), prop: 'country' },
    { label: t('agentInfo.phone'), prop: 'phone' },
    { label: t('agentInfo.website'), prop: 'website' },
    { label: t('agentInfo.travelLicence'), prop: 'travelLicence' }
];

const formModel = ref({
    ...currAgent.value
});
const formRef = ref<OptionFormInstance>();
const isEdit = ref(false);
const onSave = async () => {
    if (formRef.value) {
        const formData = await formRef.value.validate<IRecord>();
        await updateAgentInfo(formData);
        await getAgentInfo();
        formModel.value = currAgent.value || {};
        ElMessage.success(t('success').value);
        isEdit.value = false;
    }
};
</script>
