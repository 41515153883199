<template>
    <template v-for="(value, index) in valueList" :key="index">
        <template v-if="Array.isArray(value.value)">
            <template v-for="(item, itemIndex) in value.value" :key="itemIndex">
                <template v-if="option.decorate">
                    <component
                        :is="option.decorate"
                        v-bind="getFunProps(getValue(item, value.prop))"
                    >
                        {{ getValue(item, value.prop) }}
                    </component>
                </template>
                <template v-else>
                    <p>{{ getValue(item, value.prop) }}</p>
                    <span v-if="itemIndex < value.value.length - 1">,</span>
                </template>
            </template>
        </template>
        <template v-else>
            <template v-if="option.decorate">
                <component
                    :is="option.decorate"
                    v-bind="getFunProps(getValue(value.value, value.prop))"
                >
                    <div class="wrap-pre">{{ getValue(value.value, value.prop) }}</div>
                </component>
            </template>
            <template v-else>
                <div class="wrap-pre">{{ getValue(value.value, value.prop) }}</div>
                <span v-if="index < valueList.length - 1">,</span>
            </template>
        </template>
    </template>
</template>

<script lang="ts" setup>
import { ref, watch, computed } from 'vue';
import { useVueI18n } from '@use';

interface IValue {
    prop: string;
    value: any;
}

const props = withDefaults(
    defineProps<{
        row: Record<string, any>;
        option: ITableOption;
        emptyStr?: string;
    }>(),
    {
        emptyStr: ''
    }
);

const { locale } = useVueI18n();

watch(
    () => [props.row, props.option],
    () => {
        handleProps();
    }
);

const valueList = ref<IValue[]>([]);
const handleProps = () => {
    if (props.option.prop) {
        const result: IValue[] = [];
        const propArr = props.option.prop.split(',');
        propArr.forEach((prop) => {
            result.push(handleProp(prop));
        });
        valueList.value = result;
    }
};
const handleProp = (propStr: string): IValue => {
    const propArr = propStr.split('.');
    let lastProp = propArr[propArr.length - 1];
    let value: any = '';
    let currObj = props.row;
    for (let i = 0; i < propArr.length; i += 1) {
        const prop = propArr[i];
        if (Object.prototype.toString.call(currObj[prop]) === '[object Array]') {
            value = currObj[prop];
            lastProp = propArr[i + 1];
            break;
        } else {
            value = currObj[prop] ? computed(() => currObj[prop]) : '';
            break;
        }
    }
    return {
        value,
        prop: lastProp
    };
};
handleProps();
// getValue(props.option.prop);

const getValue = (value: any, prop: string) => {
    if (Object.prototype.toString.call(value) === '[object Object]') {
        return getForamtValue(value[prop]);
    }
    return getForamtValue(value);
};

const isEmpty = (value: any) => {
    if (Array.isArray(value) && !value.length) {
        return true;
    }
    if (Object.prototype.toString.call(value) === '[object Object]' && !Object.keys(value).length) {
        return true;
    }
    if (typeof value === 'string' && !value) {
        return true;
    }
    if (value === null || value === undefined) {
        return true;
    }
    return false;
};
const getForamtValue = (value: any) => {
    if (props.option.formatValue) {
        const res = props.option.formatValue(
            props.row,
            value,
            locale.value === 'en' ? 'EN' : locale.value === 'jp' ? 'JP' : 'EN'
        );
        return isEmpty(res) ? props.emptyStr : res;
    }
    return isEmpty(value) ? props.emptyStr : value;
};

// 获取既可以是对象，又可以是函数的属性
const getFunProps = (value: any) => {
    if (props.option.decorateProps instanceof Function) {
        return props.option.decorateProps(props.row, value);
    }
    return props.option.decorateProps;
};
</script>
