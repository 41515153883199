import { get, post, put } from '@/repositories/base';
import { AGENT_URL, CLIENT_GROUP_URL } from '@/common/enums/repositories';
import {
    IAgentInfo,
    IBaseUserInfo,
    IPayment,
    ITeamMemberInfo,
    IAgentStatistic,
    IBooking
} from '@ctypes';

/**
 * 获取agent列表
 */
export const getAgentList = async (data: Record<string, any>) => {
    return get<Record<string, any>, IListResponse<IAgentInfo>>({
        url: AGENT_URL,
        data
    });
};

/**
 * 获取所有的CLientGroups
 */
export const getClientGroupList = async () => {
    return get({
        url: CLIENT_GROUP_URL
    });
};

/**
 * 获取agent联系人列表
 */
export const getAgentConnectList = async (agentId: string, data: IRecord) => {
    return get<IRecord, IListResponse<ITeamMemberInfo>>({
        url: `${AGENT_URL}/${agentId}/concat/user`,
        data
    });
};

/**
 * 添加agent联系人
 */
export const addAgentConnect = async (agentId: string, data: Partial<IBaseUserInfo>) => {
    return post<IRecord, IListResponse<ITeamMemberInfo>>({
        url: `${AGENT_URL}/${agentId}/concat/user`,
        data
    });
};

/**
 * 获取agent营收信息
 */
export const getAgentStatistic = async (agentId: string) => {
    return get<undefined, IAgentStatistic>({
        url: `${AGENT_URL}/${agentId}/revenue`
    });
};

/**
 * 通过id获取agent
 */
export const getAgentInfo = async (agentId: string) => {
    return get<undefined, IAgentInfo>({
        url: `${AGENT_URL}/${agentId}`
    });
};

/**
 * 更新agent信息
 */
export const updateAgentById = async (id: string, data: Partial<IAgentInfo>) => {
    return put<Partial<IAgentInfo>, boolean>({
        url: `${AGENT_URL}/${id}`,
        data
    });
};

/**
 * 分页获取agent所有支付记录
 */
export const getAgentPaymentList = async (agentId: string, data: IRecord) => {
    return get<IRecord, IListResponse<IPayment>>({
        url: `${AGENT_URL}/${agentId}/payment`,
        data
    });
};

export const getAgentInvoice = async (agentId: string, data: IPage) => {
    return get<IPage, IListResponse<IBooking>>({
        url: `${AGENT_URL}/${agentId}/invoices`,
        data
    });
};
