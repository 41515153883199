<template>
    <div>
        <el-button type="primary" v-if="canAmend" link @click="onAmend">{{ id }}</el-button>
        <p v-else>{{ id }}</p>
        <p>
            <el-text size="small" type="info" v-if="agentNo">{{ agentNo }}</el-text>
        </p>
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from '@use';
import { RoleEnum } from '@enums';
import { useUserStore, useBookingOrderStore } from '@/stores';
import { routerRepositories } from '@/repositories';
import { RouterTypeEnum, BookingStatusEnum } from '@enums';
import { jpToLocal, setLocalStorage } from '@utils';
import {
    IAcitiviyDetailInfo,
    IAirportDetailInfo,
    IBooking,
    ICityDetailInfo,
    IPortDetailInfo,
    IStationDetailInfo
} from '@/common/types';
const props = defineProps<{
    id: string;
    agentNo?: string;
    data: IBooking;
}>();
const { hasRole, isAdmin } = useUserStore();
const isAgent = hasRole(RoleEnum.Agent);
const canAmend = computed(() => {
    if (isAdmin.value) {
        return true;
    }
    if (isAgent.value) {
        if (props.data.serviceDate - new Date().getTime() > 7 * 24 * 60 * 60 * 1000) {
            if (
                [
                    BookingStatusEnum.NEW,
                    BookingStatusEnum.VERIFIED,
                    BookingStatusEnum.PREBOOK,
                    BookingStatusEnum.COMFIRMED
                ].includes(props.data.status)
            ) {
                // 服务时间大于7天，则NEW VERIFIED，PRE_BOOK，CONFIRM的订单可以修改
                return true;
            }
        } else if (
            [BookingStatusEnum.NEW, BookingStatusEnum.VERIFIED, BookingStatusEnum.PREBOOK].includes(
                props.data.status
            )
        ) {
            // 服务时间小于7天，则NEW，PRE_BOOK，VERIFIED订单可以修改
            return true;
        }
    }
    return false;
});

const {
    routerInfo,
    peopleDateInfo,
    carInfo,
    airportInfo,
    portInfo,
    acitiviyInfo,
    stationInfo,
    cityInfo,
    cityInfoDrop,
    guestInfo,
    amendOrderId
} = useBookingOrderStore();

const { push, ROUTER_PATH_MAP } = useRouter();
const onAmend = async () => {
    const routerData = await routerRepositories.getRouterById(props.data.routeId);
    routerInfo.value = routerData;
    amendOrderId.value = props.data.id;

    peopleDateInfo.value.serviceDate = Number(`${jpToLocal(props.data.serviceDate)}`);
    peopleDateInfo.value.adultCount = props.data.adultCount;
    peopleDateInfo.value.childCount = props.data.childCount;
    peopleDateInfo.value.infantCount = props.data.infantCount;

    carInfo.value.vehicleCount = props.data.vehicleCount;
    carInfo.value.vehicleId = props.data.vehicleId;
    carInfo.value.name = props.data.vehicleName;

    switch (props.data.pickUpLocationType) {
        case RouterTypeEnum.AIRPORT:
            airportInfo.value = {
                ...props.data.pickUpDetails,
                departureDate: jpToLocal(props.data.pickUpDetails.departureDate as number)
            } as IAirportDetailInfo;
            break;
        case RouterTypeEnum.PORT:
            portInfo.value = props.data.pickUpDetails as IPortDetailInfo;
            break;
        case RouterTypeEnum.ACTIVITY:
            acitiviyInfo.value = props.data.pickUpDetails as IAcitiviyDetailInfo;
            break;
        case RouterTypeEnum.STATION:
            stationInfo.value = props.data.pickUpDetails as IStationDetailInfo;
            break;
        case RouterTypeEnum.CITY:
            cityInfo.value = props.data.pickUpDetails as ICityDetailInfo;
            break;
    }

    switch (props.data.dropOffLocationType) {
        case RouterTypeEnum.AIRPORT:
            airportInfo.value = {
                ...props.data.dropOffDetails,
                departureDate: jpToLocal(props.data.dropOffDetails.departureDate as number)
            } as IAirportDetailInfo;
            break;
        case RouterTypeEnum.PORT:
            portInfo.value = props.data.dropOffDetails as IPortDetailInfo;
            break;
        case RouterTypeEnum.ACTIVITY:
            acitiviyInfo.value = props.data.dropOffDetails as IAcitiviyDetailInfo;
            break;
        case RouterTypeEnum.STATION:
            stationInfo.value = props.data.dropOffDetails as IStationDetailInfo;
            break;
        case RouterTypeEnum.CITY:
            // cityInfo.value = props.data.dropOffDetails as ICityDetailInfo;
            cityInfoDrop.value = props.data.dropOffDetails as ICityDetailInfo;
            break;
    }

    guestInfo.value.guestNames = props.data.guestNames;
    guestInfo.value.sns = props.data.sns;
    guestInfo.value.remarks = props.data.remarks || '';
    guestInfo.value.referenceNo = props.data.referenceNo || '';
    const amendInfo = {
        amendInfo: props.data,
        routerInfo: routerInfo.value,
        amendOrderId: amendOrderId.value,
        peopleDateInfo: peopleDateInfo.value,
        carInfo: carInfo.value,
        airportInfo: airportInfo.value,
        portInfo: portInfo.value,
        acitiviyInfo: acitiviyInfo.value,
        stationInfo: stationInfo.value,
        cityInfo: cityInfo.value,
        cityInfoDrop: cityInfoDrop.value,
        guestInfo: guestInfo.value
    };
    setLocalStorage('amendInfo', amendInfo);
    // push(ROUTER_PATH_MAP.HOME_BOOKING_PATH);
    window.open(`${window.location.origin}${ROUTER_PATH_MAP.HOME_BOOKING_PATH}`);
};
</script>
