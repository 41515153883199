<template>
    <div class="payment flex flex-between">
        <el-card class="flex1 mg_5 primary-bg white">
            <el-statistic
                class="payment-statistic"
                :value="agentStatistic?.totalAmount || 0"
                :title="$t('agent.totalPurchase')"
                prefix="JPY"
            ></el-statistic>
        </el-card>
        <el-card class="flex1 mg_5 success-bg white">
            <el-statistic
                class="payment-statistic"
                :value="agentStatistic?.totalPaidAmount || 0"
                :title="$t('agent.payments')"
                prefix="JPY"
            ></el-statistic>
        </el-card>
        <el-card class="flex1 mg_5 warning-bg white">
            <el-statistic
                class="payment-statistic"
                :value="agentStatistic?.totalDueAmount || 0"
                :title="$t('agent.due')"
                prefix="JPY"
            ></el-statistic>
        </el-card>
    </div>
</template>

<script lang="ts" setup>
import { useAgentStore } from '@/stores';
const { agentStatistic } = useAgentStore();
</script>

<style lang="scss" scoped>
::v-deep .payment-statistic {
    --el-statistic-content-color: '#fff';
    --el-statistic-title-color: '#fff';
    --el-statistic-content-font-weight: bold;
}
</style>
