<template>
    <el-table ref="optionTableRef" class="w_100" :height="height" :data="data" v-bind="$attrs">
        <el-table-column fixed="left" width="70" v-if="showOrder">
            <template #default="{ $index }">
                <el-tag type="info" round>{{ $index + 1 }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column fixed="left" v-if="showSelection" type="selection" width="55" />
        <el-table-column
            v-for="(option, index) in options"
            :key="index"
            :prop="option.prop"
            :label="toValue(option.label)"
            :width="option.width"
            v-bind="option.props"
        >
            <template #default="{ row }">
                <slot :name="option.slot" :row="row" :option="option" v-if="option.slot"></slot>
                <template v-else-if="option.isEdit">
                    <component :is="switchComponent(option.component || 'elInput')"></component>
                </template>
                <template v-else>
                    <table-value :row="row" :option="option"></table-value>
                </template>
                <slot name="col" :row="row" :option="option"></slot>
            </template>
            <template #header="{ column }" v-if="option.headerSlot">
                <slot name="header" :column="column" :option="option"></slot>
            </template>
        </el-table-column>
    </el-table>
</template>

<script lang="ts" setup>
import { ref, toValue } from 'vue';
import { ElTable } from 'element-plus';
import { useOptions } from '@use';
import TableValue from './TableValue.vue';
withDefaults(
    defineProps<{
        showOrder?: boolean;
        showSelection?: boolean;
        height?: number;
        data?: Record<string, any>[];
        options: ITableOption[];
    }>(),
    {
        showOrder: true,
        options: () => []
    }
);

const { switchComponent } = useOptions();

const optionTableRef = ref<InstanceType<typeof ElTable>>();

defineExpose({});
</script>
