import axios from 'axios';
import { requestSuccess, requestFail, responseSuccess, responseFail } from './interceptors';

const service = axios.create({
    baseURL: import.meta.env.VITE_BASE_API_URL,
    withCredentials: true
});

service.interceptors.request.use(requestSuccess, requestFail);
service.interceptors.response.use(responseSuccess, responseFail);

export default service;
