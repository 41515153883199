<template>
    <div class="flex flex-between flex-top flex-stretch mg-y_20">
        <div class="flex flex-top flex-left flex-stretch w_100">
            <div>
                <el-image class="br_5 of-h w_200p" :src="baseUrl + data.img"></el-image>
                <div class="mg-t_10" v-if="data.attributeMap">
                    <p
                        class="mg-r_10 nowrap"
                        v-for="(item, index) in orderAttributeMap(data.attributeMap)"
                        :key="index"
                    >
                        <el-icon>
                            <component :is="item.icon"></component>
                        </el-icon>
                        {{ item.value }}
                    </p>
                </div>
            </div>
            <div class="fs_12 mg-l_20 flex1">
                <h3 class="mg_0 mg-b_10">{{ $t(`router.${data.name.replace(/ /g, '')}`) }}</h3>
                <h2 class="mg-t_0 bd-b_1">
                    <!-- <el-row v-for="(price, index) in priceList" :key="index">
                        <g-price :price="price" type="success" :font-size="22"></g-price>
                    </el-row> -->
                    <book-car-item-price
                        v-for="(price, index) in data.priceList"
                        :key="index"
                        :data="price"
                        :name="data.name"
                        @booking="onBookCar"
                    ></book-car-item-price>
                </h2>
            </div>
            <!-- <g-option-form
                v-if="data.name !== 'Shared Transfer'"
                class="flex1"
                ref="numberFormRef"
                :options="formOptions"
                :model="formModel"
                @change="onFormChange"
            ></g-option-form> -->
        </div>
        <!-- <div class="flex mg-l_10">
            <el-button type="success" :icon="Right" size="large" @click="bookCar">{{
                $t('bookCar')
            }}</el-button>
        </div> -->
    </div>
    <g-line type="dashed"></g-line>
</template>

<script lang="ts" setup>
import { UserFilled, Briefcase, GoodsFilled } from '@element-plus/icons-vue';
import { IVehicle } from '@/common/types';
import { baseUrl } from '@utils';
import { ICarPrice } from '@ctypes';
import BookCarItemPrice from './BookCarItemPrice.vue';
import { useBookingOrderStore } from '@/stores';

const props = defineProps<{
    data: IVehicle & { priceList: ICarPrice[] };
}>();

const { carInfo, currStep } = useBookingOrderStore();

const onBookCar = async (vehicleCount: number, price: number, routeVehiclePriceId: number) => {
    carInfo.value.vehicleCount = vehicleCount;
    carInfo.value.vehicleId = props.data.id as string;
    carInfo.value.name = props.data.name;
    carInfo.value.price = price;
    carInfo.value.routeVehiclePriceId = routeVehiclePriceId;
    currStep.value += 1;
};

const orderAttributeMap = (attributeMap: IRecord) => {
    const persons = [];
    const carrys = [];
    const suitcases = [];
    for (const key in attributeMap) {
        if (['Passengers'].includes(key)) {
            persons.push({
                icon: UserFilled,
                value: `${attributeMap[key]} ${key}`
            });
        } else if (~key.indexOf('Suitcase')) {
            suitcases.push({
                icon: Briefcase,
                value: `${attributeMap[key]} ${key}`
            });
        } else if (['Hand carry per pax'].includes(key)) {
            carrys.push({
                icon: GoodsFilled,
                value: `${attributeMap[key]} ${key}`
            });
        }
    }
    return [...persons, ...suitcases, ...carrys];
};
</script>

<style lang="scss" scoped></style>
