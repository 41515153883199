<template>
    <div class="w_100">
        <el-row class="mg-b_10">
            <el-col :span="5">{{ $t('bookingList.type') }}：</el-col>
            <el-col :span="19">
                <el-select value-key="name" v-model="currVehicle" @change="onSelectChange">
                    <el-option
                        v-for="(item, index) in vehicles"
                        :key="index"
                        :label="item.name"
                        :value="item"
                    >
                        {{ item.id }}
                        {{ item.name }}
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row v-if="currVehicle">
            <el-col :span="5"> {{ $t('router.category') }}： </el-col>
            <el-col :span="19">
                <g-radio-group
                    :modelValue="routeVehiclePriceCategory"
                    :options="currVehicle.priceList"
                    @change="onRadioChange"
                ></g-radio-group>
            </el-col>
        </el-row>
    </div>
</template>

<script lang="ts" setup>
import { watch, ref } from 'vue';
import { useBookingOrderStore } from '@/stores';
import { routerRepositories } from '@/repositories';
import { ICarPrice, IVehicle } from '@ctypes';

const props = defineProps<{
    routeId: string;
    modelValue?: {
        vehicleName: string;
        routeVehiclePriceCategory: string;
    };
}>();

const emit = defineEmits<{
    'update:modelValue': [value: { vehicleName: string; routeVehiclePriceCategory: string }];
    change: [value: { vehicleName: string; routeVehiclePriceCategory: string }];
}>();

const { vehicleList, getAllVehicle } = useBookingOrderStore();

getAllVehicle();
const vehiclePriceList = ref<ICarPrice[]>([]);
const getVehiclePrice = async () => {
    if (props.routeId) {
        vehiclePriceList.value = await routerRepositories.getVehiclePriceByRouter(props.routeId);
        vehicles.value = vehicleList.value
            .map((vehicle) => {
                const priceList: ICarPrice[] = [];
                vehiclePriceList.value.forEach((vehiclePrice) => {
                    if (vehiclePrice.vehicleId === vehicle.id) {
                        priceList.push(vehiclePrice);
                    }
                });
                return {
                    ...vehicle,
                    priceList: priceList.map((item) => ({
                        label: item.category,
                        value: item.category
                    }))
                };
            })
            .filter((vehicle) => vehicle.priceList.length);
    }
};

const currVehicle = ref<IVehicle & { priceList?: { label: string; value: string }[] }>();
const routeVehiclePriceCategory = ref<string>(props.modelValue?.routeVehiclePriceCategory || '');

const vehicles = ref<(IVehicle & { priceList: { label: string; value: string }[] })[]>([]);
watch(
    () => props.modelValue,
    async () => {
        if (!vehicleList.value.length) {
            await getAllVehicle();
        }
        if (!vehiclePriceList.value.length) {
            await getVehiclePrice();
        }
        currVehicle.value =
            vehicles.value.find((item) => item.name === props.modelValue?.vehicleName) || undefined;
    },
    { immediate: true }
);
const onSelectChange = () => {
    routeVehiclePriceCategory.value = '';
    emitModelValue();
};

const onRadioChange = (value: string) => {
    routeVehiclePriceCategory.value = value;
    emitModelValue();
};

const emitModelValue = () => {
    emit('update:modelValue', {
        vehicleName: currVehicle.value?.name || '',
        routeVehiclePriceCategory: routeVehiclePriceCategory.value
    });
    emit('change', {
        vehicleName: currVehicle.value?.name || '',
        routeVehiclePriceCategory: routeVehiclePriceCategory.value
    });
};
</script>
