<template>
    <g-page-table
        operationTypes="add"
        :title="$t('paymentList.title')"
        :options="tableOptions"
        :updateFormOptions="tableOptions"
        :pageable="false"
        :api-service="getPaymentListService"
        :add-api-service="addPaymentService"
    ></g-page-table>
</template>

<script lang="ts" setup>
import { useVueI18n } from '@use';
import { useBookingStore } from '@/stores';
import { IPayment } from '@/common/types';

const { tJoin } = useVueI18n();
const tableOptions: ITableOption[] = [
    { label: tJoin('paymentList.payMethod'), prop: 'payMethod', props: { width: 150 } },
    {
        label: tJoin('paymentList.amount'),
        prop: 'amount',
        component: 'gPrice',
        decorate: 'gPrice',
        decorateProps: (record: IPayment) => {
            return {
                price: record.amount,
                currency: record.currency
            };
        },
        props: {
            isEdit: true
        }
    },
    {
        label: tJoin('paymentList.payDate'),
        prop: 'payTime',
        component: 'elDatePicker',
        props: {
            width: 180,
            type: 'datetime',
            valueFormat: 'x'
        },
        formatValue: (record: IPayment, value: number) =>
            new Date(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
        label: tJoin('paymentList.note'),
        prop: 'notes',
        props: {
            type: 'textarea'
        }
    }
];

const { getPaymentList, addPayment } = useBookingStore();
const getPaymentListService = async () => {
    const data = await getPaymentList();
    console.log(data);
    return {
        records: data
    };
};
const addPaymentService = async (data: IPayment) => {
    await addPayment(data);
};
</script>
