import { ref } from 'vue';
import type { Component } from 'vue';
import { useRouter } from 'vue-router';
import { User, Edit, Delete } from '@element-plus/icons-vue';
import { useVueI18n } from '@use';
import { OperationEnum } from '@enums';

export const useOptions = () => {
    const getBaseProps = (component: string) => {
        switch (component) {
            case 'elDatePicker':
                return {
                    format: 'YYYY-MM-DD',
                    valueFormat: 'x'
                };
            case 'elTimePicker':
                return {
                    format: 'HH:mm',
                    valueFormat: 'HHmm'
                };
            default:
                return {};
        }
    };

    const switchComponent = (component: string | Component) => {
        if (typeof component === 'string') {
            switch (component) {
                case 'elSelect':
                    return 'gSelect';
                case 'elRadioGroup':
                    return 'gRadioGroup';
                default:
                    return component;
            }
        }
        return component;
    };
    return {
        getBaseProps,
        switchComponent
    };
};

export const useOperations = (path?: string, idKey?: string) => {
    const { tJoin } = useVueI18n();
    const { push } = useRouter();
    const operationType = ref<OperationEnum>(OperationEnum.ADD);
    const isUpdate = ref(false);
    const currRow = ref();

    // 操作列颜色
    const getOperationType = (type: string) => {
        switch (type) {
            case 'check':
                return 'primary';
            case 'edit':
                return 'primary';
            case 'delete':
                return 'danger';
            default:
                return '';
        }
    };

    const onAdd = () => {
        currRow.value = {};
        operationType.value = OperationEnum.ADD;
        isUpdate.value = true;
    };

    const onEdit = (row: Record<string, any>): void => {
        currRow.value = row;
        operationType.value = OperationEnum.EDIT;
        isUpdate.value = true;
    };

    const onCheck = async (row: Record<string, any>) => {
        currRow.value = row;
        const id = row[idKey || 'id'];
        push(`${path}/${id}`);
    };

    const onDelete = async () => {};

    const operationOptions: ITableOperation[] = [
        {
            label: tJoin('check'),
            type: 'check',
            icon: User,
            props: {
                onClick: onCheck
            }
        },
        {
            label: tJoin('edit'),
            type: 'edit',
            icon: Edit,
            props: {
                onClick: onEdit
            }
        },
        {
            label: tJoin('delete'),
            type: 'delete',
            icon: Delete,
            props: {
                onClick: onDelete
            }
        }
    ];
    return {
        currRow,
        isUpdate,
        operationType,
        operationOptions,
        onAdd,
        getOperationType
    };
};

export const useRequest = () => {
    const handleCommonParams: IFunction<IRecord, IRecord> = (data: IRecord = {}) => {
        const params: IRecord = { ...data };
        if (Array.isArray(params.createTime) && params.createTime.length === 2) {
            console.log(params.createTime);
            [params.startCreateTime, params.endCreateTime] = params.createTime;
            delete params.createTime;
        }
        return {
            ...params
        };
    };
    return {
        handleCommonParams
    };
};
