import { get, post, put, del } from '@/repositories/base';
import { MEET_POINT_URL } from '@/common/enums/repositories';
import { IMeetingPoint } from '@ctypes';

/**
 * 获取见面地点列表
 */
export const getMeetingPointList = async (data: Record<string, any>) => {
    return get<Record<string, any>, IListResponse<IMeetingPoint>>({
        url: MEET_POINT_URL,
        data
    });
};

/**
 * 获取所有见面地点列表
 */
export const getAllMeetingPointList = async () => {
    return get<undefined, IMeetingPoint[]>({
        url: `${MEET_POINT_URL}/all`
    });
};

/**
 * 添加见面地点
 */
export const addMeetingPoint = async (data: IMeetingPoint) => {
    return post<IMeetingPoint, string>({
        url: MEET_POINT_URL,
        data
    });
};

/**
 * 根据id获取见面地点
 */
export const getMeetingPointById = async (id: string) => {
    return get<undefined, IMeetingPoint>({
        url: `${MEET_POINT_URL}/${id}`
    });
};

/**
 * 更新见面地点
 */
export const updateMeetingPoint = async (id: string, data: Partial<IMeetingPoint>) => {
    return put<Partial<IMeetingPoint>, IMeetingPoint>({
        url: `${MEET_POINT_URL}/${id}`,
        data
    });
};

/**
 * 删除见面地点
 */
export const delMeetingPoint = async (id: string) => {
    return del<undefined, IMeetingPoint>({
        url: `${MEET_POINT_URL}/${id}`
    });
};
