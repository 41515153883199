<template>
    <div ref="menuRef" class="w_100 h_100 flex">
        <menu-list></menu-list>
        <div class="flex1 h_100 w_0 pd_10 body-bg of-a">
            <router-view></router-view>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useMenuStore } from '@/stores';
import MenuList from '@/components/menu/MenuList.vue';

const menuRef = ref<HTMLElement>();

const { containerHeight } = useMenuStore();

onMounted(() => {
    if (menuRef.value) {
        const { height } = menuRef.value.getBoundingClientRect();
        console.log(height);
        containerHeight.value = height;
    }
});
</script>
