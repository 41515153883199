<template>
    <el-text v-bind="$attrs" v-if="dateTime">
        {{ dateTime }}
    </el-text>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
        timestamp: string | number;
        format?: string;
    }>(),
    {
        format: 'YYYY-MM-DD'
    }
);

const dateTime = computed(() =>
    props.timestamp ? new Date(props.timestamp).format(props.format) : ''
);
</script>
