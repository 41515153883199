<template>
    <header class="h_60p flex flex-between pd-x_10 bs bd_1">
        <div class="flex h_100">
            <slot name="logo"></slot>
            <slot name="left"></slot>
        </div>
        <div class="flex">
            <slot name="right"></slot>
        </div>
    </header>
</template>

<script lang="ts" setup></script>
