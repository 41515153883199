<template>
    <g-avatar
        :model-value="currUserInfo?.avatarImg"
        :upload="canEditCurrUserInfo && (isSameUser || isAdmin)"
        @change="onChanegAvatar"
    ></g-avatar>
    <div class="relative">
        <h4>{{ currUserInfo?.firstName }} {{ currUserInfo?.lastName }}</h4>
        <el-tag
            v-if="currUserInfo?.jobTitle"
            class="absolute-r_o right_10-o"
            :type="RoleColorEnum[currUserInfo.jobTitle]"
            >{{ $t(`role.${currUserInfo.jobTitle}`) }}</el-tag
        >
    </div>
    <div>
        <div class="flex flex-left mg-x_10">
            <el-icon class="mg-r_5"><Message /></el-icon>
            {{ currUserInfo?.email }}
        </div>
        <div class="flex flex-left mg-x_10">
            <el-icon class="mg-r_5"><Phone /></el-icon>
            {{ currUserInfo?.phone || '--' }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Message, Phone } from '@element-plus/icons-vue';
import { useUserStore } from '@/stores';
import { RoleColorEnum } from '@enums';

const { currUserInfo, isSameUser, isAdmin, canEditCurrUserInfo, updateUserInfo } = useUserStore();

const onChanegAvatar = async (base64: string) => {
    if (currUserInfo.value) {
        const res = await updateUserInfo(currUserInfo.value.id, {
            avatarImgDataURI: base64
        });
        console.log(res);
        location.reload();
    }
};
</script>
