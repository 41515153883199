import { ref, computed } from 'vue';
import { useRoute } from '@use';
import { defineStore, storeToRefs } from 'pinia';

export const usePageTableStore = () => {
    const store = defineStore('pageTable', () => {
        const pageMap = ref(new Map<string, IPage>([]));

        const setCachePage = (page: IPage, name: string) => {
            pageMap.value.set(name, page);
            console.log(pageMap);
        };

        const getCachePage = (name: string): IPage => {
            const { Route } = useRoute();
            if (pageMap.value.get(name || (Route.name as string))) {
                return pageMap.value.get(name)!;
            }
            return {
                pageNum: 1,
                pageSize: 10
            };
        };
        return {
            setCachePage,
            getCachePage
        };
    });
    return {
        ...store(),
        ...storeToRefs(store())
    };
};
