<template>
    <el-table
        class="w_100"
        :data="tableData"
        border
        :cell-style="{
            verticalAlign: 'top'
        }"
    >
        <el-table-column prop="policy" label="Cancellation and Amendment Policy" width="200">
            <template #default="{ row }">
                <p class="mg-y_10">{{ row.policy }}</p>
            </template>
        </el-table-column>
        <el-table-column prop="fee" label="Fees and Procedures">
            <template #default="{ row }">
                <pre class="mg-y_10" v-html="row.fee"></pre>
            </template>
        </el-table-column>
    </el-table>
</template>

<script lang="ts" setup>
const tableData = [
    {
        policy: '1) If Notice of Cancellation or Amendment is received 8 days (192 hours) or more prior to the Service date',
        fee: 'No Charge Please amend or cancel booking through system.Confirmation email will be sent by Cherry Tomato within 24 hours to 48 hours upon receipt of amendment or cancellation.'
    },
    {
        policy: '2) If Notice of Cancellation or Amendment is received 2 days (48 hours) to 7 days (168 hours) prior to the Service date',
        fee: `<b>10% of the transfer fee</b> Please amend or cancel booking by writing to us via email or by call.
(i) Email info@cherrytomato.co.jp
(ii) Call Office Telephone number (available from 0900 till 1800 hrs) +81-3-3226-3238 (If call from overseas mobile phone) (Country Exit Code) 81-3-3226-3238 (If call from landline outside Japan) 03-3226-3238 (If call from local mobile phone or landline in Japan) 3226-3238 (If call from landline in Tokyo)
(iii) Call 24 Hour Emergency contact (1900 till 2400 hrs) +81-90-8777-9967 (If call from overseas mobile phone) (Country Exit Code) 81-90-8777-9967 (If call from landline outside Japan) 090-8777-9967 (If call from local mobile phone or landline in Japan) Confirmation email will be sent by Cherry Tomato within 24 hours upon receipt of amendment or cancellation.`
    },
    {
        policy: '3) If Notice of Cancellation or Amendment is received more than 1 day (24hours) prior to the Service time',
        fee: `<b>40% of the transfer fee</b> Please amend or cancel booking by writing to us via email or by call.
(i) Email info@cherrytomato.co.jp
(ii) Call Office Telephone number (available from 0900 till 1800 hrs) +81-3-3226-3238 (If call from overseas mobile phone) (Country Exit Code) 81-3-3226-3238 (If call from landline outside Japan) 03-3226-3238 (If call from local mobile phone or landline in Japan) 3226-3238 (If call from landline in Tokyo)
(iii) Call 24 Hour Emergency contact (1900 till 2400 hrs) +81-90-8777-9967 (If call from overseas mobile phone) (Country Exit Code) 81-90-8777-9967 (If call from landline outside Japan) 090-8777-9967 (If call from local mobile phone or landline in Japan) Confirmation email will be sent by Cherry Tomato within 24 hours upon receipt of amendment or cancellation. Confirmation via email will be sent by Cherry Tomato within 24 hours amendment or cancellation.`
    },
    {
        policy: '4) If Notice of Cancellation or Amendment is received less than 1 day (24hours) prior to the Service time',
        fee: `<b>80% of the transfer fee</b> Please amend or cancel booking by writing to us via email or by call.
(i) Email info@cherrytomato.co.jp
(ii) Call Office Telephone number (available from 0900 till 1800 hrs) +81-3-3226-3238 (If call from overseas mobile phone) 81-3-3226-3238 (If call from landline outside Japan) 03-3226-3238 (If call from local mobile phone or landline in Japan) 3226-3238 (If call from landline in Tokyo)
(iii) Call 24 Hour Emergency contact (1900 till 2400 hrs) +81-90-8777-9967 (If call from overseas mobile phone) 81-90-8777-9967 (If call from landline outside Japan) 090-8777-9967 (If call from local mobile phone or landline in Japan)`
    },
    {
        policy: '5) If cancellation or amendment notice arrives at Cherry Tomato on the service date / check in date or no cancellation or amendment notice is received (No Show) / 100% of the tour fare',
        fee: `<b>100% of the transfer fee</b>`
    }
];
</script>
