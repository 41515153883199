<template>
    <div>
        <p v-if="value" class="remark cs-p">
            {{ value }}
        </p>
        <el-button
            v-if="!isCarCompany && !isDriver"
            :icon="Edit"
            @click="
                isEdit = true;
                modelValue = value;
            "
            link
        ></el-button>
    </div>
    <el-dialog
        destroy-on-close
        :title="$t(`remark.${prop}`)"
        v-model="isEdit"
        append-to-body
        @close="onClose"
    >
        <el-input v-model="modelValue" type="textarea"></el-input>
        <template #footer>
            <el-button @click="isEdit = false">{{ $t('cancel') }}</el-button>
            <el-button @click="updateBooking" type="primary" :loading="isLoading">{{
                $t('confirm')
            }}</el-button>
        </template>
    </el-dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { Edit } from '@element-plus/icons-vue';
import { useUserStore } from '@/stores';
import { bookingRepositories } from '@/repositories';

const props = defineProps<{
    value?: string;
    id: string | number;
    prop: string;
}>();

const emit = defineEmits<{
    refresh: [];
}>();

const modelValue = ref(props.value);

const { isCarCompany, isDriver } = useUserStore();
watch(
    () => props.value,
    () => {
        modelValue.value = props.value;
    }
);
const isEdit = ref(false);
const isLoading = ref(false);
const updateBooking = async () => {
    isLoading.value = true;
    try {
        //         const remark = props.value
        //             ? `${props.value}
        // ${modelValue.value}`
        //             : modelValue.value;
        await bookingRepositories.updateOrder(`${props.id}`, props.prop, modelValue.value);
    } catch (e) {
        console.log(e);
    }
    isLoading.value = false;
    isEdit.value = false;
    emit('refresh');
};

const onClose = () => {
    modelValue.value = '';
};
</script>

<style lang="scss" scoped>
.dialogRemark {
    max-height: 300px !important;
}
</style>
