export const LOGIN_URL = '/login';

export const USER_URL = '/user';

export const ROUTE_URL = '/route';

export const SIGN_OUT_URL = '/logout';

export const SIGN_IN_URL = '/login';

export const VEHICLE_URL = '/vehicle';

export const LOCATION_URL = '/location';
export const LOCATION_TYPE_URL = '/location/type';

export const ORDER_URL = '/order';
export const ORDER_EMAIL_URL = '/order/email';
export const ORDER_PDF_URL = '/order/pdf';

export const HOTEL_URL = '/hotel';

export const TEAM_USER_URL = '/team/user';

export const ROLE_TEAM_URL = '/role/team';

export const MEET_POINT_URL = '/meetingPoint';

export const AGENT_URL = '/agent';
export const CLIENT_GROUP_URL = '/agent/clientGroups';

export const PRE_ORDER = '/tempdata';
