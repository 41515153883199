import { provide, inject, Ref } from 'vue';
import type { InjectionKey } from 'vue';
import { ElForm } from 'element-plus';

export const formContextKey: InjectionKey<Ref<InstanceType<typeof ElForm> | undefined>> =
    Symbol('formContextKey');

export const useForm = () => {
    const formContext = inject(formContextKey);
    const setFormContext = (data: Ref<InstanceType<typeof ElForm> | undefined>) => {
        provide(formContextKey, data);
    };
    return {
        formContext,
        formContextKey,
        setFormContext
    };
};
