<template>
    <el-card class="w_80 mg-x_a">
        <template #header>
            <el-page-header @back="go(-1)"></el-page-header>
        </template>
        <div class="white">
            <user-wrap></user-wrap>
        </div>
        <user-base-info></user-base-info>
    </el-card>
</template>

<script lang="ts" setup>
import { watch } from 'vue';
import { useRouter, useRoute } from '@use';
import UserWrap from './userDetail/UserWrap.vue';
import UserBaseInfo from './userDetail/UserBaseInfo.vue';
import { useUserStore } from '@/stores';

const { Route, ROUTER_PATH_MAP } = useRoute();
watch(Route, () => {
    if (~Route.path.indexOf(ROUTER_PATH_MAP.MENU_TEAM_MEMBER_DETAIL) && Route.params.id) {
        getUserInfoById(Route.params.id as string);
    }
});
const { getUserInfoById } = useUserStore();
getUserInfoById(Route.params.id as string);
const { go } = useRouter();
</script>
