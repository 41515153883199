<template>
    <el-radio-group v-bind="$attrs">
        <component
            :is="radioItem"
            v-for="(option, index) in options"
            :key="index"
            v-bind="option"
            :label="option.value"
        >
            {{ toValue(option.label) }}
        </component>
    </el-radio-group>
</template>

<script lang="ts" setup>
import { toValue, computed } from 'vue';
const props = withDefaults(
    defineProps<{
        type?: 'button' | '';
        options: ILabelValue<string>[];
    }>(),
    {
        options: () => []
    }
);
const radioItem = computed(() => `el-radio${props.type ? `-${props.type}` : ''}`);
</script>
