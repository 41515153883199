<template>
    <div ref="tagLabelRef" class="tag absolute white" :class="[`${type}-bg`]">
        {{ label || '' }}
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

defineProps<{
    label?: string;
    type?: string;
}>();

const tagLabelRef = ref<HTMLElement>();
let parentOriginPosition = '';
onMounted(() => {
    if (tagLabelRef.value && tagLabelRef.value.parentElement) {
        parentOriginPosition = tagLabelRef.value.parentElement.style.position || '';
        tagLabelRef.value.parentElement.style.position = 'relative';
    }
});
onBeforeUnmount(() => {
    if (tagLabelRef.value && tagLabelRef.value.parentElement) {
        tagLabelRef.value.parentElement.style.position = parentOriginPosition;
    }
});
</script>

<style lang="scss" scoped>
.tag {
    position: absolute;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    width: 160px;
    height: 20px;
    top: 40px;
    left: -30px;
    text-align: center;
    clip-path: polygon(0 20px, 20px 0, 140px 0, 100% 100%);
    z-index: 1000;
}
</style>
